// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import StatisticsServices from './StatisticsServices';

export default compose(
  connect(
    state => ({
      country: state?.global?.country || [],
    }),
    dispatch => ({}),
  ),
)(StatisticsServices);
