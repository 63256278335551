import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Grid,
  Button,
  Typography
} from "@mui/material";
import {
   withStyles
} from "@mui/styles";
import {
  FilterUsers,
  FilterDateRangeFull
} from "../../../../../../components";
import {
  Notification,
  NotificationTypes
} from "../../../../../../common/Notification";
import moment from "moment/moment";


const initForm = {
  "filter[created_at][gt]": moment().format("YYYY-MM-DD HH:mm:ss"),
  "filter[created_at][lt]": moment().format("YYYY-MM-DD HH:mm:ss"),
  "filter[user_id]": ""
}

class DialogForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {...initForm},
      isOpen: false,

      onSubmit: null
    }
  }

  open = ({ onSubmit }) => {
    this.setState({
      form: {...initForm},
      isOpen: true,
      onSubmit
    })
  }
  close = () => {
    this.setState({
      isOpen: false
    })
  }

  changeForm = ({ target }) => {
    const { name, value } = target;

    let form = {...this.state.form};
    form[name] = value;

    this.setState({ form })
  }
  changeFormRange = (values) => {
    let form = {...this.state.form};
    form['filter[created_at][gt]'] = moment(values[0]).format("YYYY-MM-DD HH:mm:ss");
    form['filter[created_at][lt]'] = moment(values[1]).format("YYYY-MM-DD HH:mm:ss");
    this.setState({ form })
  }

  onSubmit = () => {
    const { form } = this.state;
    if (!form['filter[user_id]']) {
      Notification({
        message: "Выберите пользователя",
        type: NotificationTypes.error
      })

      return
    }
    this.state.onSubmit(form, true)
  }

  render () {
    const {
      form,
      isOpen
    } = this.state;
    const {
      classes
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth
        onClose={this.close}
      >
        <DialogTitle sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
          <Typography variant="h3" sx={{color: "white"}}>Создание компенсации</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} py={2}>
            <Grid item xs={12}>
              <FilterUsers
                value={form['filter[user_id]']}
                name="filter[user_id]"
                size="small"
                valueName="id"
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={12} className={classes.dateRange}>
              <FilterDateRangeFull
                value={[form['filter[created_at][gt]'], form['filter[created_at][lt]']]}
                onChange={this.changeFormRange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justifyContent="flex-end" px={1}>
            <Grid item>
              <Button
                variant="outlined"
                sx={{minHeight: "initial", height: "auto", padding: "5px 20px", borderRadius: "4px", textTransform: "initial"}}
                onClick={this.close}
              >
                Закрыть
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{minHeight: "initial", height: "auto", padding: "5px 20px", borderRadius: "4px", textTransform: "initial"}}
                onClick={this.onSubmit}
              >
                Создать
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = {
  dateRange: {
    "& .daterangepicker": {
      position: "fixed",
      top: "50%!important",
      left: "50%!important",
      transform: "translate(-50%, -50%)!important"
    }
  }
}
DialogForm = withStyles(styles)(DialogForm);

export default DialogForm
