import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Grid,
	Tooltip,
	Skeleton,
	Typography,
	IconButton
} from "@mui/material";
import {
	Edit as EditIcon
} from "@mui/icons-material";

const TableCustom = (props) => {
	const {
		data,
		isLoad,

		onEdit
	} = props;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell width={100}>Приоритет</TableCell>
					<TableCell width={200}>Поставщик</TableCell>
					<TableCell>Сервисы</TableCell>
					<TableCell align="right"/>
				</TableRow>
			</TableHead>
			<TableBody>

				<VisibleContent visible={!isLoad}>
					{(data || []).map((provider) => (
						<TableRowCustom
							key={`provider-${ provider.id }`}
							provider={provider}
							onEdit={onEdit}
						/>
					))}
				</VisibleContent>
				<VisibleContent visible={isLoad}>
					{['load1','load2','load3','load4','load5',].map((item) => (
						<TableRow key={item}>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
						</TableRow>
					))}
				</VisibleContent>

			</TableBody>
		</Table>
	)
}
const VisibleContent = React.memo(({ children, visible }) => {
	if (!visible) {
		return null
	}

	return children
});
const TableRowCustom = React.memo((props) => {
	const {
		provider,
		onEdit
	} = props;
	return (
		<TableRow>
			<TableCell>{ provider.priority }</TableCell>
			<TableCell>{ provider.name }</TableCell>
			<TableCell>
				<Grid container flexDirection="column">
					<Grid item>Кол-во включенных: <Typography color="primary" component="span" fontWeight="600">{provider?.service_count_on} шт.</Typography></Grid>
					<Grid item>Кол-во отключенных: <Typography color="primary" component="span" fontWeight="600">{provider?.service_count_off} шт.</Typography></Grid>
				</Grid>
			</TableCell>
			<TableCell align="right">
				<Tooltip title="Редактировать" arrow>
					<IconButton color="primary" onClick={onEdit.bind(this, provider.id, null, false)}>
						<EditIcon/>
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	)
})

export default React.memo(TableCustom)
