import { Box, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from "react";

class UserInfo extends React.PureComponent {
    render() {
        const {
            classes,
            user,
        } = this.props;

        const userSettings = user?.user_settings || {};

        return (
            <Box className={classes.root}>

                <Typography variant='h3'>Основная информация</Typography>
                <Grid marginTop={1} container rowSpacing={2} className={classes.gridRoot}>
                    <Grid item xs={6}>
                        <Box>
                            <Typography className={classes.title}>Email:</Typography>
                            <Typography className={classes.item}>{user?.email || "-"}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box>
                            <Typography className={classes.title}>Баланс:</Typography>
                            <Typography className={classes.item}>{user?.balance || "-"}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box>
                            <Typography className={classes.title}>Дата регистрации:</Typography>
                            <Typography className={classes.item}>{user?.created_at || "-"}</Typography>
                        </Box>
                    </Grid>

                    {Object.keys(userSettings).map((key) => {
                        const item = userSettings?.[key] || "";

                        return (
                            <Grid item xs={6}>
                                <Box>
                                    <Typography className={classes.title}>{`${key}: `}</Typography>
                                    <Typography className={classes.item}>{`${item || "-"} `}</Typography>
                                </Box>
                            </Grid>

                        )
                    })}

                </Grid>
            </Box>


        )
    }
}

const styles = {
    root: {
        background: "white",
        borderRadius: "8px",
        padding: "18px 24px",
        marginTop: "16px",
    },

    gridRoot: {
        "& > *": {
            display: "flex",

        },
        "& > *:nth-child(even)": {
            justifyContent: "flex-end",
            "& > div": {
                textAlign: "end"
            }
        },
    },

    title: {
        fontSize: 16,
        opacity: 0.6,
        marginBottom: "4px",
    },
    item: {
        fontSize: 18,
    },
};

export default withStyles(styles)(UserInfo)