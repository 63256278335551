export default [

    {
        id: 1,
        label: "Русский"
    },

    {
        id: 2,
        label: "English"
    },

]
