import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Typography,
    Pagination,

    Skeleton, Avatar
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";

import {
    CheckCircle as CheckCircleIcon,
    Clear as ClearIcon
} from "@mui/icons-material";
import {
    TableHeadSortCell
} from "../../../../../../components";
import moment from "moment";

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,

        isLoad,

        onChangeFilter,
        onChangePagination,
    } = props;
    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = { ...pagination };
        newPagination.page = page;

        onChangePagination(newPagination)
    }
    const handleChangeSort = (sort) => {
        let newFilter = { ...filter };
        newFilter.sort = sort;

        onChangeFilter(newFilter, true);
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>

                        <TableHeadSortCell
                            name="updated_at"
                            filterSort={filter.sort}
                            label="Дата/время"

                            onChange={handleChangeSort}
                        />
                        <TableCell>Страна</TableCell>
                        <TableCell>Услуга</TableCell>
                        {/* <TableHeadSortCell
                            label="Сумма"
                            name="amount"
                            filterSort={filter.sort}

                            onChange={handleChangeSort}
                        /> */}

                        <TableCell>Прошлый статус</TableCell>
                        <TableCell>Новый статус</TableCell>

                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoad) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {
                                    data.length ? (

                                        data.map((row, index) => (

                                            <TableRow key={`row-item-${index}`}>

                                                <TableCell>
                                                    {moment(row.created_at).format("DD.MM.YYYY / HH:mm")}
                                                </TableCell>

                                                <TableCell>
                                                    {Boolean(row.country) ? (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <Avatar src={row.country?.icon} sx={{ width: 24, height: 24 }} />
                                                            </Grid>
                                                            <Grid item>
                                                                {row.country?.name}
                                                            </Grid>
                                                        </Grid>
                                                    ) : "—"}
                                                </TableCell>

                                                <TableCell>
                                                    {Boolean(row.country) ? (
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <Avatar src={row.service?.icon} sx={{ width: 24, height: 24 }} />
                                                            </Grid>
                                                            <Grid item>
                                                                {row.service?.name}
                                                            </Grid>
                                                        </Grid>
                                                    ) : "—"}
                                                </TableCell>

                                                <TableCell>
                                                    <Typography sx={{ color: Boolean((row.amount || "")?.[0] === "-") ? "#DD4321" : "#379E3B" }}>

                                                        {Boolean(Number(row?.model_old_attributes?.['Статус'])) ? (
                                                            <CheckCircleIcon sx={{ color: "green" }} />
                                                        ) : (
                                                            <ClearIcon sx={{ color: "red" }} />
                                                        )}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography sx={{ color: Boolean((row.amount || "")?.[0] === "-") ? "#DD4321" : "#379E3B" }}>

                                                        {Boolean(Number(row?.model_attributes?.['Статус'])) ? (
                                                            <CheckCircleIcon sx={{ color: "green" }} />
                                                        ) : (
                                                            <ClearIcon sx={{ color: "red" }} />
                                                        )}
                                                    </Typography>
                                                </TableCell>


                                                <TableCell align="right" />

                                            </TableRow>

                                        )))
                                        : (<TableRow >
                                            <TableCell colSpan='5'>
                                                <Box>

                                                    <Typography sx={{ fontWeight: "600", fontSize: "24px", textAlign: "center", mt: "10px", mb: "10px" }}>
                                                        Нет совпадений
                                                    </Typography>

                                                </Box>
                                            </TableCell>
                                            <TableCell align="right" />
                                        </TableRow>)
                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>
            <Box mt={3} />

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {}

}));

export default TableCustom
