import React from "react";
import {
  Box,
  Grid,
  Button,
  Collapse,
  Typography, InputLabel, Select, MenuItem, FormControl,
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Clear as ClearIcon,
  Search as SearchIcon,
  FilterAlt as FilterAltIcon
} from "@mui/icons-material";
import {
  FilterCountry,
  FilterDateRangeFull, FilterService
} from "../../../../../../components";

const Filter = (props) => {
  const {
    filter,
    onReset,
    onSearch,
    onChange,
    onCreate
  } = props;
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(true);

  const handleChange = ({target}) => {
    const {name, value} = target;

    let newFilter = {...filter};
    newFilter[name] = value;

    onChange(newFilter);
  }
  const handleChangeDateRange = (values) => {
    let newFilter = {...filter};

    newFilter["filter[created_at][gt]"] = values[0] || null;
    newFilter["filter[created_at][lt]"] = values[1] || null;

    onChange(newFilter);
  }

  return (
    <Box mb={1}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">Фильтры</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                onClick={onCreate.bind(this, null)}
              >
                Добавить
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                onClick={() => setOpen(!isOpen)}
                endIcon={<FilterAltIcon/>}
              >
                {isOpen ? "Свернуть" : "Развернуть"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                onClick={onReset}
                endIcon={<ClearIcon/>}
              >
                Сбросить
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                endIcon={<SearchIcon/>}
                onClick={onSearch}
              >
                Поиск
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box mt={1}/>
        <Box px={2} py={2} bgcolor="white" borderRadius={4}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <FilterCountry
                name="filter[countries_id]"
                value={filter["filter[countries_id]"]}
                size="small"
                fullWidth
                multiple

                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <FilterService
                name="filter[services_code]"
                value={filter["filter[services_code]"]}
                size="small"
                fullWidth
                multiple

                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Статус</InputLabel>
                <Select
                  value={filter['filter[active]']}
                  name="filter[active]"
                  label="Статус"
                  size="small"
                  onChange={handleChange}
                >
                  <MenuItem value="">Сбросить</MenuItem>
                  <MenuItem value="1">Активный</MenuItem>
                  <MenuItem value="0">Отключенный</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} className={classes.dateTimePicker}>
              <FilterDateRangeFull
                value={[
                  filter['filter[created_at][gt]'],
                  filter['filter[created_at][lt]'],
                ]}
                onChange={handleChangeDateRange}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  )
}

const useStyles = makeStyles(() => ({

  active: {
    display: "flex",
    alignItems: "center",
    padding: "2px 12px",
    borderRadius: 6,
    backgroundColor: "#FB7800",
    cursor: "pointer",

    fontSize: 15,
    lineHeight: "22px",
    fontWeight: "600"
  },
  activeLabel: {
    opacity: 0.6,
    fontSize: 15,
    lineHeight: "22px",
    fontWeight: "600",
    color: "white"
  },
  activeValue: {
    fontSize: 15,
    lineHeight: "22px",
    fontWeight: "600",
    color: "white",
    marginLeft: 6
  },

  dateTimePicker: {
    "& .daterangepicker": {
      "& .applyButton": {
        backgroundColor: "rgba(251,120,0, .8) !important",
        border: "none !important",
        "&:hover": {
          backgroundColor: "#FB7800 !important",
        }
      },
      "& .fromDateHourContainer": {
        border: "none !important",
      },
      "& .inputDate": {
        borderWidth: "2px !important",
        height: "33px !important",
        "&:focus": {
          borderColor: "#FB7800 !important",
          boxShadow: "none !important",
        }
      },
      "& .calendarAddon.input-group-addon": {
        borderWidth: "2px !important",
      },
      "& .multipleContentOnLine": {
        outline: "none !important",
        "& select": {
          height: "33px !important",
          borderRadius: "4px",
          borderWidth: "2px",
          outline: "none",
          borderColor: "#ccc",
          "&:focus": {
            borderColor: "#FB7800 !important",
            outline: "none !important",
          }
        }
      },
      "& .monthYearContainer": {
        alignItems: "center",
      },
      "& .timeIconStyle": {
        top: "50%",
        transform: "translate(0, -50%)",
      },
      "& .glyphicon": {
        color: "#FB7800 !important"
      },
      "& .rangecontainer": {
        "& div": {
          outline: "none !important",
        }
      },
      "& .calendarGrid": {
        "& div": {
          outline: "none !important",
        }
      }
    }
  }

}));

export default React.memo(Filter)
