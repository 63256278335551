import React from "react";
import {
  Box,
  Grid,
  Popover,
  Container,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";
import {Link, withRouter} from "react-router-dom";
import clsx from "clsx";
import PopupState, {bindTrigger, bindPopover} from "material-ui-popup-state";
import {compose} from "recompose";
import {connect} from "react-redux";
import CurrentTimeZone from "./CurrentTimeZone";

class Header extends React.PureComponent {

  componentDidMount() {
    this.props.onSetHeaderItems(this._menu());
  }

  _menu = () => {
    const {userRole} = this.props;
    const mathPath = this.props?.match?.path || "";

    let menu = [
      {
        sort: 0,
        iconPath: require("../../../../assets/svg/header/transactions.svg").default,
        label: "Финансы",
        path: "/transactions",
        isActive: Boolean(mathPath.indexOf("/transactions") > -1),
        roles: ['Bookkeeper', 'Support Service', 'Support Service High', 'Administrator']
      },
      {
        sort: 1,
        iconPath: require("../../../../assets/svg/header/financial-information.svg").default,
        label: "Транзакции",
        path: "/numbers",
        isActive: Boolean(mathPath.indexOf("/numbers") > -1),
        roles: ['Bookkeeper', 'Support Service', 'Support Service High', 'Administrator']
      },
      {
        sort: 2,
        iconPath: require("../../../../assets/svg/header/users.svg").default,
        label: "Пользователи",
        path: "/users",
        isActive: Boolean(mathPath.indexOf("/users") > -1),
        roles: ['Support Service', 'Support Service High', 'Administrator']
      },
      {
        sort: 5,
        iconPath: require("../../../../assets/svg/header/users.svg").default,
        label: "РП",
        isActive: Boolean(
          mathPath.indexOf("/vivod-sredstv") > -1 ||
          mathPath.indexOf("/register-balances") > -1
        ),
        items: [
          {label: "Вывод средств", path: "/vivod-sredstv", roles: ['Support Service', 'Support Service High', 'Administrator']},
          {label: "Реестр балансов", path: "/register-balances", roles: ['Support Service', 'Support Service High', 'Administrator']},
        ],
      },
      {
        sort: 8,
        iconPath: require("../../../../assets/svg/header/logs.svg").default,
        label: "Логи",
        path: "/logs",
        items: [
          // {label: "Логирование", path: "/logs", roles: ['Support Service High', 'Administrator']},
          // {label: "Журнал изменений", path: "/changelog", roles: ['Support Service High', 'Administrator']},
          {label: "Запросы", path: "/number-requests", roles: ['Support Service', 'Support Service High', 'Administrator']},
          // {label: "Недоступные номера", path: "/services-statuses", roles: ['Support Service High', 'Administrator']},
          {label: "Экспресс проверка остатков", path: "/logs/express-check-balances", roles: ['Support Service', 'Support Service High', 'Administrator']},
        ],
        isActive: Boolean(
          mathPath.indexOf("/logs") > -1 ||
          mathPath.indexOf("/number-requests") > -1 ||
          mathPath.indexOf("/changelog") > -1 ||
          mathPath.indexOf("/logs/express-check-balances") > -1 ||
          mathPath.indexOf("/services-statuses") > -1
        )
      },
      {
        sort: 9,
        iconPath: require("../../../../assets/svg/header/prices.svg").default,
        label: "Поставщики",
        path: "/go-providers",
        items: [
          {label: "Поставщики", path: "/go-providers", roles: ['Administrator']},
          {label: "Управление странами", path: "/go-providers/country-governance", roles: ['Administrator']},
          {label: "Управление сервисами", path: "/go-providers/service-management", roles: ['Administrator']},
          {label: "Управление пользователями", path: "/go-providers/user-management", roles: ['Administrator']},
          {label: "Статистика", path: "/go-providers/prstatistics", roles: ['Support Service', 'Administrator']},
          // {label: "Настройки", path: "/settings", roles: ['Administrator']},
          {label: "Балансы поставщиков", path: "/go-providers/supplier-balance", roles: ['Administrator']},
          {label: "Вывод протокольщиков", path: "/go-providers/minutemen-balance", roles: ['Administrator']},
          {label: "Вывод за аккаунты", path: "/go-providers/create-payout", roles: ['Administrator']},
          {label: "Блокировка и фильтрация сервисов", path: "/blocking-filtering-services", roles: ['Administrator']},
        ],
        isActive: Boolean(
          mathPath.indexOf("/go-providers") > -1 ||
          mathPath.indexOf("/go-providers/country-governance") > -1 ||
          mathPath.indexOf("/go-providers/service-management") > -1 ||
          mathPath.indexOf("/go-providers/user-management") > -1 ||
          mathPath.indexOf("/go-providers/prstatistics") > -1 ||
          mathPath.indexOf("/go-providers/supplier-balance") > -1 ||
          mathPath.indexOf("/go-providers/minutemen-balance") > -1 ||
          mathPath.indexOf("/blocking-filtering-services") > -1 ||
          mathPath.indexOf("/settings") > -1
        )
      },
      {
        sort: 7,
        iconPath: require("../../../../assets/svg/header/statistics.svg").default,
        label: "Статистика",
        path: "/statistics",
        items: [
          // {label: "Статистика", path: "/statistics", roles: ['Support Service High', 'Administrator']},
          // {label: "Статистика сервисов", path: "/statistics-services", roles: ['Support Service High', 'Administrator']},
          {label: "Статистика платежных сервисов", path: "/statistics-payments", roles: ['Bookkeeper', 'Administrator']},
          // {label: "Статистика пользователей", path: "/statistics-users", roles: ['Support Service High', 'Administrator']},
          {label: "Аренда номеров", path: "/rented-numbers", roles: ['Support Service', 'Support Service High', 'Administrator']},
          {label: "Сводный отчет", path: "/statistics/summary-report-pnl", roles: ['Administrator']},
        ],
        isActive: Boolean(
          mathPath.indexOf("/statistics") > -1 ||
          mathPath.indexOf("/rented-numbers") > -1 ||
          mathPath.indexOf("/statistics-services") > -1 ||
          mathPath.indexOf("/statistics-payments") > -1 ||
          mathPath.indexOf("/statistics/summary-report-pnl") > -1 ||
          mathPath.indexOf("/statistics-users") > -1
        )
      },
      {
        sort: 3,
        iconPath: require("../../../../assets/svg/header/account-tree.svg").default,
        label: "Каскад",
        path: "/cascade/list",
        isActive: Boolean(
          mathPath.indexOf("/cascade/list") > -1 ||
          mathPath.indexOf("/cascade/services") > -1
        ),
        items: [
          {label: "Каскад", path: "/cascade/list", roles: ['Support Service High', 'Administrator']},
          {label: "Каскады стран и сервисов", path: "/cascade/services", roles: ['Support Service High', 'Administrator']},
        ]
      },
      {
        sort: 4,
        iconPath: require("../../../../assets/svg/header/users.svg").default,
        label: "Группы",
        path: "/user-groups",
        items: [
          {label: "Группы", path: "/common-user-groups", roles: ['Support Service High', 'Administrator']},
          {label: "Ценовые группы", path: "/user-groups", roles: ['Support Service High', 'Administrator']},
        ],
        isActive: Boolean(
          mathPath.indexOf("/common-user-groups") > -1 ||
          mathPath.indexOf("/user-groups") > -1
        )
      },
      {
        sort: 6,
        iconPath: require("../../../../assets/svg/header/prices.svg").default,
        label: "Цены",
        isActive: Boolean(
          mathPath.indexOf("/go-prices") > -1 ||
          mathPath.indexOf("/loyalty-ranks") > -1 ||
          mathPath.indexOf("/loyalty-program") > -1 ||
          mathPath.indexOf("/terms-loyalty-program-users") > -1 ||
          mathPath.indexOf("/supply-prices") > -1
        ),
        items: [
          {label: "Управление ценами", path: "/go-prices", roles: ['Support Service High', 'Administrator']},
          // {label: "Цены поставщиков", path: "/supply-prices", roles: ['Support Service High', 'Administrator']},
          {label: "Компенсации TG 2fa", path: "/compensation-tg-2fa", roles: ['Support Service High', 'Administrator']},
          {label: "Ранги лояльности", path: "/loyalty-ranks", roles: ['Support Service High', 'Administrator']},
          {label: "Начисления по программе лояльности", path: "/loyalty-program", roles: ['Support Service High', 'Administrator']},
          {label: "Условия программы лояльности<br/>для групп пользователей", path: "/terms-loyalty-program-users", roles: ['Support Service High', 'Administrator']},
        ]
      },
    ]
    menu = menu.map((t) => {
      const isChildren = Boolean((t?.items || []).length > 0);
      if (!isChildren) {
        return Boolean((t.roles || []).includes(userRole)) ? t : null
      }

      const children = t.items.filter((t) => (t.roles || []).includes(userRole));
      if (children.length <= 0) {
        return null
      }

      return {
        ...t,
        items: children
      }
    }).filter((t) => !!t);
    menu = menu.sort((a, b) => {
      if (a.sort > b.sort) {
        return 1
      }
      if (a.sort < b.sort) {
        return -1
      }
      return 0
    });

    return menu
  }

  render() {
    const {
      classes,
      userRole
    } = this.props;

    return (
      <Box className={classes.root}>
        <Grid container justifyContent="space-between">

          <Grid item>
            <Box className={classes.logo}>
              <Box className={classes.logoLabel}>admin</Box>
              <img src={require("../../../../assets/logos/header-logo.svg").default}/>
            </Box>
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              {
                this._menu().map((item, index) => (
                  <Grid item key={`menu-item-${index}`}>

                    {Boolean((item.items || []).length <= 0) && (
                      <Link to={item.path}>
                        <Box className={clsx({
                          [classes.menuItem]: true,
                          [classes.menuItemDisable]: Boolean(item.path === "#"),
                        })}>
                          <Box className={classes.menuItemIcon}>
                            <img src={item.iconPath}/>
                          </Box>
                          <Typography
                            className={classes.menuItemLabel}
                            dangerouslySetInnerHTML={{__html: item.label}}
                          />

                          {Boolean(item.isActive) && (
                            <Box className={classes.menuItemIndicator}/>
                          )}
                        </Box>
                      </Link>
                    )}

                    {Boolean((item.items || []).length > 0) && (
                      <PopupState variant="popover" popupId="demo-popup-popover">
                        {(popupState) => (
                          <div>
                            <Box {...bindTrigger(popupState)}>
                              <Box className={clsx({
                                [classes.menuItem]: true,
                                [classes.menuItemDropDown]: true,
                                [classes.menuItemDisable]: Boolean(item.path === "#"),
                              })}>
                                <Box className={classes.menuItemIcon}>
                                  <img src={item.iconPath}/>
                                </Box>
                                <Typography
                                  className={classes.menuItemLabel}
                                  dangerouslySetInnerHTML={{__html: item.label}}
                                />
                                <Box className={classes.menuItemIconDropDown}>
                                  <KeyboardArrowDownIcon color="primary"/>
                                </Box>

                                {Boolean(item.isActive) && (
                                  <Box className={classes.menuItemIndicator}/>
                                )}
                              </Box>
                            </Box>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              {item.items.map((item, indexItem) => (
                                <Link to={item.path}>
                                  <Box className={clsx({
                                    [classes.dropDownItem]: true
                                  })}>
                                    <Typography
                                      className={classes.menuItemLabel}
                                      dangerouslySetInnerHTML={{__html: item.label}}
                                    />
                                  </Box>
                                </Link>
                              ))}
                            </Popover>
                          </div>
                        )}
                      </PopupState>
                    )}

                  </Grid>
                ))
              }

              <Grid item sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <CurrentTimeZone/>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Box>
    );
  }
}

const styles = {
  root: {
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
    width: "100%",
    paddingRight: 40
  },

  logo: {
    display: "flex"
  },
  logoLabel: {
    height: 64,
    backgroundColor: "#FB7800",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 20,

    fontSize: 24,
    lineHeight: "24px",
    color: "white",
    fontWeight: "600"
  },
  logoIcon: {},

  menuItem: {
    display: "flex",
    alignItems: "center",
    height: 64,
    position: "relative"
  },
  menuItemDropDown: {
    cursor: "pointer"
  },
  menuItemDisable: {
    opacity: 0.4,
    cursor: "not-allowed"
  },
  menuItemIcon: {
    width: 20,
    height: 20
  },
  menuItemIconDropDown: {
    width: 20,
    height: 20,
    marginLeft: 12
  },
  menuItemLabel: {
    fontSize: 16,
    lineHeight: "26px",
    fontWeight: "600",
    color: "#22252D",
    marginLeft: 8
  },
  menuItemIndicator: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#FB7800",
    height: 5
  },

  dropDownItem: {
    padding: "8px 16px"
  }
}

const headerStyles = withStyles(styles)(Header);
const StylesRouteHeader = withRouter(headerStyles);

export default compose(
  connect(
    state => ({
      userRole: state?.global?.userRole || "Administrator"
    }),
    dispatch => ({}),
  ),
)(StylesRouteHeader)
