import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,


	Typography,
	TextField,
	Button,
	Grid,
	Box,
	Divider,


	FormHelperText,
	FormControl,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import {
	VisibleContent
} from "../../../../../../components";
import * as Yup from "yup";
import {Formik} from "formik";
import cryptoNetworks from "../../../../../../constants/cryptoNetworks";

const pageInitForm = {
	amount: undefined,
	amountMin: undefined,
	payment_method_class: "",

	usdt_summ: undefined,
	wallet: "",
	comment: "",
};

class DialogTopUpBalance extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			initForm: {},

			userId: null,

			open: false,
			isCreated: true,


			eventSubmit: null
		}

		this.refFormik = React.createRef();
	}

	onSubmit = (form) => {
		if (!form.usdt_summ || !form.wallet) {
			delete form.usdt_summ;
			delete form.wallet;
			delete form.comment;
		}
		delete form.amountMin;

		this.state.eventSubmit(this.state.userId, form);
	}
	changeForm = ({target}) => {
		const {name, value} = target;
		const { customPayments } = this.props;

		let newForm = {...this.refFormik.current.values};
		newForm[name] = value;

		const commissionPercent = Number.parseFloat(customPayments.find((t) => t.class === newForm?.payment_method_class)?.commission || '0');
		if (name === 'payment_method_class') {
			newForm['amountMin'] = String(Number.parseFloat(newForm['amount']) - Number.parseFloat(newForm['amount']) * commissionPercent);
		}
		if (name === 'amount' && commissionPercent > 0) {
			newForm['amountMin'] = String(Number.parseFloat(value) - Number.parseFloat(value) * commissionPercent);
		}
		if (name === 'amountMin' && commissionPercent > 0) {
			const _value = Number.parseFloat(value);
			const _commission = (_value / (100 - (commissionPercent*100))) * 100;
			newForm['amount'] = String(_commission);
		}

		this.refFormik.current.setValues(newForm);
	}


	open = async ({initForm, eventSubmit, userId} = {}) => {
		await this.setState({
			open: true,
			eventSubmit,
			userId
		});

		this.refFormik.current.setValues(initForm || pageInitForm);
	}
	close = () => {
		this.setState({
			open: false
		})
	}

	onSuccess = () => {
		this.refFormik.current.handleSubmit()
	}
	onCancel = () => {
		this.close()
	}

	render() {
		const {open} = this.state;
		const {
			customPayments
		} = this.props;

		return (
			<Dialog
				open={open}
				maxWidth="md"
				fullWidth={true}
				onClose={this.onCancel}
			>

				<DialogTitle sx={{backgroundColor: "rgba(251, 120, 0, 1)"}}>
					<Typography variant="h3" sx={{color: "white"}}>
						Пополнение баланса
					</Typography>
				</DialogTitle>

				<DialogContent>
					<Box px={1} py={3}>
						<Formik
							innerRef={this.refFormik}
							initialValues={this.state.initForm}
							validationSchema={validationSchema}
							onSubmit={this.onSubmit}
						>
							{(props) => {
								const {
									values,
									errors,
									touched,
									handleSubmit,
								} = props;
								const commissionPercent = Number.parseFloat(customPayments.find((t) => t.class === values?.payment_method_class)?.commission || '0');

								return (
									<Box>
										<form
											onSubmit={handleSubmit}
											onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
										>
											<Grid container spacing={1}>
												<Grid item xs={12}>
													<FormControl fullWidth size="small" error={Boolean(touched.payment_method_class && errors.payment_method_class)}>
														<InputLabel>Тип операции</InputLabel>
														<Select
															value={values.payment_method_class}
															label="Тип операции"
															name="payment_method_class"
															onChange={this.changeForm}
														>
															{customPayments.map((item) => (
																<MenuItem value={item.class}>{ item.name }</MenuItem>
															))}
														</Select>
														{Boolean(touched.payment_method_class && errors.payment_method_class) && (
															<FormHelperText error>{touched.payment_method_class && errors.payment_method_class}</FormHelperText>
														)}
													</FormControl>
												</Grid>
												<Grid item xs={12}>
													<TextField
														value={values.amount}
														error={Boolean(touched.amount && errors.amount)}
														helperText={touched.amount && errors.amount}
														name="amount"
														type="number"
														size="small"
														label="Сумма пополнения"
														placeholder="Введите сумму пополнения"
														variant="outlined"
														fullWidth
														InputLabelProps={{ shrink: Boolean(String(values.amount)) }}

														onChange={this.changeForm}
													/>
												</Grid>

												<VisibleContent visible={commissionPercent && commissionPercent > 0}>
													<Grid item xs={12}>
														<TextField
															value={values.amountMin}
															error={Boolean(touched.amountMin && errors.amountMin)}
															helperText={touched.amountMin && errors.amountMin}
															name="amountMin"
															type="number"
															size="small"
															label={`Сумма пополнения (с комиссией ${commissionPercent*100}%)`}
															placeholder="Введите сумму пополнения"
															variant="outlined"
															fullWidth
															InputLabelProps={{ shrink: Boolean(String(values.amountMin)) }}

															onChange={this.changeForm}
														/>
													</Grid>
												</VisibleContent>
											</Grid>
											<VisibleContent visible={false}>
												<Box my={2}>
													<Divider/>
												</Box>
												<Grid container spacing={1}>
													<Grid item>
														<Typography variant="h4">Криптовалюта</Typography>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<TextField
																value={values.usdt_summ}
																error={Boolean(touched.usdt_summ && errors.usdt_summ)}
																helperText={touched.usdt_summ && errors.usdt_summ}
																name="usdt_summ"
																type="number"
																size="small"
																label="Сумма USDT"
																placeholder="Введите сумму USDT"
																variant="outlined"
																fullWidth

																onChange={this.changeForm}
															/>
														</Box>
													</Grid>
													<Grid item xs={12}>
														<FormControl fullWidth size="small">
															<InputLabel>Сеть</InputLabel>
															<Select
																value={values.wallet}
																label="Сеть"
																name="wallet"
																onChange={this.changeForm}
															>
																{cryptoNetworks.map((item) => (
																	<MenuItem value={item.value}>{ item.label }</MenuItem>
																))}
															</Select>
														</FormControl>
													</Grid>
													<Grid item xs={12}>
														<TextField
															value={values.comment}
															name="comment"
															multiline
															fullWidth
															variant="outlined"
															label="Комментарий"
															rows={4}

															onChange={this.changeForm}
														/>
													</Grid>
												</Grid>
											</VisibleContent>
										</form>
									</Box>
								)
							}}
						</Formik>
					</Box>
				</DialogContent>

				<DialogActions>
					<Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="outlined" onClick={this.onCancel}>
								Отмена
							</Button>
						</Grid>
						<Grid item>
							<Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="contained" onClick={this.onSuccess}>
								Пополнить
							</Button>
						</Grid>
					</Grid>
				</DialogActions>

			</Dialog>
		)
	}
}

const validationSchema = Yup.object().shape({
	amount: Yup.number("Введите сумму").required("Введите сумму"),
	payment_method_class: Yup.string().required("Выберите тип пополнения"),

	usdt_summ: Yup.number("Введите сумму").max(999999.99, 'Сумма не должна превышать: 999999.99'),
});

export default DialogTopUpBalance
