import React, { Component } from "react";
import {
    Box,
    Backdrop,
    Container,
    Typography,
    CircularProgress
} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent
} from "./components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import {
    DialogConfirmAction
} from "../../../../components";
import agent from "../../../../agent/agent";
import queryString from "query-string";
import moment from "moment";

const initialFilter = {
    "country": [],
    "service": "",

    "sort": ""
};

class SupplyPrices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prices: [],
            countries: [],
            services: [],

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            editFormId: null,

            isLoad: false,
            isShowBackdrop: false
        };

        this.refDialogForm = React.createRef();
        this.refDialogConfirmAction = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();

        // Получение списка доп данных
        await this.getPrices();
        await this.getCountry();
        await this.getService();
    }
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        //
        if (!!filter.country && typeof filter.country === "string") {
            filter.country = (filter.country || "").split(",")
        }

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }


    getPrices = async () => {

        this.setState({isLoad: true});

        const filter = this.getFilter();
        const {data, headers} = await agent.get(`/smsgoapi/go-prices/supply-prices${ filter }`).then((res) => {
            let list = res.data;

            return {
                data: list,
                headers: res.headers
            }
        }).catch((err) => {
            return {data: [], headers: {}}
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: headers?.["x-pagination-page-count"] || 1
        };

        this.setState({
            prices: data,
            pagination,
            isLoad: false
        });

    }
    getCountry = async () => {

        const data = await agent.get(`/api/country`).then((res) => {
            return res.data
        }).catch((err) => {
            return []
        });

        this.setState({
            countries: data
        })

    }
    getService = async () => {

        const data = await agent.get(`/api/service/get-all?v=${Date.now()}`).then((res) => {
            return res.data
        }).catch((err) => {
            return []
        });


        this.setState({
            services: data
        })

    }

    getFilter = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${ pagination.page }`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key] && filter[key].length > 0) {

                let value = filter[key];

                if (
                    key === "filter[created_at][gt]" ||
                    key === "filter[created_at][lt]"
                ) {
                    value = moment(value).format("YYYY-MM-DD")
                }
                if (
                    key === "filter[price][gt]" ||
                    key === "filter[price][lt]"
                ) {
                    value = value.replace(/\D+/g,"");
                }

                string.push(`${ key }=${ value }`);

            }
        });

        window.history.replaceState(null, null, `/supply-prices?${string.join("&")}`);

        return `?${ string.join("&") }`
    }
    changeFilter = async (filter, isFastStart) => {

        await this.setState({ filter });

        if (!isFastStart) {
            return null
        }

        this.getFilter();
        await this.getPrices();

    }
    resetFilter = async () => {

        await this.setState({ filter: initialFilter });


        this.getFilter();
        await this.getPrices();

    }

    changePagination = async (pagination) => {
        await this.setState({ pagination });
        await this.getPrices();
    }

    render() {
        const {
            prices,
            countries,
            services,

            filter,
            pagination,

            isLoad,
            isShowBackdrop
        } = this.state;

        return (
            <>
                <Container maxWidth="xl">

                    <FilterComponent
                        filter={filter}
                        countries={countries}

                        onCreate={() => this.createPrice()}
                        onReset={this.resetFilter}
                        onSearch={this.getPrices}
                        onChange={this.changeFilter}
                    />


                    <TableComponent
                        data={prices}
                        filter={filter}
                        pagination={pagination}
                        countries={countries}

                        isLoad={isLoad}

                        onEdit={this.editPrice}
                        onDelete={this.deletePrice}
                        onChangePagination={this.changePagination}
                    />

                </Container>

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </>
        );
    }
}

export default SupplyPrices
