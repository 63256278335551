import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Skeleton,
	Collapse,
	Checkbox,
	TextField,
	Typography,
	IconButton,
	FormControlLabel
} from "@mui/material";
import {
	ArrowCircleDown as ArrowCircleDownIcon
} from "@mui/icons-material";
import agent from "../../../../../../agent/agent";
import {Notification, NotificationTypes} from "../../../../../../common/Notification";
import {
	AutocompleteCountry
} from "../../../../../../components";
import {makeStyles} from "@mui/styles";

class DialogEdit extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			provider: {},
			countryList: [],
			activeCountryList: [],

			searchName: "",

			providerId: null,
			onSubmit: null,

			isOpen: false,
			isLoading: true,
			isVisibleOnlyActive: false,
			isOpenListCountries: true
		}
	}

	open = ({ providerId, onSubmit }) => {
		this.setState({
			providerId,
			onSubmit,
			isOpen: true,
			isLoading: true
		}, async () => {
			await this.getProvider();
		})
	}
	close = () => {
		this.setState({
			provider: {},
			countryList: [],
			activeCountryList: [],

			searchName: "",

			providerId: null,
			onSubmit: null,

			isOpen: false
		})
	}

	getProvider = async () => {
		const provider = await agent.get(`/smsgoapi/go-provider/${ this.state.providerId }`).then((res) => {
			return res.data?.country_list || []
		}).catch(() => {
			return []
		});
		const countries = await agent.get(`/smsgoapi/go-provider/countries/${ this.state.providerId }`).then((res) => {
			return (res.data || []).map((item) => {
				return {
					...item,
					country_id: String(item.country_id)
				}
			})
		}).catch(() => {
			return []
		});

		await this.setState({
			provider,
			countryList: countries,
			activeCountryList: (provider || []).map((t) => String(t?.external_id)).filter((t) => !!t),

			isLoading: false
		})
	}
	changeSearchName = ({ target }) => {
		this.setState({
			searchName: target.value
		})
	}
	saveProvider = () => {
		let countryList = [...this.state.countryList].filter((t) => !!t.status);

		this.state.onSubmit(this.state.providerId, countryList, false);
	}

	changeExternalId = (item, value) => {
		let countryList = [...this.state.countryList];
		countryList.find((t) => String(t.country_id) === String(item.country_id)).external_id = value;
		this.setState({countryList});
	}
	changeActiveCountryList = (country, value) => {
		let countryList = [...this.state.countryList];
		countryList.find((t) => String(t.country_id) === String(country)).status = value;
		this.setState({countryList});
	}
	changeOpenListCountries = () => {
		this.setState({
			isOpenListCountries: !this.state.isOpenListCountries
		})
	}

	addNewCountry = (country) => {
		let countryList = [...this.state.countryList];
		countryList.push({
			external_id: country?.external_id,
			provider_id: this.state.providerId,
			name: country.name,
			country_id: country?.external_id,
			status: 1,
		})
		this.setState({countryList});
	};

	_filterListCounty = () => {
		const search = (this.state.searchName || "").toLowerCase();

		let list = [...this.state.countryList];
		if (search) {
			list = list.filter((t) => {
				return Boolean(((t.name || '').toLowerCase()).indexOf(search) > -1)
			})
		}

		return list
	}

	render () {
		const {
			searchName,
			countryList,

			isOpen,
			isLoading,
			isOpenListCountries
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				disablePortal={true}
				PaperProps={{
					sx: { overflow: "initial" }
				}}
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle sx={{ backgroundColor: "#FB7800" }}>
					<Typography variant="h3" sx={{color: "white"}}>Редактирование провайдера</Typography>

					<TextField
						value={searchName}
						sx={{ marginTop: "8px" }}
						fullWidth
						size="small"
						variant="outlined"
						label="Наименование страны"
						placeholder="Начните печатать..."
						onChange={this.changeSearchName}
					/>
				</DialogTitle>

				<DialogContent>
					<Box py={2}>
						{Boolean(!isLoading) && (
							<>
								<Box>
									<Grid container alignItems="center" justifyContent="space-between">
										<Grid item>
											<Typography variant="h4">Список стран провайдера</Typography>
										</Grid>
										<Grid item>
											<IconButton
												size="small"
												onClick={this.changeOpenListCountries}
											>
												<ArrowCircleDownIcon sx={{transform: Boolean(isOpenListCountries) && 'rotate(180deg)'}}/>
											</IconButton>
										</Grid>
									</Grid>
									<Collapse in={isOpenListCountries}>
										<Grid container spacing={1}>
											{this._filterListCounty().map((item, index) => (
												<Grid item key={`ElementForm-${item.country_id}-${item.external_id}-${index}`}>
													<ElementForm
														item={item}
														checked={Boolean(item.status)}
														onChange={this.changeActiveCountryList.bind(this, String(item.country_id), !Boolean(item.status))}
														onChangeExternalId={this.changeExternalId}
													/>
												</Grid>
											))}
										</Grid>
									</Collapse>
								</Box>
								<Box mt={2} width="100%">
									<Typography variant="h4" mb={1}>Добавление новой страны</Typography>
									<FormAddNewService
										countryList={countryList}
										onCreate={this.addNewCountry}
									/>
								</Box>
							</>
						)}
						{Boolean(isLoading) && (
							<Grid container spacing={1}>
								{[0,1,2,3,4,5,6,7,8,9].map((item, index) => (
									<Grid key={`skeleton-${index}`} item xs={12}>
										<Skeleton height={24} inset sx={{transform: "initial"}}/>
									</Grid>
								))}
							</Grid>
						)}
					</Box>
				</DialogContent>

				<DialogActions>
					<Grid container justifyContent="flex-end">
						<Grid item>
							<Grid container spacing={2}>
								<Grid item>
									<Button
										variant="outlined"
										size="small"
										sx={{
											borderRadius: "4px",
											height: "auto",
											textTransform: "initial"
										}}
										onClick={this.close}
									>Отменить</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										size="small"
										sx={{
											borderRadius: "4px",
											height: "auto",
											textTransform: "initial"
										}}
										onClick={this.saveProvider}
									>Сохранить</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

let timeOutChangeFromElementForm = null;
const ElementForm = React.memo((props) => {
	const {
		item,
		checked,
		onChange,
		onChangeExternalId
	} = props;

	const [externalId, setExternalId] = React.useState((item.external_id || ""));
	React.useEffect(() => {
		setExternalId(item.external_id || "");
	}, [item])

	const handleExternalId = ({ target }) => {
		clearTimeout(timeOutChangeFromElementForm);
		const { value } = target;
		setExternalId(value);
		timeOutChangeFromElementForm = setTimeout(() => {
			onChangeExternalId(item, value)
		}, 200);
	};

	return (
		<Grid container alignItems="center" spacing={1} wrap="nowrap">
			<Grid item>
				<FormControlLabel
					control={<Checkbox />}
					checked={checked}
					onChange={onChange}
					label={item.name}
				/>
			</Grid>
			<Grid item>
				<TextField
					value={externalId}
					label="Код поставщика"
					size="small"
					variant="outlined"
					placeholder="Код поставщика"
					onChange={handleExternalId}
				/>
			</Grid>
		</Grid>
	)
})

const FormAddNewService = React.memo((props) => {
	const {
		countryList,
		onCreate
	} = props;
	const classes = useStyles();
	const [country, setCountry] = React.useState(null);

	const handleAddService = (country) => {
		setCountry(country);
	};
	const handleSubmitNewService = () => {
		if (!country) {
			Notification({
				type: NotificationTypes.error,
				message: "Выберите страну"
			})
			return;
		}
		if (countryList.find((t) => String(t.country_id) === String(country.external_id))) {
			Notification({
				type: NotificationTypes.error,
				message: "У вас уже добавлена выбранная страна"
			});
			setCountry(null);

			return
		}
		onCreate(country);
		setCountry(null);
		Notification({
			type: NotificationTypes.success,
			message: "Новая страна добавлена в Ваш список"
		})
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<AutocompleteCountry
					value={country?.external_id || null}
					fullWidth
					onlyValue
					size="small"
					onChange={handleAddService}
				/>
			</Grid>
			<Grid item xs={12}>
				<Button
					size="small"
					variant="contained"
					fullWidth
					className={classes.buttonSubmit}
					onClick={handleSubmitNewService}
				>Добавить</Button>
			</Grid>
		</Grid>
	)
});
const useStyles = makeStyles(() => ({
	buttonSubmit: {
		padding: "5px",
		height: "auto",
		borderRadius: "4px",
		fontSize: "14px",
		lineHeight: "20px"
	}
}));

export default DialogEdit
