import React from "react";
import {
    Autocomplete,
    TextField
} from "@mui/material";
import agent from "../../../agent/agent";

class FilterCountry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],

            isLoad: false
        };
    }

    componentDidMount = async () => {

        if (Boolean(this.props.value)) {
            await this.getOptions();

            if (this.props.onChangeActiveFilterCountry) {
                this.props.onChangeActiveFilterCountry((this.state.list || []).find((t) => t[this.props.valueName] === this.props.value)?.name);
            }
        }

    }

    getOptions = async () => {
        if ((this.state?.list || []).length > 0) {
            return null
        }

        this.setState({ isLoad: true });

        const list = await agent.get(this.props.apiUrl).then((res) => {
            return res.data || []
        }).catch(() => {
            return []
        });

        this.setState({
            list,
            isLoad: false
        });
    }

    onChange = (value) => {
        if ( this.props.multiple ) {
            const parseValue = [...value].map((item) => {
                return item?.[this.props.valueName] || item
            })

            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: parseValue || []
                }
            });
            if (this.props.onChangeActiveFilterCountry) {
                // this.props.onChangeActiveFilterCountry(value.name);
            }
        }
        if ( !this.props.multiple ) {
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: value?.[this.props.valueName] || null
                }
            });
            if (this.props.onChangeActiveFilterCountry) {
                this.props.onChangeActiveFilterCountry(value.name);
            }
        }
    }

    _getOptionLabel = (row) => {
        const options = this.state.list;

        if (typeof row !== 'object') {
            const item = options.find((t) => String(t[this.props.valueName]) === String(row));

            if (!item) {
                return ""
            }

            return `${ item?.name } (код: ${ item?.[this.props.valueName] })`
        }

        return `${ row.name } (код: ${ row[this.props.valueName] })`
    }
    _filterOptions = (list, params) => {
        const inputValue = params?.inputValue || "";

        return [...list].filter((t) => {

            if (!inputValue) {
                return true
            }

            const nameLowerCase = t.name.toLowerCase();
            const inputValueLowerCase = inputValue.toLowerCase();

            return Boolean(nameLowerCase.indexOf(inputValueLowerCase) > -1)

        })
    }

    render() {
        const {
            value,
            name,
            disableClearable,
          error,
          helperText
        } = this.props;
        const {
            list,
            isLoad
        } = this.state;

        return (
            <>

                <Autocomplete
                    {...this.props}
                    value={value}
                    disablePortal
                    options={list}
                    onChange={(event, value) => this.onChange(value, name)}
                    name={name}
                    variant="outlined"
                    fullWidth
                    disableClearable={disableClearable}
                    autoComplete={false}
                    loading={isLoad}
                    onOpen={this.getOptions}
                    limitTags={1}

                    getOptionLabel={this._getOptionLabel}
                    filterOptions={this._filterOptions}

                    renderInput={(params) => <TextField
                        {...params}
                        label="Услуга"
                        fullWidth
                        error={error}
                        helperText={helperText}
                    />}
                />

            </>
        );
    }

}

FilterCountry.defaultProps = {
    apiUrl: `/api/service/get-all?v=${Date.now()}`,
    valueName: "external_id",

    disableClearable: true
}

export default FilterCountry
