import React from "react";
import {
	Box,
	Grid,
	Button
} from "@mui/material";
import {
	PlusOne as PlusOneIcon
} from "@mui/icons-material";

const ControlsPage = (props) => {
	const {
		onAddUser
	} = props;

	return (
		<Box mb={1}>
			<Grid container justifyContent="flex-end">
				<Grid item>
					<Button
						variant="outlined"
						size="small"
						sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
						endIcon={<PlusOneIcon/>}
						onClick={onAddUser}
					>
						Добавить пользователя
					</Button>
				</Grid>
			</Grid>
		</Box>
	)
}

export default React.memo(ControlsPage)