import React from "react";
import {
    Box,

    TableCell,
    TableSortLabel
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import PropTypes from "prop-types";

const HeadSortCell = (props) => {
    const {
        label,
        name,
        filterSort,

        ...commonProps
    } = props;
    const classes = useStyles();
    const sortDirection = "" || false;
    const active = Boolean(filterSort.indexOf(name) > -1);
    const direction = Boolean(filterSort.indexOf("-") > -1) ? "desc" : "asc";

    const handleChangeSort = (type) => {
        const newFilterSort = `${type}${ name }`;

        props.onChange(newFilterSort);
    }

    return (
        <TableCell
            sortDirection={sortDirection}
            {...commonProps}
            sx={{flexDirection: "row"}}
        >
            <TableSortLabel
                className={classes.tableSortLabel}
                hideSortIcon

                onClick={() => handleChangeSort(Boolean(direction === "desc") ? "" : "-")}
            >
                {label}

                <Box className={classes.containerSort}>

                    <SortTop
                        active={Boolean(active && direction === "desc")}
                        // onClick={() => handleChangeSort("")}
                    />
                    <SortDown
                        active={Boolean(active && direction === "asc")}
                        // onClick={() => handleChangeSort("-")}
                    />

                </Box>

            </TableSortLabel>
        </TableCell>
    )
}

const SortTop = ({active, onClick}) => {
    const classes = useStyles();

    return (
        <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.iconSort} onClick={onClick}>
            <g opacity={active ? 1 : 0.3}>
                <path
                    d="M0.796691 5C0.502693 5 0.355458 4.64454 0.563346 4.43665L4.76665 0.233346C4.89553 0.104473 5.10447 0.104473 5.23335 0.233346L9.43666 4.43665C9.64454 4.64454 9.49731 5 9.20331 5L0.796691 5Z"
                    fill="white"/>
            </g>
        </svg>
    )
}
const SortDown = ({active, onClick}) => {
    const classes = useStyles();

    return (
        <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.iconSort} onClick={onClick}>
            <g opacity={active ? 1 : 0.3}>
                <path
                    d="M0.796691 -8.04579e-07C0.502693 -8.30281e-07 0.355458 0.355457 0.563346 0.563345L4.76665 4.76665C4.89553 4.89553 5.10447 4.89553 5.23335 4.76665L9.43666 0.563345C9.64454 0.355457 9.49731 -4.39468e-08 9.20331 -6.9649e-08L0.796691 -8.04579e-07Z"
                    fill="white"/>
            </g>
        </svg>
    )
}


const useStyles = makeStyles(() => ({

    containerSort: {
        display: "flex",
        flexDirection: "column",
        marginTop: -1,
        marginLeft: 8,

        "& > *": {
            marginTop: 1,
        }
    },

    tableSortLabel: {
        color: "white!important",

        "&:hover": {
            color: "white"
        }
    },

    iconSort: {
        "&:hover g": {
            opacity: 0.8
        }
    }

}));

HeadSortCell.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    filterSort: PropTypes.string.isRequired,
};

export default HeadSortCell
