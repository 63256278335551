import MuiButton from "./MuiButton";
import MuiTypography from "./MuiTypography";
import MuiTextField from "./MuiTextField";
import MuiInputBase from "./MuiInputBase";
import MuiTooltip from "./MuiTooltip";
import MuiBackdrop from "./MuiBackdrop";

import MuiPagination from "./MuiPagination";

import MuiTableHead from "./MuiTableHead";
import MuiTableBody from "./MuiTableBody";

export default {
    MuiButton,
    MuiTextField,
    MuiTypography,
    MuiInputBase,
    MuiTooltip,
    MuiBackdrop,

    MuiPagination,

    MuiTableHead,
    MuiTableBody
}
