import React from "react";
import {
  Grid,
  Button,
  Skeleton,
  Pagination,

  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import moment from "moment";

const TableCustom = (props) => {
  const {
    data,
    filter,
    isLoad,
    totalPages,

    onGetSmsInfo,
    onChangeFilter
  } = props;

  const handleChangePage = (event, page) => {
    let newFilter = {...filter};
    newFilter.page = page;
    onChangeFilter(newFilter, true);
  }

  return (
    <>

      <Grid container mb={1} justifyContent="flex-end">
        <Grid item>
          <Pagination
            page={filter.page}
            count={totalPages}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Страна</TableCell>
            <TableCell>Сервис</TableCell>
            <TableCell>Пользователь</TableCell>
            <TableCell>Номер телефона</TableCell>
            <TableCell>Стоимость</TableCell>
            <TableCell>Период</TableCell>
            <TableCell>Дата/время аренда</TableCell>
            <TableCell>Дата/время завершения аренды</TableCell>
            <TableCell>Статус</TableCell>
            <TableCell>SMS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoad && data.map((item) => <TableRowItem
            key={`item.${item.id}`}
            item={item}
            onGetSmsInfo={onGetSmsInfo}
          />)}
          {isLoad && <TableRowLoading/>}
        </TableBody>
      </Table>

      <Grid container mt={1} justifyContent="flex-end">
        <Grid item>
          <Pagination
            page={filter.page}
            count={totalPages}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>

    </>
  )
}
const TableRowItem = React.memo((props) => {
  const {
    item,
    onGetSmsInfo
  } = props;

  return (
    <TableRow>
      <TableCell>{item.id}</TableCell>
      <TableCell>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <img
              src={`https://admsms.grizzlysms.com/api/storage/image/${ item.country_icon }.webp`}
              style={{ width: 30, height: 30, borderRadius: 4, boxShadow: "0 0 1px black", objectFit: "cover", marginRight: 5 }}
            />
          </Grid>
          <Grid item>
            <span>{ item.country_name }</span>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <img
              src={`https://admsms.grizzlysms.com/api/storage/image/${ item.service_icon }.webp`}
              style={{ width: 30, height: 30, borderRadius: 4, boxShadow: "0 0 1px black", objectFit: "cover", marginRight: 5 }}
            />
          </Grid>
          <Grid item>
            <span>{ item.service_name }</span>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>{item.user_name}</TableCell>
      <TableCell>{item.phone_number}</TableCell>
      <TableCell>{item.price}₽</TableCell>
      <TableCell>{item.period}</TableCell>
      <TableCell>{moment(item.created_at).format("DD.MM.YYYY/HH:mm:ss")}</TableCell>
      <TableCell>{moment(item.rent_end_date).format("DD.MM.YYYY/HH:mm:ss")}</TableCell>
      <TableCell>{item.status}</TableCell>
      <TableCell>
        {Boolean(item.with_code === 1) && (
          <Button
            variant="outlined"
            onClick={onGetSmsInfo.bind(this, item)}
            sx={{padding: "0 8px", fontSize: 12, textTransform: 'initial', height: 'auto', borderRadius: '4px'}}
          >
            Показать SMS
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
})
const TableRowLoading = React.memo(() => {
  return new Array(10).fill(1).map(() => (
    <TableRow>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
    </TableRow>
  ))
})

export default React.memo(TableCustom)
