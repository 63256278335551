import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Box,
  Grid,
  Tooltip,
  Skeleton,
  Checkbox,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  List as ListIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon
} from "@mui/icons-material";
import moment from "moment";
import agent from "../../../../../../agent/agent";

const TableCustom = (props) => {
  const {
    data,
    isLoad,
    filter,
    totalPage,

    onEdit,
    onChangePage,
    onUpdateList,
    onSetProvider,
  } = props;

  return (
    <>

      <Box mb={2}>
        <Grid container alignItems="flex-end" justifyContent="flex-end">
          <Pagination
            page={filter.page}
            count={Number.parseFloat(totalPage)}
            onChange={onChangePage}
          />
        </Grid>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Наименование</TableCell>
            <TableCell>ID группы</TableCell>
            <TableCell>Мин. профит</TableCell>
            <TableCell align="center">Активный</TableCell>
            <TableCell align="right">Дата обновления</TableCell>
            <TableCell align="right">Дата создания</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>

        <TableBody>
          {(isLoad) ? (<TableRowsLoading count={10}/>) : (
            <>{data.map((row, index) => <TableRowData {...row} updateList={onUpdateList} onEdit={onEdit} onSetProvider={onSetProvider}/>)}</>
          )}

        </TableBody>
      </Table>

      <Box mt={2}>
        <Grid container alignItems="flex-end" justifyContent="flex-end">
          <Pagination
            page={filter.page}
            count={Number.parseFloat(totalPage)}
            onChange={onChangePage}
          />
        </Grid>
      </Box>

    </>
  )
}

const TableRowData = React.memo((props) => {
  const {
    onEdit,
    onSetProvider,
    updateList
  } = props;

  const handleOpenEdit = () => {
    const form = {
      "id": props.id,
      "group_id": props.group_id,
      "name": props.name,
      "min_profit": String(props.min_profit),
      "active": Boolean(props.active)
    };
    onEdit(form, false)
  }
  const handleSetProvider = () => {
    onSetProvider(props.id, false)
  }
  const handleChangeActive = async () => {
    const body = {
      "name": props.name,
      "min_profit": props.min_profit,
      "active": !Boolean(props.active)
    };
    await agent.put(`/smsgoapi/go-cascade/${props.id}`, body).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    await updateList({
      cancelLoad: true
    });
  }

  return (
    <TableRow>
      <TableCell>{props.id}</TableCell>
      <TableCell>{props.name}</TableCell>
      <TableCell>{props.group_id}</TableCell>
      <TableCell>{props.min_profit}</TableCell>
      <TableCell align="center">
        <Checkbox
          checked={Boolean(props.active)}
          onChange={handleChangeActive}
        />
      </TableCell>
      <TableCell align="right">{moment(props.updated_at).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
      <TableCell align="right">{moment(props.created_at).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
      <TableCell align="right">
        <Grid container spacing={1} alignItems="flex-end" justifyContent="flex-end">
          <Grid item>
            <Tooltip title="Установка каскада" arrow>
              <IconButton color="warning" onClick={handleSetProvider}>
                <ListIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Настройки каскада" arrow>
              <IconButton
                color="primary"
                onClick={handleOpenEdit}
              >
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})
const TableRowsLoading = React.memo((props) => {
  const {count} = props;
  return (
    <>
      {Array(count).fill(<TableRow>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
      </TableRow>)}
    </>
  )
})

export default React.memo(TableCustom)
