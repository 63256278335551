import React, {Component} from "react";
import {
	Box,
	Grid,
	Container,
	Typography, InputLabel, Select, MenuItem, FormControl
} from "@mui/material";
import {
	CardUsers as CardUsersComponent,
	CardServices as CardServicesComponent,
	CardSums as CardSumsComponent
} from "./components";
import agent from "../../../../agent/agent";

class Statistics extends Component {
	constructor(props) {
		super(props);

		this.state = {
			statisticsServices: [],
			statisticsSums: {},
			statisticsUsers: [],

			hoursServices: 1,
			hoursSums: 1,
			hoursUsers: 1,
			hoursGlobal: 1,

			isLoadStatServices: true,
			isLoadStatSums: true,
			isLoadStatUsers: true,
			isGlobalLoading: true
		};
	}

	componentDidMount = async () => {
		await this.getInitStatistics();
	}

	// Логика получения данных по статистике
	getInitStatistics = async () => {
		const statistics = await agent.get(`/smsgoapi/go-stat?hours=${this.state.hoursGlobal}`).then((res) => {
			return res.data
		}).catch((err) => {
			return {}
		});

		this.setState({
			statisticsServices: statistics.services || [],
			statisticsSums: statistics.sums || {},
			statisticsUsers: statistics.users || [],

			isLoadStatServices: false,
			isLoadStatSums: false,
			isLoadStatUsers: false,
			isGlobalLoading: false,
		})
	}


	// Логика по статистике Сервисы
	getStatisticsServices = async () => {
		this.setState({isLoadStatServices: true});

		const statistics = await agent.get(`/smsgoapi/go-stat?hours=${this.state.hoursServices}`).then((res) => {
			return res.data
		}).catch((err) => {
			return {}
		});

		this.setState({
			statisticsServices: statistics.services || [],
			isLoadStatServices: false
		})
	}
	changeHoursServices = async (hours) => {
		await this.setState({hoursServices: hours});
		await this.getStatisticsServices();
	}

	// Логика по статистике Суммы
	getStatisticsSums = async () => {
	}
	changeHoursSums = async (hours) => {
		await this.setState({hoursSums: hours});
		await this.getStatisticsSums();
	}

	// Логика по статистике Пользователя
	getStatisticsUsers = async () => {

		this.setState({isLoadStatUsers: true});

		const statistics = await agent.get(`/smsgoapi/go-stat?hours=${this.state.hoursUsers}`).then((res) => {
			return res.data
		}).catch((err) => {
			return {}
		});

		this.setState({
			statisticsUsers: statistics.users || [],
			isLoadStatUsers: false
		})

	}
	changeHoursUsers = async (hours) => {
		await this.setState({hoursUsers: Number(hours)});
		await this.getStatisticsUsers();
	}


	changeGlobalHours = async ({target}) => {
		await this.setState({
			hoursGlobal: target.value,
			isGlobalLoading: true
		});
		await this.getInitStatistics();
	}

	render() {
		const {
			statisticsServices,
			statisticsSums,
			statisticsUsers,

			hoursServices,
			hoursSums,
			hoursUsers,
			hoursGlobal,

			isLoadStatServices,
			isLoadStatSums,
			isLoadStatUsers,
			isGlobalLoading,
		} = this.state;

		return (
			<Container maxWidth="xl">

				<Box mb={2} mt={1}>
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item>
							<Typography variant="h1" textAlign="left">Статистика</Typography>
						</Grid>
						<Grid item>
							<FormControl sx={{width: 240}} size="small">
								<InputLabel>За период</InputLabel>
								<Select
									value={hoursGlobal}
									label="За период"
									size="small"
									onChange={this.changeGlobalHours}
								>
									<MenuItem value={1}>Последний час</MenuItem>
									<MenuItem value={24}>За сутки</MenuItem>
									<MenuItem value={68}>За неделю</MenuItem>
									<MenuItem value={744}>За месяц</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Box>

				<Grid container columnSpacing={1} rowSpacing={1}>
					<Grid item xs={12}>
						<CardSumsComponent
							data={statisticsSums}
							hours={hoursSums}
							isLoad={isGlobalLoading}

							onChange={this.changeHoursSums}
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container columnSpacing={1}>
							<Grid item xs={6}>
								<CardUsersComponent
									data={statisticsUsers}
									hours={hoursUsers}
									isLoad={isGlobalLoading}

									onChange={this.changeHoursUsers}
								/>
							</Grid>
							<Grid item xs={6}>
								<CardServicesComponent
									data={statisticsServices}
									hours={hoursServices}
									isLoad={isGlobalLoading}

									onChange={this.changeHoursServices}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

			</Container>
		);
	}
}

export default Statistics
