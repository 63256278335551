import React, {Component} from "react";
import {
  Backdrop,
  CircularProgress
} from "@mui/material";
import {
  Table as TableComponent,
  DialogChangeBalance,
  DialogBalanceHistory
} from "./components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

class SupplierBalance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],

      isLoad: true,
      isBackdrop: false,
    };

    this.refDialogChangeBalance = React.createRef();
    this.refDialogBalanceHistory = React.createRef();
  }

  componentDidMount = async () => {
    await this.getList();
  }

  // Получение списка поставщиков
  getList = async () => {
    const res = await agent.post('/smsgoapi/go-provider/list').then((res) => {
      return res.data
    }).catch(() => {
      return []
    });

    this.setState({
      list: res,
      isLoad: false
    });
  }

  // Добавление изменения баланса
  addEventChangeBalance = async (providerId, form = null) => {
    if (!form) {
      this.refDialogChangeBalance.current.open({
        providerId,
        onSubmit: this.addEventChangeBalance.bind(this)
      })
      return
    }

    this.setState({ isBackdrop: true });

    form.amount = Number.parseFloat(form.amount);
    const response = await agent.post(`/smsgoapi/go-provider/create-provider-balance-transaction/${providerId}`, form).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });

    await this.getList();
    this.setState({ isBackdrop: false });
    this.refDialogChangeBalance.current.close();
    Notification({
      type: NotificationTypes.success,
      message: "История баланса изменена"
    })
  }

  // Открытие Pop Up окна истории пополенения поставщика
  openPopUpHistoryUpdateBalance = (provider) => {
    this.refDialogBalanceHistory.current.open({
      provider
    })
  }

  render() {
    const {
      list,
      isLoad,
      isBackdrop
    } = this.state;

    return (
      <>
        <TableComponent
          data={list}
          isLoad={isLoad}

          onChangeBalance={this.addEventChangeBalance}
          onOpenPopUpHistoryUpdate={this.openPopUpHistoryUpdateBalance}
        />

        <DialogChangeBalance
          ref={this.refDialogChangeBalance}
        />
        <DialogBalanceHistory
          ref={this.refDialogBalanceHistory}
        />


        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </>
    );
  }
}

export default SupplierBalance
