import React from "react";
import {
	Box,
	Grid,
	TextField,
	Typography,
	InputAdornment,
	CircularProgress
} from "@mui/material";
import {

} from "@mui/styles";

const Header = ({ title, search, isLoadingSearch, onChangeSearch }) => {

	return (
		<Box mb={1}>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h3">{ title }</Typography>
				</Grid>
				<Grid item>
					<InputSearch
						value={search}
						loading={isLoadingSearch}
						onChange={onChangeSearch}
					/>
				</Grid>
			</Grid>
		</Box>
	)
};
const InputSearch = React.memo(({ value, loading, onChange }) => {
	const handleChangeSearch = ({ target }) => {
		onChange(target?.value)
	}
	return (
		<Box width="320px" position="relative">
			<TextField
				value={value}
				size="small"
				variant="outlined"
				label="Поиск по поставщику"
				placeholder="ID / наименованию / адаптеру / алиасу"
				fullWidth
				onChange={handleChangeSearch}
			/>
			{loading && (
				<Box position="absolute" top={0} right={0} bottom={0} display="flex" alignItems="center" justifyContent="center" paddingRight={1}>
					<CircularProgress color="primary" sx={{ width: "16px!important", height: "16px!important" }}/>
				</Box>
			)}
		</Box>
	)
})

export default Header
