import React from "react";
import {
  Grid,
  Tooltip,
  Checkbox,
  Skeleton,
  IconButton,
  Pagination,

  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import {
  Edit as EditIcon
} from "@mui/icons-material";

const TableCustom = (props) => {
  const {
    data,
    filter,
    totalCount,
    isLoad,
    onChange,
    onChangePopUp,
    onChangeFilter
  } = props;

  const handleChangePage = (event, page) => {
    let newFilter = {...filter};
    newFilter.page = page;
    onChangeFilter(newFilter)
  }

  return (
    <>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Наименование</TableCell>
            <TableCell>Процент кэшбэка</TableCell>
            <TableCell>Минимальный депозит</TableCell>
            <TableCell>Статус</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(!isLoad) && data.map((i, t) => <RowData
            key={`RowData-${t}`}
            item={i}
            onChange={onChange}
            onChangePopUp={onChangePopUp}
          />)}
          {Boolean(isLoad) && <RowLoading/>}
        </TableBody>
      </Table>

      <Grid container mt={2} justifyContent="flex-end">
        <Grid item>
          <Pagination
            page={filter.page}
            count={totalCount}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>

    </>
  )
}
const RowData = React.memo((props) => {
  const {
    item,
    onChange,
    onChangePopUp
  } = props;

  const handleChangeField = (name, value) => {
    let body = {
      ...item,
      [name]: value
    }
    onChange(body, true);
  }

  return (
    <TableRow>
      <TableCell>
        { item.id }
      </TableCell>
      <TableCell>
        { item.name }
      </TableCell>
      <TableCell>
        { item.cashback_percent }%
      </TableCell>
      <TableCell>
        { item.min_deposit }
      </TableCell>
      <TableCell>
        <Checkbox
          checked={item.active}
          onChange={handleChangeField.bind(this, 'active', !item.active)}
        />
      </TableCell>
      <TableCell>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Tooltip title="Редактировать" arrow>
              <IconButton color="primary" onClick={onChangePopUp.bind(this, item, false)}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})
const RowLoading = React.memo(() => {
  return (
    <>
      {Array(10).fill(0).map((t) => (
        <TableRow>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
        </TableRow>
      ))}
    </>
  )
})

export default React.memo(TableCustom)
