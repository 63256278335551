import React from "react";
import {
  Box,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import {convertorNumber} from "../../../../../../helper/convertor";

const TotalInfo = (props) => {
  const {
    data,
    userRole,
    isLoad
  } = props;
  const item = data?.[0] || {};

  return (
    <Box py={2} px={3} borderRadius={4} bgcolor="white">
      <Grid container alignItems="flex-end" spacing={3}>
        <Grid item>
          <Typography variant="h4">Суммарно:</Typography>
        </Grid>

        {Boolean(isLoad) ? (
          <Grid item flex="auto">
            <Skeleton style={{height: 50, marginBottom: "-12px"}}/>
          </Grid>
        ) : (
          <>
            <Grid flex="auto" item>
              <Typography variant="body1" component="div">Номеров отдано:</Typography>
              <Typography variant="h5" component="div">
                {item.numbers_given}
              </Typography>
            </Grid>
            <Grid flex="auto" item>
              <Typography variant="body1" component="div">Смс отдано:</Typography>
              <Typography variant="h5" component="div">
                {item.numbers_received}
              </Typography>
            </Grid>
            <Grid flex="auto" item>
              <Typography variant="body1" component="div">Доставка смс:</Typography>
              <Typography variant="h5" component="div">
                {Boolean(item.numbers_percentage) ? `${item.numbers_percentage}%` : '-'}
              </Typography>
            </Grid>

            {Boolean(userRole === 'Administrator') && (
              <>
                <Grid flex="auto" item>
                  <Typography variant="body1" component="div">Выручка:</Typography>
                  <Typography variant="h5" component="div">
                    {convertorNumber(item.income || 0)}
                  </Typography>
                </Grid>
                <Grid flex="auto" item>
                  <Typography variant="body1" component="div">Прибыль:</Typography>
                  <Typography variant="h5" component="div">
                    {convertorNumber(item.profit || 0)}
                  </Typography>
                </Grid>
                <Grid flex="auto" item>
                  <Typography variant="body1" component="div">Маржа:</Typography>
                  <Typography variant="h5" component="div">
                    {Boolean(item.margin) ? `${item.margin}%` : '-'}
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  )
}

export default React.memo(TotalInfo)
