import React from 'react';
import { Box, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { withStyles } from '@mui/styles'
import {
    KeyboardArrowUp as KeyboardArrowUpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";

class TopServicesList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        }
    }

    openCollapse = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const {
            classes,
            topServices,
        } = this.props;

        const {
            isOpen
        } = this.state;

        const IconHead = Boolean(isOpen) ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

        console.log('topServices: ', topServices);

        return (
            <>
                <Box className={classes.root}>
                    <Box className={classes.collapseTop}>

                        <Typography className={classes.title} variant='h3'>Топ сервисов</Typography>
                        <IconButton className={classes.headButton} onClick={this.openCollapse}>
                            <IconHead />
                        </IconButton>

                    </Box>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit={true}>
                        <Box py={2} bgcolor="white" >
                            <Table className={classes.table}>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Наименование сервиса</TableCell>
                                        <TableCell>Страна</TableCell>
                                        <TableCell>Кол-во отправленных смс</TableCell>
                                        <TableCell>Кол-во доставленных смс</TableCell>
                                        <TableCell>% доставки</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(topServices).length ? (

                                      Object.keys(topServices).map((serviceKey, idx) => {
                                          const service = topServices[serviceKey];

                                          return (
                                            <TableRow key={`service-id-${idx}`}>
                                                <TableCell>{service.service}</TableCell>
                                                <TableCell>{service.country}</TableCell>
                                                <TableCell>{service.count_numbers}</TableCell>
                                                <TableCell>{service.received_numbers}</TableCell>
                                                <TableCell>{service.received_numbers_percentage}</TableCell>
                                            </TableRow>
                                          )
                                      })

                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Box>
                                                    <Typography variant='h3' sx={{ textAlign: "center" }}> Нет данных</Typography>
                                                </Box>
                                            </TableCell>

                                        </TableRow>

                                    )}


                                </TableBody>

                            </Table>
                        </Box>
                    </Collapse>
                </Box>

            </>
        )
    }
}
const styles = {
    root: {
        background: "white",
        borderRadius: "8px",
        padding: "18px 24px",
        marginTop: "16px",
    },
    collapseTop: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    table: {
        "& .MuiTableRow-root > th:first-child": {
            borderRadius: 0,
        },
        "& .MuiTableRow-root > th:last-child": {
            borderRadius: 0,
        },
    },

    headButton: {
        width: 32,
        height: 32,
        margin: "-6px 0 -6px 30px",
        padding: 0,
        "& > svg": {
            fill: "#938c8c",
        },
    },
};

export default withStyles(styles)(TopServicesList)