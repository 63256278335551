import React from "react";
import {
	Box,
	Grid,
	Button,
	Collapse,
	TextField,
	Typography,

	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Clear as ClearIcon,
	Search as SearchIcon,
	PlusOne as PlusOneIcon,
	FilterAlt as FilterAltIcon,
	HighlightOff as HighlightOffIcon
} from "@mui/icons-material";
import {
	FilterCountry,
	FilterService,
	FilterUsers
} from "../../../../../../components";

const Filter = (props) => {
	const {
		filter,
		countries,
		services,
		users,

		onCreate,
		onReset,
		onSearch,
		onChange
	} = props;
	const [isOpen, setOpen] = React.useState(true);
	const classes = useStyles();

	const handleChangeCommon = ({target}) => {
		const {name, value} = target;

		let newFilter = {...filter};
		newFilter[name] = value;

		onChange(newFilter);
	}

	const _activeFilters = () => {

		let items = [];

		if ((filter["countries"] || []).length > 0) {
			const valueCountry = (filter["countries"] || []).map((item) => {
				return (countries || [])?.find((t) => +t.external_id === +item)?.name
			}).join(', ')

			items.push({
				label: "Страна",
				value: valueCountry,
				onClick: _deleteCommonArray.bind(this, "countries")
			})
		}
		if ((filter["services"] || []).length > 0) {
			const valueCountry = (filter["services"] || []).map((item) => {
				return (services || [])?.find((t) => t.external_id === item)?.name
			}).join(', ')

			items.push({
				label: "Услуга",
				value: valueCountry,
				onClick: _deleteCommonArray.bind(this, "services")
			})
		}
		if ((filter["users"] || []).length > 0) {
			const valueCountry = (filter["users"] || []).map((item) => {
				return (users || [])?.find((t) => t.external_id === item)?.email
			}).join(', ')

			items.push({
				label: "Пользователи",
				value: valueCountry,
				onClick: _deleteCommonArray.bind(this, "users")
			})
		}


		return items
	}
	const _deleteCommon = (name) => {
		let newFilter = {...filter};
		newFilter[name] = "";

		onChange(newFilter, true);
	}
	const _deleteCommonArray = (name) => {
		let newFilter = {...filter};
		newFilter[name] = [];

		onChange(newFilter, true);
	}

	return (
		<Box mb={1}>

			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Typography variant="h4">Фильтры</Typography>
				</Grid>
				<Grid item>
					<Grid container spacing={1}>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								onClick={onCreate}
								endIcon={<PlusOneIcon/>}
							>
								Добавить
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								onClick={() => setOpen(!isOpen)}
								endIcon={<FilterAltIcon/>}
							>
								{isOpen ? "Свернуть" : "Развернуть"}
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								onClick={onReset}
								endIcon={<ClearIcon/>}
							>
								Сбросить
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								endIcon={<SearchIcon/>}
								onClick={onSearch}
							>
								Поиск
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Box my={1}/>

			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<Box px={2} py={2} bgcolor="white" borderRadius={4}>
					<Grid container spacing={4}>
						<Grid item sm={3}>
							<FilterCountry
								name="countries"
								value={filter["countries"]}
								size="small"
								fullWidth
								multiple

								onChange={handleChangeCommon}
							/>
						</Grid>
						<Grid item sm={3}>
							<FilterService
								name="services"
								value={filter["services"]}
								size="small"
								fullWidth
								multiple

								onChange={handleChangeCommon}
							/>
						</Grid>
						<Grid item sm={3}>
							<FilterUsers
								name="users"
								value={filter["users"]}
								size="small"
								fullWidth
								multiple
								valueName="id"

								onChange={handleChangeCommon}
							/>
						</Grid>
						<Grid item sm={3}>
							<FormControl fullWidth size="small">
								<InputLabel>Статус</InputLabel>
								<Select
									value={filter.active}
									label="active"
									name="active"
									onChange={handleChangeCommon}
								>
									<MenuItem value="">Все</MenuItem>
									<MenuItem value="true">Активные</MenuItem>
									<MenuItem value="false">Неактивные</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item sm={3}>
							<TextField
								value={filter.alias}
								name="alias"
								label="Алиас"
								size="small"
								fullWidth
								onChange={handleChangeCommon}
							/>
						</Grid>
						<Grid item sm={3}>
							<TextField
								value={filter.name}
								name="name"
								label="Наименование"
								size="small"
								fullWidth
								onChange={handleChangeCommon}
							/>
						</Grid>
					</Grid>
				</Box>
			</Collapse>

			{Boolean(_activeFilters().length > 0) && (
				<>

					<Box my={1}/>

					<Grid container spacing={2}>
						{_activeFilters().map((item, index) => (
							<Grid item>
								<Box className={classes.active} onClick={item.onClick}>
									<Typography className={classes.activeLabel}>{item.label}:</Typography>
									<Typography className={classes.activeValue}>{item.value}</Typography>
									<HighlightOffIcon sx={{color: "white", fontSize: 16, marginLeft: 1}}/>
								</Box>
							</Grid>
						))}
					</Grid>

				</>
			)}

		</Box>
	);
}

const useStyles = makeStyles(() => ({

	active: {
		display: "flex",
		alignItems: "center",
		padding: "2px 12px",
		borderRadius: 6,
		backgroundColor: "#FB7800",
		cursor: "pointer",

		fontSize: 15,
		lineHeight: "22px",
		fontWeight: "600"
	},
	activeLabel: {
		opacity: 0.6,
		fontSize: 15,
		lineHeight: "22px",
		fontWeight: "600",
		color: "white"
	},
	activeValue: {
		fontSize: 15,
		lineHeight: "22px",
		fontWeight: "600",
		color: "white",
		marginLeft: 6
	},

	dateTimePicker: {
		"& .daterangepicker": {
			"& .applyButton": {
				backgroundColor: "rgba(251,120,0, .8) !important",
				border: "none !important",
				"&:hover": {
					backgroundColor: "#FB7800 !important",
				}
			},
			"& .fromDateHourContainer": {
				border: "none !important",
			},
			"& .inputDate": {
				borderWidth: "2px !important",
				height: "33px !important",
				"&:focus": {
					borderColor: "#FB7800 !important",
					boxShadow: "none !important",
				}
			},
			"& .calendarAddon.input-group-addon": {
				borderWidth: "2px !important",
			},
			"& .multipleContentOnLine": {
				outline: "none !important",
				"& select": {
					height: "33px !important",
					borderRadius: "4px",
					borderWidth: "2px",
					outline: "none",
					borderColor: "#ccc",
					"&:focus": {
						borderColor: "#FB7800 !important",
						outline: "none !important",
					}
				}
			},
			"& .monthYearContainer": {
				alignItems: "center",
			},
			"& .timeIconStyle": {
				top: "50%",
				transform: "translate(0, -50%)",
			},
			"& .glyphicon": {
				color: "#FB7800 !important"
			},
			"& .rangecontainer": {
				"& div": {
					outline: "none !important",
				}
			},
			"& .calendarGrid": {
				"& div": {
					outline: "none !important",
				}
			}
		}
	}
}));

export default Filter
