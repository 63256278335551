import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography
} from "@mui/material";
import {
  FilterUsers,
  AutocompleteService,
  AutocompleteCountry
} from "../../../../../../components";
import {
  makeStyles
} from "@mui/styles";

const Filter = (props) => {
  const {
    data,
    progress,
    isLoad
  } = props;
  const [filter, setFilter] = React.useState({...data});
  const classes = useStyles();

  const handleChangeFilter = ({ target }) => {
    const {
      name,
      value
    } = target;

    let newFilter = {...filter}
    newFilter[name] = value;
    setFilter(newFilter);
  }
  const handleSubmitFilter = () => {
    props.onSubmit(filter);
  }

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <FilterUsers
            value={filter.user_id}
            size="small"
            name="user_id"
            onChange={handleChangeFilter}
          />
        </Grid>
        <Grid item xs={5}>
          <AutocompleteService
            value={filter.services}
            size="small"
            name="services"
            multiple={true}
            onChange={handleChangeFilter}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            disabled={isLoad}
            fullWidth
            className={classes.buttonSubmit}
            onClick={handleSubmitFilter}
          >
            Запросить
          </Button>
        </Grid>
      </Grid>

      {Boolean(!!progress) && (
        <>
          <Box mt={2}/>
          <Typography variant="caption">
            Текущий прогресс: {progress}
          </Typography>
        </>
      )}
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
    borderRadius: 10,
    overflow: "hidden",
    padding: "20px"
  },
  buttonSubmit: {
    padding: 0,
    height: 37,
    borderRadius: 5,
    fontSize: 14
  }
}));

export default React.memo(Filter)
