import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,


    Typography,
    Button,
    Grid,
    Box, TextField, InputLabel, Select, MenuItem, FormControl, FormHelperText
} from "@mui/material";
import {
    AutocompleteCountry,
    AutocompleteService,
    AutocompleteUsers
} from "../../../../../../components";
import * as Yup from "yup";
import { Formik } from "formik";

const pageInitForm = {
    countries: [],
    services: []
};

class DialogCreateGroup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initForm: {},

            open: false,
            isCreated: true,

            eventSubmit: null
        }

        this.refFormik = React.createRef();
    }


    onSubmit = (form) => {
        this.state.eventSubmit(form, true);
    }
    changeForm = ({ target }) => {
        const { name, value } = target;

        let newForm = { ...this.refFormik.current.values };
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    }

    open = async ({ initForm, eventSubmit } = {}) => {
        await this.setState({
            open: true,
            isCreated: Boolean(Object.keys((initForm || {})).length <= 0),
            eventSubmit
        });

        this.refFormik.current.setValues(initForm || pageInitForm);
    }
    close = () => {
        this.setState({
            open: false
        })
    }

    onSuccess = () => {
        this.refFormik.current.handleSubmit()
    }
    onCancel = () => {
        this.close()
    }

    render() {
        const {
            open,
            isCreated
        } = this.state;
        const {
            countries,
            services
        } = this.props;

        return (
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                disablePortal={true}
                PaperProps={{
                    sx: { overflow: "initial" }
                }}


                onClose={this.onCancel}
            >

                <DialogTitle sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
                    <Typography variant="h3" sx={{ color: "white" }}>
                        Выполнить проверку
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box px={1} py={3}>
                        <Formik
                            innerRef={this.refFormik}
                            initialValues={this.state.initForm}
                            validationSchema={validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched,
                                    handleSubmit,
                                } = props;

                                if (Object.keys(values || {}).length <= 0) {
                                    return null
                                }

                                return (
                                    <Box>
                                        <form
                                            onSubmit={handleSubmit}
                                            onKeyDown={({ keyCode }) => (keyCode === 13) && handleSubmit()}
                                        >

                                            <Box mb={4}>
                                                <AutocompleteService
                                                    value={values.services}

                                                    name="services"
                                                    label="Услуги"

                                                    multiple={true}

                                                    error={Boolean(touched.services && errors.services)}
                                                    helperText={touched.services && errors.services || ""}

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <AutocompleteCountry
                                                    value={values.countries}

                                                    name="countries"
                                                    label="Страны"

                                                    multiple={true}

                                                    error={Boolean(touched.countries && errors.countries)}
                                                    helperText={touched.countries && errors.countries || ""}

                                                    onChange={this.changeForm}
                                                />

                                            </Box>


                                        </form>
                                    </Box>
                                )
                            }}
                        </Formik>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <Button sx={{ borderRadius: "4px", textTransform: "initial" }} variant="outlined" onClick={this.onCancel}>
                                Отмена
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button sx={{ borderRadius: "4px", textTransform: "initial" }} variant="contained" onClick={this.onSuccess}>
                                Выполнить
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        )
    }
}

const validationSchema = Yup.object().shape({
    services: Yup
        .array()
        .when('countries', {
            is: (countries) => (countries || []).length <= 0,
            then: Yup.array().required().min(1, "Выберите минимум 1 страну"),
        }),
    countries: Yup
        .array()
        .when('services', {
            is: (services) => (services || []).length <= 0,
            then: Yup.array().required().min(1, "Выберите минимум 1 сервис"),
        }),
}, [["services", "countries"]]);

export default DialogCreateGroup
