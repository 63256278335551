import React from "react";
import {
    makeStyles
} from "@mui/styles";
import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import { Box } from '@mui/system';

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,

        isLoad,

        onChangeFilter,
        onChangePagination,
    } = props;

    const classes = useStyles();



    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Дата и время</TableCell>
                        <TableCell>Пользователь</TableCell>
                        <TableCell>Действие</TableCell>
                        <TableCell>Создание/Изменение</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {

                        Boolean(isLoad) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>
                        ) : (
                            <>
                                {(data || []).map((row, idx2) => (
                                    <TableRow key={`row-item-${idx2}`}>
                                        <TableCell>{row?.updated_at || "-"}</TableCell>
                                        <TableCell>{row?.admin_user || "-"}</TableCell>
                                        <TableCell className={classes.dataWrapper}>
                                            <Box>
                                                <strong>id:</strong>
                                                {` ${row?.model_id || "нет"}`}
                                            </Box>
                                            <Box>
                                                <strong>type:</strong>
                                                {` ${row?.model_name || "нет"}`}
                                            </Box>
                                            <Box>
                                                <strong>Исходные данные:</strong> <br />
                                                {JSON.stringify(row?.model_old_attributes || "нет").split(/[{}]/)}
                                            </Box>
                                            <Box>
                                                <strong>Измененные данные:</strong><br />
                                                {JSON.stringify(row?.model_attributes || "нет").split(/[{}]/)}
                                            </Box>
                                        </TableCell>
                                        <TableCell>{`${row?.new == 0 ? "Изменение" : "Создание"}`}</TableCell>
                                    </TableRow>
                                ))}

                            </>

                        )}
                </TableBody>

            </Table>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {},
    dataWrapper: {
        "& > *:not(:last-child)": {
            marginBottom: 6,
        },
        "&  strong": {
            fontWeight: "bold"
        }
    },

}));

export default TableCustom