import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Clear as ClearIcon,
	Search as SearchIcon
} from "@mui/icons-material";
import {FilterProvider, FilterUsers} from "../../../../../../components";
import {
	SelectElement
} from "../DialogCreate/index";


const Filter = (props) => {
	const {
		filter,
		config,
		onReset,
		onSearch,
		onCreate,
		onChange
	} = props;
	const classes = useStyles();

	const handleChangeFilter = ({ target }) => {
		const { value, name } = target
		let _filter = {...filter};
		_filter[name] = value;
		_filter.page = 1;
		onChange(_filter);
	}

	return (
		<>
			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Typography variant="h4">Фильтры</Typography>
				</Grid>
				<Grid item>
					<Grid container spacing={1}>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								endIcon={<ClearIcon/>}
								onClick={onReset}
							>Сбросить</Button>
						</Grid>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								onClick={onCreate}
							>Добавить выплату</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								endIcon={<SearchIcon/>}
								onClick={onSearch}
							>Поиск</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Box className={classes.root} mt={1}>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<SelectElement
							value={filter['filter[currency]']}
							name="filter[currency]"
							options={config.currencies || []}
							onChange={handleChangeFilter}
							label="Валюта"
						/>
					</Grid>
					<Grid item xs={4}>
						<SelectElement
							value={filter['filter[status]']}
							name="filter[status]"
							options={config.statuses || []}
							onChange={handleChangeFilter}
							label="Статус"
						/>
					</Grid>
					<Grid item xs={4}>
						<SelectElement
							value={filter['filter[payout_type]']}
							name="filter[payout_type]"
							options={config.types || []}
							onChange={handleChangeFilter}
							label="Тип кошелька"
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		padding: "20px",
		borderRadius: "10px",
		boxSizing: "border-box",
		background: "white"
	}
}));

export default Filter
