import React from "react";
import {
  Box,
  Grid,
  Skeleton,
  Checkbox,
  IconButton,
  Pagination,

  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Edit as EditIcon
} from "@mui/icons-material";
import moment from "moment";

const TableCustom = (props) => {
  const {
    data,
    filter,
    totalPages,
    isLoad,

    onEdit,
    onChangePage
  } = props;

  const handleChangePage = (e, v) => {
    let newFilter = {...filter};
    newFilter.page = v;
    onChangePage(newFilter, true);
  }

  return (
    <>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Наименование</TableCell>
            <TableCell>Каскад</TableCell>
            <TableCell>Страны</TableCell>
            <TableCell>Сервисы</TableCell>
            <TableCell align="center">Статус</TableCell>
            <TableCell align="right">Дата создания</TableCell>
            <TableCell align="right">Дата обновления</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoad && data.map((item) => <RowData
            item={item}
            onEdit={onEdit}
          />)}
          {isLoad && <RowLoading/>}
        </TableBody>
      </Table>

      <Grid container justifyContent="flex-end" mt={1}>
        <Grid item>
          <Pagination
            page={filter.page}
            count={totalPages}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>

    </>
  )
}
const RowData = React.memo((props) => {
  const {
    item,
    onEdit
  } = props;
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell>{ item?.id }</TableCell>
      <TableCell>{ item?.name }</TableCell>
      <TableCell>{ item?.cascade_id }</TableCell>
      <TableCell>
        <Grid container spacing={1} flexDirection="column">
          {(item?.countries || []).map((country) => (
            <Grid item>
              <Box className={classes.iconTextItem}>
                <img src={`https://admsms.grizzlysms.com/api/storage/image/${ country.icon }.webp`}/>
                <span>{country.name}</span>
              </Box>
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell>
        <Grid container spacing={1} flexDirection="column">
          {(item?.services || []).map((service) => (
            <Grid item>
              <Box className={classes.iconTextItem}>
                <img src={`https://admsms.grizzlysms.com/api/storage/image/${ service.icon }.webp`}/>
                <span>{service.name}</span>
              </Box>
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell align="center">
        <Checkbox checked={Boolean(item?.status)}/>
      </TableCell>
      <TableCell align="right">
        {moment(item?.created_at).format("DD.MM.YYYY HH:mm:ss")}
      </TableCell>
      <TableCell align="right">
        {moment(item?.updated_at).format("DD.MM.YYYY HH:mm:ss")}
      </TableCell>
      <TableCell align="right">
        <Grid container wrap="nowrap" justifyContent="flex-end">
          <Grid item>
            <IconButton
              color="primary"
              onClick={onEdit.bind(this, item, false)}
            >
              <EditIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})
const RowLoading = React.memo(() => {
  return new Array(10).fill(1).map((t) => (
    <TableRow>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
      <TableCell><Skeleton/></TableCell>
    </TableRow>
  ))
});

const useStyles = makeStyles(() => ({
  iconTextItem: {
    display: "flex",
    alignItems: "center",

    "& img": {
      width: 24,
      height: 24,
      borderRadius: "100%",
      marginRight: 6,
    }
  }
}))

export default React.memo(TableCustom)
