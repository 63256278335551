import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography
} from "@mui/material";
import moment from "moment";


class CurrentTimeZone extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentTimeZone: null
		}
	}

	componentDidMount = () => {
		this.initState();
	}

	initState = () => {
		const localStorageTimeZone = "";
		const browserTimeZone = moment().format('Z');
		this.setState({
			currentTimeZone: browserTimeZone
		})
	}

	openChangeTimeZone = () => {}

	render () {
		const {
			currentTimeZone
		} = this.state;

		return (
			<>
				<Grid container flexDirection="column" alignItems="flex-end">
					<Grid item>
						<Typography variant="caption">
							Часовой пояс:
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant="caption"
							fontWeight="600"
							sx={{
								cursor: "pointer",
								textDecoration: "underline"
							}}
						>
							GMT {currentTimeZone}
						</Typography>
					</Grid>
				</Grid>


			</>
		)
	}
}

export default CurrentTimeZone