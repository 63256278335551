import React from "react";
import {
  Box,
  Grid,
  Checkbox,
  Skeleton,
  Typography,
  Pagination,

  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {convertorNumber} from "../../../../../../helper/convertor";
import agent from "../../../../../../agent/agent";
import {palette} from "../../../../../../theme/common";

class TableCustom extends React.PureComponent {
  render() {
    const {
      data,
      isLoad,

      checkedUsers,
      onChangeCheckedUsers
    } = this.props;

    return (
      <>
        <Box sx={{display: "flex"}}>
          <AmountSelectedUser
            data={data?.user_amounts || []}
            checked={checkedUsers}
          />
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={64}/>
              <TableCell>Пользователь</TableCell>
              <TableCell>Сумма</TableCell>
              <TableCell>Расходы по странам 2FA</TableCell>
              <TableCell>Расходы по странам WC</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(isLoad) && <RowsLoading/>}
            {(data?.user_amounts || []).map((t, index) => <RowInfo
              key={`index-${index}`} {...t}
              checked={checkedUsers.find((_val) => Boolean(String(_val) === String(t.user_id)))}
              onChangeCheckedUsers={onChangeCheckedUsers}
            />)}
          </TableBody>
        </Table>
      </>
    )
  }
}
const AmountSelectedUser = React.memo(({ data, checked }) => {
  const classes = useStyles();

  const [amount, setAmount] = React.useState(0);
  const calcAmount = () => {
    const _users = [...data].filter((user) => {
      return checked.find((userId) => Boolean(String(user?.user_id) === String(userId)))
    })
    const _balance = _users.reduce((value, user) => {
      return value + (user?.amount || 0)
    }, 0)
    setAmount(_balance);
  };
  React.useEffect(() => {
    calcAmount();
  }, [checked]);
	return (
		<Box className={classes.amountSelectedUser}>
      Сумма выбранных пользователей: <span>{ convertorNumber(amount, 2, '.') } ₽</span>
    </Box>
	)
});
const RowInfo = React.memo((props) => {
  const {
    email,
    amount,
    user_id,
    checked,
    countries,
    countries_wc,
    user_external_id,
    onChangeCheckedUsers
  } = props;

  const _countries = () => {
    if (!countries) {
      return []
    }

    let _items = [];
    Object.keys(countries).map((t) => {
      _items.push({label: t, value: countries[t]})
    })

    return _items.filter((t) => t.value > 0)
  }
  const _countriesWc = () => {
    if (!countries_wc) {
      return []
    }

    let _items = [];
    Object.keys(countries_wc).map((t) => {
      _items.push({label: t, value: countries_wc[t]})
    })

    return _items.filter((t) => t.value > 0)
  }

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          checked={checked}
          name={user_id}
          onChange={onChangeCheckedUsers}
        />
      </TableCell>
      <TableCell>
        {`${ email } (${ user_external_id })`}
      </TableCell>
      <TableCell>
        {convertorNumber(amount)} ₽
      </TableCell>
      <TableCell>
        <Grid container spacing={1} direction="column">
          {_countries().map((item) => (
            <Grid item>
              { item.label }: <span style={{color: palette.primary.main}}>{item.value} ₽</span>
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell>
        <Grid container spacing={1} direction="column">
          {_countriesWc().map((item) => (
            <Grid item>
              { item.label }: <span style={{color: palette.primary.main}}>{item.value} ₽</span>
            </Grid>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  )
});
const RowsLoading = React.memo(() => {
  return (
    <>{Array(10).fill(0).map((t) => (
      <TableRow>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
      </TableRow>
    ))}</>
  )
})

const useStyles = makeStyles(() => ({
  amountSelectedUser: {
    padding: "4px 8px",
    boxSizing: "border-box",
    background: "#FB7800",
    marginLeft: 10,
    borderRadius: "4px 4px 0 0",

    fontSize: 14,
    lineHeight: "18px",
    color: "white",

    "& span": {
      fontWeight: "600"
    }
  }
}));

export default TableCustom
