import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Checkbox,
	TextField,
	Typography, FormControlLabel, IconButton, Collapse, Skeleton
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	ArrowCircleDown as ArrowCircleDownIcon
} from "@mui/icons-material";
import {
	AutocompleteService
} from "../../../../../../components";
import {
	Notification,
	NotificationTypes
} from "../../../../../../common/Notification";
import agent from "../../../../../../agent/agent";

class DialogEdit extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			providerUserList: [],
			providerServices: [],

			activeServiceList: [],

			searchName: "",

			providerId: null,
			onSubmit: null,

			isOpen: false,
			isLoading: true,
			isVisibleOnlyActive: false,
			isOpenListCountries: true
		}
	}

	open = ({ providerId, onSubmit }) => {
		this.setState({
			providerId,
			onSubmit,
			isOpen: true,
			isLoading: true,
		}, async () => {
			await this.getProviderServices();
		})
	}
	close = () => {
		this.setState({
			providerUserList: [],
			providerServices: [],

			activeServiceList: [],

			searchName: "",

			providerId: null,
			onSubmit: null,

			isOpen: false
		})
	}

	getProviderServices = async () => {
		let response = await agent.get(`/smsgoapi/go-provider/services/${ this.state.providerId }`).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		});
		this.setState({
			providerServices: response,
			isLoading: false
		})
	}

	changeExternalId = (item, value) => {
		let providerServices = [...this.state.providerServices];
		providerServices.find((t) => t.service_id === item.service_id).external_id = value;
		this.setState({providerServices});
	}
	changeSearchName = ({ target }) => {
		this.setState({
			searchName: target.value
		})
	}
	changeActiveCountryList = (service, value) => {
		let providerServices = [...this.state.providerServices];
		providerServices.find((t) => t.service_id === service).status = value;
		this.setState({providerServices});
	}

	addNewService = (service) => {
		let providerServices = [...this.state.providerServices];
		providerServices.push({
			external_id: String(service?.external_id),
			name: service.name,
			provider_id: this.state.providerId,
			service_id: service.internal_id,
			status: 1,
		})
		this.setState({providerServices});
	}

	saveServices = () => {
		const { providerServices } = this.state;
		const list = [...providerServices].filter((t) => !!t.status)
		this.state.onSubmit(this.state.providerId, list, false);
	}

	_filterListService = () => {
		const search = (this.state.searchName || "").toLowerCase();

		let list = [...this.state.providerServices];
		if (search) {
			list = list.filter((t) => {
				return Boolean(((t.name || '').toLowerCase()).indexOf(search) > -1)
			})
		}

		return list
	}

	render () {
		const {
			activeServiceList,
			searchName,
			providerServices,

			isOpen,
			isLoading,
			isOpenListCountries
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
				disablePortal={true}
				PaperProps={{
					sx: { overflow: "initial" }
				}}
				onClose={this.close}
			>
				<DialogTitle sx={{ backgroundColor: "#FB7800" }}>
					<Typography variant="h3" sx={{color: "white"}}>Редактирование провайдера</Typography>

					<TextField
						value={searchName}
						sx={{ marginTop: "8px" }}
						fullWidth
						size="small"
						variant="outlined"
						label="Наименование сервиса"
						placeholder="Начните печатать..."
						onChange={this.changeSearchName}
					/>
				</DialogTitle>

				<DialogContent>
					<Box py={2}>
						<Grid container>
							{Boolean(!isLoading) && (
								<>
									<Box width="100%">
										<Grid container alignItems="center" justifyContent="space-between">
											<Grid item>
												<Typography variant="h4">Список сервисов провайдера</Typography>
											</Grid>
											<Grid item>
												<IconButton
													size="small"
													onClick={() => this.setState({isOpenListCountries: !this.state.isOpenListCountries})}
												>
													<ArrowCircleDownIcon sx={{transform: Boolean(isOpenListCountries) && 'rotate(180deg)'}}/>
												</IconButton>
											</Grid>
										</Grid>
										<Collapse in={isOpenListCountries}>
											<Grid container spacing={1}>
												{this._filterListService().map((item, index) => (
													<Grid item key={`ElementForm-${item.external_id}-${item.service_id}-${index}`}>
														<ElementForm
															item={item}
															checked={Boolean(item.status)}
															onChange={this.changeActiveCountryList.bind(this, item.service_id, !Boolean(item.status))}
															onChangeExternalId={this.changeExternalId}
														/>
													</Grid>
												))}
											</Grid>
										</Collapse>
									</Box>
									<Box mt={2} width="100%">
										<Typography variant="h4" mb={1}>Добавление нового сервиса</Typography>
										<FormAddNewService
											providerServices={providerServices}
											onCreate={this.addNewService}
										/>
									</Box>
								</>
							)}
							{Boolean(isLoading) && (
								<Grid container spacing={1}>
									{[0,1,2,3,4,5,6,7,8,9].map((item, index) => (
										<Grid key={`skeleton-${index}`} item xs={12}>
											<Skeleton height={24} inset sx={{transform: "initial"}}/>
										</Grid>
									))}
								</Grid>
							)}
						</Grid>
					</Box>
				</DialogContent>

				<DialogActions>
					<Grid container justifyContent="flex-end">
						<Grid item>
							<Grid container spacing={2}>
								<Grid item>
									<Button
										variant="outlined"
										size="small"
										sx={{
											borderRadius: "4px",
											height: "auto",
											textTransform: "initial"
										}}
										onClick={this.close}
									>Отменить</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										size="small"
										sx={{
											borderRadius: "4px",
											height: "auto",
											textTransform: "initial"
										}}
										onClick={this.saveServices}
									>Сохранить</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

let timeOutChangeFromElementForm = null;
const ElementForm = React.memo((props) => {
	const {
		item,
		checked,
		onChange,
		onChangeExternalId
	} = props;

	const [externalId, setExternalId] = React.useState((item.external_id || ""));
	React.useEffect(() => {
		setExternalId(item.external_id || "");
	}, [item])

	const handleExternalId = ({ target }) => {
		clearTimeout(timeOutChangeFromElementForm);
		const { value } = target;
		setExternalId(value);
		timeOutChangeFromElementForm = setTimeout(() => {
			onChangeExternalId(item, value)
		}, 500);
	};

	return (
		<Grid container alignItems="center" spacing={1} wrap="nowrap">
			<Grid item>
				<FormControlLabel
					control={<Checkbox />}
					checked={checked}
					onChange={onChange}
					label={item.name}
				/>
			</Grid>
			<Grid item>
				<TextField
					value={externalId}
					label="Код поставщика"
					size="small"
					variant="outlined"
					placeholder="Код поставщика"
					onChange={handleExternalId}
				/>
			</Grid>
		</Grid>
	)
});

const FormAddNewService = React.memo((props) => {
	const {
		providerServices,
		onCreate
	} = props;
	const classes = useStyles();
	const [service, setService] = React.useState(null);

	const handleAddService = (service) => {


		setService(service);
	};
	const handleSubmitNewService = () => {
		if (!service) {
			Notification({
				type: NotificationTypes.error,
				message: "Выберите сервис"
			})
			return;
		}
		if (providerServices.find((t) => t.service_id === service.internal_id)) {
			Notification({
				type: NotificationTypes.error,
				message: "У вас уже добавлен данный сервис"
			});
			setService(null);

			return
		}
		onCreate(service);
		setService(null);
		Notification({
			type: NotificationTypes.success,
			message: "Новый сервис добавлен в Ваш список"
		})
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<AutocompleteService
					value={service?.external_id || ""}
					fullWidth
					onlyValue
					size="small"
					onChange={handleAddService}
				/>
			</Grid>
			<Grid item xs={12}>
				<Button
					size="small"
					variant="contained"
					fullWidth
					className={classes.buttonSubmit}
					onClick={handleSubmitNewService}
				>Добавить</Button>
			</Grid>
		</Grid>
	)
});
const useStyles = makeStyles(() => ({
	buttonSubmit: {
		padding: "5px",
		height: "auto",
		borderRadius: "4px",
		fontSize: "14px",
		lineHeight: "20px"
	}
}));

export default DialogEdit
