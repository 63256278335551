import React from "react";
import {
    Box
} from "@mui/material";

class Minimal extends React.Component {

    render () {
        return (
            <Box>
                <Box height="100vh" overflow="auto" px={2} pb={1} pt={6} boxSizing="border-box">
                    { this.props.children }
                </Box>
            </Box>
        )
    }
}

export default Minimal
