import React, {Component} from "react";
import {
  Box,
  Container
} from "@mui/material";
import {
  Filter,
  Table
} from "./components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

const initFilter = {
  user_id: "",
  services: []
};

class ExpressCheckBalances extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      leftovers: [],

      filter: {...initFilter},

      progressString: "",
      sort: "",

      isLoadData: false
    };
  }

  submitFilter = async (filter) => {
    let errors = [];
    if (!filter.user_id) {
      errors.push("Выберите пользователя")
    }
    // if (!filter.country) {
    //   errors.push("Выберите страну")
    // }
    if (!filter.services.length) {
      errors.push("Выберите сервис")
    }
    if (errors.length > 0) {
      Notification({
        type: NotificationTypes.error,
        message: errors.join(";<br/>")
      })
      return
    }

    await this.setState({filter});
    await this.getCountries();
  }

  getCountries = async () => {
    this.setState({
      leftovers: [],
      isLoadData: true,
      progressString: "Запрашиваем список стран"
    });
    const countries = await agent.get('/api/country').then((res) => {
      return res.data
    }).catch((err) => {
      return []
    });
    this.setState({progressString: `0 / ${countries.length}`})
    await Promise.all(countries.map(async (country, index) => {
      const list = await this._expressCheck(country.external_id);
      await this.setState({
        progressString: `${index} / ${countries.length}`,
        leftovers: [...list, ...this.state.leftovers]
      });
    }));
    this.setState({
      progressString: "",
      isLoadData: false
    })
  }
  _expressCheck = async (country) => {
    const filter = this.state.filter;
    return await agent.get(`/smsgoapi/gonumbers/express-check?user_id=${filter.user_id}&services=${filter.services.join(',')}&country=${country}`)
      .then((res) => {
        return res.data || []
      }).catch(() => {
        return []
      });
  }

  changeSort = async (sort) => {
    if (this.state.isLoadData) {
      return
    }

    const isSortNegative = Boolean((sort || "")[0] === '-');
    let leftovers = [...this.state.leftovers];

    if (sort.indexOf('service') > -1) {
      leftovers = leftovers.sort((a, b) => {
        const aName = a?.service?.name;
        const bName = b?.service?.name;
        if (aName > bName) {
          return isSortNegative ? 1 : -1
        }
        if (aName < bName) {
          return isSortNegative ? -1 : 1
        }
        return 0
      });
    }
    if (sort.indexOf('country') > -1) {
      leftovers = leftovers.sort((a, b) => {
        const aName = a?.countries?.[0]?.country?.name;
        const bName = b?.countries?.[0]?.country?.name;
        if (aName > bName) {
          return isSortNegative ? 1 : -1
        }
        if (aName < bName) {
          return isSortNegative ? -1 : 1
        }
        return 0
      });
    }
    if (sort.indexOf('status') > -1) {
      leftovers = leftovers.sort((a, b) => {
        const aStatus = a?.countries?.[0]?.status;
        const bStatus = b?.countries?.[0]?.status;
        if (aStatus > bStatus) {
          return isSortNegative ? 1 : -1
        }
        if (aStatus < bStatus) {
          return isSortNegative ? -1 : 1
        }
        return 0
      });
    }

    this.setState({
      leftovers,
      sort
    })
  }

  render() {
    const {
      filter,
      leftovers,
      sort,
      progressString,
      isLoadData
    } = this.state;

    return (
      <Container>
        <Filter
          data={filter}
          progress={progressString}
          isLoad={isLoadData}
          onSubmit={this.submitFilter}
        />

        {Boolean(leftovers.length) && (
          <>
            <Box mt={2}/>
            <Table
              data={leftovers}
              sort={sort}
              onChangeSort={this.changeSort}
            />
          </>
        )}
      </Container>
    );
  }
}

export default ExpressCheckBalances
