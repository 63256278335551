import React from "react";
import {
    TableCell,
    TableRow,

    Tooltip,
    Typography,
    IconButton,
    Collapse,
    Grid,
    Box,
} from "@mui/material";
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon
} from "@mui/icons-material";
import moment from "moment";

class TableRowItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            open: false,

            parseResponse: {}
        };
    }

    openCollapse = () => {
        const newOpen = !this.state.open;

        this.setState({ open: newOpen });

        if (!newOpen) {
            return
        }

        const parseResponse = JSON.parse(this.props.row?.response?.data || {});
        this.setState({ parseResponse });
    }

    render() {
        const {
            row
        } = this.props;
        const {
            open,
            parseResponse
        } = this.state;
        const Icon = Boolean(open) ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

        return (
            <>

                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <Tooltip title={Boolean(open) ? "Свернуть" : "Развернуть (могут возникнуть временные зависания сайта)"}>
                            <IconButton onClick={this.openCollapse}>
                                <Icon sx={{color: "#FB7800"}}/>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell>{ moment(row.created_at).format("DD.MM.YYYY / HH:mm") }</TableCell>
                    <TableCell>{ row.method }</TableCell>
                    <TableCell>{ row.code }</TableCell>
                    <TableCell>{ row.response_time } м.с.</TableCell>
                    <TableCell>
                        <Tooltip title={row.url}>
                            <Box maxWidth={240}>
                                <a href={row.url} target="_blank">
                                    <Typography sx={{color: "#FB7800", wordWrap: "break-word"}}>
                                        { row.url }
                                    </Typography>
                                </a>
                            </Box>
                        </Tooltip>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell style={{padding: 0}} colSpan={6}>
                        <Box borderBottom={Boolean(!open) ? "1px solid #FB7800" : ""}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box px={4} py={2} sx={{marginBottom: 2}} bgcolor="white" borderRadius="0 0 10px 10px" borderBottom="1px solid #FB7800">
                                    <Grid container spacing={6}>
                                        { Object.keys(parseResponse).map((item, index) => (
                                            <Grid item xs={6} key={`table-cel-${ row.id }-${ index }`}>
                                                {
                                                    Object.keys((parseResponse[item] || {})).map((key, indexKey) => (
                                                        <Typography key={`table-cel-${ row.id }-${ index }-${ indexKey }`}>
                                                            {`${ key }: Цена - ${ parseResponse?.[item]?.[key]?.['price'] }; Телефонов - ${ parseResponse?.[item]?.[key]?.['phones'] };`}
                                                        </Typography>
                                                    ))
                                                }
                                            </Grid>
                                        )) }
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </TableCell>
                </TableRow>

            </>
        );
    }
}

export default TableRowItem
