import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Typography,
    Pagination,
    Skeleton,

    Tooltip,
    IconButton
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from "@mui/icons-material";

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,

        isLoad,

        onChangeFilter,
        onChangePagination,


        onEdit,
        onDelete,
    } = props;
    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }
    const handleChangeSort = (sort) => {
        let newFilter = {...filter};
        newFilter.sort = sort;

        onChangeFilter(newFilter, true);
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>Наименование группы</TableCell>
                        <TableCell>Размер скидки</TableCell>
                        <TableCell align="right"/>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoad) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (

                                        <TableRow key={`row-item-${index}`}>
                                            <TableCell>{ row.name }</TableCell>
                                            <TableCell>{ row.discount } коэф.</TableCell>
                                            <TableCell>
                                                <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                                                    <Grid item>
                                                        <Tooltip title="Редактировать" arrow>
                                                            <IconButton onClick={() => onEdit(row)}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Удалить" arrow>
                                                            <IconButton onClick={() => onDelete(row)}>
                                                                <DeleteIcon color="error"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {}

}));

export default TableCustom
