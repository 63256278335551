import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Grid,
	Avatar,
	Tooltip,
	Checkbox,
	Skeleton,
	Pagination,
	IconButton,
} from "@mui/material";
import {} from "@mui/styles";
import {
	Edit as EditIcon,
	Delete as DeleteIcon
} from "@mui/icons-material";
import {
	VisibleContent
} from "../../../../../../components";
import moment from "moment";

const TableCustom = (props) => {
	const {
		data,
		filter,
		totalPage,
		isLoad,
		onEdit,
		onDelete,
		onChangePage
	} = props;

	const handleChangePage = (event, page) => {
		let newFilter = {...filter};
		newFilter.page = page;
		onChangePage(newFilter, true);
	}

	return (
		<>

			<Table>
				<TableHead>
					<TableRow>
						<TableCell>ID</TableCell>
						<TableCell>Наименования</TableCell>
						<TableCell>Часы</TableCell>
						<TableCell align="center">Получено СМС</TableCell>
						<TableCell align="center">Статус</TableCell>
						<TableCell>Провайдер</TableCell>
						<TableCell>Услуга</TableCell>
						<TableCell>Страна</TableCell>
						<TableCell>Кол-во</TableCell>
						<TableCell align="right">Дата обновления</TableCell>
						<TableCell align="right">Дата создания</TableCell>
						<TableCell align="right"/>
					</TableRow>
				</TableHead>
				<TableBody>
					{Boolean(!isLoad) && data.map((t) => (
						<RowData
							key={`RowData-${t.id}`}
							item={t}
							onEdit={onEdit}
							onDelete={onDelete}
						/>
					))}
					{Boolean(isLoad) && <RowLoading/>}
				</TableBody>
			</Table>

			<Grid container mt={2} justifyContent="flex-end">
				<Grid item>
					<Pagination
						page={filter.page}
						count={totalPage}
						onChange={handleChangePage}
					/>
				</Grid>
			</Grid>

		</>
	)
}
const RowData = React.memo((props) => {
	const {
		item,
		onEdit,
		onDelete
	} = props;

	return (
		<TableRow>
			<TableCell>{item.id}</TableCell>
			<TableCell>{item.filter_name}</TableCell>
			<TableCell>
				{item.hours}
			</TableCell>
			<TableCell align="center">
				<Checkbox checked={Boolean(item.has_code)}/>
			</TableCell>
			<TableCell align="center">
				<Checkbox checked={Boolean(item.status)}/>
			</TableCell>
			<TableCell>
				<VisibleContent visible={Boolean((item?.providers || []).length > 0)}>
					{(item?.providers || []).map((t) => (
						<div>{t.name || "-"}</div>
					))}
				</VisibleContent>
				<VisibleContent visible={Boolean((item?.providers || []).length <= 0)}>
					<span style={{color: "#FB7800", fontWeight: "600"}}>Все провайдеры</span>
				</VisibleContent>
			</TableCell>
			<TableCell>
				<VisibleContent visible={Boolean((item?.services || []).length > 0)}>
					<div style={{marginTop: -6}}>
						{(item?.services || []).map((t) => (
							<div style={{marginTop: 6}}>
								<Grid container spacing={1} alignItems="center" wrap="nowrap">
									<Grid item>
										<Avatar
											variant="square"
											src={`https://admsms.grizzlysms.com/api/storage/image/${t.icon_id}.webp`}
										/>
									</Grid>
									<Grid item>
										{t?.name}
									</Grid>
								</Grid>
							</div>
						))}
					</div>
				</VisibleContent>
				<VisibleContent visible={Boolean((item?.services || []).length <= 0)}>
					<span style={{color: "#FB7800", fontWeight: "600"}}>Все услуги</span>
				</VisibleContent>
			</TableCell>
			<TableCell>
				<VisibleContent visible={Boolean((item?.countries || []).length > 0)}>
					<div style={{marginTop: -6}}>
						{(item?.countries || []).map((t) => (
							<div style={{marginTop: 6}}>
								<Grid container spacing={1} alignItems="center" wrap="nowrap">
									<Grid item>
										<Avatar
											variant="square"
											src={`https://admsms.grizzlysms.com/api/storage/image/${t.icon_id}.webp`}
										/>
									</Grid>
									<Grid item>
										{t?.name}
									</Grid>
								</Grid>
							</div>
						))}
					</div>
				</VisibleContent>
				<VisibleContent visible={Boolean((item?.countries || []).length <= 0)}>
					<span style={{color: "#FB7800", fontWeight: "600"}}>Все страны</span>
				</VisibleContent>
			</TableCell>
			<TableCell>{item.count}</TableCell>
			<TableCell align="right">{moment(item.updated_at).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
			<TableCell align="right">{moment(item.created_at).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
			<TableCell align="right">
				<Grid container justifyContent="flex-end" wrap="nowrap">
					<Grid item>
						<Tooltip title="Удалить" arrow>
							<IconButton color="error" onClick={onDelete.bind(this, item, false)}>
								<DeleteIcon/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title="Редактировать" arrow>
							<IconButton color="primary" onClick={onEdit.bind(this, item, false)}>
								<EditIcon/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</TableCell>
		</TableRow>
	)
});
const RowLoading = React.memo(() => (
	<>
		{new Array(10).fill(1).map((t) => (
			<TableRow>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell align="right"><Skeleton/></TableCell>
				<TableCell align="right"><Skeleton/></TableCell>
				<TableCell align="right"><Skeleton/></TableCell>
			</TableRow>
		))}
	</>
))

export default React.memo(TableCustom)
