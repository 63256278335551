import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_HOST_API || 'https://grizzlysms.com',
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'x-user-locale': 'ru'
    }
})

export default axiosInstance
