// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import GoNumbers from './GoNumbers';

export default compose(
  connect(
    state => ({
      users: state?.global?.users || [],
      userRole: state.global?.userRole || ""
    }),
    dispatch => ({}),
  ),
)(GoNumbers);
