import React, {useState} from "react";
import {
    Box,
    Grid,
    Typography,
    Button,

    Collapse,

    FormControl,
    InputLabel,
    Select,
    MenuItem,

    TextField
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Search as SearchIcon,
    Clear as ClearIcon,
    FilterAlt as FilterAltIcon,
    HighlightOff as HighlightOffIcon,
    DocumentScanner as DocumentScannerIcon
} from "@mui/icons-material";
import {
    FilterUsers,
  FilterDateRangeFull
} from "../../../../../../components";
import moment from "moment";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import {convertorDateToString} from "../../../../../../helper/convertor";

const Filter = (props) => {
    const {
        paymentsType,

        filter,
        activeFilterCountry,
        initOpenFilter,

        onChange,
        onReset,
        onSearch,
        onUploadDocument,
        onChangeActiveFilterCountry
    } = props;
    const [isOpen, setOpen] = React.useState(true);
    const classes = useStyles();
    let now = new Date();
    const [startDate, setStartDate] = useState(moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)))
    const [endDate, setEndDate] = useState(moment(startDate).add(1, "days").subtract(1, "seconds"));
    const maxDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).add(1, "days").subtract(1, "seconds")
    let local = {
        format: "DD.MM.YY HH:mm",
        sundayFirst: false,
        days: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
        months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',],
        fromDate: 'Дата (от):',
        toDate: 'Дата (до):',
        selectingFrom: 'Выбрать от',
        selectingTo: 'Выбрать до',
        maxDate: 'Максимальная дата',
        close: 'Закрыть',
        apply: 'Принять',
        cancel: 'Отменить',
        showCustomRangeLabel: false,
    };
    let ranges = {
        "Сегодня": [moment(startDate), moment(endDate)],
        "Вчера": [moment(startDate).subtract(1, "days"), moment(endDate).subtract(1, "days")],
        "Неделя": [moment(startDate).subtract(7, "days"), moment(endDate)],
        "Месяц": [moment(startDate).subtract(1, "months"), moment(endDate)],
        "Год": [moment(startDate).subtract(1, "years"), moment(endDate)],
    }
    const dateTimeStyle = {
        fromDot: {backgroundColor: '#FB7800'},
        toDot: {backgroundColor: 'rgba(251,120,0, .6)'},
        fromDate: {backgroundColor: '#FB7800'},
        toDate: {backgroundColor: 'rgba(251,120,0, .6)'},
        hoverCell: {color: '#FB7800'},
        customRangeButtons: {backgroundColor: 'rgba(251,120,0, .6)', color: "#ffffff"},
        customRangeSelected: {backgroundColor: '#FB7800', color: "#ffffff"},
    }

    React.useEffect(() => {
        setOpen(initOpenFilter || true);
    }, [initOpenFilter]);

    const handleChangeDateRange = (values) => {
        let newFilter = {...filter};

        newFilter["date_start"] = values[0] || null;
        newFilter["date_end"] = values[1] || null;

        onChange(newFilter);
    }
    const handleChangeCommon = ({target}) => {
        const {name, value} = target;

        let newFilter = {...filter};
        newFilter[name] = value;

        onChange(newFilter);
    }

    const _activeFilters = () => {

        let items = [];

        if (filter["country"]) {
            items.push({
                label: "Страна",
                value: activeFilterCountry,
                onClick: _deleteCommon.bind(this, "country")
            })
        }
        if (filter["service"]) {
            items.push({
                label: "Услуга",
                value: filter["service"],
                onClick: _deleteCommon.bind(this, "service")
            })
        }
        if (filter["users"] && filter["users"].length > 0) {
            items.push({
                label: "ID пользователей",
                value: filter["users"].join(", "),
                onClick: _deleteCommonArray.bind(this, "users")
            })
        }
        if (filter["filter[type]"]) {
            items.push({
                label: "Тип операции",
                value: filter["filter[type]"],
                onClick: _deleteCommon.bind(this, "filter[type]")
            })
        }
        if (filter["filter[phone_number_id]"]) {
            items.push({
                label: "ИД транзакции",
                value: filter["filter[phone_number_id]"],
                onClick: _deleteCommon.bind(this, "filter[phone_number_id]")
            })
        }
        if (filter["date_start"] && filter["date_end"]) {
            const startDate = convertorDateToString(filter["date_start"]);
            const startEnd = convertorDateToString(filter["date_end"]);

            items.push({
                label: "Дата",
                value: `${startDate} - ${startEnd}`,
                onClick: _deleteDateRange.bind(this, "country")
            })
        }
        if (filter["filter[amount][gt]"] && filter["filter[amount][lt]"]) {
            items.push({
                label: "Сумма",
                value: `${filter["filter[amount][gt]"]} ₽ - ${filter["filter[amount][lt]"]} ₽`,
                onClick: _deleteAmountRange.bind(this)
            })
        }


        return items
    }
    const _deleteCommon = (name) => {
        let newFilter = {...filter};
        newFilter[name] = "";

        onChange(newFilter, true);
    }
    const _deleteCommonArray = (name) => {
        let newFilter = {...filter};
        newFilter[name] = [];

        onChange(newFilter, true);
    }
    const _deleteDateRange = () => {
        setStartDate(moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)));
        setEndDate(moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).add(1, "days").subtract(1, "seconds"));

        let newFilter = {...filter};
        newFilter["date_start"] = null;
        newFilter["date_end"] = null;

        onChange(newFilter, true);
    }
    const _deleteAmountRange = () => {
        let newFilter = {...filter};
        newFilter["filter[amount][gt]"] = null;
        newFilter["filter[amount][lt]"] = null;

        onChange(newFilter, true);
    }

    return (
        <Box mb={1}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Фильтры</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={onUploadDocument}
                                endIcon={<DocumentScannerIcon/>}
                            >
                                Выгрузить документ
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={() => setOpen(!isOpen)}
                                endIcon={<FilterAltIcon/>}
                            >
                                {isOpen ? "Свернуть" : "Развернуть"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={onReset}
                                endIcon={<ClearIcon/>}
                            >
                                Сбросить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                endIcon={<SearchIcon/>}
                                onClick={onSearch}
                            >
                                Поиск
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1}/>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>

                <Box px={2} py={2} bgcolor="white" borderRadius={4}>
                    <Grid container spacing={4}>
                        <Grid item sm={3}>
                            <FilterUsers
                                name="users"
                                value={filter["users"]}
                                size="small"
                                fullWidth
                                multiple


                                onChange={handleChangeCommon}
                            />
                        </Grid>
                        <Grid item sm={3} className={classes.dateTimePicker}>
                            <FilterDateRangeFull
                              value={[
                                  filter['date_start'],
                                  filter['date_end'],
                              ]}

                              onChange={handleChangeDateRange}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <TextField
                                label="За последних часов"
                                name="hours"
                                value={filter["hours"]}
                                size="small"
                                fullWidth

                                onChange={handleChangeCommon}
                            />
                        </Grid>
                    </Grid>
                </Box>

            </Collapse>

            {Boolean(_activeFilters().length > 0) && (
                <>

                    <Box my={1}/>

                    <Grid container spacing={2}>
                        {_activeFilters().map((item, index) => (
                            <Grid item>
                                <Box className={classes.active} onClick={item.onClick}>
                                    <Typography className={classes.activeLabel}>{item.label}:</Typography>
                                    <Typography className={classes.activeValue}>{item.value}</Typography>
                                    <HighlightOffIcon sx={{color: "white", fontSize: 16, marginLeft: 1}}/>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                </>
            )}

        </Box>
    )
}

const useStyles = makeStyles(() => ({

    active: {
        display: "flex",
        alignItems: "center",
        padding: "2px 12px",
        borderRadius: 6,
        backgroundColor: "#FB7800",
        cursor: "pointer",

        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600"
    },
    activeLabel: {
        opacity: 0.6,
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white"
    },
    activeValue: {
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
        marginLeft: 6
    },

    dateTimePicker: {
        "& .daterangepicker": {
            "& .applyButton": {
                backgroundColor: "rgba(251,120,0, .8) !important",
                border: "none !important",
                "&:hover": {
                    backgroundColor: "#FB7800 !important",
                }
            },
            "& .fromDateHourContainer": {
                border: "none !important",
            },
            "& .inputDate": {
                borderWidth: "2px !important",
                height: "33px !important",
                "&:focus": {
                    borderColor: "#FB7800 !important",
                    boxShadow: "none !important",
                }
            },
            "& .calendarAddon.input-group-addon": {
                borderWidth: "2px !important",
            },
            "& .multipleContentOnLine": {
                outline: "none !important",
                "& select": {
                    height: "33px !important",
                    borderRadius: "4px",
                    borderWidth: "2px",
                    outline: "none",
                    borderColor: "#ccc",
                    "&:focus": {
                        borderColor: "#FB7800 !important",
                        outline: "none !important",
                    }
                }
            },
            "& .monthYearContainer": {
                alignItems: "center",
            },
            "& .timeIconStyle": {
                top: "50%",
                transform: "translate(0, -50%)",
            },
            "& .glyphicon": {
                color: "#FB7800 !important"
            },
            "& .rangecontainer": {
                "& div": {
                    outline: "none !important",
                }
            },
            "& .calendarGrid": {
                "& div": {
                    outline: "none !important",
                }
            }
        }
    }
}));

export default Filter
