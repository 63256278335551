import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Select,
  Button,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText
} from "@mui/material";
import {
  Formik
} from "formik";
import * as Yup from "yup";
import {
  FilterCountry,
  FilterService,
  FilterCascade
} from "../../../../../../components";

const initForm = {
  "name": "",
  "countries_id": [],
  "service_id": [],
  "cascade_id": "",
  "priority": "",
  "status": ""
};
class PopUpForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      isEdit: false,
      isOpen: false,
      onSubmit: null
    };
    this.refFormik = React.createRef();
  }

  open = ({ form, onSubmit }) => {
    this.setState({
      form: {...(form ||initForm)},
      isOpen: true,
      isEdit: Boolean(form),
      onSubmit
    })
  }
  close = () => {
    this.setState({
      form: {...initForm},
      isOpen: false,
      isEdit: false,
      onSubmit: null
    })
  }

  changeForm = ({ target }) => {
    const { name, value } = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }

  onSubmit = () => {
    let values = this.refFormik.current.values;
    values.priority = Number.parseFloat(values.priority);
    this.state.onSubmit(values, true);
  }

  render () {
    const {
      form,
      isOpen,
      isEdit
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        disablePortal={true}
        PaperProps={{
          sx: {overflow: "initial"}
        }}
      >
        <DialogTitle sx={{backgroundColor: "rgba(251, 120, 0, 1)", borderRadius: "4px 4px 0 0"}}>
          <Typography variant="h3" sx={{color: "white"}}>
            {isEdit ? "Редактирование" : "Добавление"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            innerRef={this.refFormik}
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
          >{(props) => {
            const {
              values,
              errors,
              touched,
              handleSubmit,
            } = props;

            if (Object.keys(form || {}).length <= 0) {
              return null
            }
            return (
              <>
                <Grid container spacing={2} pt={2}>
                  <Grid item xs={12}>
                    <TextField
                      value={values.name}
                      name="name"
                      size="small"
                      label="Наименование"
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FilterCountry
                      name="countries_id"
                      value={values["countries_id"]}
                      size="small"
                      nameField="external_id"
                      fullWidth
                      multiple
                      error={Boolean(touched.countries_id && errors.countries_id)}
                      helperText={touched.countries_id && errors.countries_id}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FilterService
                      name="service_id"
                      value={values["service_id"]}
                      valueName="external_id"
                      size="small"
                      fullWidth
                      multiple
                      error={Boolean(touched.service_id && errors.service_id)}
                      helperText={touched.service_id && errors.service_id}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FilterCascade
                      name="cascade_id"
                      value={values["cascade_id"]}
                      size="small"
                      fullWidth
                      error={Boolean(touched.cascade_id && errors.cascade_id)}
                      helperText={touched.cascade_id && errors.cascade_id}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={values.priority}
                      name="priority"
                      size="small"
                      label="Приоритет"
                      type="number"
                      fullWidth
                      error={Boolean(touched.priority && errors.priority)}
                      helperText={touched.priority && errors.priority}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      size="small"
                      error={Boolean(touched.status && errors.status)}
                      fullWidth
                    >
                      <InputLabel>Статус</InputLabel>
                      <Select
                        value={values.status}
                        label="Статус"
                        name="status"
                        fullWidth
                        onChange={this.changeForm}
                        error={Boolean(touched.status && errors.status)}
                        helperText={touched.status && errors.status}
                      >
                        <MenuItem value={1}>Активен</MenuItem>
                        <MenuItem value={0}>Отключенный</MenuItem>
                      </Select>
                      {Boolean(touched.status && errors.status) && (
                        <FormHelperText error={true}>
                          {touched.status && errors.status}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{textTransform: "initial"}}
                      onClick={this.close}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      size="small"
                      variant="contained"
                      sx={{textTransform: "initial"}}
                      onClick={handleSubmit}
                    >
                      {isEdit ? 'Изменить' : 'Добавить'}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )
          }}</Formik>
        </DialogContent>
      </Dialog>
    )
  }
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Заполните наименование'),
  status: Yup.string().nullable().required('Выберите статус'),
  priority: Yup.string().nullable().required('Заполните приоритет'),
  cascade_id: Yup.string().nullable().required('Выберите поставщика'),
  service_id: Yup.array().when('countries_id', {
    is: countries_id => Boolean((countries_id || []).length <= 0),
    then: Yup.array().required().min(1, "Выберите минимум 1 услугу")
  }),
  countries_id: Yup.array().when('service_id', {
    is: service_id => Boolean(service_id.length <= 0),
    then: Yup.array().required().min(1, "Выберите минимум 1 страну"),
  })
}, [['service_id', 'countries_id']]);

export default PopUpForm
