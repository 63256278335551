import React, { Component } from "react";
import {
    Box,
    Grid,
    Button,
    TextField,
    Container
} from "@mui/material";
import {
    Table as TableComponent
} from "./components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import queryString from "query-string";
import moment from "moment";
import agent from "../../../../agent/agent";

const initFilter = {
    "sort": ""
};

class NumberRequests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],

            pagination: {
                page: 1,
                totalPage: 1
            },
            filter: {...initFilter},

            phoneNumber: "",

            isLoad: false,
            initOpenFilter: false,
            isShowBackdrop: false
        };
        this.apiController = null;
    }

    componentDidMount = async () => {
        await this.initFilter();
    }

    // Получение списка данных
    getListData = async () => {
        if (this.apiController) {
            this.apiController.abort();
        }
        if (!this.state.phoneNumber) {
            Notification({
                type: NotificationTypes.error,
                message: "Введите номер телефона"
            })

            return
        }

        this.setState({ isLoad: true });

        const filter = this._getFilter();
        const apiController = new AbortController();
        this.apiController = apiController;
        const response = await agent.get(`/smsgoapi/gonumbers/info${ filter }&number=${ this.state.phoneNumber }`, {
            signal: apiController.signal
        }).then((res) => {
            return res
        }).catch((err) => {
            return {
                data: [],
                headers: {}
            }
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: response?.headers?.["x-pagination-page-count"] || 1
        };

        this.setState({
            data: [response.data || []],
            pagination,
            isLoad: false
        });
    }


    // Логика работы фильтров
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }
    _getFilter = () => {

        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${ pagination.page }`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {

                let value = filter[key];

                if (
                    key === "date_start" ||
                    key === "date_end"
                ) {
                    value = moment(value).format("YYYY-MM-DD")
                }
                if (
                    key === "filter[amount][gt]" ||
                    key === "filter[amount][lt]"
                ) {
                    value = value.replace(/\D+/g,"");
                }

                string.push(`${ key }=${ value }`);

            }
        });

        window.history.replaceState(null, null, `/number-requests?${string.join("&")}`);

        return `?${ string.join("&") }`
    }


    // Логика радектирование номера телефона
    changePhoneNumber = ({ target }) => {
        const { value: phoneNumber } = target;

        this.setState({ phoneNumber })
    }
    resetPhoneNumber = () => {
        this.setState({
            data: [],
            phoneNumber: ""
        })
    }


    // Логика работы пагиации
    paginationChange = async (pagination) => {
        await this.setState({ pagination });

        await this.getListData();
    }


    render() {
        const {
            data,
            filter,
            pagination,

            phoneNumber,

            isLoad
        } = this.state;

        return (
            <Container maxWidth="xl">

                <Box mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                value={phoneNumber}
                                placeholder="Введите номер телефона"
                                fullWidth
                                size="small"
                                type="number"
                                onChange={this.changePhoneNumber}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                sx={{height: 37, textTransform: "initial", borderRadius: "4px", padding: "0 32px"}}
                                onClick={this.getListData}
                            >Поиск</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                size="small"
                                sx={{height: 37, textTransform: "initial", borderRadius: "4px", padding: "0 32px"}}
                                onClick={this.resetPhoneNumber}
                            >Сбросить</Button>
                        </Grid>
                    </Grid>
                </Box>

                <TableComponent
                    data={data}
                    filter={filter}
                    pagination={pagination}

                    isLoad={isLoad}

                    onChangePagination={this.paginationChange}
                />

            </Container>
        );
    }
}

export default NumberRequests
