import React, {Component} from "react";
import {
    Box,
    Backdrop,
    CircularProgress
} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent,
    DialogProviderForm as DialogProviderFormComponent,
    DialogProviderCreate as DialogProviderCreateComponent
} from "./components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

const initFilter = {
    "services": [],
    "countries": [],
    "users": [],
    "alias": "",
    "name": "",
    "active": "",
    "sort": "",
    "limit": 100,
    "page": 1
}

class GoProviders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            providers: [],

            filter: {...initFilter},

            isLoad: true,
            isShowBackdrop: false
        };

        this.apiController = null;
        this.refDialogProviderForm = React.createRef();
        this.refDialogProviderCreate = React.createRef();
    }

    componentDidMount = async () => {
        await this.getListProviders();
    }

    getListProviders = async () => {
        if (this.apiController) {
            this.apiController.abort();
        }
        const filter = this._getFilter();
        this.setState({isLoad: true});
        const apiController = new AbortController();
        this.apiController = apiController;
        const providers = await agent.post(`/smsgoapi/go-provider/list`, filter, {
            signal: apiController.signal
        }).then((res) => {
            return res?.data || []
        }).catch(() => {
            return []
        })

        this.setState({
            providers,
            isLoad: false
        });
    }
    _getFilter = () => {
        let filter = {...this.state.filter};
        Object.keys(filter).map((key) => {
            if (!filter[key]) {
                delete filter[key]
            }
            if (Array.isArray(filter[key]) && filter[key].length <= 0) {
                delete filter[key]
            }
            if (!!filter[key] && key === "active") {
                filter[key] = Boolean(filter[key] === "true");
            }
        })

        return filter
    }

    changeFilter = (filter) => {
        this.setState({filter});
    }
    resetFilter = async () => {
        await this.setState({filter: {...initFilter}});
        await this.getListProviders();
    }

    createProvider = async (form, isCreate = false) => {
        if (!isCreate) {
            this.refDialogProviderCreate.current.open({
                onSubmit: this.createProvider.bind(this)
            })
            return
        }

        this.setState({ isShowBackdrop: true });
        const res = await agent.post('/smsgoapi/go-provider/create-provider', form).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        });
        if (res?.error) {
            this.setState({ isShowBackdrop: false });
            Notification({
                type: NotificationTypes.error,
                message: "Ошибка создания товара"
            })
            return
        }

        this.refDialogProviderCreate.current.close();
        await this.getListProviders();
        this.setState({ isShowBackdrop: false });
        Notification({
            type: NotificationTypes.success,
            message: "Провайдер успешно создан"
        })
    }
    editProvider = async (provider, isSave = false) => {
        if (!isSave) {
            this.refDialogProviderForm.current.open({
                form: {...provider},
                onSubmit: this.editProvider.bind(this)
            })
            return null
        }

        this.setState({isShowBackdrop: true})

        provider.retry_count = Number.parseFloat(provider.retry_count);
        provider.req_timeout = Number.parseFloat(provider.req_timeout);
        const response = await agent.put(`/smsgoapi/go-provider/${provider.id}`, provider).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        });
        if (response.error) {
            this.setState({isShowBackdrop: false})

            Notification({
                type: NotificationTypes.error,
                message: "Возникл ошибка, обратитесь к администратору."
            })

            return null
        }

        await this.getListProviders();
        Notification({
            type: NotificationTypes.success,
            message: "Провайдер успешно изменен"
        });
        this.refDialogProviderForm.current.close();
        this.setState({isShowBackdrop: false});
    }

    render() {
        const {
            providers,
            filter,
            isLoad,
            isShowBackdrop
        } = this.state;

        return (
            <>

                <FilterComponent
                    filter={filter}

                    onCreate={this.createProvider}
                    onReset={this.resetFilter}
                    onSearch={this.getListProviders}
                    onChange={this.changeFilter}
                />

                <Box mt={2}/>

                <TableComponent
                    data={providers}
                    isLoad={isLoad}

                    onEditProvider={this.editProvider}
                />


                <DialogProviderFormComponent
                    ref={this.refDialogProviderForm}
                />
                <DialogProviderCreateComponent
                  ref={this.refDialogProviderCreate}
                />


                <Backdrop open={isShowBackdrop}>
                    <CircularProgress/>
                </Backdrop>

            </>
        );
    }
}

export default GoProviders
