import React from "react";
import {
  Box,
  Grid,
  Button,
  Collapse,
  TextField,
  Typography, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Clear as ClearIcon,
  FilterAlt as FilterAltIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import {
  FilterUsers,
  FilterDateRangeFull
} from "../../../../../../components";
import {IMaskInput} from "react-imask";

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCollapse: true
    }
  }

  changeCollapse = () => {
    this.setState({ isCollapse: !this.state.isCollapse })
  }
  changeCommon = ({target}) => {
    const {name, value} = target;

    let newFilter = {...this.props.filter};
    newFilter[name] = value;

    this.props.onChange(newFilter);
  }
  changeDateRange = (values) => {
    let newFilter = {...this.props.filter};

    newFilter["filter[created_at][gt]"] = values[0] || null;
    newFilter["filter[created_at][lt]"] = values[1] || null;

    this.props.onChange(newFilter);
  }

  render () {
    const {
      isCollapse
    } = this.state;
    const {
      filter,
      classes,
      loyalty,

      onReset,
      onSearch,
    } = this.props;


    return (
      <Box mt={1}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">Фильтры</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                  onClick={this.changeCollapse}
                  endIcon={<FilterAltIcon/>}
                >
                  {isCollapse ? "Свернуть" : "Развернуть"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                  onClick={onReset}
                  endIcon={<ClearIcon/>}
                >
                  Сбросить
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                  endIcon={<SearchIcon/>}
                  onClick={onSearch}
                >
                  Поиск
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box mt={1}/>

        <Collapse in={isCollapse} timeout="auto" unmountOnExit>
          <Box px={2} py={2} bgcolor="white" borderRadius={4}>
            <Grid container spacing={2}>
              <Grid item sm={1.5}>
                <TextField
                  value={filter['filter[id]']}
                  name="filter[id]"
                  label="ID"
                  size="small"
                  fullWidth
                  onChange={this.changeCommon}
                />
              </Grid>
              <Grid item sm={1.5}>
                <FormControl fullWidth size="small">
                  <InputLabel>Реферальная программа</InputLabel>
                  <Select
                    value={filter['filter[loyalty_id]']}
                    name="filter[loyalty_id]"
                    label="Реферальная программа"
                    onChange={this.changeCommon}
                  >
                    <MenuItem value="">Сбросить</MenuItem>
                    {loyalty.map((t) => (
                      <MenuItem value={t.id}>{ t.name }</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={3} className={classes.dateTimePicker}>
                <FilterDateRangeFull
                  value={[
                    filter['filter[created_at][gt]'],
                    filter['filter[created_at][lt]'],
                  ]}
                  onChange={this.changeDateRange}
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  value={filter['filter[amount][gt]']}
                  name="filter[amount][gt]"
                  label="Сумма (от)"
                  size="small"
                  fullWidth
                  inputProps={{ scale: 2 }}
                  InputProps={{ inputComponent: CustomInputAmount }}
                  onChange={this.changeCommon}
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  value={filter['filter[amount][lt]']}
                  name="filter[amount][lt]"
                  label="Сумма (до)"
                  size="small"
                  fullWidth
                  inputProps={{ scale: 2 }}
                  InputProps={{ inputComponent: CustomInputAmount }}
                  onChange={this.changeCommon}
                />
              </Grid>
              <Grid item sm={2}>
                <FilterUsers
                  name="filter[user_id]"
                  value={filter["filter[user_id]"]}
                  size="small"
                  valueName="id"
                  fullWidth
                  onChange={this.changeCommon}
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Box>
    )
  }
}
const CustomInputAmount = ({inputRef, ...rest}) => {

  const onComplete = (value) => {
    rest.onChange({
      target: {
        name: rest.name,
        value
      }
    })
  }

  return (
    <IMaskInput
      ref={inputRef}
      mask={Number}
      thousandsSeparator=""
      radix="."
      mapToRadix={[',']}
      scale={rest?.scale || 4}
      padFractionalZeros={true}

      {...rest}

      onComplete={onComplete}
      onChange={null}
    />
  )
}

const styles = {
  active: {
    display: "flex",
    alignItems: "center",
    padding: "2px 12px",
    borderRadius: 6,
    backgroundColor: "#FB7800",
    cursor: "pointer",

    fontSize: 15,
    lineHeight: "22px",
    fontWeight: "600"
  },
  activeLabel: {
    opacity: 0.6,
    fontSize: 15,
    lineHeight: "22px",
    fontWeight: "600",
    color: "white"
  },
  activeValue: {
    fontSize: 15,
    lineHeight: "22px",
    fontWeight: "600",
    color: "white",
    marginLeft: 6
  },

  dateTimePicker: {
    "& .daterangepicker": {
      "& .applyButton": {
        backgroundColor: "rgba(251,120,0, .8) !important",
        border: "none !important",
        "&:hover": {
          backgroundColor: "#FB7800 !important",
        }
      },
      "& .fromDateHourContainer": {
        border: "none !important",
      },
      "& .inputDate": {
        borderWidth: "2px !important",
        height: "33px !important",
        "&:focus": {
          borderColor: "#FB7800 !important",
          boxShadow: "none !important",
        }
      },
      "& .calendarAddon.input-group-addon": {
        borderWidth: "2px !important",
      },
      "& .multipleContentOnLine": {
        outline: "none !important",
        "& select": {
          height: "33px !important",
          borderRadius: "4px",
          borderWidth: "2px",
          outline: "none",
          borderColor: "#ccc",
          "&:focus": {
            borderColor: "#FB7800 !important",
            outline: "none !important",
          }
        }
      },
      "& .monthYearContainer": {
        alignItems: "center",
      },
      "& .timeIconStyle": {
        top: "50%",
        transform: "translate(0, -50%)",
      },
      "& .glyphicon": {
        color: "#FB7800 !important"
      },
      "& .rangecontainer": {
        "& div": {
          outline: "none !important",
        }
      },
      "& .calendarGrid": {
        "& div": {
          outline: "none !important",
        }
      }
    }
  }
};
Filter = withStyles(styles)(Filter);

export default Filter
