import React from "react";
import {
    Box,
    Grid,
    Skeleton,
    Typography,

    FormControl,
    InputLabel,
    Select,
    MenuItem,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {convertorNumber} from "../../../../../../helper/convertor";


const CardUsers = (props) => {
    const {
        data,
        hours,
        isLoad,

        onChange
    } = props;
    const classes = useStyles();


    const handleChangeHour = ({ target }) => {
        onChange(target.value)
    }

    return (
        <Box className={classes.root}>
            <Box mb={2}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h3" fontWeight="600">Топ пользователей по доходу</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Сервис</TableCell>
                        <TableCell align="right">Сумма</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Boolean(!isLoad) && (
                      <>
                          {data.map((user, index) => (
                            <TableRow key={`user-row-${ index }`}>
                                <TableCell>{ user.email || "Удаленный пользователь" }</TableCell>
                                <TableCell>{ user.service }</TableCell>
                                <TableCell align="right">{ convertorNumber(user.price_sum, 2) }</TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                    {Boolean(isLoad) && (
                      <>
                          {[0,1,2,3,4,5].map((user, index) => (
                            <TableRow key={`user-row-load-${ index }`}>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell align="right"><Skeleton/></TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                </TableBody>
            </Table>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        boxSizing: "border-box",

        padding: "24px 36px",
        borderRadius: 4,
        backgroundColor: "rgba(251, 120, 0, 0.1)"
    },

    table: {
        "& .MuiTableHead-root": {
            boxShadow: "none"
        },
        "& .MuiTableHead-root > tr > th": {
            backgroundColor: "transparent",
            padding: "4px 0",
            border: "none",

            color: "black",
            opacity: 0.6,
            fontWeight: "normal"
        },
        "& .MuiTableBody-root > tr > td": {
            backgroundColor: "transparent",
            padding: "8px 0",
            border: "none",

            color: "black",
            fontWeight: "normal"
        },
    }
}));

export default CardUsers
