import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Box,
    Grid,
    Button,
    Divider,
    Checkbox,
    TextField,
    Typography,
    FormControlLabel,
} from "@mui/material";
import {
    Formik
} from "formik";
import * as Yup from "yup";
import {IMaskInput} from "react-imask";
import {FilterUsers} from "../../../../../../components";
import {AdapterSelect} from "../DialogProviderCreate";
import agent from "../../../../../../agent/agent";

const initNotificationSettings = {
    enable: false,
    common_chat_id: '',
    with_country_chat_id: '',
    with_additional_chat_id: '',
};

class DialogProviderForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            adapters: [],

            form: {},

            onSubmit: null,

            isOpen: false
        }

        this.refFormik = React.createRef();
    }

    open = ({form: initForm, onSubmit}) => {
        let form = {...initForm};
        form.notification_settings = {
            ...initNotificationSettings,
            ...(JSON.parse(form?.settings?.notification_settings))
        };

        this.setState({
            form,
            onSubmit,

            isOpen: true
        }, async () => {
            await this.getAdapters();
        });

    }
    close = () => {
        this.setState({
            form: {},
            onSubmit: null,

            isOpen: false
        })
    }

    getAdapters = async () => {
        const { adapters } = this.state;
        if (adapters.length > 0) {
            return null
        }

        const res = await agent.get('/smsgoapi/go-provider/adapters').then((res) => {
            return res.data
        }).catch(() => {
            return []
        });
        this.setState({ adapters: res });
    }

    onSubmit = (form) => {
        let body = {...form};
        if (body.user_id) {
            body.user_id = Number(body.user_id);
        }

        body.settings.notification_settings = JSON.stringify(body.notification_settings);
        delete body.notification_settings;

        this.state.onSubmit(body, true);
    }
    onValidate = () => {
        this.refFormik.current.handleSubmit();
    }

    changeForm = ({target}) => {
      if (!this.refFormik.current) {
        return
      }

        const {name, value} = target;
        let values = this.refFormik.current.values;
        values[name] = value;
        this.refFormik.current.setValues(values);
    }
    changeFormSettings = ({target}) => {
        const {name, value} = target;
        let values = this.refFormik.current.values;
        values['settings'][name] = value;
        this.refFormik.current.setValues(values);
    }
    changeFormNotificationSettings = ({target}) => {
        const {name, value} = target;
        let values = this.refFormik.current.values;
        values['notification_settings'][name] = value;
        this.refFormik.current.setValues(values);
    }

    _filterUserId = (list, params) => {
        list = [...list].filter((t) => t.adapter === this.refFormik.current.values?.adapter);
        return list
    }

    render() {
        const {
            form,
            isOpen,
            adapters
        } = this.state;

        if (!isOpen) {
            return null
        }

        return (
            <Dialog
                open={isOpen}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="h3">Редактирование</Typography>
                </DialogTitle>

                <DialogContent>
                    <Formik
                        innerRef={this.refFormik}
                        initialValues={form}
                        validationSchema={validationSchema}
                        onSubmit={this.onSubmit}
                    >{(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit,
                        } = props;

                        return (
                            <>

                                <Box mt={1} mb={2}>
                                    <TextField
                                        value={values.name}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                        name="name"
                                        label="Наименование"
                                        placeholder="Наименование"
                                        variant="outlined"
                                        size="small"
                                        fullWidth

                                        onChange={this.changeForm}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        value={values.api_token}
                                        error={Boolean(touched.api_token && errors.api_token)}
                                        helperText={touched.api_token && errors.api_token}
                                        name="api_token"
                                        label="API-токен"
                                        placeholder="API-токен"
                                        variant="outlined"
                                        size="small"
                                        fullWidth

                                        onChange={this.changeForm}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                      value={String(values.client_id || '')}
                                      name="client_id"
                                      label="API KEY 2"
                                      placeholder="..."
                                      size="small"
                                      fullWidth
                                      onChange={this.changeForm}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                      value={String(values.base_url || '')}
                                      name="base_url"
                                      label="Базовый URL"
                                      placeholder="..."
                                      size="small"
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      onChange={this.changeForm}
                                      error={Boolean(errors.base_url && touched.base_url)}
                                      helperText={touched.base_url && errors.base_url}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <AdapterSelect
                                      value={values.adapter}
                                      name="adapter"
                                      options={adapters}
                                      error={Boolean(errors.adapter && touched.adapter)}
                                      helperText={touched.adapter && errors.adapter}
                                      onChange={this.changeForm}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <FilterUsers
                                      value={values.user_id}
                                      name="user_id"
                                      size="small"
                                      label="Пользователь"
                                      valueName="id"
                                      disabled={!values.adapter}
                                      onChange={this.changeForm}
                                      error={Boolean(errors.user_id && touched.user_id)}
                                      helperText={touched.user_id && errors.user_id}
                                      customFilterOptions={this._filterUserId}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        value={values.priority}
                                        error={Boolean(touched.appriorityi_token && errors.priority)}
                                        helperText={touched.priority && errors.priority}
                                        name="priority"
                                        label="Приоритет"
                                        placeholder="Приоритет"
                                        variant="outlined"
                                        size="small"
                                        fullWidth

                                        onChange={this.changeForm}
                                    />
                                </Box>
                                <Box mb={2}>
                                <TextField
                                  value={String(values.retry_count || '')}
                                  name="retry_count"
                                  label="Количество повторов на взятие номера"
                                  placeholder="..."
                                  size="small"
                                  fullWidth
                                  inputProps={{scale: 0}}
                                  InputProps={{inputComponent: CustomInputAmount}}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={this.changeForm}
                                />
                              </Box>
                                <Box mb={2}>
                                <TextField
                                  value={String(values.req_timeout || '')}
                                  name="req_timeout"
                                  label="Таймаут на запрос"
                                  placeholder="..."
                                  size="small"
                                  fullWidth
                                  inputProps={{scale: 0}}
                                  InputProps={{inputComponent: CustomInputAmount}}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={this.changeForm}
                                />
                              </Box>
                                <Box mb={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={values.active}/>}
                                        name="active"
                                        label="Активный"
                                        onChange={(event, value) => this.changeForm({target: {value, name: "active"}})}
                                    />
                                </Box>

                                <Box my={4}>
                                    <Divider/>
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        value={values.settings.min_profit_coefficient_wholesale}
                                        error={
                                            Boolean(touched.settings?.min_profit_coefficient_wholesale
                                                && errors.settings?.min_profit_coefficient_wholesale)
                                        }
                                        helperText={
                                            touched.settings?.min_profit_coefficient_wholesale
                                            && errors.settings?.min_profit_coefficient_wholesale
                                        }
                                        name="min_profit_coefficient_wholesale"
                                        label="Мин. коэфф. прибыли (опт)"
                                        placeholder="Мин. коэфф. прибыли (опт)"
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        fullWidth

                                        onChange={this.changeFormSettings}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        value={values.settings.price_coefficient_wholesale}
                                        error={Boolean(touched.settings?.price_coefficient_wholesale && errors.settings?.price_coefficient_wholesale)}
                                        helperText={touched.settings?.price_coefficient_wholesale && errors.settings?.price_coefficient_wholesale}
                                        name="price_coefficient_wholesale"
                                        label="Ценовой коэффициент(опт)"
                                        placeholder="Ценовой коэффициент(опт)"
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        fullWidth

                                        onChange={this.changeFormSettings}
                                    />
                                </Box>
                                <Box my={4}>
                                    <Divider/>
                                </Box>

                                <Box mb={2}>
                                    <TextField
                                        value={values.settings.min_profit_coefficient}
                                        error={Boolean(touched.settings?.min_profit_coefficient && errors.settings?.min_profit_coefficient)}
                                        helperText={touched.settings?.min_profit_coefficient && errors.settings?.min_profit_coefficient}
                                        name="min_profit_coefficient"
                                        label="Мин. коэфф. прибыли"
                                        placeholder="Мин. коэфф. прибыли"
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        fullWidth

                                        onChange={this.changeFormSettings}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        value={values.settings.price_coefficient}
                                        error={Boolean(touched.settings?.price_coefficient && errors.settings?.price_coefficient)}
                                        helperText={touched.settings?.price_coefficient && errors.settings?.price_coefficient}
                                        name="price_coefficient"
                                        label="Ценовой коэффициент"
                                        placeholder="Ценовой коэффициент"
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        fullWidth

                                        onChange={this.changeFormSettings}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        value={values.settings.currency_rate}
                                        error={Boolean(touched.settings?.currency_rate && errors.settings?.currency_rate)}
                                        helperText={touched.settings?.currency_rate && errors.settings?.currency_rate}
                                        name="currency_rate"
                                        label="Курс валюты"
                                        placeholder="Курс валюты"
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        fullWidth

                                        onChange={this.changeFormSettings}
                                    />
                                </Box>

                                <Box mb={2}>
                                    <Typography variant="h5" sx={{marginBottom: "20px"}}>Процент реферальной
                                        программы</Typography>
                                    <FieldRefCoefficient
                                        value={values.settings.ref_coefficient}
                                        name="ref_coefficient"
                                        error={Boolean(touched.settings?.ref_coefficient && errors.settings?.ref_coefficient)}
                                        helperText={touched.settings?.ref_coefficient && errors.settings?.ref_coefficient}
                                        onChange={this.changeFormSettings}
                                    />
                                </Box>

                                <Box mb={2}>
                                    <Typography variant="h5" sx={{marginBottom: "20px"}}>
                                        Настройки уведомлений
                                    </Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                              control={<Checkbox checked={values.notification_settings.enable}/>}
                                              name="enable"
                                              label="Включено"
                                              onChange={(event, value) => this.changeFormNotificationSettings({target: {value, name: "enable"}})}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                              value={values.notification_settings.common_chat_id}
                                              error={Boolean(touched.notification_settings?.common_chat_id && errors.notification_settings?.common_chat_id)}
                                              helperText={touched.notification_settings?.common_chat_id && errors.notification_settings?.common_chat_id}
                                              name="common_chat_id"
                                              label="Общий идентификатор чата"
                                              placeholder="Общий идентификатор чата"
                                              variant="outlined"
                                              size="small"
                                              fullWidth

                                              onChange={this.changeFormNotificationSettings}
                                            />
                                        </Grid>
                                        {/*<Grid item xs={12}>*/}
                                        {/*    <TextField*/}
                                        {/*      value={values.notification_settings.with_country_chat_id}*/}
                                        {/*      error={Boolean(touched.notification_settings?.with_country_chat_id && errors.notification_settings?.with_country_chat_id)}*/}
                                        {/*      helperText={touched.notification_settings?.with_country_chat_id && errors.notification_settings?.with_country_chat_id}*/}
                                        {/*      name="with_country_chat_id"*/}
                                        {/*      label="Идентификатор чата с учетом страны"*/}
                                        {/*      placeholder="Идентификатор чата с учетом страны"*/}
                                        {/*      variant="outlined"*/}
                                        {/*      size="small"*/}
                                        {/*      fullWidth*/}

                                        {/*      onChange={this.changeFormNotificationSettings}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}
                                        <Grid item xs={12}>
                                            <TextField
                                              value={values.notification_settings.with_additional_chat_id}
                                              error={Boolean(touched.notification_settings?.with_additional_chat_id && errors.notification_settings?.with_additional_chat_id)}
                                              helperText={touched.notification_settings?.with_additional_chat_id && errors.notification_settings?.with_additional_chat_id}
                                              name="with_additional_chat_id"
                                              label="Идентификатор чата с учетом дополнительных поставщиков"
                                              placeholder="Идентификатор чата с учетом дополнительных поставщиков"
                                              variant="outlined"
                                              size="small"
                                              fullWidth

                                              onChange={this.changeFormNotificationSettings}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                            </>
                        )
                    }}</Formik>
                </DialogContent>

                <DialogActions>
                    <Box px={2}>
                        <Grid container justifyContent="flex-end" spacing={2}>
                            <Grid item>
                                <Button variant="outlined" color="primary" sx={{
                                    height: "auto",
                                    textTransform: "initial",
                                    padding: "4px 16px",
                                    borderRadius: "4px"
                                }} onClick={this.close}>Отменить</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" size="small" sx={{
                                    height: "auto",
                                    textTransform: "initial",
                                    padding: "4px 16px",
                                    borderRadius: "4px"
                                }} onClick={this.onValidate}>Сохранить</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogActions>
            </Dialog>
        )
    }
}

const FieldRefCoefficient = React.memo((props) => {
    const {
        value,
        name,

        onChange
    } = props;
    const [parseValue, setParseValue] = React.useState({});
    const [indexAdd, setIndexAdd] = React.useState(0);

    React.useEffect(() => {
        const valueJson = JSON.parse(value || "{}");
        let newParseValue = {};

        let index = indexAdd + 1;
        Object.keys(valueJson).map((key) => {
            newParseValue[index] = {
                key: key,
                value: valueJson[key]
            };
            index = index + 1;
        });

        setIndexAdd(index);

        setParseValue(newParseValue);
    }, [value]);

    const handleInputKey = ({target}, key) => {
        const {value} = target;

        let newParseValue = {...parseValue};
        newParseValue[key]['key'] = value;

        handleChangeValue(newParseValue);
    }
    const handleInputValue = ({target}, key) => {
        const {value} = target;

        let newParseValue = {...parseValue};
        newParseValue[key]['value'] = value;

        handleChangeValue(newParseValue);
    }
    const handleAddNewItem = () => {
        let newParseValue = {...parseValue};
        newParseValue[indexAdd] = {
            key: "key",
            value: "",
        }
        setIndexAdd(indexAdd + 1);
        handleChangeValue(newParseValue)
    }
    const handleRemoveItem = (key) => {
        let newParseValue = {...parseValue};
        delete newParseValue[key];
        setIndexAdd(indexAdd + 1);
        handleChangeValue(newParseValue);
    }

    const handleChangeValue = (newParseValue) => {
        let sendValues = {};
        Object.keys(newParseValue).map((key) => {
            const value = newParseValue[key];
            sendValues[value.key] = Number.parseFloat(value.value);
        });

        onChange({
            target: {
                value: JSON.stringify(sendValues),
                name: name
            }
        })
    }

    return (
        <Grid container spacing={2}>
            {Object.keys(parseValue).map((key) => {
                const value = parseValue[key];
                const disabled = Boolean(value.key === "default");

                return (
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <TextField
                                    value={value.key}
                                    fullWidth
                                    disabled={disabled}
                                    label="Ключ"
                                    placeholder="Введите ключ"
                                    size="small"
                                    onChange={(event) => handleInputKey(event, key)}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    value={value.value}
                                    fullWidth
                                    type="number"
                                    label="Значение"
                                    placeholder="Введите Значение"
                                    size="small"
                                    onChange={(event) => handleInputValue(event, key)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    disabled={disabled}
                                    sx={{
                                        height: 38,
                                        borderRadius: "4px",
                                        fontSize: 12
                                    }}
                                    onClick={() => handleRemoveItem(key)}
                                >
                                    Удалить
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="contained"
                    size="small"
                    sx={{
                        height: "auto",
                        minHeight: "auto",
                        fontSize: 12,
                        borderRadius: "4px"
                    }}
                    onClick={handleAddNewItem}
                >
                    Добавить новый пункт
                </Button>
            </Grid>
        </Grid>
    )
});
const CustomInputAmount = ({inputRef, ...rest}) => {

  const onComplete = (value) => {
    rest.onChange({
      target: {
        name: rest.name,
        value
      }
    })
  }

  return (
    <IMaskInput
      ref={inputRef}
      mask={Number}
      thousandsSeparator=""
      scale={rest?.scale || 4}
      min={0}

      {...rest}

      onComplete={onComplete}
      onChange={null}
    />
  )
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Заполните поле'),
    api_token: Yup.string().required('Заполните поле'),
    priority: Yup.string().required('Заполните поле'),
    base_url: Yup.string().required('Заполните поле'),
    adapter: Yup.string().required('Заполните поле'),

    settings: Yup.object().shape({
        price_coefficient_wholesale: Yup.number().required('Заполните поле'),
        min_profit_coefficient_wholesale: Yup.number().required('Заполните поле'),
        min_profit_coefficient: Yup.number().required('Заполните поле'),
        price_coefficient: Yup.number().required('Заполните поле'),
        ref_coefficient: Yup.string().required('Заполните поле'),
        currency_rate: Yup.number().required('Заполните поле'),
    })
});

export default DialogProviderForm
