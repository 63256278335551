import React from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Divider,
    Collapse, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Search as SearchIcon,
    Clear as ClearIcon,
    FilterAlt as FilterAltIcon,
    HighlightOff as HighlightOffIcon, DocumentScanner as DocumentScannerIcon
} from "@mui/icons-material";
import {
    FilterAmountRange,
    FilterCountry,
    FilterService,
    FilterUsers,
    FilterDateRangeFull, FilterProvider
} from "../../../../../../components";
import moment from "moment";
import {convertorDateToString, convertorNumber} from "../../../../../../helper/convertor";

const Filter = (props) => {
    const {
        filter,
        userRole,
        activeFilterCountry,
        totalCount,
        userCounts,

        onChange,
        onReset,
        onSearch,
        onExportStatistics,
        onChangeActiveFilterCountry
    } = props;
    const [isOpen, setOpen] = React.useState(true);
    const classes = useStyles();

    const handleChangeDateRange = (values) => {
        let newFilter = {...filter};

        newFilter["filter[created_at][gt]"] = values[0] || null;
        newFilter["filter[created_at][lt]"] = values[1] || null;

        onChange(newFilter);
    }
    const handleChangeAmountRange = (value) => {
        let newFilter = {...filter};

        newFilter["filter[price][gt]"] = value[0];
        newFilter["filter[price][lt]"] = value[1];

        onChange(newFilter);
    }
    const handleChangeCommon = ({target}) => {
        const {name, value} = target;

        let newFilter = {...filter};
        newFilter[name] = value;

        onChange(newFilter);
    }

    const _activeFilters = () => {

        let items = [];

        if (filter["filter[country]"]) {
            items.push({
                label: "Страна",
                value: activeFilterCountry,
                onClick: _deleteCommon.bind(this, "filter[country]")
            })
        }
        if (filter["filter[service]"]) {
            items.push({
                label: "Услуга",
                value: filter["filter[service]"],
                onClick: _deleteCommon.bind(this, "filter[service]")
            })
        }
        if (filter["filter[user_id]"]) {
            items.push({
                label: "ID пользователя",
                value: filter["filter[user_id]"],
                onClick: _deleteCommon.bind(this, "filter[user_id]")
            })
        }
        if (filter["filter[activation_status]"]) {
            items.push({
                label: "Статус",
                value: filter["filter[activation_status]"],
                onClick: _deleteCommon.bind(this, "filter[activation_status]")
            })
        }
        if (filter["filter[created_at][gt]"] && filter["filter[created_at][lt]"]) {
            const start = convertorDateToString(filter["filter[created_at][gt]"]);
            const end = convertorDateToString(filter["filter[created_at][lt]"]);

            items.push({
                label: "Дата",
                value: `${start} - ${end}`,
                onClick: _deleteDateRange.bind(this)
            })
        }
        if (filter["filter[price][gt]"] && filter["filter[price][lt]"]) {
            items.push({
                label: "Стоимость",
                value: `${filter["filter[price][gt]"]} ₽ - ${filter["filter[price][lt]"]} ₽`,
                onClick: _deleteAmountRange.bind(this)
            })
        }


        return items
    }
    const _deleteCommon = (name) => {
        let newFilter = {...filter};
        newFilter[name] = "";

        onChange(newFilter, true);
    }
    const _deleteDateRange = () => {
        let newFilter = {...filter};
        newFilter["filter[created_at][gt]"] = null;
        newFilter["filter[created_at][lt]"] = null;

        onChange(newFilter, true);
    }
    const _deleteAmountRange = () => {
        let newFilter = {...filter};
        newFilter["filter[price][gt]"] = "";
        newFilter["filter[price][lt]"] = "";

        onChange(newFilter, true);
    }

    return (
        <Box mb={1}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">
                        Фильтры
                        <Typography variant="body2" component="span" sx={{marginLeft: 1}}>всего найдено: {convertorNumber(totalCount)}</Typography>
                        <Typography variant="body2" component="span" sx={{marginLeft: 1}}>уникальных пользователей: {userCounts}</Typography>
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                              variant="outlined"
                              size="small"
                              sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                              onClick={onExportStatistics}
                              endIcon={<DocumentScannerIcon/>}
                            >
                                Выгрузить документ
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={() => setOpen(!isOpen)}
                                endIcon={<FilterAltIcon/>}
                            >
                                {isOpen ? "Свернуть" : "Развернуть"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={onReset}
                                endIcon={<ClearIcon/>}
                            >
                                Сбросить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                endIcon={<SearchIcon/>}
                                onClick={onSearch}
                            >
                                Поиск
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1}/>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>

                <Box px={2} py={2} bgcolor="white" borderRadius={4}>
                    <Grid container spacing={4}>
                        <Grid item sm={3}>
                            <FilterCountry
                                name="filter[country]"
                                value={filter["filter[country]"]}
                                size="small"
                                fullWidth

                                onChange={handleChangeCommon}
                                onChangeActiveFilterCountry={onChangeActiveFilterCountry}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FilterService
                                name="filter[service]"
                                value={filter["filter[service]"]}
                                size="small"
                                fullWidth

                                onChange={handleChangeCommon}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FilterUsers
                                name="filter[user_id]"
                                value={filter["filter[user_id]"]}
                                size="small"
                                fullWidth
                                valueName="id"

                                onChange={handleChangeCommon}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FilterProvider
                              name="filter[provider_id]"
                              value={filter["filter[provider_id]"]}
                              size="small"
                              fullWidth

                              onChange={handleChangeCommon}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FormControl fullWidth size="small">
                                <InputLabel>Статус</InputLabel>
                                <Select
                                    value={filter['filter[activation_status][]']}
                                    name="filter[activation_status][]"
                                    label="Статус"
                                    size="small"
                                    multiple
                                    onChange={handleChangeCommon}
                                >
                                    <MenuItem value="">Сбросить</MenuItem>
                                    <MenuItem value="Готов">Готов</MenuItem>
                                    <MenuItem value="Ожидает кода">Ожидает кода</MenuItem>
                                    <MenuItem value="Завершен">Завершен</MenuItem>
                                    <MenuItem value="Отменен">Отменен</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={3} className={classes.dateTimePicker}>
                            <FilterDateRangeFull
                              value={[
                                  filter['filter[created_at][gt]'],
                                  filter['filter[created_at][lt]'],
                              ]}

                              onChange={handleChangeDateRange}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FilterAmountRange
                                value={[filter["filter[price][gt]"], filter["filter[price][lt]"]]}

                                onChange={handleChangeAmountRange}
                            />
                        </Grid>
                    </Grid>
                    <Box my={3}>
                        <Divider/>
                    </Box>
                    <Box>
                        <Typography variant="h4" mb={1}>Исключающие фильтры</Typography>
                        <Grid container spacing={4}>
                            <Grid item sm={3}>
                                <FilterService
                                  name="filter[and][][not][service]"
                                  value={filter["filter[and][][not][service]"]}
                                  size="small"
                                  fullWidth
                                  multiple
                                  onChange={handleChangeCommon}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

            </Collapse>

            {Boolean(_activeFilters().length > 0) && (
                <>

                    <Box my={1}/>

                    <Grid container spacing={2}>
                        {_activeFilters().map((item, index) => (
                            <Grid item>
                                <Box className={classes.active} onClick={item.onClick}>
                                    <Typography className={classes.activeLabel}>{item.label}:</Typography>
                                    <Typography className={classes.activeValue}>{item.value}</Typography>
                                    <HighlightOffIcon sx={{color: "white", fontSize: 16, marginLeft: 1}}/>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                </>
            )}

        </Box>
    );
}

const useStyles = makeStyles(() => ({

    active: {
        display: "flex",
        alignItems: "center",
        padding: "2px 12px",
        borderRadius: 6,
        backgroundColor: "#FB7800",
        cursor: "pointer",

        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600"
    },
    activeLabel: {
        opacity: 0.6,
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white"
    },
    activeValue: {
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
        marginLeft: 6
    },


    dateTimePicker: {
        "& .daterangepicker": {
            "& .applyButton": {
                backgroundColor: "rgba(251,120,0, .8) !important",
                border: "none !important",
                "&:hover": {
                    backgroundColor: "#FB7800 !important",
                }
            },
            "& .fromDateHourContainer": {
                border: "none !important",
            },
            "& .inputDate": {
                borderWidth: "2px !important",
                height: "33px !important",
                "&:focus": {
                    borderColor: "#FB7800 !important",
                    boxShadow: "none !important",
                }
            },
            "& .calendarAddon.input-group-addon": {
                borderWidth: "2px !important",
            },
            "& .multipleContentOnLine": {
                outline: "none !important",
                "& select": {
                    height: "33px !important",
                    borderRadius: "4px",
                    borderWidth: "2px",
                    outline: "none",
                    borderColor: "#ccc",
                    "&:focus": {
                        borderColor: "#FB7800 !important",
                        outline: "none !important",
                    }
                }
            },
            "& .monthYearContainer": {
                alignItems: "center",
            },
            "& .timeIconStyle": {
                top: "50%",
                transform: "translate(0, -50%)",
            },
            "& .glyphicon": {
                color: "#FB7800 !important"
            },
            "& .rangecontainer": {
                "& div": {
                    outline: "none !important",
                }
            },
            "& .calendarGrid": {
                "& div": {
                    outline: "none !important",
                }
            }
        }
    }
}));

export default Filter
