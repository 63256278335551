import React from "react";
import {
	Header as HeaderComponent
} from "./components";
import {
	Box,
	Typography
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class Default extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			headerItems: [],
		}
	}

	setHeaderItems = (items) => {
		let expandedItems = [];
		items.map((item) => {
			if ((item.items || []).length > 0) {
				expandedItems.push(...item.items)
				return
			}
			expandedItems.push(item)
		});
		this.setState({
			headerItems: expandedItems
		})
	}

	render() {
		const {
			classes,
			currentBuildVersion
		} = this.props;
		const {
			headerItems
		} = this.state;
		const currentPath = this.props?.match?.path;

		return (
			<Box>

				<HeaderComponent
					onSetHeaderItems={this.setHeaderItems}
				/>

				<Box height="calc(100vh - 64px)" overflow="auto" px={2} py={1} boxSizing="border-box" paddingBottom="60px">
					{Boolean(headerItems.find((t) => t.path === currentPath)) ? this.props.children : <AccessDenied/>}
				</Box>


				<div className={classes.currentBuildVersion}>
					Время сборки:<br/>{currentBuildVersion}
				</div>
			</Box>
		)
	}
}
const AccessDenied = React.memo(() => {
	return (
		<Box sx={{
			backgroundColor: "white",
			borderRadius: 10,
			padding: 24,
			textAlign: "center",
		}}>
			<Typography variant="h1">
				Доступ запрещен
			</Typography>
		</Box>
	)
})

const styles = {
	currentBuildVersion: {
		background: "#22252D",
		borderRadius: "8px",
		position: "fixed",
		bottom: "10px",
		left: "10px",
		fontSize: "12px",
		padding: "4px 8px",
		color: "white",
		lineHeight: "16px"
	}
}
Default = withStyles(styles)(Default);

Default = withRouter(Default);


export default compose(
	connect(
		state => ({
			currentBuildVersion: state?.global?.currentBuildVersion || ""
		}),
		dispatch => ({}),
	),
)(Default)
