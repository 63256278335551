import React from "react";
import {
	Box,
	Grid,
	Button,
	Divider,
	TextField,
	Typography,
	Autocomplete,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions, FormControlLabel, Checkbox
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	DatePicker
} from "@mui/lab";
import {
	Formik
} from "formik";
import {

} from "../../../../../../components";
import * as Yup from "yup";
import agent from "../../../../../../agent/agent";
import moment from "moment";

const initForm = {
	"date": "",
	"amount": "",
	"origin": "",
	"comment": "",
	"currency": "USD",
	"wallet_id": "",
	"direction": "",
	"project_id": "",
	"commission": "",
	"purpose_id": "",
	"category_id": "",
	"is_approved": true,
}

class DialogForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {},

			isOpen: false,
			isCreate: false,
			onSubmit: null
		};
		this.refFormik = React.createRef();
	}

	open = ({ itemForm, onSubmit }) => {
		this.setState({
			form: {...(itemForm || initForm)},
			isOpen: true,
			isCreate: Boolean(!itemForm),
			onSubmit
		});
	}
	close = () => {
		this.setState({
			isOpen: false,
			isCreate: false,
			onSubmit: null
		})
	}
	submit = (values) => {
		values.date = moment(values.date).format("YYYY-MM-DD");
		this.state.onSubmit(values);
	}
	change = ({ target }) => {
		const { name, value } = target;
		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);
	}

	render () {
		const {
			form,
			isOpen,
			isCreate
		} = this.state;
		const {
			classes
		} = this.props;

		if (!isOpen) {
			return null
		}
		return (
			<Dialog
				open
				fullWidth
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle className={classes.dialogTitle}>
					<Typography variant="h3">
						{isCreate ? 'Создание отчета' : 'Изменение отчета'}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Formik
						innerRef={this.refFormik}
						initialValues={form}
						validationSchema={validationSchema}
						onSubmit={this.submit}
					>{(params) => {
						const {
							values,
							errors,
							touched,
							handleSubmit,
						} = params;

						return (
							<Box pt={2}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Typography className={classes.formTitle}>Валюта</Typography>
										<AutocompleteSelectStatic
											value={values.currency}
											options={["USD", "RUB", "GBP", "EUR"]}
											error={Boolean(touched.currency && errors.currency)}
											helperText={touched.currency && errors.currency}
											name="currency"
											classes={classes}
											onChange={this.change}
										/>
									</Grid>
									<Grid item xs={6}>
										<Typography className={classes.formTitle}>Кошелёк</Typography>
										<AutocompleteSelectApi
											value={values.wallet_id}
											name="wallet_id"
											classes={classes}
											error={Boolean(touched.wallet_id && errors.wallet_id)}
											helperText={touched.wallet_id && errors.wallet_id}
											api="/api/finance/wallets?per-page=999&page=1"
											onChange={this.change}
										/>
									</Grid>
									<Grid item xs={6}>
										<Typography className={classes.formTitle}>Сумма</Typography>
										<TextField
											value={values.amount}
											name="amount"
											size="small"
											error={Boolean(touched.amount && errors.amount)}
											helperText={touched.amount && errors.amount}
											className={classes.formInput}
											fullWidth
											onChange={this.change}
										/>
									</Grid>
									<Grid item xs={6}>
										<Typography className={classes.formTitle}>Комиссия</Typography>
										<TextField
											value={values.commission}
											name="commission"
											size="small"
											className={classes.formInput}
											fullWidth
											error={Boolean(touched.commission && errors.commission)}
											helperText={touched.commission && errors.commission}
											onChange={this.change}
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider flexItem/>
									</Grid>

									<Grid item xs={12}>
										<Typography className={classes.formTitle}>Проект</Typography>
										<AutocompleteSelectApi
											value={values.project_id}
											name="project_id"
											classes={classes}
											api="/api/finance/projects?per-page=999&page=1"
											error={Boolean(touched.project_id && errors.project_id)}
											helperText={touched.project_id && errors.project_id}
											onChange={this.change}
										/>
									</Grid>
									<Grid item xs={12}>
										<Typography className={classes.formTitle}>Категория</Typography>
										<AutocompleteSelectApi
											value={values.category_id}
											name="category_id"
											classes={classes}
											api="/api/finance/categories?per-page=999&page=1"
											error={Boolean(touched.category_id && errors.category_id)}
											helperText={touched.category_id && errors.category_id}
											onChange={this.change}
										/>
									</Grid>
									<Grid item xs={12}>
										<Typography className={classes.formTitle}>Назначение</Typography>
										<AutocompleteSelectApi
											value={values.purpose_id}
											name="purpose_id"
											classes={classes}
											error={Boolean(touched.purpose_id && errors.purpose_id)}
											helperText={touched.purpose_id && errors.purpose_id}
											api={`/api/finance/purposes?per-page=999&page=1&filter[category_id]=${ values.category_id }`}
											onChange={this.change}
										/>
									</Grid>
									<Grid item xs={12}>
										<Typography className={classes.formTitle}>Комментарий</Typography>
										<TextField
											value={values.comment}
											className={classes.formInput}
											name="comment"
											rows={2}
											multiline
											fullWidth
											onChange={this.change}
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider flexItem/>
									</Grid>

									<Grid item xs={12}>
										<Typography className={classes.formTitle}>Дата</Typography>
										<DatePickerInput
											value={values.date}
											name="date"
											classes={classes}
											error={Boolean(touched.date && errors.date)}
											helperText={touched.date && errors.date}
											onChange={this.change}
										/>
									</Grid>
									<Grid item xs={12}>
										<Box sx={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
											<FormControlLabel
												value={values.is_approved}
												checked={values.is_approved}
												name="is_approved"
												control={<Checkbox />}
												label="Проверена"
												onChange={(e,v) => this.change({target: {name: 'is_approved', value: v}})}
											/>
										</Box>
									</Grid>
								</Grid>
								<Box mt={2}/>
								<Grid container spacing={1} justifyContent="flex-end">
									<Grid item>
										<Button
											className={classes.button}
											variant="outlined"
											onClick={this.close}
										>Закрыть</Button>
									</Grid>
									<Grid item>
										<Button
											className={classes.button}
											variant="contained"
											onClick={handleSubmit}
										>{isCreate ? 'Создать' : 'Сохранить'}</Button>
									</Grid>
								</Grid>
							</Box>
						)
					}}</Formik>
				</DialogContent>
			</Dialog>
		)
	}
}
const DatePickerInput = ({ value, classes, name, onChange, error, helperText }) => {
	const handleChange = (value) => {
		onChange({
			target: {
				name,
				value: value || ""
			}
		})
	}
	return (
		<DatePicker
			value={value}
			ignoreInvalidInputs
			onChange={handleChange}
			renderInput={(params) => (<TextField
				{...params}
				error={error}
				helperText={helperText}
				className={classes.formInput}
				size="small"
				fullWidth
			/>)}
		/>
	)
}
const AutocompleteSelectApi = ({ value, onChange, name, api, label, classes, error, helperText, disabledAutoSelect }) => {
	const [options, setOptions] = React.useState([]);
	const handleGetOptions = async () => {
		const res = await agent.get(api).then((res) => {
			return res?.data || []
		}).catch(() => []);
		setOptions(res);

		if (disabledAutoSelect) {
			return
		}
		const defaultOption = res.find((t) => t.is_default) || res?.[0] || null;
		if (!!defaultOption) {
			handleChange(null, defaultOption);
		}
	}
	React.useEffect(() => {
		(async () => {
			await handleGetOptions();
		})();
	}, [api]);

	const handleChange = (event, value) => {
		onChange({
			target: {
				name: name,
				value: value?.id
			}
		});
	}
	const _getOptionLabel = (option) => {
		if (!option) {
			return ""
		}
		if (typeof option !== "object") {
			option = options.find((t) => Boolean(String(option) === String(t?.id)))
		}
		return option?.name
	}

	return (
		<Autocomplete
			value={value}
			options={options || []}
			getOptionLabel={_getOptionLabel}
			filterOptions={(_options) => [..._options]}
			onChange={handleChange}
			renderInput={(params) => (<TextField
				{...params}
				error={error}
				label={label || ""}
				helperText={helperText}
				className={classes?.formInput}
				size="small"
				fullWidth
			/>)}
		/>
	)
}
const AutocompleteSelectStatic = ({ value, name, classes, label, options, onChange, error, helperText }) => {

	const handleChange = (event, value) => {
		onChange({
			target: {
				name: name,
				value: value
			}
		});
	}

	return (
		<Autocomplete
			value={value}
			options={options}
			filterOptions={(_options) => [..._options]}
			onChange={handleChange}
			renderInput={(params) => (<TextField
				{...params}
				error={error}
				helperText={helperText}
				className={classes?.formInput}
				size="small"
				label={label || ""}
				fullWidth
			/>)}
		/>
	)
}

const styles = {
	dialogTitle: {
		background: "#FB7800",

		"& .MuiTypography-h3": {
			color: "white",
			fontWeight: "500"
		}
	},

	formTitle: {
		fontSize: 16,
		lineHeight: 1.2,
		marginLeft: "4px",
		marginBottom: "6px",
	},
	formInput: {
		"& legend": {
			display: "none"
		}
	},

	button: {
		height: "auto",
		minHeight: "auto",
		borderRadius: "8px",
		padding: "8px 24px",

		fontSize: 18,
		lineHeight: 1.2,
		textTransform: "initial",
	}
};
DialogForm = withStyles(styles)(DialogForm);

const validationSchema = Yup.object().shape({
	date: Yup.string().required(),
	amount: Yup.string().required(),
	currency: Yup.string().required(),
	wallet_id: Yup.string().required(),
	project_id: Yup.string().required(),
	category_id: Yup.string().required(),
	purpose_id: Yup.string().required(),
});

export default DialogForm
export {
	styles,
	AutocompleteSelectApi,
	AutocompleteSelectStatic,
}
