import React from "react";
import {
    Box,
    Grid,
    Typography,
    Button, TextField, FormControl, InputLabel, Select, MenuItem, Collapse, FormHelperText
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Clear as ClearIcon, FilterAlt as FilterAltIcon, HighlightOff as HighlightOffIcon,
    PlusOne as PlusOneIcon, Search as SearchIcon
} from "@mui/icons-material";
import {FilterAmountRange, FilterCountry, FilterService} from "../../../../../../components";
import moment from "moment";

const Filter = (props) => {
    const {
        filter,
        countries,

        onCreate,
        onReset,
        onSearch,
        onChange
    } = props;

    const [ isOpen, setOpen ] = React.useState(true);

    const classes = useStyles();

    const handleChangeCommon = ({ target }) => {
        const { name, value } = target;

        let newFilter = {...filter};
        newFilter[name] = value;

        onChange(newFilter);
    }

    const _activeFilters = () => {

        let items = [];

        if ((filter["country"] || []).length > 0) {
            const valueCountry = (filter["country"] || []).map((item) => {
                return (countries || [])?.find((t) => +t.external_id === +item)?.name
            }).join(', ')

            items.push({
                label: "Страна",
                value: valueCountry,
                onClick: _deleteCommon.bind(this, "country")
            })
        }
        if (filter["service"]) {
            items.push({
                label: "Услуга",
                value: filter["service"],
                onClick: _deleteCommon.bind(this, "service")
            })
        }


        return items
    }
    const _deleteCommon = (name) => {
        let newFilter = {...filter};
        newFilter[name] = "";

        onChange(newFilter, true);
    }

    return (
        <Box mb={1}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Фильтры</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={() => setOpen(!isOpen)}
                                endIcon={<FilterAltIcon/>}
                            >
                                {isOpen ? "Свернуть" : "Развернуть"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={onReset}
                                endIcon={<ClearIcon/>}
                            >
                                Сбросить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                endIcon={<SearchIcon/>}
                                onClick={onSearch}
                            >
                                Поиск
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1}/>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box px={2} py={2} bgcolor="white" borderRadius={4}>
                    <Grid container spacing={4}>
                        <Grid item sm={3}>
                            <FilterCountry
                                name="country"
                                value={filter["country"]}
                                size="small"
                                fullWidth
                                multiple
                                filterSelectedOptions
                                autoComplete={true}

                                isOptionEqualToValue={(option, value) => Boolean(String(option.external_id) === String(value))}

                                onChange={handleChangeCommon}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FilterService
                                name="service"
                                value={filter["service"]}
                                size="small"
                                fullWidth

                                onChange={handleChangeCommon}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>

            {Boolean(_activeFilters().length > 0) && (
                <>

                    <Box my={1}/>

                    <Grid container spacing={2}>
                        {_activeFilters().map((item, index) => (
                            <Grid item>
                                <Box className={classes.active} onClick={item.onClick}>
                                    <Typography className={classes.activeLabel}>{ item.label }:</Typography>
                                    <Typography className={classes.activeValue}>{ item.value }</Typography>
                                    <HighlightOffIcon sx={{color: "white", fontSize: 16, marginLeft: 1}}/>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                </>
            )}

        </Box>
    )
}

const useStyles = makeStyles(() => ({

    active: {
        display: "flex",
        alignItems: "center",
        padding: "2px 12px",
        borderRadius: 6,
        backgroundColor: "#FB7800",
        cursor: "pointer",

        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600"
    },
    activeLabel: {
        opacity: 0.6,
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white"
    },
    activeValue: {
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
        marginLeft: 6
    },

}));

export default Filter
