import {palette} from "../common";

export default {
    styleOverrides: {
        root: {
            "& tr td": {
                backgroundColor: "white"
            },
            "& tr:nth-child(2n) td": {
                backgroundColor: "#F6F6F7"
            },
            "& tr:last-child td:first-child": {
                borderRadius: "0 0 0 10px"
            },
            "& tr:last-child td:last-child": {
                borderRadius: "0 0 10px 0"
            },
            "& td": {
                padding: "14px 16px",
                border: "none",

                fontSize: 15,
                lineHeight: "22px",
                color: "#22252D"
            },
        }
    }
}
