import React from "react";
import {
    Box,
    Grid,
    Typography,
    Button,

    Collapse,

    FormControl,
    InputLabel,
    Select,
    MenuItem,

    TextField
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Search as SearchIcon,
    Clear as ClearIcon,
    FilterAlt as FilterAltIcon,
    HighlightOff as HighlightOffIcon
} from "@mui/icons-material";
import {
    FilterCountry,
    FilterDateRange,
    FilterAmountRange,
    FilterService
} from "../../../../../../components";
import moment from "moment";

const Filter = (props) => {
    const {
        paymentsType,

        filter,
        activeFilterCountry,
        initOpenFilter,

        runCheck,
        onChange,
        onReset,
        onSearch,
        onChangeActiveFilterCountry
    } = props;
    const [isOpen, setOpen] = React.useState(true);
    const classes = useStyles();

    React.useEffect(() => {
        setOpen(initOpenFilter || true);
    }, [initOpenFilter]);

    const handleChangeDateRange = (value) => {
        let newFilter = { ...filter };

        newFilter["filter[created_at][gt]"] = value[0];
        newFilter["filter[created_at][lt]"] = value[1];

        onChange(newFilter);
    }
    const handleChangeAmountRange = (value) => {
        let newFilter = { ...filter };

        newFilter["filter[amount][gt]"] = value[0];
        newFilter["filter[amount][lt]"] = value[1];

        onChange(newFilter);
    }
    const handleChangeCommon = ({ target }) => {
        const { name, value } = target;

        let newFilter = { ...filter };
        newFilter[name] = value;

        onChange(newFilter);
    }

    const _activeFilters = () => {

        let items = [];

        if (filter["filter[country_id]"]) {
            items.push({
                label: "Страна",
                value: activeFilterCountry,
                onClick: _deleteCommon.bind(this, "filter[country_id]")
            })
        }

        if (filter["filter[service_id]"]) {
            items.push({
                label: "Услуга",
                value: filter["filter[service_id]"],
                onClick: _deleteCommon.bind(this, "filter[service_id]")
            })
        }

        return items
    }
    const _deleteCommon = (name) => {
        let newFilter = { ...filter };
        newFilter[name] = "";

        onChange(newFilter, true);
    }


    return (
        <Box mb={1}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Фильтры</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1 }}
                                onClick={() => setOpen(!isOpen)}
                                endIcon={<FilterAltIcon />}
                            >
                                {isOpen ? "Свернуть" : "Развернуть"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1 }}
                                onClick={onReset}
                                endIcon={<ClearIcon />}
                            >
                                Сбросить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{ textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1 }}
                                endIcon={<SearchIcon />}
                                onClick={onSearch}
                            >
                                Поиск
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1} />

            <Collapse in={isOpen} timeout="auto" unmountOnExit>

                <Box px={2} py={2} bgcolor="white" borderRadius={4}>
                    <Grid container spacing={4}>
                        <Grid item sm={3}>
                            <FilterCountry
                                name="filter[country_id]"
                                value={filter["filter[country_id]"]}
                                size="small"
                                fullWidth

                                onChange={handleChangeCommon}
                                onChangeActiveFilterCountry={onChangeActiveFilterCountry}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FilterService
                                name="filter[service_id]"
                                value={filter["filter[service_id]"]}
                                size="small"
                                fullWidth

                                onChange={handleChangeCommon}
                            />
                        </Grid>
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} sm={6}>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{ textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1 }}
                                onClick={runCheck}
                            >
                                Запустить проверку
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

            </Collapse>

            {Boolean(_activeFilters().length > 0) && (
                <>

                    <Box my={1} />

                    <Grid container spacing={2}>
                        {_activeFilters().map((item, index) => (
                            <Grid item key={`active-filters-${index}`}>
                                <Box className={classes.active} onClick={item.onClick}>
                                    <Typography className={classes.activeLabel}>{item.label}:</Typography>
                                    <Typography className={classes.activeValue}>{item.value}</Typography>
                                    <HighlightOffIcon sx={{ color: "white", fontSize: 16, marginLeft: 1 }} />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                </>
            )}

        </Box>
    )
}

const useStyles = makeStyles(() => ({

    active: {
        display: "flex",
        alignItems: "center",
        padding: "2px 12px",
        borderRadius: 6,
        backgroundColor: "#FB7800",
        cursor: "pointer",

        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600"
    },
    activeLabel: {
        opacity: 0.6,
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white"
    },
    activeValue: {
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
        marginLeft: 6
    },

}));

export default Filter
