import React, {Component} from "react";
import {
	Box,
	Grid,
	Typography,
	Button,
	TextField,
	Container,
	Backdrop,
	CircularProgress
} from "@mui/material";
import agent from "../../../../agent/agent";
import * as Yup from "yup";
import {
	IMaskInput
} from "react-imask";
import {Formik} from "formik";
import {Notification, NotificationTypes} from "../../../../common/Notification";

class GoSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			settings: {},
		};

		this.refFormik = React.createRef();
	}

	componentDidMount = async () => {
		await this.getSettings();
	}

	getSettings = async () => {
		const data = await agent.get("/smsgoapi/go-settings").then((res) => {
			return res.data
		}).catch((err) => {
			return {}
		});

		this.refFormik.current.setValues(data);
	}
	saveSettings = async (form) => {
		this.setState({isShowBackdrop: true});

		const response = await agent.post(`/smsgoapi/go-settings`, form).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})

		Notification({
			message: "Настройки успешно обновлен",
			type: NotificationTypes.success
		});

		this.setState({isShowBackdrop: false});

		await this.getSettings();
	};

	changeForm = ({target}) => {
		if (!this.refFormik.current) {
			return null
		}

		const {name, value} = target;

		let newForm = {...this.refFormik.current.values};
		newForm[name] = value;

		this.refFormik.current.setValues(newForm);
	}

	render() {
		const {
			isShowBackdrop
		} = this.state;

		return (
			<Container maxWidth="md">
				<Box bgcolor="white" borderRadius={4} px={4} py={3} my={4}>

					<Box mb={4}>
						<Typography variant="h1">
							Настройки
						</Typography>
					</Box>

					<Formik
						innerRef={this.refFormik}
						initialValues={this.state.settings}
						validationSchema={validationSchema}
						onSubmit={this.saveSettings}
					>
						{(props) => {
							const {
								values,
								errors,
								touched,
								handleSubmit,
							} = props;

							if (Object.keys(values).length <= 0) {
								return null
							}

							return (
								<Box>
									<form
										onSubmit={handleSubmit}
										onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
									>

										<Box mb={4}>
											<TextField
												value={values.price_coefficient}
												error={Boolean(touched.price_coefficient && errors.price_coefficient)}
												helperText={touched.price_coefficient && errors.price_coefficient}
												name="price_coefficient"
												type="number"
												label="Коэффицент стоимости"
												placeholder="Введите коэффицент стоимости"
												variant="outlined"
												fullWidth

												onChange={this.changeForm}
											/>
										</Box>

										<Box mb={4}>
											<TextField
												value={values.currency_rate}
												error={Boolean(touched.currency_rate && errors.currency_rate)}
												helperText={touched.currency_rate && errors.currency_rate}
												name="currency_rate"
												type="number"
												label="Курс USD"
												placeholder="Введите курс USD"
												variant="outlined"
												fullWidth

												onChange={this.changeForm}
											/>
										</Box>

										<Box mb={4}>
											<TextField
												value={String(values.ref_coefficient)}
												error={Boolean(touched.ref_coefficient && errors.ref_coefficient)}
												helperText={touched.ref_coefficient && errors.ref_coefficient}
												name="ref_coefficient"
												label="Процент для реферальной программы"
												placeholder="Введите процент для реферальной программы"
												variant="outlined"
												fullWidth

												inputProps={{ scale: 2 }}
												InputProps={{ inputComponent: CustomInputAmount }}
												InputLabelProps={{
													shrink: true,
												}}

												onChange={this.changeForm}
											/>
										</Box>

										<Box mb={4}>
											<TextField
												value={String(values.min_profit_coefficient)}
												error={Boolean(touched.min_profit_coefficient && errors.min_profit_coefficient)}
												helperText={touched.min_profit_coefficient && errors.min_profit_coefficient}
												name="min_profit_coefficient"
												label="Процент минимальной прибыльности при продаже"
												placeholder="Введите процент минимальной прибыльности при продаже"
												variant="outlined"
												fullWidth

												inputProps={{ scale: 2 }}
												InputProps={{ inputComponent: CustomInputAmount }}
												InputLabelProps={{
													shrink: true,
												}}

												onChange={this.changeForm}
											/>
										</Box>

									</form>


									<Button variant="contained" fullWidth sx={{borderRadius: "4px"}} onClick={handleSubmit}>
										Сохранить
									</Button>

								</Box>
							)
						}}
					</Formik>

				</Box>


				<Backdrop open={isShowBackdrop}>
					<CircularProgress color="inherit"/>
				</Backdrop>
			</Container>
		);
	}
}
const CustomInputAmount = ({inputRef, ...rest}) => {

	const onComplete = (value) => {
		rest.onChange({
			target: {
				name: rest.name,
				value
			}
		})
	}

	return (
		<IMaskInput
			ref={inputRef}
			mask={Number}
			thousandsSeparator=""
			radix="."
			mapToRadix={[',']}
			scale={rest?.scale || 4}
			padFractionalZeros={true}

			{...rest}

			onComplete={onComplete}
			onChange={null}
		/>
	)
}

const validationSchema = Yup.object().shape({
	price_coefficient: Yup.string().required('Заполните коэффицент стоимости'),
	currency_rate: Yup.string().required('Заполните курс'),
	ref_coefficient: Yup.string().required('Заполните процент для реферальной программы'),
	min_profit_coefficient: Yup.string().required('Заполните процент минимальной прибыльности при продаже'),
});

export default GoSettings
