import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,


    Typography,
    TextField,
    Button,
    Grid,
    Box,


    FormControl,
    InputLabel,
    MenuItem,
    Select, FormHelperText
} from "@mui/material";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import {Formik} from "formik";
import {IMaskInput} from "react-imask";

const pageInitForm = {
    discount: "",
    discount_group: "",

    ref_param: "",
    ref_code: "",
    ref_coefficient: "",
    is_wholesale: null,
};

class DialogCreateGroup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initForm: {},

            open: false,
            isCreated: true,


            eventSubmit: null
        }

        this.refFormik = React.createRef();
    }


    onSubmit = (form) => {
        this.state.eventSubmit(form);
    }
    changeForm = ({target}) => {
        if (!this.refFormik.current) {
            return
        }

        const {name, value} = target;

        let newForm = {...this.refFormik.current.values};
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    }

    open = async ({initForm, eventSubmit} = {}) => {
        await this.setState({
            open: true,
            isCreated: Boolean(Object.keys((initForm || {})).length <= 0),
            eventSubmit
        });

        console.log(initForm, "initForm")

        this.refFormik.current.setValues(initForm || pageInitForm);
    }
    close = () => {
        this.setState({
            open: false
        })
    }

    onSuccess = () => {
        this.refFormik.current.handleSubmit()
    }
    onCancel = () => {
        this.close()
    }

    render() {
        const {
            open,
            isCreated
        } = this.state;
        const {
            groupList
        } = this.props;

        return (
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}


                onClose={this.onCancel}
            >

                <DialogTitle sx={{backgroundColor: "rgba(251, 120, 0, 1)"}}>
                    <Typography variant="h3" sx={{color: "white"}}>
                        {isCreated ? "Создание пользователя" : "Редактирование пользователя"}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box px={1} py={3}>
                        <Formik
                            innerRef={this.refFormik}
                            initialValues={this.state.initForm}
                            validationSchema={validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched,
                                    handleSubmit,
                                } = props;

                                if (Object.keys(values || {}).length <= 0) {
                                    return null
                                }

                                console.log('valuesvaluesvaluesvaluesvaluesvaluesvalues: ', values);
                                console.log('values.is_wholesale: ', values.is_wholesale);

                                return (
                                    <Box>
                                        <form
                                            onSubmit={handleSubmit}
                                            onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
                                        >

                                            <Box mb={4}>
                                                <FormControl
                                                    error={Boolean(touched.is_wholesale && errors.is_wholesale)}
                                                    fullWidth
                                                >
                                                    <InputLabel>Оптовая цена</InputLabel>
                                                    <Select
                                                        value={values.is_wholesale}
                                                        label="Оптовая цена"
                                                        name="is_wholesale"
                                                        fullWidth
                                                        onChange={this.changeForm}
                                                        error={Boolean(touched.is_wholesale && errors.is_wholesale)}
                                                        helperText={touched.is_wholesale && errors.is_wholesale}
                                                    >
                                                        <MenuItem value={false}>Нет</MenuItem>
                                                        <MenuItem value={true}>Да</MenuItem>
                                                    </Select>

                                                    {Boolean(touched.is_wholesale && errors.is_wholesale) && (
                                                        <FormHelperText error={true}>
                                                            {touched.is_wholesale && errors.is_wholesale}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Box>


                                            <Box mb={4}>
                                                <TextField
                                                    value={values.discount}
                                                    error={Boolean(touched.discount && errors.discount)}
                                                    helperText={touched.discount && errors.discount}
                                                    name="discount"
                                                    type="number"
                                                    label="Коэффициент скидки"
                                                    placeholder="Введите коэффициент скидки"
                                                    variant="outlined"
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.ref_param}
                                                    error={Boolean(touched.ref_param && errors.ref_param)}
                                                    helperText={touched.ref_param && errors.ref_param}
                                                    name="ref_param"
                                                    label="Параметр реферальной программы"
                                                    placeholder="Введите параметр реферальной программы"
                                                    variant="outlined"
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.ref_code}
                                                    error={Boolean(touched.ref_code && errors.ref_code)}
                                                    helperText={touched.ref_code && errors.ref_code}
                                                    name="ref_code"
                                                    label="Значение для реферальной программы"
                                                    placeholder="Введите значение для реферальной программы"
                                                    variant="outlined"
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <TextField
                                                    value={String(values.ref_coefficient)}
                                                    error={Boolean(touched.ref_coefficient && errors.ref_coefficient)}
                                                    helperText={touched.ref_coefficient && errors.ref_coefficient}
                                                    name="ref_coefficient"
                                                    label="Процент для реферальной программы"
                                                    placeholder="Введите процент для реферальной программы"
                                                    variant="outlined"
                                                    fullWidth

                                                    inputProps={{scale: 2}}
                                                    InputProps={{inputComponent: CustomInputAmount}}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            {Boolean(Object.keys(values).length > 0) && (
                                                <Box mb={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel>Группа пользователя</InputLabel>
                                                        <Select
                                                            name="discount_group"
                                                            value={values.discount_group}
                                                            label="Группа пользователя"
                                                            onChange={this.changeForm}

                                                            error={Boolean(touched.discount_group && errors.discount_group)}
                                                            helperText={touched.discount_group && errors.discount_group}
                                                        >
                                                            <MenuItem value={null}>Выберите</MenuItem>
                                                            {groupList.map((group, index) => (
                                                                <MenuItem value={group.id}
                                                                          key={`discount_group-${index}`}>
                                                                    {`${group.name} (${group.discount} коэф.)`}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            )}

                                        </form>
                                    </Box>
                                )
                            }}
                        </Formik>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="outlined"
                                    onClick={this.onCancel}>
                                Отмена
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="contained"
                                    onClick={this.onSuccess}>
                                {isCreated ? "Создать" : "Сохранить"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        )
    }
}

const CustomInputAmount = ({inputRef, ...rest}) => {

    const onComplete = (value) => {
        rest.onChange({
            target: {
                name: rest.name,
                value
            }
        })
    }

    return (
        <IMaskInput
            ref={inputRef}
            mask={Number}
            thousandsSeparator=""
            radix="."
            mapToRadix={[',']}
            scale={rest?.scale || 4}
            padFractionalZeros={true}

            {...rest}

            onComplete={onComplete}
            onChange={null}
        />
    )
}

const validationSchema = Yup.object().shape({
    is_wholesale: Yup.string().required('Выберите типи цены'),
    discount: Yup.string().required('Заполните персональный коэффициент скидки пользователя'),
    discount_group: Yup.string().nullable(),

    ref_param: Yup.string().nullable(),
    ref_code: Yup.string().nullable(),
    ref_coefficient: Yup.string().nullable(),
});

export default DialogCreateGroup
