import React, {Component} from "react";
import {
  Grid,
  Button,
  Backdrop,
  Container,
  Typography,
  CircularProgress
} from "@mui/material";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import {
  Table as TableComponent,
  PopUpForm as PopUpFormComponent,
  InstallingProvider as InstallingProviderComponent
} from "./components";

class CommonUserGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],

      filter: {
        "sort": "id",
        "page": 1
      },
      pagination: {
        pageCount: 1
      },

      isLoadList: true,
      isBackdrop: false,
    };

    this.refPopUpFormComponent = React.createRef();
    this.refInstallingProviderComponent = React.createRef();
  }

  componentDidMount = async () => {
    await this.getList();
  }

  getList = async ({ cancelLoad } = {}) => {
    if (!cancelLoad) {
      this.setState({ isLoadList: true });
    }

    const filter = this._getUrlFromFilter();
    const response = await agent.get(`/smsgoapi/go-user-groups?${filter}`).then((res) => {
      return res
    }).catch(() => {
      return null
    });

    this.setState({
      list: response?.data || [],
      pagination: {
        perPage: response?.headers?.['x-pagination-page-count'] || 1
      },
      isLoadList: false
    });
  }

  onEdit = async (form, isSubmit = false) => {
    if (!isSubmit) {
      this.refPopUpFormComponent.current.open({
        form,
        onSubmit: this.onEdit.bind(this)
      })
      return
    }

    this.setState({isBackdrop: true});
    let body = {...form};
    delete body.id;
    const res = await agent.put(`/smsgoapi/go-user-groups/${form.id}`, body).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    await this.getList();
    this.refPopUpFormComponent.current.close();
    this.setState({isBackdrop: false});
    Notification({
      message: "Группа успешно изменена",
      type: NotificationTypes.success
    })
  }
  onCreate = async (form, isSubmit = false) => {
    if (!isSubmit) {
      this.refPopUpFormComponent.current.open({
        onSubmit: this.onCreate.bind(this)
      })
      return
    }

    this.setState({isBackdrop: true});
    const res = await agent.post('/smsgoapi/go-user-groups', form).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    await this.getList();
    this.refPopUpFormComponent.current.close();
    this.setState({isBackdrop: false});
    Notification({
      message: "Каскад успешно создан",
      type: NotificationTypes.success
    })
  }
  onChangePage = async (event, newPage) => {
    let filter = {...this.state.filter}
    filter.page = newPage;
    await this.setState({filter});
    await this.getList();
  }
  onSetProvider = async (cascadeId, isSubmit, form) => {
    if (!isSubmit) {
      this.refInstallingProviderComponent.current.open({
        cascadeId: cascadeId,
        onSubmit: this.onSetProvider.bind(this)
      });
      return
    }

    this.setState({isBackdrop: true});
    const body = {
      "overwrite": true,
      "userIds": (form || []).map((t) => {
        return Number.parseFloat(t.user_id)
      })
    }
    const res = await agent.post(`/smsgoapi/go-user-groups/set-users/${cascadeId}`, body).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    await this.getList();
    this.refInstallingProviderComponent.current.close();
    this.setState({isBackdrop: false});
    Notification({
      message: "Вы успешно установили провайдерв",
      type: NotificationTypes.success
    })
  }

  _getUrlFromFilter = () => {
    const filter = {...this.state.filter}

    let strings = [];
    Object.keys(filter).map((filterKey) => {
      const filterValue = filter[filterKey];
      if (!!filterValue) {
        strings.push(`${filterKey}=${filter[filterKey]}`)
      }
    })

    return strings.join("&")
  }

  render() {
    const {
      list,
      filter,
      pagination,
      isLoadList,
      isBackdrop
    } = this.state;

    return (
      <Container maxWidth="xl">

        <Grid container alignItems="center" justifyContent="space-between" mb={2}>
          <Grid item>
            <Typography variant="h1">Группы</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{borderRadius: "4px", textTransform: "initial", padding: "0 24px"}}
              onClick={this.onCreate.bind(this, null, false)}
            >
              Создать
            </Button>
          </Grid>
        </Grid>

        <TableComponent
          data={list}
          isLoad={isLoadList}
          filter={filter}
          totalPage={pagination.perPage}

          onEdit={this.onEdit}
          onUpdateList={this.getList}
          onChangePage={this.onChangePage}
          onSetProvider={this.onSetProvider}
        />

        <PopUpFormComponent ref={this.refPopUpFormComponent}/>
        <InstallingProviderComponent ref={this.refInstallingProviderComponent}/>
        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Container>
    );
  }
}

export default CommonUserGroups
