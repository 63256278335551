import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl, FormHelperText, TextField, Checkbox, FormControlLabel
} from "@mui/material";
import {
  Formik
} from "formik";
import * as Yup from "yup";
import {FilterUserGroups} from "../../../../../../components";
import {IMaskInput} from "react-imask";

const initForm = {
  loyalty_id: "",
  cashback_percent: "",
  user_group_id: "",
  is_ban: false,
};
const initState = {
  form: {...initForm},
  isOpen: false,
  isEdit: false,
  onSubmit: null
}

class DialogForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {...initForm},
      isOpen: false,
      isEdit: false,
      onSubmit: null
    };
    this.refFormik = React.createRef();
  }

  open = ({ form, onSubmit }) => {
    this.setState({
      form: form || {...initForm},
      isOpen: true,
      isEdit: Boolean(form),
      onSubmit
    })
  }
  close = () => {
    this.setState({
      ...initState
    })
  }

  changeForm = ({ target }) => {
    if (!this.refFormik.current) {
      return
    }
    const { name, value } = target;
    let values = this.refFormik.current?.values || {};
    values[name] = value;
    this.refFormik.current.setValues(values);
  }

  onSubmit = (form) => {
    form.is_ban = Number(form.is_ban);
    this.state.onSubmit(form, true);
  }

  render () {
    const {
      form,
      isOpen,
      isEdit
    } = this.state;
    const {
      loyalty
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        onClose={this.close}
      >
        <DialogTitle sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
          <Typography variant="h3" sx={{color: "white"}}>
            {isEdit ? "Редактирование" : "Создание"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            innerRef={this.refFormik}
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
          >{(props) => {
            const {
              values,
              errors,
              touched,
              handleSubmit
            } = props;
            return (
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl error={Boolean(touched.loyalty_id && errors.loyalty_id)} fullWidth size="small">
                      <InputLabel>Программа лояльности</InputLabel>
                      <Select
                        value={values.loyalty_id}
                        name="loyalty_id"
                        label="Программа лояльности"
                        onChange={this.changeForm}
                      >
                        <MenuItem value="">Сбросить</MenuItem>
                        {loyalty.map((t) => (
                          <MenuItem value={t.id}>{ t.name }</MenuItem>
                        ))}
                      </Select>
                      {Boolean(touched.loyalty_id && errors.loyalty_id) && (
                        <FormHelperText error={true}>
                          {touched.loyalty_id && errors.loyalty_id}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FilterUserGroups
                      name="user_group_id"
                      value={values.user_group_id}
                      size="small"
                      fullWidth
                      onChange={this.changeForm}
                    />
                    {Boolean(touched.user_group_id && errors.user_group_id) && (
                      <FormHelperText error={true}>
                        {touched.user_group_id && errors.user_group_id}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={values.cashback_percent}
                      name="cashback_percent"
                      label="Процент кэшбэка"
                      size="small"
                      fullWidth
                      error={Boolean(touched.cashback_percent && errors.cashback_percent)}
                      helperText={touched.cashback_percent && errors.cashback_percent}
                      inputProps={{ scale: 2 }}
                      InputProps={{ inputComponent: CustomInputAmount }}
                      InputLabelProps={{ shrink: Boolean(values.cashback_percent) }}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox />}
                      value={values.is_ban}
                      checked={Boolean(values.is_ban)}
                      name="is_ban"
                      label="Программа лояльности отключена для группы"
                      onChange={this.changeForm.bind(this, {target: {name: "is_ban", value: !Boolean(values.is_ban)}})}
                    />
                  </Grid>
                </Grid>
                <Box mt={4}>
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{borderRadius: "4px", textTransform: "initial"}}
                        onClick={this.close}
                      >Отменить</Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{borderRadius: "4px", textTransform: "initial"}}
                        onClick={handleSubmit}
                      >Сохранить</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )
          }}</Formik>
        </DialogContent>
      </Dialog>
    )
  }
}
const CustomInputAmount = ({inputRef, ...rest}) => {

  const onComplete = (value) => {
    rest.onChange({
      target: {
        name: rest.name,
        value
      }
    })
  }

  return (
    <IMaskInput
      ref={inputRef}
      mask={Number}
      thousandsSeparator=""
      radix="."
      mapToRadix={[',']}
      scale={rest?.scale || 4}
      padFractionalZeros={true}

      {...rest}

      onComplete={onComplete}
      onChange={null}
    />
  )
}

const validationSchema = Yup.object().shape({
  loyalty_id: Yup.string().nullable().required('Обязательно к заполнению'),
  cashback_percent: Yup.string().nullable().required('Обязательно к заполнению'),
  user_group_id: Yup.string().nullable().required('Обязательно к заполнению'),
});

export default DialogForm
