import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel
} from "@mui/material";
import {
  IMaskInput
} from "react-imask";
import {
  Formik
} from "formik";
import * as Yup from "yup";

const initForm = {
  name: "",
  cashback_percent: "",
  min_deposit: "",
  active: false,
}

class DialogForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      isOpen: false,
      isEdit: false,
      onSubmit: null
    };
    this.refFormik = React.createRef();
  }

  open = ({ form, onSubmit }) => {
    let newForm = form || {...initForm};
    this.setState({
      form: newForm,
      isOpen: true,
      isEdit: Boolean(form),
      onSubmit
    })
  }
  close = () => {
    this.setState({
      isOpen: false
    })
  }

  changeForm = ({ target }) => {
    if (!this.refFormik.current) {
      return
    }

    const { name, value } = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }

  submit = (form) => {
    this.state.onSubmit(form, true);
  }
  checkValidation = () => {
    this.refFormik.current.handleSubmit();
  }

  render () {
    const {
      form,
      isOpen,
      isEdit
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        onClose={this.close}
      >
        <DialogTitle sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
          <Typography variant="h3" sx={{color: "white"}}>
            {isEdit ? 'Редактирование' : 'Создание'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            innerRef={this.refFormik}
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={this.submit}
          >{(props) => {
            const {
              values,
              touched,
              errors,
            } = props;

            return (
              <Grid container spacing={2} pt={2}>
                <Grid item xs={12}>
                  <TextField
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    name="name"
                    size="small"
                    label="Наименование"
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={values.cashback_percent}
                    error={Boolean(touched.cashback_percent && errors.cashback_percent)}
                    helperText={touched.cashback_percent && errors.cashback_percent}
                    name="cashback_percent"
                    size="small"
                    label="Процент кэшбэка"
                    fullWidth
                    inputProps={{ scale: 2 }}
                    InputLabelProps={{ shrink: Boolean(isEdit) }}
                    InputProps={{ inputComponent: CustomInputAmount }}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={values.min_deposit}
                    error={Boolean(touched.min_deposit && errors.min_deposit)}
                    helperText={touched.min_deposit && errors.min_deposit}
                    name="min_deposit"
                    size="small"
                    label="Минимальный депозит"
                    fullWidth
                    inputProps={{ scale: 2 }}
                    InputLabelProps={{ shrink: Boolean(isEdit) }}
                    InputProps={{ inputComponent: CustomInputAmount }}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    value={values.active}
                    checked={values.active}
                    control={<Checkbox/>}
                    label="Статус"
                    onChange={this.changeForm.bind(this, {target: {name: 'active', value: !values.active}})}
                  />
                </Grid>
              </Grid>
            )
          }}</Formik>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={1} px={2}>
            <Grid item>
              <Button variant="outlined" onClick={this.close}>
                Закрыть
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={this.checkValidation}>
                {isEdit ? 'Редактировать' : 'Создать'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}
const CustomInputAmount = ({inputRef, ...rest}) => {

  const onComplete = (value) => {
    rest.onChange({
      target: {
        name: rest.name,
        value
      }
    })
  }

  return (
    <IMaskInput
      ref={inputRef}
      mask={Number}
      thousandsSeparator=""
      radix="."
      mapToRadix={[',']}
      scale={rest?.scale || 4}
      padFractionalZeros={true}

      {...rest}

      onComplete={onComplete}
      onChange={null}
    />
  )
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Заполните поле'),
  cashback_percent: Yup.string().required('Заполните поле'),
  min_deposit: Yup.string().required('Заполните поле'),
});

export default DialogForm
