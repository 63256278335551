import React from "react";
import {
    Box,
    Grid,
    Typography,
    Button,

} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    PlusOne as PlusOneIcon,
} from "@mui/icons-material";

const Filter = (props) => {
    const {
        onCreate,
    } = props;


    return (
        <Box mb={1}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Фильтры</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={onCreate}
                                endIcon={<PlusOneIcon/>}
                            >
                                Добавить
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1}/>


        </Box>
    );
}

const useStyles = makeStyles(() => ({

    active: {
        display: "flex",
        alignItems: "center",
        padding: "2px 12px",
        borderRadius: 6,
        backgroundColor: "#FB7800",
        cursor: "pointer",

        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600"
    },
    activeLabel: {
        opacity: 0.6,
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white"
    },
    activeValue: {
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
        marginLeft: 6
    },

    dateTimePicker: {
        "& .daterangepicker": {
            "& .applyButton": {
                backgroundColor: "rgba(251,120,0, .8) !important",
                border: "none !important",
                "&:hover": {
                    backgroundColor: "#FB7800 !important",
                }
            },
            "& .fromDateHourContainer": {
                border: "none !important",
            },
            "& .inputDate": {
                borderWidth: "2px !important",
                height: "33px !important",
                "&:focus": {
                    borderColor: "#FB7800 !important",
                    boxShadow: "none !important",
                }
            },
            "& .calendarAddon.input-group-addon": {
                borderWidth: "2px !important",
            },
            "& .multipleContentOnLine": {
                outline: "none !important",
                "& select": {
                    height: "33px !important",
                    borderRadius: "4px",
                    borderWidth: "2px",
                    outline: "none",
                    borderColor: "#ccc",
                    "&:focus": {
                        borderColor: "#FB7800 !important",
                        outline: "none !important",
                    }
                }
            },
            "& .monthYearContainer": {
                alignItems: "center",
            },
            "& .timeIconStyle": {
                top: "50%",
                transform: "translate(0, -50%)",
            },
            "& .glyphicon": {
                color: "#FB7800 !important"
            },
            "& .rangecontainer": {
                "& div": {
                    outline: "none !important",
                }
            },
            "& .calendarGrid": {
                "& div": {
                    outline: "none !important",
                }
            }
        }
    }
}));

export default Filter
