import React, {Component} from "react";
import {
  Container,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  Table,
  Filter,
  PopUpForm
} from "./components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import moment from "moment/moment";

const initFilter = {
  "filter[countries_id]": [],
  "filter[services_code]": [],
  "filter[cascades_id]": [],
  "filter[status]": "",
  "filter[created_at][gt]": null,
  "filter[created_at][lt]": null,

  page: 1,
  limit: 20
};
const perPage = 20;
class CascadeServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filter: {...initFilter},
      totalPages: 1,
      isLoad: true,
      isBackdrop: false
    };
    this.refPopUpForm = React.createRef();
    this.apiController = null;
  }

  componentDidMount = async () => {
    await this.getData();
  }

  getData = async () => {
    if (this.apiController) {
      this.apiController.abort();
    }
    this.setState({ isLoad: true });
    const filter = this.getFilter();
    const apiController = new AbortController();
    this.apiController = apiController;
    const res = await agent.get(`/smsgoapi/go-cascade/cascade-services/list${ filter }`, {
      signal: apiController.signal
    }).then((res) => {
      return res?.data
    }).catch((err) => {
      return {}
    });
    this.setState({
      data: (res?.cascades_service || []),
      totalPages: Math.ceil(Number.parseFloat(res.total_count) / perPage),
      isLoad: false
    });
  }
  createData = async (form) => {
    if (!form) {
      this.refPopUpForm.current.open({
        form: null,
        onSubmit: this.createData.bind(this)
      })
      return
    }

    this.setState({ isBackdrop: true });
    const res = await agent.post(`/smsgoapi/go-cascade/cascade-services/create`, form).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    this.refPopUpForm.current.close();
    await this.getData();
    this.setState({ isBackdrop: false });
    Notification({
      message: "Каскад успешно добавлен",
      type: NotificationTypes.success
    })
  }
  editData = async (item, isSubmit) => {
    if (!isSubmit) {
      const form = {
        "id": item.id,
        "name": item.name,
        "countries_id": item.countries_id,
        "service_id": (item.services || []).map((t) => t.id),
        "cascade_id": item.cascade_id,
        "priority": item.priority,
        "status": item.status
      }
      this.refPopUpForm.current.open({
        form,
        onSubmit: this.editData.bind(this)
      })
      return
    }
    this.setState({ isBackdrop: true });
    const res = await agent.post(`/smsgoapi/go-cascade/cascade-services/update/${ item.id }`, item).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    })
    this.refPopUpForm.current.close();
    await this.getData();
    this.setState({ isBackdrop: false });
    Notification({
      message: "Каскад успешно изменен",
      type: NotificationTypes.success
    })
  }

  getFilter = () => {
    const filter = {...this.state.filter};

    let string = [];
    Object.keys(filter).map((key) => {
      let value = filter[key];

      if (['filter[created_at][gt]', 'filter[created_at][lt]'].includes(key) && value) {
        value = moment(value).format("YYYY-MM-DD HH:mm")
      }
      if (['filter[countries_id]', 'filter[services_code]', 'filter[cascades_id]'].includes(key)) {
        value = value.join(',');
      }

      if (value) {
        string.push(`${key}=${value}`);
      }
    });
    window.history.replaceState(null, null, `/cascade/services?${string.join("&")}`);

    return `?${string.join("&")}`
  }
  changeFilter = async (filter, isFastState = false) => {
    await this.setState({ filter });
    if (!isFastState) {
      return
    }
    await this.getData();
  }
  resetFilter = async () => {
    await this.setState({ filter: {...initFilter} });
    await this.getData();
  }

  render () {
    const {
      data,
      filter,
      totalPages,
      isLoad,
      isBackdrop
    } = this.state;

    return (
      <Container maxWidth="xl">

        <Filter
          filter={filter}

          onReset={this.resetFilter}
          onSearch={this.getData}
          onChange={this.changeFilter}
          onCreate={this.createData}
        />

        <Table
          data={data}
          filter={filter}
          totalPages={totalPages}
          isLoad={isLoad}
          onEdit={this.editData}
          onChangePage={this.changeFilter}
        />

        <PopUpForm
          ref={this.refPopUpForm}
        />

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </Container>
    );
  }
}

export default CascadeServices
