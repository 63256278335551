import React from "react";
import {
	Box,
	Grid,

	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Checkbox,
	Typography,
	Pagination,
	Skeleton,
	Switch,

	Tooltip,
	IconButton, FormControlLabel
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Login as LoginIcon,
	Savings as SavingsIcon,
	Remove as RemoveIcon,
	Telegram as TelegramIcon,
	CheckCircle as CheckCircleIcon,
	Analytics as AnalyticsIcon,
	VpnKey as KeyIcon,
	Edit as EditIcon,
	CreditCard as CreditCardIcon
} from "@mui/icons-material";
import {
	TableHeadSortCell
} from "../../../../../../components";
import moment from "moment";

const TableCustom = (props) => {
	const {
		data,
		filter,
		pagination,
		userRole,

		isLoad,

		onChangeFilter,
		onChangePagination,

		onEdit,
		onOpenUserStatistics,
		onGetUserInfo,
		onUpdateKeyUser,
		onTopUpBalance,
		onSetTelegramInfo,
		onLoginAccountUser,
		onChangeActiveUser,
		onOpenUserLoyaltyProgram
	} = props;
	const classes = useStyles();

	const handleChangePage = (event, page) => {
		let newPagination = {...pagination};
		newPagination.page = page;

		onChangePagination(newPagination)
	}
	const handleChangeSort = (sort) => {
		let newFilter = {...filter};
		newFilter.sort = sort;

		onChangeFilter(newFilter, true);
	}

	return (
		<>

			<Table>

				<TableHead>
					<TableRow>
						<TableCell>Опт</TableCell>
						<TableHeadSortCell name="external_id" filterSort={filter.sort} label="ID" onChange={handleChangeSort}/>
						<TableCell>Email польз.</TableCell>
						<TableHeadSortCell name="created_at" filterSort={filter.sort} label="Дата/время регистрации"
						                   onChange={handleChangeSort}/>
						<TableHeadSortCell name="balance" filterSort={filter.sort} label="Баланс" onChange={handleChangeSort}/>
						<TableHeadSortCell name="discount" filterSort={filter.sort} label="Перс. скидка"
						                   onChange={handleChangeSort}/>
						<TableCell width={200}>Реф. парам.</TableCell>
						<TableHeadSortCell name="discount_group" filterSort={filter.sort} label="Группа"
						                   onChange={handleChangeSort}/>
						<TableHeadSortCell name="active" filterSort={filter.sort} label="Активный" onChange={handleChangeSort}/>
						<TableCell align="right"/>
					</TableRow>
				</TableHead>

				<TableBody>

					{

						Boolean(isLoad) ? (

							<>

								{
									[0, 1, 2, 3, 4, 5].map((item) => (

										<TableRow key={`row-item-load-${item}`}>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
										</TableRow>

									))
								}

							</>

						) : (

							<>

								{

									data.map((row, index) => (

										<TableRow key={`row-item-${index}`}>
											<TableCell align='center'>
												{row.is_wholesale ?
													<CheckCircleIcon color={'success'}/> :
													<RemoveIcon color={'error'}/>}
											</TableCell>
											<TableCell sx={{cursor: "pointer"}}
											           onClick={() => onGetUserInfo(row)}>{row.id}</TableCell>
											<TableCell sx={{cursor: "pointer"}}
											           onClick={() => onGetUserInfo(row)}>{row.email}</TableCell>
											<TableCell>{moment(row.created_at).format("DD.MM.YYYY / HH:mm")}</TableCell>
											<TableCell>{row.balance} ₽</TableCell>
											<TableCell>{row.discount || 0} коэф.</TableCell>
											<TableCell>
												Параметр: {row.ref_param}<br/>

												Значение: {row.ref_code}<br/>

												Процент: {row.ref_coefficient}
											</TableCell>
											<TableCell>{row.discount_group_name || "Не задано"}</TableCell>
											<TableCell>
												<Tooltip
													title={Boolean(Number(row.active || "0")) ? "Выключить пользователя" : "Включить пользователя"}>
													<Switch
														value={Boolean(Number(row.active || "0"))}
														checked={Boolean(Number(row.active || "0"))}
														onChange={(event, value) => onChangeActiveUser(row, value)}
													/>
												</Tooltip>
												<Box mt={1}/>
												<FormControlLabel
													checked={Boolean(row.is_ban)}
													control={<Checkbox/>}
													label="Антифрод бан"
												/>
											</TableCell>
											<TableCell>
												<Grid
													container
													alignItems="center"
													justifyContent="flex-end"
													wrap="nowrap"
												>
													<Grid item>
														<Tooltip title="Программа лояльности пользователя">
															<IconButton size="small" onClick={() => onOpenUserLoyaltyProgram(row)}>
																<SavingsIcon color='primary'/>
															</IconButton>
														</Tooltip>
													</Grid>
													<Grid item>
														<Tooltip title="Статистика пользователя">
															<IconButton size="small" onClick={() => onOpenUserStatistics(row)}>
																<AnalyticsIcon color='primary'/>
															</IconButton>
														</Tooltip>
													</Grid>
													<Grid item>
														<Tooltip title="Пополнить баланс">
															<IconButton size="small" onClick={() => onTopUpBalance(row.external_id, null)}>
																<CreditCardIcon color="primary"/>
															</IconButton>
														</Tooltip>
													</Grid>

													{Boolean(userRole === "Administrator") && (
														<>
															<Grid item>
																<Tooltip title="Установка Telegram BOT">
																	<IconButton size="small" onClick={() => onSetTelegramInfo(row)}>
																		<TelegramIcon color="primary"/>
																	</IconButton>
																</Tooltip>
															</Grid>
															<Grid item>
																<Tooltip title="Войти в ЛК пользователя">
																	<IconButton size="small" onClick={() => onLoginAccountUser(row)}>
																		<LoginIcon color="primary"/>
																	</IconButton>
																</Tooltip>
															</Grid>
															<Grid item>
																<Tooltip title="Обновить API ключ">
																	<IconButton size="small" onClick={() => onUpdateKeyUser(row)}>
																		<KeyIcon color="primary"/>
																	</IconButton>
																</Tooltip>
															</Grid>
															<Grid item>
																<Tooltip title="Редактировать">
																	<IconButton size="small" onClick={() => onEdit(row)}>
																		<EditIcon/>
																	</IconButton>
																</Tooltip>
															</Grid>
														</>
													)}
												</Grid>
											</TableCell>
										</TableRow>

									))

								}

							</>

						)

					}

				</TableBody>

			</Table>

			<Box mt={3}/>

			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Pagination
						page={Number(pagination.page)}
						count={Number(pagination.totalPage)}

						onChange={handleChangePage}
					/>
				</Grid>
				<Grid item></Grid>
			</Grid>

		</>
	)
}

const useStyles = makeStyles((theme) => ({

	buttonAction: {}

}));

export default TableCustom
