import React, {Component} from "react";
import {
	Box,
	Grid,
	Backdrop,
	CircularProgress
} from "@mui/material";
import agent from "../../../../agent/agent";
import {
	Table,
	Filter,
	DialogForm
} from "./components";
import {
	DialogConfirmAction
} from "../../../../components";
import {
	Notification,
	NotificationTypes
} from "../../../../common/Notification";
import moment from "moment";

const initFilter = {
	page: 1,
	"filter[date][gte]": "",
	"filter[date][lte]": "",
	"filter[amount][gte]": "",
	"filter[amount][lte]": "",

	"filter[currency]": "",

	"filter[wallet_id]": "",
	"filter[project_id]": "",
	"filter[category_id]": "",
	"filter[purpose_id]": "",
	"filter[origin]": "",
	"filter[is_approved]": "",
};
export const currencyColor = {
	"USD": "#0A53A8",
	"RUB": "#B10202",
	"GBP": "#11734B",
	"EUR": "#FFE5A0"
};

class SummaryReportPnl extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
			filter: {...initFilter},
			totalPage: 1,
			isLoading: false,
			isBackdrop: false
		};
		this.refDialogForm = React.createRef();
		this.refDialogConfirmAction = React.createRef();
	}

	componentDidMount = async () => {
		await this.initFilter();
		await this.getItems();
	}

	getItems = async () => {
		await this.setState({ isLoading: true });
		const filter = this.getFilter();
		const res = await agent.get(`/api/finance/operations${ filter }`)
			.then((res) => {
				return res
			}).catch(() => null)

		const items = res?.data || [];
		const totalPage = res?.headers?.['x-pagination-page-count'] || 1;

		await this.setState({
			items,
			totalPage,
			isLoading: false
		});
	}

	getFilter = () => {
		const filter = {...this.state.filter};
		let string = [
			'per-page=20',
			'expand=category,project,purpose,wallet'
		];

		Object.keys(filter).map((key) => {
			let value = filter[key];
			if (['filter[date][gte]', 'filter[date][lte]'].includes(key) && value) {
				value = moment(value).format('YYYY-MM-DD');
			}
			if (value) {
				string.push(`${key}=${value}`);
			}
		});

		return `?${ string.join('&') }`
	}
	initFilter = async () => {};
	resetFilter = async () => {
		await this.setState({ filter: {...initFilter} });
		await this.getItems();
	};
	changeFilter = async (filter, isFastStart) => {
		await this.setState({ filter });
		if (!isFastStart) {
			return
		}
		await this.getItems();
	};

	openDialogForm = (form = null) => {
		if (!form) {
			this.refDialogForm.current.open({
				onSubmit: this.createItem.bind(this)
			});
		}
		if (form) {
			this.refDialogForm.current.open({
				itemForm: form,
				onSubmit: this.editItem.bind(this)
			});
		}
	}
	editItem = async (form) => {
		await this.setState({ isBackdrop: true });
		const res = await agent.put(`/api/finance/operations/${ form.id }`, form)
			.then((res) => res?.data)
			.catch((err) => {
				return {error: err?.response}
			})
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			const errorMessage = (res?.error?.data || []).map((t) => t?.message || '').filter((t) => !!t).join(";<br/>");
			Notification({
				type: NotificationTypes.error,
				message: errorMessage || "Ошибка изменения отчета"
			})
			return
		}
		await this.getItems();
		this.refDialogForm.current.close();
		this.setState({
			isBackdrop: false
		});
		Notification({
			type: NotificationTypes.success,
			message: "Отчет успешно изменен"
		})
	};
	createItem = async (form) => {
		await this.setState({ isBackdrop: true });
		const res = await agent.post(`/api/finance/operations`, form)
			.then((res) => res?.data)
			.catch((err) => {
				return {error: err?.response}
			})
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			const errorMessage = (res?.error?.data || []).map((t) => t?.message || '').filter((t) => !!t).join(";<br/>");
			Notification({
				type: NotificationTypes.error,
				message: errorMessage || "Ошибка создания отчета"
			})
			return
		}
		await this.getItems();
		this.refDialogForm.current.close();
		this.setState({
			isBackdrop: false
		});
		Notification({
			type: NotificationTypes.success,
			message: "Отчет успешно создан"
		})
	};
	removeItem = async (item, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmAction.current.open({
				message: `Вы действительно хотите удалить отчет?`,
				onSuccess: this.removeItem.bind(this, item, true)
			})
			return
		}
		this.setState({ isBackdrop: true });
		const isSuccess = await agent.delete(`/api/finance/operations/${ item.id }`)
			.then(() => true)
			.catch(() => false)
		if (!isSuccess) {
			this.setState({ isBackdrop: false });
			Notification({
				type: NotificationTypes.error,
				message: "Ошибка удаления отчета"
			});
			return
		}
		await this.getItems();
		this.setState({ isBackdrop: false });
		Notification({
			type: NotificationTypes.success,
			message: "Отчет успешно удален"
		});
	}

	render() {
		const {
			items,
			filter,
			isLoading,
			totalPage,
			isBackdrop
		} = this.state;

		return (
			<>
				<Filter
					filter={filter}
					onReset={this.resetFilter}
					onSearch={this.getItems}
					onChange={this.changeFilter}
					onCreate={this.openDialogForm.bind(this, null)}
				/>

				<Table
					items={items}
					filter={filter}
					isLoading={isLoading}
					totalPage={totalPage}
					onChange={this.changeFilter}
					onEdit={this.openDialogForm}
					onRemove={this.removeItem}
				/>

				<DialogForm ref={this.refDialogForm}/>
				<DialogConfirmAction ref={this.refDialogConfirmAction}/>
				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		);
	}
}

export default SummaryReportPnl
