import React from "react";
import {
	Box,
	Grid,

	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Typography,
	Pagination,
	Skeleton,
	Switch,

	Tooltip,
	IconButton
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Analytics as AnalyticsIcon,
	VpnKey as KeyIcon,
	Edit as EditIcon,
	CreditCard as CreditCardIcon
} from "@mui/icons-material";
import {
	TableHeadSortCell
} from "../../../../../../components";
import moment from "moment";
import {convertorNumber} from "../../../../../../helper/convertor";

const TableCustom = (props) => {
	const {
		data,
		filter,
		pagination,

		isLoad,

		onChangeFilter,
		onChangePagination
	} = props;
	const classes = useStyles();

	const handleChangePage = (event, page) => {
		let newPagination = {...pagination};
		newPagination.page = page;

		onChangePagination(newPagination)
	}
	const handleChangeSort = (sort) => {
		let newFilter = {...filter};
		newFilter.sort = sort;

		onChangeFilter(newFilter, true);
	}

	const _fundsWithdrawn = (item) => {
		const ref_sum = item?.ref_balance?.ref_sum || item?.ref_sum || 0;
		const ref_balance = item?.ref_balance?.ref_balance || item?.ref_balance || 0;
		const withdrawn = ref_sum - ref_balance;

		return `${convertorNumber(withdrawn, 2, '.')} ₽`
	}

	return (
		<>

			<Table>

				<TableHead>
					<TableRow>
						<TableHeadSortCell name="external_id" filterSort={filter.sort} label="ID" onChange={handleChangeSort}/>
						<TableCell>Email пользователя</TableCell>
						<TableHeadSortCell name="ref_sum" filterSort={filter.sort} label="Начислено денежных средств" onChange={handleChangeSort}/>
						<TableHeadSortCell name="ref_withdrawal" filterSort={filter.sort} label="Выведено" onChange={handleChangeSort}/>
						<TableHeadSortCell name="ref_balance" filterSort={filter.sort} label="Баланс" onChange={handleChangeSort}/>
						<TableHeadSortCell name="ref_count" filterSort={filter.sort} label="Количество активированных ссылок рефералов" onChange={handleChangeSort}/>
					</TableRow>
				</TableHead>

				<TableBody>

					{

						Boolean(isLoad) ? (

							<>

								{
									[0, 1, 2, 3, 4, 5].map((item) => (

										<TableRow key={`row-item-load-${item}`}>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
										</TableRow>

									))
								}

							</>

						) : (

							<>

								{
									
									(data || []).map((row, index) => (

										<TableRow key={`row-item-${index}`}>
											<TableCell>{row?.external_id}</TableCell>
											<TableCell>{row?.email}</TableCell>
											<TableCell>{row?.ref_balance?.ref_sum || row?.ref_sum || '-'} ₽</TableCell>
											<TableCell>{_fundsWithdrawn(row)}</TableCell>
											<TableCell>{row?.ref_balance?.ref_balance || row?.ref_balance || '-'} ₽</TableCell>
											<TableCell>{row.ref_count || '-'}</TableCell>
										</TableRow>

									))

								}

							</>

						)

					}

				</TableBody>

			</Table>

			<Box mt={3}/>

			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Pagination
						page={Number(pagination.page)}
						count={Number(pagination.totalPage)}

						onChange={handleChangePage}
					/>
				</Grid>
				<Grid item></Grid>
			</Grid>

		</>
	)
}

const useStyles = makeStyles((theme) => ({

	buttonAction: {}

}));

export default TableCustom
