import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Typography,
    Pagination,
    Skeleton,

    Tooltip,
    IconButton,
    Avatar
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Remove as RemoveIcon,
    CheckCircle as CheckCircleIcon,
    Edit as EditIcon,
    Delete as DeleteIcon
} from "@mui/icons-material";
import {
    TableHeadSortCell
} from "../../../../../../components";
import moment from "moment";

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,

        isLoad,

        onChangeFilter,
        onChangePagination,


        onEdit,
        onDelete,
    } = props;
    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }
    const handleChangeSort = (sort) => {
        let newFilter = {...filter};
        newFilter.sort = sort;

        onChangeFilter(newFilter, true);
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Опт</TableCell>
                        <TableHeadSortCell
                            label="Порядок"
                            name="sort"
                            filterSort={filter.sort}
                            onChange={handleChangeSort}
                        />
                        <TableCell>Наименование</TableCell>
                        <TableCell>Страна</TableCell>
                        <TableCell sx={{whiteSpace: "nowrap"}}>Поставщик ID</TableCell>
                        <TableCell>Услуга</TableCell>
                        <TableCell>Пользователи</TableCell>
                        <TableHeadSortCell
                            label="Редак."
                            name="updated_at"
                            align="right"
                            filterSort={filter.sort}
                            onChange={handleChangeSort}
                        />
                        <TableHeadSortCell
                            label="Созд."
                            name="created_at"
                            align="right"
                            filterSort={filter.sort}
                            onChange={handleChangeSort}
                        />
                        <TableHeadSortCell
                            label="Цена"
                            name="price"
                            align="right"
                            filterSort={filter.sort}
                            onChange={handleChangeSort}
                        />
                        <TableCell align="right"/>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoad) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (

                                        <TableRow key={`row-item-${index}`}>
                                            <TableCell>{row?.id}</TableCell>

                                            <TableCell align='center'>
                                                {row.for_wholesale ?
                                                    <CheckCircleIcon color={'success'}/> :
                                                    <RemoveIcon color={'error'}/>}
                                            </TableCell>
                                            <TableCell>{row.sort}</TableCell>

                                            <TableCell>{row?.name || "Незаполнено"}</TableCell>
                                            <TableCell width={200}>
                                                <Grid container>
                                                    {(row.countries || []).map((country, indexCountry) => (
                                                        <Grid key={`country-${indexCountry}-row-${index}`} item xs={12}>
                                                            <Grid container spacing={1} alignItems="center"
                                                                  wrap="nowrap">
                                                                <Grid item>
                                                                    <Avatar src={country?.icon}
                                                                            sx={{width: 24, height: 24}}/>
                                                                </Grid>
                                                                <Grid item>
                                                                    {country?.name}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </TableCell>
                                            <TableCell>{row?.provider_id || '-'}</TableCell>
                                            <TableCell width={250}>
                                                <Grid container>
                                                    {(row.services || []).map((service, indexService) => (
                                                        <Grid key={`service-${indexService}-row-${index}`} item xs={12}>
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item>
                                                                    <Avatar src={service?.icon}
                                                                            sx={{width: 24, height: 24}}/>
                                                                </Grid>
                                                                <Grid item>
                                                                    {service?.name}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </TableCell>
                                            <TableCell width={250}>
                                                <Grid container>
                                                    {(row.users || []).map((user, indexUser) => (
                                                        <Grid key={`user-${indexUser}-row-${index}`} item xs={12}>
                                                            {user?.cms_user?.email}
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>{moment(row.updated_at).format("DD.MM.YYYY")}</div>
                                                <div>{moment(row.updated_at).format("HH:mm:ss")}</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>{moment(row.created_at).format("DD.MM.YYYY")}</div>
                                                <div>{moment(row.created_at).format("HH:mm:ss")}</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.price} ₽
                                            </TableCell>
                                            <TableCell>
                                                <Grid container spacing={1} wrap="nowrap" alignItems="center"
                                                      justifyContent="flex-end">
                                                    <Grid item>
                                                        <Tooltip title="Редактировать" arrow>
                                                            <IconButton onClick={() => onEdit(row)}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Удалить" arrow>
                                                            <IconButton onClick={() => onDelete(row)}>
                                                                <DeleteIcon color="error"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {}

}));

export default TableCustom
