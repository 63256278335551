import React from "react";
import {
  Grid,

  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  CheckCircleOutline as CheckIcon,
  HighlightOff as CloseIcon
} from "@mui/icons-material";
import {TableHeadSortCell} from "../../../../../../components";

const TableCustom = (props) => {
  const {
    data,
    sort
  } = props;

  const handleSort = (newSort) => {
    props.onChangeSort(newSort);
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadSortCell
            label="Сервис"
            name="service"
            filterSort={sort}
            onChange={handleSort}
          />
          <TableHeadSortCell
            label="Страна"
            name="country"
            filterSort={sort}
            onChange={handleSort}
          />
          <TableHeadSortCell
            label="Статус"
            name="status"
            filterSort={sort}
            onChange={handleSort}
          />
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <TableRow key={`table-row-service${item?.service?.id}-countries${item.countries.length}${item.countries?.[0]?.country?.id}`}>
            <TableCell>
              <ServiceInfo {...item.service}/>
            </TableCell>
            <TableCell>
              {(item?.countries || []).map((t) => <CountryInfo {...t.country}/>)}
            </TableCell>
            <TableCell>
              {Boolean(item?.countries?.[0]?.status) ? (
                <CheckIcon sx={{color: "#379E3B"}}/>
              ) : (
                <CloseIcon sx={{color: "#DD4321"}}/>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
const ServiceInfo = React.memo((service) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <img src={service.icon} style={{width: 32, height: 32, borderRadius: "100%", objectFit: "contain"}}/>
      </Grid>
      <Grid item>
        {service.name}
      </Grid>
    </Grid>
  )
});
const CountryInfo = React.memo((country) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <img src={country.icon} style={{width: 32, height: 32, borderRadius: "100%", objectFit: "contain"}}/>
      </Grid>
      <Grid item>
        {country.name}
      </Grid>
    </Grid>
  )
})

export default React.memo(TableCustom)
