import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Typography,

    Pagination,

    Skeleton, Avatar
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    TableHeadSortCell
} from "../../../../../../components";
import moment from "moment";
import {convertorNumber} from "../../../../../../helper/convertor";

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,

        isLoad,

        onChangeFilter,
        onChangePagination,
    } = props;
    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }
    const handleChangeSort = (sort) => {
        let newFilter = {...filter};
        newFilter.sort = sort;

        onChangeFilter(newFilter, true);
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>Сервис</TableCell>
                        <TableCell>Страна</TableCell>
                        <TableCell>Запрошено номеров</TableCell>
                        <TableCell>Выдано номеров</TableCell>
                        <TableCell>% выдачи</TableCell>
                        <TableCell>Получено СМС</TableCell>
                        <TableCell>% доставки</TableCell>
                        <TableCell>Расход закупа</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoad) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (

                                        <TableRow key={`row-item-${index}`}>
                                            <TableCell>{row?.service || "-"}</TableCell>
                                            <TableCell>{row?.country || "-"}</TableCell>
                                            <TableCell>{row?.count_numbers || "-"}</TableCell>
                                            <TableCell>{row?.issued_numbers || "-"}</TableCell>
                                            <TableCell>{row?.issued_numbers_percentage || "-"}</TableCell>
                                            <TableCell>{row?.received_numbers || "-"}</TableCell>
                                            <TableCell>{row?.received_numbers_percentage || "-"}</TableCell>
                                            <TableCell>{convertorNumber(row?.sum_supply, 2, '.')}</TableCell>
                                        </TableRow>

                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {}

}));

export default TableCustom
