import {palette} from "../common";

export default {
    styleOverrides: {

        root: {
            fontSize: 18,
            lineHeight: "26px",
            fontWeight: "600",
            height: 45,
            borderRadius: 12,
            boxShadow: "none"
        },

        outlined: {
            borderColor: palette.primary.main,
            color: palette.primary.main
        }
    }
}
