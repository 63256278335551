import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon
} from "@mui/icons-material";
import agent from "../../../../../../agent/agent";
import {
  FilterUsers,
  FilterUserGroups
} from "../../../../../../components";
import {IMaskInput} from "react-imask";
import {
  Notification,
  NotificationTypes
} from "../../../../../../common/Notification";

class InstallingProvider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      providers: [],
      onSubmit: null,
      cascadeId: null,
      isOpen: false,
    }
  }

  open = ({cascadeId, onSubmit} = {}) => {
    this.setState({
      onSubmit,
      cascadeId,
      isOpen: true
    }, async () => {
      await this.getInitialCascade();
    })
  }
  close = () => {
    this.setState({
      providers: [],
      cascadeId: null,
      isOpen: false
    })
  }
  submit = () => {
    const providers = [...this.state.providers];

    let errors = [];
    providers.map((provider, index) => {
      if (!provider?.user_id) {
        errors.push(`Проверьте корректность запонения полей в ${index}эл.`)
      }
    })
    if (errors.length > 0) {
      Notification({
        type: NotificationTypes.error,
        message: errors.join("; <br/>")
      })
      return
    }

    this.state.onSubmit(this.state.cascadeId, true, providers);
  }

  getInitialCascade = async () => {
    const res = await agent.get(`/smsgoapi/go-user-groups/${this.state.cascadeId}`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    })

    this.setState({
      providers: res?.group_users || []
    })
  }

  onAddProvider = () => {
    let providers = [...this.state.providers];
    providers.push({
      user_id: null
    })
    this.setState({providers})
  }
  onRemoveProvider = (index) => {
    let providers = [...this.state.providers];
    providers.splice(index, 1);
    this.setState({providers})
  }
  onChangeProvider = (index, {target}) => {
    let providers = [...this.state.providers];
    providers[index][target.name] = target.value;
    this.setState({providers});
  }

  render () {
    const {
      providers,
      isOpen
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        onClose={this.close}
      >
        <DialogTitle>
          <Typography variant="h3">Установка пользователей</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            py={2}
            spacing={2}
            container
          >
            {providers.map((provider, index) => (
              <Grid item xs={12}>
                <ProviderForm
                  provider={provider}
                  onChange={this.onChangeProvider.bind(this, index)}
                  onRemove={this.onRemoveProvider.bind(this, index)}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                sx={{textTransform: "initial", borderRadius: "4px"}}
                variant="contained"
                fullWidth
                onClick={this.onAddProvider}
              >
                Добавить пользователя
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{textTransform: "initial", borderRadius: "4px"}}
                variant="outlined"
                fullWidth
                onClick={this.close}
              >
                Закрыть
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{textTransform: "initial", borderRadius: "4px"}}
                variant="contained"
                fullWidth
                onClick={this.submit}
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}
const ProviderForm = React.memo((props) => {
  const {
    provider,
    onChange,
    onRemove
  } = props;

  return (
    <Box
      padding="15px"
      borderRadius="10px"
      boxShadow="0 0 3px -1px black"
      position="relative"
    >
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={12}>
          <FilterUsers
            value={provider.user_id}
            name="user_id"
            size="small"
            valueName="id"
            onChange={onChange}
          />
        </Grid>
        <Grid item>
          <IconButton color="error" onClick={onRemove}>
            <DeleteIcon/>
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
})
const CustomInputAmount = ({inputRef, ...rest}) => {

  const onComplete = (value) => {
    rest.onChange({
      target: {
        name: rest.name,
        value
      }
    })
  }

  return (
    <IMaskInput
      ref={inputRef}
      mask={Number}
      thousandsSeparator=""
      radix="."
      mapToRadix={[',']}
      scale={rest?.scale || 4}
      padFractionalZeros={false}

      {...rest}

      onComplete={onComplete}
      onChange={null}
    />
  )
}

export default InstallingProvider
