import React from "react";
import {
	Box,
	Grid,
	Checkbox,
	Skeleton,
	Pagination,
	IconButton,

	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Edit as EditIcon,
	Delete as DeleteIcon
} from "@mui/icons-material";
import {
	VisibleContent
} from "../../../../../../components"
import {
	convertorNumber
} from "../../../../../../helper/convertor";
import {
	currencyColor
} from "../../SummaryReportPnl";
import moment from "moment";

const TableCustom = (props) => {
	const {
		items,
		filter,
		totalPage,
		isLoading,

		onEdit,
		onChange,
		onRemove,
	} = props;

	const handleChangePage = (event, page) => {
		let _filter = {...filter};
		_filter.page = page;
		onChange(_filter, true);
	}

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>№</TableCell>
						<TableCell>Месяц</TableCell>
						<TableCell>Неделя</TableCell>
						<TableCell>Дата</TableCell>
						<TableCell>Сумма</TableCell>
						<TableCell>Валюта</TableCell>
						<TableCell>Кошелёк</TableCell>
						<TableCell>Назначение</TableCell>
						<TableCell>Категория</TableCell>
						<TableCell>Проверка</TableCell>
						<TableCell align="right"/>
						<TableCell/>
					</TableRow>
				</TableHead>
				<TableBody>
					<VisibleContent visible={!isLoading}>
						{items.map((item) => (
							<RowItem
								item={item}

								onEdit={onEdit.bind(this, item)}
								onRemove={onRemove.bind(this, item, false)}
							/>
						))}
					</VisibleContent>
					<VisibleContent visible={isLoading}>
						<RowsLoading/>
					</VisibleContent>
				</TableBody>
			</Table>

			<VisibleContent visible={totalPage > 1}>
				<Box mt={2}/>
				<Grid container justifyContent="flex-end">
					<Grid item>
						<Pagination
							page={filter.page}
							count={totalPage}
							onChange={handleChangePage}
						/>
					</Grid>
				</Grid>
			</VisibleContent>
		</>
	)
}
const RowItem = (props) => {
	const { item, onEdit, onRemove } = props;
	const classes = useStyles();

	return (
		<TableRow>
			<TableCell>{ item?.id }</TableCell>
			<TableCell>{moment(item.date).format('YY')}-{ moment(item.date).format('MM') }</TableCell>
			<TableCell>{moment(item.date).format('YY')}-{ moment(item.date).week() }</TableCell>
			<TableCell>{ moment(item.date).format('DD.MM.YYYY') }</TableCell>
			<TableCell>{ convertorNumber(item.amount, 2, '.') }</TableCell>
			<TableCell>
				<Box className={classes.tag} sx={{background: currencyColor?.[item.currency] || "#999999"}}>
					{ item.currency }
				</Box>
			</TableCell>
			<TableCell>
				<Box className={classes.tag} sx={{background: item?.wallet?.color || "#999999"}}>
					{ item?.wallet?.name }
				</Box>
			</TableCell>
			<TableCell>
				<Box className={classes.tag} sx={{background: item?.purpose?.color || "#999999"}}>
					{ item?.purpose?.name }
				</Box>
			</TableCell>
			<TableCell>
				<Box className={classes.tag} sx={{background: item?.category?.color || "#999999"}}>
					{ item?.category?.name }
				</Box>
			</TableCell>
			<TableCell>
				<Checkbox checked={Boolean(item?.is_approved)}/>
			</TableCell>
			<TableCell>
				<span style={{opacity: 0.6}}>
					созд. {moment(item.created_at).format('DD.MM.YYYY HH:mm')}<br/>
					измн. {moment(item.updated_at).format('DD.MM.YYYY HH:mm')}
				</span>
			</TableCell>
			<TableCell>
				<Grid container wrap="nowrap" justifyContent="flex-end">
					<Grid item>
						<IconButton onClick={onEdit}>
							<EditIcon/>
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton onClick={onRemove}>
							<DeleteIcon/>
						</IconButton>
					</Grid>
				</Grid>
			</TableCell>
		</TableRow>
	)
}
const RowsLoading = () => {
	return new Array(10).fill(1).map((t, i) => (
		<TableRow>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell><Skeleton sx={{height: 10}}/></TableCell>
			<TableCell/>
		</TableRow>
	))
}

const useStyles = makeStyles(() => ({
	tag: {
		padding: "4px 8px",
		borderRadius: "6px",
		textAlign: "center",
		fontSize: "14px",
		color: "white",
		fontWeight: "600",
	}
}));

export default TableCustom
