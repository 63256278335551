export default {

    // user
    "api-usertoken-login": "admin/api-usertoken-login",
    "user-me": "admin/user/me",
    userSession: "admin/api-admin-user/session",

    // transactions
    getTransactionList: "/smsgoapi/gotransactions",

    // gonumbers
    getGoNumbersList: "/smsgoapi/gonumbers",

    // gousers
    getUsersList: "/smsgoapi/gousers",

    // golog
    getLogosList: "/smsgoapi/golog",

    // Files
    filesUpload: "admin/api-admin-storage/files-upload",
    getFileId: "admin/api-admin-storage/file-info?id=",

    // Profile
    profileView: "admin/api-profile-profile",

    // not avalible numbers
    getNotAvalibleNumberList: '/smsgoapi/go-service-status-log'
}
