import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography} from '@mui/material';
import {withStyles} from '@mui/styles';
import React from 'react';

class DialogUserInfo extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentUser: {},

			open: false,
		}
	}

	open = ({currentUser} = {}) => {
		this.setState({
			currentUser: currentUser,
			open: true,
		})
	}

	close = () => {
		this.setState({
			open: false,
		})
	}

	render() {
		const {
			classes,
		} = this.props;

		const {
			currentUser,
			open,
		} = this.state;
		console.log('currentUser: ', currentUser);
		const userSettings = currentUser?.user_settings || {};

		return (
			<Dialog
				open={open}
				maxWidth="sm"
				fullWidth={true}

				onClose={this.close}
			>
				<DialogTitle sx={{backgroundColor: "rgba(251, 120, 0, 1)"}}>
					<Typography variant="h3" sx={{color: "white"}}>
						Информация о пользователе
					</Typography>
				</DialogTitle>

				<DialogContent>
					<Box px={1} py={3}>
						{Object.keys(userSettings).length ? (

							Object.keys(userSettings).map((key) => {
								const item = userSettings?.[key] || "";

								return (

									<Box>
										<Box className={classes.titleWrapper}>
											<Typography className={classes.title}>{`${key}: `} </Typography>
											<Typography className={classes.item}>{item || "-"}</Typography>
										</Box>
										<Divider sx={{margin: "12px 0px"}}/>
									</Box>

								)
							})

						) : (
							<Box className={classes.item} sx={{textAlign: "center"}}>
								Нет данных
							</Box>
						)}


					</Box>
				</DialogContent>
				<DialogActions>
					<Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="contained" onClick={this.close}>
								Отмена
							</Button>
						</Grid>

					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = {

	titleWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},
	title: {
		fontSize: "18px",
		lineHeight: "26px",
		display: "flex",
		color: "#22252d",
		opacity: "0.6",
	},
	item: {
		fontSize: "18px",
		lineHeight: "26px",
		color: "#22252d",
	}
};

export default withStyles(styles)(DialogUserInfo)