import React, {Component} from "react";
import {
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	ControlsPage as ControlsPageComponent,
	Table as TableComponent,
	DialogFormProvider as DialogFormProviderComponent,
	DialogEditProvider as DialogEditProviderComponent
} from "./components";
import {
	DialogConfirmAction
} from "../../../../components";
import {
	Notification,
	NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

class UserManagement extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],

			filter: {},
			pagination: {},

			isLoad: true,
			isShowBackdrop: false
		};

		this.refDialogFormProvider = React.createRef();
		this.refDialogEditProvider = React.createRef();
		this.refDialogConfirmAction = React.createRef();
	}

	componentDidMount = async () => {
		await this.getData();
	}

	// Логику получения данные
	getData = async () => {
		this.setState({ isLoad: true });

		const response = await agent.post(`/smsgoapi/go-provider/list`)
			.then((res) => {
				return res.data
			})
			.catch(() => {
				return []
			})

		this.setState({
			data: response,
			isLoad: false
		})
	}

	// Логика добавления пользователя
	addUser = async (form = null) => {
		if (!form) {
			this.refDialogFormProvider.current.open({
				onSubmit: this.addUser.bind(this)
			});

			return
		}

		this.setState({ isShowBackdrop: true });

		console.log('form: ', form);

		const body = {
			"overwrite": false,
			"user_list": [
				{
					"user_id": Number.parseFloat(form.user),
					"service_code": form.service,
					"country_id": Number.parseFloat(form.country)
				}
			]
		}
		const response = await agent.post(`/smsgoapi/go-provider/set-users/${ form.provider }`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (response.error) {
			this.setState({ isShowBackdrop: false });

			Notification({
				type: NotificationTypes.error,
				message: "Возникла ошибка при редактировании, обратитесь к администраторам."
			})

			return
		}

		await this.getData();
		this.refDialogFormProvider.current.close();
		this.setState({ isShowBackdrop: false });
		Notification({
			type: NotificationTypes.success,
			message: "Пользователь успешно добавлен"
		})
	}

	// Логика удаления пользователя
	removeUser = async (provider, userItem, userList, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmAction.current.open({
				title: "Подтверждение",
				message: `Вы действительно хотите удалить пользователя в провайдере "${ provider.name }"?`,
				onSuccess: this.removeUser.bind(this, provider, userItem, userList, true)
			})

			return
		}

		this.setState({ isShowBackdrop: true });

		let newUserList = [...userList];
		let indexRemoveItem = newUserList.findIndex((t) => Boolean(
			t.country_id === userItem.country_id &&
			t.service_code === userItem.service_code &&
			t.user_id === userItem.user_id
		));
		newUserList.splice(indexRemoveItem, 1);

		const body = {
			"overwrite": true,
			"user_list": newUserList.map((item) => {
				return {
					"user_id": item.user_id,
					"service_code": item.service_code,
					"country_id": Number.parseFloat(item.country_id)
				}
			})
		};
		const response = await agent.post(`/smsgoapi/go-provider/set-users/${ provider.id }`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (response.error) {
			this.setState({ isShowBackdrop: false });

			Notification({
				type: NotificationTypes.error,
				message: "Возникла ошибка при редактировании, обратитесь к администраторам."
			})

			return
		}

		await this.getData();
		this.setState({ isShowBackdrop: false });
		Notification({
			type: NotificationTypes.success,
			message: "Пользователь успешно удален"
		})

	}

	// Логика редактирование пользователя
	editUser = async (provider, userItem, userList, isSubmit, indexElement) => {
		if (!isSubmit) {
			const indexChangeItem = userList.findIndex((t) => Boolean(
				t.country_id === userItem.country_id &&
				t.service_code === userItem.service_code &&
				t.user_id === userItem.user_id
			));

			this.refDialogEditProvider.current.open({
				provider,
				userItem,
				userList,
				indexChangeItem,
				onSubmit: this.editUser.bind(this)
			})

			return
		}

		this.setState({ isShowBackdrop: true });

		let newUserList = [...userList];
		newUserList.splice(indexElement, 1);
		newUserList.push(userItem)

		const body = {
			"overwrite": true,
			"user_list": newUserList.map((item) => {
				return {
					"user_id": item.user_id,
					"service_code": item.service_code,
					"country_id": Number.parseFloat(item.country_id)
				}
			})
		};
		const response = await agent.post(`/smsgoapi/go-provider/set-users/${ provider.id }`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (response.error) {
			this.setState({ isShowBackdrop: false });

			Notification({
				type: NotificationTypes.error,
				message: "Возникла ошибка при редактировании, обратитесь к администраторам."
			})

			return
		}

		await this.getData();
		this.refDialogEditProvider.current.close();
		this.setState({ isShowBackdrop: false });
		Notification({
			type: NotificationTypes.success,
			message: "Пользователь успешно изменен"
		})
	}


	render() {
		const {
			data,

			filter,
			pagination,

			isLoad,
			isShowBackdrop
		} = this.state;

		return (
			<>

				<ControlsPageComponent
					onAddUser={this.addUser.bind(this, null)}
				/>

				<TableComponent
					data={data}
					filter={filter}
					pagination={pagination}
					isLoad={isLoad}

					onRemoveUser={this.removeUser}
					onEditUser={this.editUser}
				/>

				<DialogFormProviderComponent
					ref={this.refDialogFormProvider}
				/>
				<DialogEditProviderComponent
					ref={this.refDialogEditProvider}
				/>
				<DialogConfirmAction
					ref={this.refDialogConfirmAction}
				/>
				<Backdrop open={isShowBackdrop}>
					<CircularProgress/>
				</Backdrop>

			</>
		);
	}
}

export default UserManagement