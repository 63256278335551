import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel
} from "@mui/material";
import {
  Formik
} from "formik";
import {
  FilterProvider,
  FilterService,
  FilterCountry
} from "../../../../../../components";
import * as Yup from "yup";
import {IMaskInput} from "react-imask";

const initForm = {
  "providers": [],
  "services": [],
  "countries": [],

  "filter_name": "",
  "provider_id": "",
  "service_id": "",
  "country_id": "",
  "has_code": false,
  "status": false,
  "count": "",
  "hours": ""
};
const initState = {
  form: {...initForm},
  isOpen: false,
  isEdit: false,
  onSubmit: null
};
class DialogForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {...initState};
    this.refFormik = React.createRef();
  }

  open = ({ form, onSubmit }) => {
    if (!!form) {
      form = {
        ...initForm,
        ...form
      }
    }

    console.log('form: ', form);

    this.setState({
      form: form || {...initForm},
      isOpen: true,
      isEdit: Boolean(form),
      onSubmit
    })
  }
  close = () => {
    this.setState({
      ...initState
    })
  }

  changeForm = ({ target }) => {
    if (!this.refFormik.current) {
      return
    }
    const { name, value } = target;
    let values = this.refFormik.current?.values || {};
    values[name] = value;
    this.refFormik.current.setValues(values);
  }
  submitForm = (form) => {
    const body = {...form};
    body.service_id = Number.parseFloat(body.service_id);
    body.status = Number(body.status);
    body.has_code = Number(body.has_code);
    body.count = Number.parseFloat(body.count || 0);
    body.hours = Number.parseFloat(body.hours || 0);
    this.state.onSubmit(body, true);
  }

  render () {
    const {
      form,
      isOpen,
      isEdit
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { overflow: "initial" }
        }}
      >
        <DialogTitle sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
          <Typography variant="h3" sx={{color: "white"}}>
            {isEdit ? "Редактирование" : "Создание"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            innerRef={this.refFormik}
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={this.submitForm}
          >{(props) => {
            const {
              values,
              errors,
              touched,
              handleSubmit
            } = props;

            return (
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      value={values.filter_name}
                      name="filter_name"
                      label="Наименование"
                      size="small"
                      fullWidth
                      error={Boolean(touched.filter_name && errors.filter_name)}
                      helperText={touched.filter_name && errors.filter_name}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FilterProvider
                      value={values.providers}
                      name="providers"
                      label="Провайдер"
                      size="small"
                      fullWidth
                      multiple={true}
                      error={Boolean(touched.providers && errors.providers)}
                      helperText={touched.providers && errors.providers}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FilterService
                      value={values.services}
                      name="services"
                      label="Услуга"
                      size="small"
                      valueName="internal_id"
                      fullWidth
                      multiple={true}
                      error={Boolean(touched.services && errors.services)}
                      helperText={touched.services && errors.services}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FilterCountry
                      value={values.countries}
                      name="countries"
                      label="Страна"
                      size="small"
                      fullWidth
                      multiple={true}
                      error={Boolean(touched.countries && errors.countries)}
                      helperText={touched.countries && errors.countries}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={String(values.count)}
                      name="count"
                      label="Количество"
                      size="small"
                      fullWidth
                      error={Boolean(touched.count && errors.count)}
                      helperText={touched.count && errors.count}
                      inputProps={{ scale: 0 }}
                      InputProps={{ inputComponent: CustomInputAmount }}
                      InputLabelProps={{ shrink: Boolean(values.count !== '') }}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={String(values.hours)}
                      name="hours"
                      label="Часы"
                      size="small"
                      fullWidth
                      error={Boolean(touched.hours && errors.hours)}
                      helperText={touched.hours && errors.hours}
                      inputProps={{ scale: 0 }}
                      InputProps={{ inputComponent: CustomInputAmount }}
                      InputLabelProps={{ shrink: Boolean(values.hours) }}
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      checked={Boolean(values.has_code)}
                      control={<Checkbox />}
                      label="Получено СМС"
                      error={Boolean(touched.has_code && errors.has_code)}
                      helperText={touched.has_code && errors.has_code}
                      onChange={this.changeForm.bind(this, {target: {name: "has_code", value: !Boolean(values.has_code)}})}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      checked={Boolean(values.status)}
                      control={<Checkbox />}
                      label="Статус"
                      error={Boolean(touched.status && errors.status)}
                      helperText={touched.status && errors.status}
                      onChange={this.changeForm.bind(this, {target: {name: "status", value: !Boolean(values.status)}})}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={4} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      sx={{borderRadius: "4px", textTransform: "initial"}}
                      onClick={this.close}
                    >Отменить</Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{borderRadius: "4px", textTransform: "initial"}}
                      onClick={handleSubmit}
                    >Сохранить</Button>
                  </Grid>
                </Grid>
              </Box>
            )
          }}</Formik>
        </DialogContent>
      </Dialog>
    )
  }
}
const CustomInputAmount = ({inputRef, ...rest}) => {

  const onComplete = (value) => {
    rest.onChange({
      target: {
        name: rest.name,
        value
      }
    })
  }

  return (
    <IMaskInput
      ref={inputRef}
      mask={Number}
      thousandsSeparator=""
      radix="."
      mapToRadix={[',']}
      scale={rest?.scale || 4}

      {...rest}

      onComplete={onComplete}
      onChange={null}
    />
  )
}

const validationSchema = Yup.object().shape({
  filter_name: Yup.string().nullable().required('Обязательно к заполнению'),
  count: Yup.string().nullable().required('Обязательно к заполнению'),
  hours: Yup.string().nullable().required('Обязательно к заполнению'),

  services: Yup.array().when(["providers", "countries"], {
    is: (providers, countries) => {
      return Boolean((providers || []).length <= 0 && (countries || []).length <= 0)
    },
    then: Yup.array().min(1, "Обязательно к заполнению")
  }),
  providers: Yup.array().when(["services", "countries"], {
    is: (services, countries) => {
      return Boolean((services || []).length <= 0 && (countries || []).length <= 0)
    },
    then: Yup.array().min(1, "Обязательно к заполнению")
  }),
  countries: Yup.array().when(["providers", "services"], {
    is: (providers, services) => {
      return Boolean((providers || []).length <= 0 && (services || []).length <= 0)
    },
    then: Yup.array().min(1, "Обязательно к заполнению")
  }),
}, [
  ["providers", "services"],
  ["providers", "countries"],
  ["services", "countries"],
]);

export default DialogForm
