import React, {Component} from "react";
import {
  Grid,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  Table as TableComponent,
  DialogForm as DialogFormComponent
} from "./components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

class LoyaltyRanks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loyalty: [],
      filter: {
        page: 1
      },
      totalCount: 1,
      isLoad: true,
      isBackdrop: false
    };
    this.refDialogForm = React.createRef();
  }

  componentDidMount = async () => {
    await this.getLoyalty();
  }

  getLoyalty = async () => {
    this.setState({ isLoad: true });
    const filter = this._getFilter();
    const res = await agent.get(`/admin/api-sms-loyalty/list?${ filter }`).then((res) => {
      return res
    }).catch(() => {
      return null
    });

    this.setState({
      loyalty: res?.data || [],
      totalCount: res?.headers?.['x-pagination-page-count'] || 1,
      isLoad: false
    })
  }
  createLoyalty = async (form) => {
    if (!form) {
      this.refDialogForm.current.open({
        onSubmit: this.createLoyalty.bind(this)
      })

      return
    }

    this.setState({ isBackdrop: true });

    let body = {...form};
    body.active = Number(body.active);
    body.min_deposit = Number.parseFloat(body.min_deposit);
    body.cashback_percent = Number.parseFloat(body.cashback_percent);

    const res = await agent.post(`/admin/api-sms-loyalty`, body).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    if (res.error) {
      this.setState({ isBackdrop: false });

      let errors = [];
      if (Boolean(Array.isArray(res?.error?.data))) {
        (res?.error?.data || []).map((t) => {
          errors.push(t?.message)
        });
      }
      if (errors.length <= 0) {
        errors.push(res?.error?.data?.message)
      }

      Notification({
        type: NotificationTypes.error,
        message: errors.join(";</br>")
      })

      return
    }

    await this.getLoyalty();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      type: NotificationTypes.success,
      message: "Ранг добавлен"
    });
  }
  changeLoyalty = async (form, isSubmit) => {
    if (!isSubmit) {
      this.refDialogForm.current.open({
        form: form,
        onSubmit: this.changeLoyalty.bind(this)
      })

      return
    }

    this.setState({ isBackdrop: true })

    let body = {...form};
    body.active = Number(body.active);
    const res = await agent.put(`/admin/api-sms-loyalty/${ form.id }?pool=null`, body).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    })
    if (res.error) {
      this.setState({ isBackdrop: false });

      let errors = [];
      if (Boolean(Array.isArray(res?.error?.data))) {
        (res?.error?.data || []).map((t) => {
          errors.push(t?.message)
        });
      }
      if (errors.length <= 0) {
        errors.push(res?.error?.data?.message)
      }

      Notification({
        type: NotificationTypes.error,
        message: errors.join(";</br>")
      })
      return
    }

    await this.getLoyalty();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false })
    Notification({
      type: NotificationTypes.success,
      message: "Успешно изменено"
    });
  }

  _getFilter = () => {
    let filter = [
      'fields=id%2Cid%2Cname%2Ccashback_percent%2Cmin_deposit%2Cactive',
      'ngrestCallType=list',
      'sort=-name',
      `page=${this.state.filter.page}`
    ]

    return filter.join("&")
  }
  changeFilter = async (filter) => {
    await this.setState({ filter });
    await this.getLoyalty();
  }

  render () {
    const {
      loyalty,
      filter,
      totalCount,
      isLoad,
      isBackdrop
    } = this.state;

    return (
      <>

        <Grid container justifyContent="flex-end" mb={1}>
          <Grid item>
            <Button variant="contained" onClick={this.createLoyalty.bind(this, null)}>
              Добавить
            </Button>
          </Grid>
        </Grid>

        <TableComponent
          data={loyalty}
          filter={filter}
          totalCount={totalCount}
          isLoad={isLoad}
          onChange={this.changeLoyalty}
          onChangePopUp={this.changeLoyalty}
        />

        <DialogFormComponent ref={this.refDialogForm}/>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </>
    );
  }
}

export default LoyaltyRanks
