import React from "react";
import {
	Box,
	Grid,
	Radio,
	Button,
	RadioGroup,
	Typography,
	FormControl,
	FormControlLabel,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";

class DialogChangeStatus extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			value: "",

			item: null,
			isOpen: false,
			onSubmit: null
		}
	}

	open = ({ item, onSubmit }) => {
		this.setState({
			item,
			onSubmit,

			isOpen: true
		})
	}
	close = () => {
		this.setState({
			item: null,
			isOpen: false,
			onSubmit: null
		})
	}

	change = (event, value) => {
		this.setState({ value });
	}
	submit = () => {
		const { item, value } = this.state;
		const _item = {...item, status: value};

		this.state.onSubmit(_item, true)
	}

	render () {
		const {
			item,
			isOpen,
		} = this.state;
		const {
			classes
		} = this.props;
		console.log('item: ', item);

		if (!isOpen) {
			return null
		}
		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="xs"
			>
				<DialogTitle>
					<Typography variant="h3">Изменение статуса вывода средств</Typography>
				</DialogTitle>
				<DialogContent>
					<FormControl>
						<RadioGroup value={this.state.value} onChange={this.change}>
							<FormControlLabel value="success" control={<Radio />} label="Выполнен" />
							<FormControlLabel value="refuse" control={<Radio />} label="Отклонен" />
						</RadioGroup>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Box px={2} pb={1}>
						<Grid container justifyContent="flex-end" spacing={1}>
							<Grid item>
								<Button
									size="small"
									color="primary"
									variant="outlined"
									className={classes.buttonAction}
									onClick={this.close}
								>Отменить</Button>
							</Grid>
							<Grid item>
								<Button
									size="small"
									color="primary"
									variant="contained"
									className={classes.buttonAction}
									onClick={this.submit}
								>Применить</Button>
							</Grid>
						</Grid>
					</Box>
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = {

	buttonAction: {
		height: "auto",
		padding: "8px 20px",
		borderRadius: 4,

		fontSize: "16px",
		lineHeight: "16px",
	}
};
DialogChangeStatus = withStyles(styles)(DialogChangeStatus);

export default DialogChangeStatus
