// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import CascadeServices from './CascadeServices';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(CascadeServices);
