import {palette} from "../common";

export default {
    styleOverrides: {

        root: {
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 999999
        }
    }
}
