import React from "react";
import {
    TextField,
    Autocomplete
} from "@mui/material";
import {compose} from "recompose";
import {connect} from "react-redux";

class AutocompleteUsers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoad: false
        };


        this.timeOutSearch = null;
    }

    onChange = (value) => {

        if ( this.props.multiple ) {
            const parseValue = [...value].map((item) => {
                return item?.external_id || item
            })

            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: parseValue || []
                }
            });
        }

        if ( !this.props.multiple ) {
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: String(value?.external_id) || null
                },
            });
        }

    }

    _getOptionLabel = (row) => {
        const options = this.props.users;

        if (typeof row !== 'object') {
            const item = options.find((t) => String(t.external_id) === String(row));

            if (!item) {
                return ""
            }

            return `${item?.email} (ID: ${item?.external_id})`
        }

        return `${row?.email} (ID: ${row?.external_id})`
    }
    _filterOptions = (list, params) => {
        const inputValue = params?.inputValue || "";

        return [...list].filter((t) => {

            if (!inputValue) {
                return true
            }

            const nameLowerCase = t.email.toLowerCase();
            const inputValueLowerCase = inputValue.toLowerCase();

            return Boolean(nameLowerCase.indexOf(inputValueLowerCase) > -1)

        })
    }

    render() {
        const {
            value,
            name,
            users,
            multiple
        } = this.props;
        const {
            isLoad
        } = this.state;

        return (
            <>

                <Autocomplete
                    {...this.props}
                    value={value}
                    disablePortal
                    options={users}
                    onChange={(event, value) => this.onChange(value, name)}
                    name={name}
                    variant="outlined"
                    fullWidth
                    disableClearable
                    loading={Boolean(users.length <= 0)}
                    onOpen={this.getOptions}
                    limitTags={1}
                    filterSelectedOptions={Boolean(multiple)}
                    autoComplete={Boolean(multiple)}

                    isOptionEqualToValue={(option, value) => Boolean(String(option.external_id) === String(value))}

                    getOptionLabel={this._getOptionLabel}
                    filterOptions={this._filterOptions}

                    renderInput={(params) => <TextField
                        {...params}
                        label="Пользователи"
                        fullWidth
                    />}
                />

            </>
        );
    }

}

export default compose(
    connect(
        state => ({
            users: state?.global?.users || []
        })
    ),
)(AutocompleteUsers)
