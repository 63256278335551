import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import {
  FilterProvider,
  FilterService,
  FilterCountry,
  FilterDateRangeFull,
} from "../../../../../../components";

const Filter = (props) => {
  const {
    filter,
    onReset,
    onChange,
    onSearch
  } = props;

  const handleChangeFilter = ({ target }) => {
    let newFilter = {...filter};
    newFilter[target.name] = target.value;
    onChange(newFilter);
  }
  const handleChangeDateRange = (event) => {
    let newFilter = {...filter};
    newFilter.date_start = event[0]
    newFilter.date_end = event[1]
    onChange(newFilter)
  }

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">Фильтры</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            {Boolean(false) && (
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                >
                  Download CSV
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                onClick={onReset}
              >
                Сбросить
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                onClick={onSearch}
              >
                Поиск
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={2} borderRadius={4} p={2} bgcolor="white">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FilterDateRangeFull
              value={[filter.date_start, filter.date_end]}
              onChange={handleChangeDateRange}
            />
          </Grid>
          <Grid item xs={2.25}>
            <FilterService
              value={filter.service}
              size="small"
              name="service"
              multiple={true}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={2.25}>
            <FilterCountry
              value={filter.country}
              name="country"
              size="small"
              multiple={true}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={2.25}>
            <FilterProvider
              value={filter.provider}
              name="provider"
              size="small"
              multiple={true}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={2.25}>
            <FormControl fullWidth size="small">
              <InputLabel>Группировать</InputLabel>
              <Select
                value={filter.group_by}
                name="group_by"
                label="Группировать"
                onChange={handleChangeFilter}
              >
                <MenuItem value="provider">Провайдер</MenuItem>
                <MenuItem value="service">Сервис</MenuItem>
                <MenuItem value="country">Страна</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default React.memo(Filter)
