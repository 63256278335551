import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	TextField,
	Typography
} from "@mui/material";
import agent from "../../../../../../agent/agent";

class TelegramGroup extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				chat_id: "",
				balance_threshold: "1000",
			},

			user: null,
			onSubmit: null,
			isOpen: false,
			isLoading: true,
		};
	}

	open = ({ user, onSubmit }) => {
		this.setState({
			user,
			onSubmit,
			isOpen: true,
			isLoading: true
		}, async () => {
			await this.initForm();
		})
	}
	close = () => {
		this.setState({
			form: {
				chat_id: "",
				balance_threshold: "1000",
			},

			user: null,
			onSubmit: null,
			isOpen: false,
			isLoading: true,
		})
	}

	initForm = async () => {
		const res = await agent.get(`/api/telegrambot/get-balance-group?user_id=${ this.state.user?.external_id }`).then((res) => {
			return res.data || null
		}).catch(() => {
			return null
		});
		if (!res) {
			this.setState({ isLoading: false })
			return
		}

		this.setState({
			form: {
				chat_id: res?.chat_id,
				balance_threshold: String(res?.balance_threshold),
			},
			isLoading: false
		})
	}
	changeForm = ({ target }) => {
		const { name, value } = target;
		let form = {...this.state.form};
		form[name] = value;
		this.setState({ form });
	}
	submitForm = () => {
		const { user, form } = this.state;
		const body = {
			chat_id: form.chat_id,
			user_id: user?.external_id,
			balance_threshold: form.balance_threshold,
		};
		this.state.onSubmit(user, body)
	}

	render () {
		const {
			user,
			form,
			isOpen,
			isLoading
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth={true}
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h3">
						Telegram
					</Typography>
				</DialogTitle>
				<DialogContent>

					{Boolean(!isLoading) && (
						<Grid container py={1} spacing={2}>
							<Grid item xs={12}>
								<TextField
									value={form.chat_id}
									fullWidth={true}
									label="ID чата"
									name="chat_id"
									size="small"
									placeholder="Введите ID чата"
									onChange={this.changeForm}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={form.balance_threshold}
									fullWidth={true}
									label="Сумма баланса для оповещения"
									name="balance_threshold"
									placeholder="Введите сумма"
									type="number"
									size="small"
									onChange={this.changeForm}
								/>
							</Grid>
						</Grid>
					)}
					{Boolean(isLoading) && (
						<Typography variant="caption">
							Идет загрузка...
						</Typography>
					)}

				</DialogContent>
				<DialogActions>
					<Box px={2}>
						<Grid container justifyContent="flex-end" spacing={1}>
							<Grid item>
								<Button
									size="small"
									variant="outlined"
									onClick={this.close}
								>Отменить</Button>
							</Grid>
							<Grid item>
								<Button
									size="small"
									variant="contained"
									disabled={Boolean(!form.chat_id || form.balance_threshold === '')}
									onClick={this.submitForm}
								>Применить</Button>
							</Grid>
						</Grid>
					</Box>
				</DialogActions>
			</Dialog>
		)
	}
}

export default TelegramGroup
