import React from "react";
import {
	Box,
	Grid,
	Button,
	Select,
	MenuItem,
	TextField,
	InputLabel,
	Typography,
	FormControl,
	FormHelperText,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Formik
} from "formik";
import {
	IMaskInput
} from "react-imask";
import {
	FilterProvider
} from "../../../../../../components";
import * as Yup from "yup";

const initForm = {
	sum: "",
	wallet: "",
	comment: "",
	currency: "",
	provider_id: "",
	payout_type: "",
};

class DialogCreate extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			initValues: {...initForm},
			isOpen: false,
			onSubmit: null,
		};
		this.refFormik = React.createRef();
	}

	open = ({ onSubmit }) => {
		this.setState({
			initValues: {...initForm},
			isOpen: true,
			onSubmit
		})
	}
	close = () => {
		this.setState({
			isOpen: false
		})
	}
	submit = (form) => {
		this.state.onSubmit(form)
	}

	changeForm = ({ target }) => {
		const { name, value } = target;
		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);
	};

	render () {
		const {
			isOpen,
			initValues
		} = this.state;
		const {
			config,
			classes
		} = this.props;

		if (!isOpen) {
			return null
		}
		return (
			<Dialog
				open={true}
				fullWidth
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle sx={{ background: "#FB7800" }}>
					<Typography variant="h3" sx={{ color: "white" }}>Создание выплаты</Typography>
				</DialogTitle>
				<DialogContent>
					<Box mt={2}/>
					<Formik
						innerRef={this.refFormik}
						initialValues={initValues}
						validationSchema={validationSchema}
						onSubmit={this.submit}
					>{(params) => {
						const {
							values,
							errors,
							touched,
							handleSubmit
						} = params;

						return (
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<SelectElement
										value={values.provider_id}
										name="provider_id"
										size="small"
										label="Провайдер"
										options={[{label: 'accishop', value: 1}]}
										helperText={touched.provider_id && errors.provider_id}
										error={Boolean(errors.provider_id && touched.provider_id)}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<SelectElement
										label="Валюта"
										name="currency"
										value={values.currency}
										options={config?.currencies || []}
										helperText={touched.currency && errors.currency}
										error={Boolean(errors.currency && touched.currency)}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<SelectElement
										label="Тип кошелька"
										name="payout_type"
										value={values.payout_type}
										options={config?.types || []}
										helperText={touched.payout_type && errors.payout_type}
										error={Boolean(errors.payout_type && touched.payout_type)}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										value={values.wallet}
										label="Адрес кошелька"
										placeholder="***"
										name="wallet"
										size="small"
										fullWidth
										helperText={touched.wallet && errors.wallet}
										error={Boolean(errors.wallet && touched.wallet)}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										value={values.sum}
										label="Сумма"
										placeholder="***"
										name="sum"
										size="small"
										fullWidth
										inputProps={{scale: 2}}
										helperText={touched.sum && errors.sum}
										error={Boolean(errors.sum && touched.sum)}
										InputProps={{inputComponent: CustomInputAmount}}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										value={values.comment}
										rows={3}
										label="Комментарий"
										name="comment"
										fullWidth
										multiline
										helperText={touched.comment && errors.comment}
										error={Boolean(errors.comment && touched.comment)}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={2} justifyContent="flex-end">
										<Grid item>
											<Button
												size="small"
												variant="outlined"
												onClick={this.close}
											>Отменить</Button>
										</Grid>
										<Grid item>
											<Button
												size="small"
												variant="contained"
												onClick={handleSubmit}
											>Добавить</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)
					}}</Formik>
				</DialogContent>
			</Dialog>
		)
	}
}
const CustomInputAmount = ({inputRef, ...rest}) => {

	const onComplete = (value) => {
		rest.onChange({
			target: {
				name: rest.name,
				value
			}
		})
	}

	return (
		<IMaskInput
			ref={inputRef}
			mask={Number}
			thousandsSeparator=""
			scale={rest?.scale || 4}
			min={0}
			{...rest}
			onComplete={onComplete}
			onChange={null}
		/>
	)
}
const SelectElement = ({ label, options, name, value, error, helperText, onChange }) => {
	return (
		<FormControl
			fullWidth
			size="small"
			error={error}
		>
			<InputLabel>{ label }</InputLabel>
			<Select
				name={ name }
				value={ value }
				label={ label }
				onChange={onChange}
			>
				{options.map((_item, index) => (
					<MenuItem
						key={`MenuItem-${ index }`}
						value={_item.value}
					>{_item.label}</MenuItem>
				))}
			</Select>
			{Boolean(error) && (
				<FormHelperText error={true}>{ helperText }</FormHelperText>
			)}
		</FormControl>
	)
}

const validationSchema = Yup.object().shape({
	sum: Yup.string().required('Заполните поле'),
	wallet: Yup.string().required('Заполните поле'),
	currency: Yup.string().required('Заполните поле'),
	provider_id: Yup.string().required('Заполните поле'),
	payout_type: Yup.string().required('Заполните поле'),
});

const styles = {
	toggleButtonGroup: {}
};
DialogCreate = withStyles(styles)(DialogCreate);

export default DialogCreate
export {
	SelectElement
}
