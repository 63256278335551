// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import CompensationTg2fa from './CompensationTg2fa';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(CompensationTg2fa);
