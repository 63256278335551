import React from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Collapse,

} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Search as SearchIcon,
    Clear as ClearIcon,
    FilterAlt as FilterAltIcon,
    HighlightOff as HighlightOffIcon,
    DocumentScanner as DocumentScannerIcon
} from "@mui/icons-material";
import {
    FilterAdmin,
} from "../../../../../../components";
import moment from "moment";

const Filter = (props) => {
    const {
        paymentsType,

        filter,
        activeFilterCountry,
        initOpenFilter,

        onChange,
        onReset,
        onSearch,
        onUploadDocument,
        onChangeActiveFilterCountry
    } = props;
    const [isOpen, setOpen] = React.useState(true);
    const classes = useStyles();

    React.useEffect(() => {
        setOpen(initOpenFilter || true);
    }, [initOpenFilter]);


    const handleChangeCommon = ({ target }) => {
        const { name, value } = target;

        let newFilter = { ...filter };
        newFilter[name] = value;

        onChange(newFilter);
    }

    const _activeFilters = () => {

        let items = [];

        if (filter["country"]) {
            items.push({
                label: "Страна",
                value: activeFilterCountry,
                onClick: _deleteCommon.bind(this, "country")
            })
        }
        if (filter["service"]) {
            items.push({
                label: "Услуга",
                value: filter["service"],
                onClick: _deleteCommon.bind(this, "service")
            })
        }
        if (filter["filter[type]"]) {
            items.push({
                label: "Тип операции",
                value: filter["filter[type]"],
                onClick: _deleteCommon.bind(this, "filter[type]")
            })
        }
        if (filter["filter[phone_number_id]"]) {
            items.push({
                label: "ИД транзакции",
                value: filter["filter[phone_number_id]"],
                onClick: _deleteCommon.bind(this, "filter[phone_number_id]")
            })
        }
        if (filter["date_start"] && filter["date_end"]) {
            items.push({
                label: "Дата",
                value: `${moment(filter["date_start"]).format('DD.MM.YYYY')} - ${moment(filter["date_end"]).format('DD.MM.YYYY')}`,
                onClick: _deleteDateRange.bind(this, "country")
            })
        }
        if (filter["filter[amount][gt]"] && filter["filter[amount][lt]"]) {
            items.push({
                label: "Сумма",
                value: `${filter["filter[amount][gt]"]} ₽ - ${filter["filter[amount][lt]"]} ₽`,
                onClick: _deleteAmountRange.bind(this)
            })
        }


        return items
    }
    const _deleteCommon = (name) => {
        let newFilter = { ...filter };
        newFilter[name] = "";

        onChange(newFilter, true);
    }
    const _deleteDateRange = () => {
        let newFilter = { ...filter };
        newFilter["date_start"] = null;
        newFilter["date_end"] = null;

        onChange(newFilter, true);
    }
    const _deleteAmountRange = () => {
        let newFilter = { ...filter };
        newFilter["filter[amount][gt]"] = null;
        newFilter["filter[amount][lt]"] = null;

        onChange(newFilter, true);
    }

    return (
        <Box mb={1}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Фильтры</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>

                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1 }}
                                onClick={() => setOpen(!isOpen)}
                                endIcon={<FilterAltIcon />}
                            >
                                {isOpen ? "Свернуть" : "Развернуть"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1 }}
                                onClick={onReset}
                                endIcon={<ClearIcon />}
                            >
                                Сбросить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{ textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1 }}
                                endIcon={<SearchIcon />}
                                onClick={onSearch}
                            >
                                Поиск
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1} />

            <Collapse in={isOpen} timeout="auto" unmountOnExit>

                <Box px={2} py={2} bgcolor="white" borderRadius={4}>
                    <Grid container spacing={4}>
                        <Grid item sm={3}>
                            <FilterAdmin
                                name="filter[admin_user_id]"
                                value={filter["filter[admin_user_id]"]}
                                size="small"
                                fullWidth

                                onChange={handleChangeCommon}
                            />
                        </Grid>

                    </Grid>
                </Box>

            </Collapse>

            {Boolean(_activeFilters().length > 0) && (
                <>

                    <Box my={1} />

                    <Grid container spacing={2}>
                        {_activeFilters().map((item, index) => (
                            <Grid item>
                                <Box className={classes.active} onClick={item.onClick}>
                                    <Typography className={classes.activeLabel}>{item.label}:</Typography>
                                    <Typography className={classes.activeValue}>{item.value}</Typography>
                                    <HighlightOffIcon sx={{ color: "white", fontSize: 16, marginLeft: 1 }} />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                </>
            )}

        </Box>
    )
}

const useStyles = makeStyles(() => ({

    active: {
        display: "flex",
        alignItems: "center",
        padding: "2px 12px",
        borderRadius: 6,
        backgroundColor: "#FB7800",
        cursor: "pointer",

        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600"
    },
    activeLabel: {
        opacity: 0.6,
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white"
    },
    activeValue: {
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
        marginLeft: 6
    },

}));

export default Filter
