import React from "react";
import {
	Box,
	Grid,
	Button,
	Collapse,
	Typography,

	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Add as AddIcon,
	Clear as ClearIcon,
	Search as SearchIcon,
	FilterAlt as FilterAltIcon
} from "@mui/icons-material";
import {
	FilterAmountRange,
	FilterDateRangeFull
} from "../../../../../../components";
import {
	styles,
	AutocompleteSelectApi,
	AutocompleteSelectStatic
} from "../DialogForm";
import {convertorNumber} from "../../../../../../helper/convertor";

const Filter = (props) => {
	const {
		filter,
		initOpenFilter,
		totalCount,

		onChange,
		onReset,
		onSearch,
		onCreate
	} = props;
	const [isOpen, setOpen] = React.useState(true);
	const classes = useStyles();

	React.useEffect(() => {
		setOpen(initOpenFilter || true);
	}, [initOpenFilter]);

	const handleChangeCommon = ({ target }) => {
		const { name, value } = target;
		console.log('{ name, value }: ', { name, value })
		const _filter = {...filter};
		_filter[name] = value;
		_filter.page = 1;
		onChange(_filter);
	}
	const handleChangeDateRange = (values) => {
		let newFilter = {...filter};

		newFilter["filter[date][gte]"] = values[0] || null;
		newFilter["filter[date][lte]"] = values[1] || null;

		onChange(newFilter);
	}
	const handleChangeAmountRange = (value) => {
		let newFilter = {...filter};

		newFilter["filter[amount][gte]"] = value[0];
		newFilter["filter[amount][lte]"] = value[1];

		onChange(newFilter);
	}

	return (
		<Box mb={1}>

			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Typography variant="h4">
						Фильтры
						<Typography variant="body2" component="span" sx={{marginLeft: 1}}>всего
							найдено: {convertorNumber(totalCount)}</Typography>
					</Typography>
				</Grid>
				<Grid item>
					<Grid container spacing={1}>
						<Grid item>
							<Button
								variant="contained"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								endIcon={<AddIcon/>}
								onClick={onCreate}
							>
								Добавить
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								onClick={() => setOpen(!isOpen)}
								endIcon={<FilterAltIcon/>}
							>
								{isOpen ? "Свернуть" : "Развернуть"}
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								onClick={onReset}
								endIcon={<ClearIcon/>}
							>
								Сбросить
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								endIcon={<SearchIcon/>}
								onClick={onSearch}
							>
								Поиск
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Box my={1}/>

			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<Box px={2} py={2} bgcolor="white" borderRadius={4}>
					<Grid container spacing={2}>
						<Grid item sm={3} className={classes.dateTimePicker}>
							<FilterDateRangeFull
								value={[filter['filter[date][gte]'], filter['filter[date][lte]']]}
								onChange={handleChangeDateRange}
							/>
						</Grid>
						<Grid item sm={3}>
							<FilterAmountRange
								value={[filter["filter[amount][gte]"], filter["filter[amount][lte]"]]}
								labelAmountEnd="Сумма (до)"
								labelAmountStart="Сумма (от)"
								onChange={handleChangeAmountRange}
							/>
						</Grid>
						<Grid item sm={2}>
							<AutocompleteSelectStatic
								value={filter['filter[currency]']}
								options={["USD", "RUB", "GBP", "EUR"]}
								name="filter[currency]"
								label="Валюты"
								onChange={handleChangeCommon}
							/>
						</Grid>
						<Grid item sm={2}>
							<AutocompleteSelectApi
								value={filter['filter[wallet_id]']}
								label="Кошелёк"
								name="filter[wallet_id]"
								disabledAutoSelect
								api="/api/finance/wallets?per-page=999&page=1"
								onChange={handleChangeCommon}
							/>
						</Grid>
						<Grid item sm={2}>
							<AutocompleteSelectApi
								value={filter['filter[project_id]']}
								label="Проект"
								name="filter[project_id]"
								disabledAutoSelect
								api="/api/finance/projects?per-page=999&page=1"
								onChange={handleChangeCommon}
							/>
						</Grid>
						<Grid item sm={2}>
							<AutocompleteSelectApi
								value={filter['filter[category_id]']}
								label="Категория"
								name="filter[category_id]"
								disabledAutoSelect
								api="/api/finance/projects?per-page=999&page=1"
								onChange={handleChangeCommon}
							/>
						</Grid>
						<Grid item sm={2}>
							<AutocompleteSelectApi
								value={filter['filter[purpose_id]']}
								label="Назначение"
								name="filter[purpose_id]"
								disabledAutoSelect
								api={`/api/finance/purposes?per-page=999&page=1&filter[category_id]=${filter['filter[category_id]']}`}
								onChange={handleChangeCommon}
							/>
						</Grid>
						<Grid item sm={2}>
							<FormControl fullWidth size="small">
								<InputLabel>Проверка</InputLabel>
								<Select
									size="small"
									value={filter['filter[is_approved]']}
									label="Проверка"
									name="filter[is_approved]"
									onChange={(e,v) => handleChangeCommon({target: {name: 'filter[is_approved]', value: v?.props?.value}})}
								>
									<MenuItem value=""></MenuItem>
									<MenuItem value="1">Да</MenuItem>
									<MenuItem value="0">Нет</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Box>
			</Collapse>

		</Box>
	);
}

const useStyles = makeStyles(() => ({

	active: {
		display: "flex",
		alignItems: "center",
		padding: "2px 12px",
		borderRadius: 6,
		backgroundColor: "#FB7800",
		cursor: "pointer",

		fontSize: 15,
		lineHeight: "22px",
		fontWeight: "600"
	},
	activeLabel: {
		opacity: 0.6,
		fontSize: 15,
		lineHeight: "22px",
		fontWeight: "600",
		color: "white"
	},
	activeValue: {
		fontSize: 15,
		lineHeight: "22px",
		fontWeight: "600",
		color: "white",
		marginLeft: 6
	},

	dateTimePicker: {
		"& .daterangepicker": {
			"& .applyButton": {
				backgroundColor: "rgba(251,120,0, .8) !important",
				border: "none !important",
				"&:hover": {
					backgroundColor: "#FB7800 !important",
				}
			},
			"& .fromDateHourContainer": {
				border: "none !important",
			},
			"& .inputDate": {
				borderWidth: "2px !important",
				height: "33px !important",
				"&:focus": {
					borderColor: "#FB7800 !important",
					boxShadow: "none !important",
				}
			},
			"& .calendarAddon.input-group-addon": {
				borderWidth: "2px !important",
			},
			"& .multipleContentOnLine": {
				outline: "none !important",
				"& select": {
					height: "33px !important",
					borderRadius: "4px",
					borderWidth: "2px",
					outline: "none",
					borderColor: "#ccc",
					"&:focus": {
						borderColor: "#FB7800 !important",
						outline: "none !important",
					}
				}
			},
			"& .monthYearContainer": {
				alignItems: "center",
			},
			"& .timeIconStyle": {
				top: "50%",
				transform: "translate(0, -50%)",
			},
			"& .glyphicon": {
				color: "#FB7800 !important"
			},
			"& .rangecontainer": {
				"& div": {
					outline: "none !important",
				}
			},
			"& .calendarGrid": {
				"& div": {
					outline: "none !important",
				}
			}
		}
	}

}));

export default Filter
