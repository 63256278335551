import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Grid,
  Skeleton,
  Pagination,
} from "@mui/material";
import {
  TableHeadSortCell
} from "../../../../../../components";
import {convertorNumber} from "../../../../../../helper/convertor";

const TableCustom = (props) => {
  const {
    data,
    filter,
    isLoad,
    totalPage,
    userRole,
    onChange
  } = props;

  const handleChangePage = (event, newPage) => {
    let newFilter = {...filter}
    newFilter.page = newPage;
    onChange(newFilter, true);
  }
  const handleChangeSort = (value) => {
    let newFilter = {...filter};
    newFilter.sort = value;
    newFilter.page = 1;
    onChange(newFilter, true);
  }

  return (
    <>
      <Grid container justifyContent="flex-end" mb={1}>
        <Grid item>
          <Pagination count={totalPage} page={filter.page} onChange={handleChangePage}/>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Провайдер</TableCell>
            <TableCell>Сервис</TableCell>
            <TableCell>Страна</TableCell>
            <TableHeadSortCell label="Номеров отдано" align="center" filterSort={filter.sort} name="numbers_given" onChange={handleChangeSort}/>
            <TableHeadSortCell label="Смс отдано" align="center" filterSort={filter.sort} name="numbers_received" onChange={handleChangeSort}/>
            <TableHeadSortCell label="Смс доставлено" align="center" filterSort={filter.sort} name="numbers_percentage" onChange={handleChangeSort}/>

            {Boolean(userRole === 'Administrator') && (
              <>
                <TableHeadSortCell label="Выручка" align="center" filterSort={filter.sort} name="income" onChange={handleChangeSort}/>
                <TableHeadSortCell label="Прибыль" align="center" filterSort={filter.sort} name="profit" onChange={handleChangeSort}/>
                <TableHeadSortCell label="Маржа" align="center" filterSort={filter.sort} name="margin" onChange={handleChangeSort}/>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(isLoad) && <RowsLoading userRole={userRole}/>}
          {data.map((item, index) => <RowData key={`RowData-${index}`} item={item} userRole={userRole}/>)}
        </TableBody>
      </Table>
      <Grid container justifyContent="flex-end" mt={1}>
        <Grid item>
          <Pagination count={totalPage} page={filter.page} onChange={handleChangePage}/>
        </Grid>
      </Grid>
    </>
  )
}
const RowData = React.memo((props) => {
  const {
    item,
    userRole
  } = props;
  console.log('userRole: ', userRole);

  return (
    <TableRow>
      <TableCell>
        {item.provider_name}
      </TableCell>
      <TableCell>
        {item.service_name}
      </TableCell>
      <TableCell>
        {item.country_name}
      </TableCell>

      <TableCell align="center">
        {item.numbers_given}
      </TableCell>
      <TableCell align="center">
        {item.numbers_received}
      </TableCell>
      <TableCell align="center">
        {Boolean(item.numbers_percentage) ? `${item.numbers_percentage}%` : '-'}
      </TableCell>

      {Boolean(userRole === 'Administrator') && (
        <>
          <TableCell align="center">
            {convertorNumber(item.income || 0)}
          </TableCell>
          <TableCell align="center">
            {convertorNumber(item.profit || 0)}
          </TableCell>
          <TableCell align="center">
            {Boolean(item.margin) ? `${item.margin}%` : '-'}
          </TableCell>
        </>
      )}
    </TableRow>
  )
});
const RowsLoading = React.memo((props) => {
  const {
    userRole
  } = props;

  return (
    <>
      {Array.apply(null, Array(10)).map((t, key) => (
        <TableRow key={`table-row-${key}`}>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          {Boolean(userRole === 'Administrator') && (
            <>
              <TableCell><Skeleton/></TableCell>
              <TableCell><Skeleton/></TableCell>
              <TableCell><Skeleton/></TableCell>
            </>
          )}
        </TableRow>
      ))}
    </>
  )
})


export default React.memo(TableCustom)
