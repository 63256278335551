import React from "react";
import {
    Autocomplete,
    TextField
} from "@mui/material";
import agent from "../../../agent/agent";

class FilterAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],

            "email": "",

            isLoad: false
        };


        this.timeOutSearch = null;
    }

    componentDidMount = async () => {

        if (Boolean(this.props.value)) {
            await this.getOptions();

            if (this.props.onChangeActiveFilterCountry) {
                this.props.onChangeActiveFilterCountry((this.state.list || []).find((t) => t.id === this.props.value)?.name);
            }
        }

    }

    getOptions = async (isSearch = false) => {
        if ((this.state?.list || []).length > 0 && !isSearch) {
            return null
        }

        this.setState({ isLoad: true });

        const search = Boolean(this.state.email) ? `?filter[email]=${this.state.email}` : "";
        const list = await agent.get(`/admin/api-admin-user/list?ngrestCallType=list&fields=id%2Cid%2Ctitle%2Cfirstname%2Clastname%2Cemail`).then((res) => {
            return res.data
        });

        this.setState({
            list,
            isLoad: false
        });
    }

    onChange = (value) => {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: String(value?.id) || null
            },
        });
    }

    _getOptionLabel = (row) => {
        const options = this.state.list;

        if (typeof row !== 'object') {
            const item = options.find((t) => String(t.id) === String(row));

            if (!item) {
                return ""
            }

            return `${item?.email} (ID: ${item?.id})`
        }

        return `${row?.email} (ID: ${row?.id})`
    }
    _filterOptions = (list, params) => {
        if (this.state.email !== params?.inputValue) {
            clearTimeout(this.timeOutSearch);

            this.setState({
                isLoad: true,
                email: params?.inputValue || ""
            }, () => {
                this.timeOutSearch = setTimeout(async () => {
                    await this.getOptions(true);
                }, 1000);
            });
        }

        return [...list]
    }

    render() {
        const {
            value,
            name
        } = this.props;
        const {
            list,
            isLoad
        } = this.state;

        return (
            <>

                <Autocomplete
                    {...this.props}
                    value={value}
                    disablePortal
                    options={list}
                    onChange={(event, value) => this.onChange(value, name)}
                    name={name}
                    variant="outlined"
                    fullWidth
                    disableClearable
                    autoComplete={false}
                    loading={isLoad}
                    onOpen={this.getOptions}
                    limitTags={1}

                    getOptionLabel={this._getOptionLabel}
                    filterOptions={this._filterOptions}

                    renderInput={(params) => <TextField
                        {...params}
                        label="Пользователи"
                        fullWidth
                    />}
                />

            </>
        );
    }

}

export default FilterAdmin
