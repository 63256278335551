import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import moment from "moment";

class DialogReadSms extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			item: null,
			list: [],
			isOpen: false
		};
	}

	open = ({ item }) => {
		this.setState({
			item,
			list: item?.sms || [],
			isOpen: true
		})
	}
	close = () => {
		this.setState({
			item: null,
			list: [],
			isOpen: false
		})
	}

	render () {
		const {
			list,
			isOpen
		} = this.state;
		console.log('this.state: ', this.state);

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle  sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
					<Typography variant="h3" sx={{color: "white"}}>SMS</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2} mt={1}>
						{list.map((item) => (
							<Grid item xs={12}>
								<SmsCard item={item}/>
							</Grid>
						))}
					</Grid>

					{Boolean((list || []).length <= 0) && (
						<Typography variant="subtitle1" textAlign="center" mt={1}>
							Нет SMS
						</Typography>
					)}
				</DialogContent>
			</Dialog>
		)
	}
}
const SmsCard = React.memo((props) => {
	const {
		item
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.smsCard}>
			<Box className={classes.smsCardRow}>
				<img src={`https://admsms.grizzlysms.com/api/storage/image/${ item.service_icon }.webp`}/>
				<span>{item?.service_name}</span>
			</Box>

			<Typography variant="h5" mb={1}>Полученное СМС</Typography>
			<Typography variant="h4" mb={2}>
				{item.code}
			</Typography>

			<Box mt={2}/>

			<Typography variant="h5" mb={1}>Дата получения СМС</Typography>
			<Typography variant="h4" mb={2}>
				{moment(item.created_at).format("DD.MM.YYYY HH:mm:ss")}
			</Typography>
		</Box>
	)
});

const useStyles = makeStyles(() => ({
	smsCard: {
		padding: 10,
		boxSizing: 'border-box',
		borderRadius: '10px',
		background: '#FFF',
		boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.05)',
	},
	smsCardRow: {
		display: "flex",
		alignItems: "center",
		marginBottom: 10,

		"& img": {
			width: 32,
			height: 32,
			borderRadius: 10,
			objectFit: "cover",
			marginRight: 8
		},
		"& span": {
			fontSize: 16,
			lineHeight: "110%",
			fontWeight: "600"
		}
	}
}));

export default DialogReadSms
