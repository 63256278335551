import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,


    Typography,
    TextField,
    Button,
    Grid,
    Box,


    FormControl,
    InputLabel,
    MenuItem,
    Select, FormHelperText
} from "@mui/material";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import {Formik} from "formik";
import languages from "../../../../../../constants/languages";

const pageInitForm = {
    name: "",
    email: "",
    password: "",
    lang_id: "",
};

class DialogCreateUser extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initForm: {},

            open: false,
            isCreated: true,


            eventSubmit: null
        }

        this.refFormik = React.createRef();
    }


    onSubmit = (form) => {
        this.state.eventSubmit(form);
    }
    changeForm = ({ target }) => {
        const { name, value } = target;

        let newForm = {...this.refFormik.current.values};
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    }

    open = async ({ initForm, eventSubmit } = {}) => {
        await this.setState({
            open: true,
            isCreated: Boolean(Object.keys((initForm || {})).length <= 0),
            eventSubmit
        });

        this.refFormik.current.setValues(initForm || pageInitForm);
    }
    close = () => {
        this.setState({
            open: false
        })
    }

    onSuccess = () => {
        this.refFormik.current.handleSubmit()
    }
    onCancel = () => {
        this.close()
    }

    render() {
        const {
            open,
            isCreated
        } = this.state;
        const {
            countries
        } = this.props;

        return (
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}


                onClose={this.onCancel}
            >

                <DialogTitle sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
                    <Typography variant="h3" sx={{color: "white"}}>
                        { isCreated ? "Создание пользователя": "Редактирование пользователя" }
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box px={1} py={3}>
                        <Formik
                            innerRef={this.refFormik}
                            initialValues={this.state.initForm}
                            validationSchema={validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched,
                                    handleSubmit,
                                } = props;

                                return (
                                    <Box>
                                        <form
                                            onSubmit={handleSubmit}
                                            onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
                                        >

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.name}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                    name="name"
                                                    label="Имя пользователя"
                                                    placeholder="Введите имя пользователя"
                                                    variant="outlined"
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.email}
                                                    error={Boolean(touched.email && errors.email)}
                                                    helperText={touched.email && errors.email}
                                                    name="email"
                                                    label="Email пользователя"
                                                    placeholder="Введите Email адрес пользователя"
                                                    variant="outlined"
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.password}
                                                    error={Boolean(touched.password && errors.password)}
                                                    helperText={touched.password && errors.password}
                                                    name="password"
                                                    label="Пароль пользователя"
                                                    placeholder="Введите пароль пользователя"
                                                    variant="outlined"
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <FormControl fullWidth error={Boolean(touched.lang_id && errors.lang_id)}>
                                                    <InputLabel>Язык</InputLabel>
                                                    <Select
                                                        name="lang_id"
                                                        value={values.lang_id}
                                                        label="Язык"
                                                        onChange={this.changeForm}

                                                        error={Boolean(touched.lang_id && errors.lang_id)}
                                                        helperText={touched.lang_id && errors.lang_id}
                                                    >
                                                        <MenuItem value={null}>Выберите</MenuItem>
                                                        {languages.map((country, index) => (
                                                            <MenuItem value={country.id} key={`country-${index}`}>
                                                                { country.label }
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {Boolean(touched.lang_id && errors.lang_id) && (
                                                        <FormHelperText>{ touched.lang_id && errors.lang_id }</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Box>

                                        </form>
                                    </Box>
                                )
                            }}
                        </Formik>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <Button sx={{ borderRadius: "4px", textTransform: "initial" }} variant="outlined" onClick={this.onCancel}>
                                Отмена
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button sx={{ borderRadius: "4px", textTransform: "initial" }} variant="contained" onClick={this.onSuccess}>
                                { isCreated ? "Создать" : "Сохранить" }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        )
    }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Заполните имя пользователья').min(2, "Минимум 2 символа"),
    email: Yup.string().required('Заполните Email пользователья').email("Невальный Email адрес"),
    password: Yup.string().required('Заполните пароль пользователья').min(8, "Минимальная длина пароля: 8"),
    lang_id: Yup.string().required('Выберите страну').nullable(),
});

export default DialogCreateUser
