import React from "react";
import {
	Box, Button,
	Grid, Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Clear as ClearIcon,
	Search as SearchIcon
} from "@mui/icons-material";
import {
	FilterUsers,
	FilterProvider,
} from "../../../../../../components";

const Filter = (props) => {
	const {
		filter,

		onReset,
		onChange,
		onSearch
	} = props;
	const classes = useStyles();

	const handleChange = ({ target }) => {
		const { name, value } = target;
		let _filter = {...filter};
		_filter[name] = value;
		_filter.page = 1;
		onChange(_filter);
	}

	return (
		<>
			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Typography variant="h4">Фильтры</Typography>
				</Grid>
				<Grid item>
					<Grid container spacing={1}>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								endIcon={<ClearIcon/>}
								onClick={onReset}
							>Сбросить</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								endIcon={<SearchIcon/>}
								onClick={onSearch}
							>Поиск</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Box className={classes.root} mt={1}>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<FilterUsers
							value={filter['filter[user_id]']}
							name="filter[user_id]"
							size="small"
							valueName="id"
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={4}>
						<FilterProvider
							value={filter['filter[provider_id]']}
							name="filter[provider_id]"
							size="small"
							valueName="id"
							onChange={handleChange}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		padding: "20px",
		borderRadius: "10px",
		boxSizing: "border-box",
		background: "white"
	}
}));

export default Filter
