import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Grid,
  Skeleton,
  Pagination
} from "@mui/material";
import moment from "moment";

const TableCustom = (props) => {
  const {
    data,
    total,
    filter,
    isLoad,
    onChangePage
  } = props;

  const handleChangePage = (event, page) => {
    let newFilter = {...filter};
    newFilter.page = page;
    onChangePage(newFilter, true)
  }

  return (
    <>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Пользователь</TableCell>
            <TableCell>Ранг</TableCell>
            <TableCell>Сумма</TableCell>
            <TableCell align="right">Дата обновления</TableCell>
            <TableCell align="right">Дата создания</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(!isLoad) && data.map((t, i) => <RowCustom
            key={`RowCustom-${ i }`}
            item={t}
          />)}
          {Boolean(isLoad) && (
            <RowLoading/>
          )}
        </TableBody>
      </Table>

      <Grid container justifyContent="flex-end" mt={1}>
        <Grid item>
          <Pagination
            count={total}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>

    </>
  )
}
const RowCustom = React.memo((props) => {
  const {
    item
  } = props;

  return (
    <TableRow>
      <TableCell>{ item.id }</TableCell>
      <TableCell>{ item?.user?.cms_user?.email || "-" }</TableCell>
      <TableCell>{ item?.rank?.name }</TableCell>
      <TableCell>{ item.amount }</TableCell>
      <TableCell align="right">
        {moment(item.updated_at).format("DD.MM.YYYY HH:mm:ss")}
      </TableCell>
      <TableCell align="right">
        {moment(item.created_at).format("DD.MM.YYYY HH:mm:ss")}
      </TableCell>
    </TableRow>
  )
})
const RowLoading = React.memo(() => {
  return (
    <>
      {new Array(10).fill(1).map((t) => (
        <TableRow>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
        </TableRow>
      ))}
    </>
  )
})

export default React.memo(TableCustom)
