// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Statistics from './Statistics';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(Statistics);
