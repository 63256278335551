import {
	Notification,
	NotificationTypes
} from "../common/Notification";

const downloadFile = async (linkFile) => {
	const file = await fetch(linkFile, {
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('token')}`
		}
	})
		.then((res) => {
			return res.blob()
		})
		.catch((err) => {
			return {
				error: err
			}
		});

	if (file.error) {
		Notification({
			message: file.error?.message || "Ошибка скачивания файла.",
			type: NotificationTypes.error
		})
		return
	}

	const fileBlob = file;
	const fileName = (linkFile || file?.url || "").split("/").pop();

	var link = document.createElement('a');
	link.href = window.URL.createObjectURL(fileBlob);
	link.download = fileName;

	document.body.appendChild(link);

	link.click();
}


export {
	downloadFile
}
