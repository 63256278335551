import React from "react";
import {
	TextField,
	Autocomplete
} from "@mui/material";
import agent from "../../../agent/agent";
import PropTypes from "prop-types";

class FilterProvider extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			users: [],
			selectedUser: [],

			search: "",

			isLoad: false
		};


		this.timeOutSearch = null;
	}

	componentDidMount = async () => {
		if (this.props.multiple && (this.props.value || []).length > 0) {
			await this.inititSelectedValues();
		}
		if (!this.props.multiple && !!this.props.value) {
			await this.inititSelectedValues();
		}
	}
	componentDidUpdate = async (prevProps) => {
		if (this.props.multiple && JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
			await this.inititSelectedValues();
		}
		if (!this.props.multiple && prevProps.value !== this.props.value) {
			await this.inititSelectedValues();
		}
	}

	inititSelectedValues = async () => {
		let value = this.props.value;
		if (Boolean(this.props.multiple)) {
			value = ([...(this.props.value || [])] || []).join(',')
		}

		let data = await agent.post(`/smsgoapi/go-provider/list?filter[${ this.props.valueName }]=${ value }&per-page=100`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})
		if (this.props.isGroupAdapter && data.find((t) => t.adapter === "protocol")) {
			data.push({
				name: "Протокольщики",
				[this.props.valueName]: "protocol"
			});
			data = data.filter((t) => t.adapter !== 'protocol');
		}

		this.setState({
			selectedUser: data
		});
	}

	getUser = async () => {
		if (this.state.users.length > 0) {
			return
		}

		let users = await agent.post(`/smsgoapi/go-provider/list`).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		});
		if (this.props.isGroupAdapter && users.find((t) => t.adapter === "protocol")) {
			users.push({
				name: "Протокольщики",
				[this.props.valueName]: "protocol"
			});
			users = users.filter((t) => t.adapter !== 'protocol');
		}

		this.setState({
			users,
			isLoad: false
		})
	}

	onChange = (value) => {
		if (this.props.multiple) {
			const parseValue = [...value].map((item) => {
				return item?.[this.props.valueName] || item
			})

			this.props.onChange({
				target: {
					name: this.props.name,
					value: parseValue || []
				}
			});
		}
		if (!this.props.multiple) {
			this.props.onChange({
				target: {
					name: this.props.name,
					value: String(value?.[this.props.valueName]) || null
				},
			});
		}
	}

	_getOptionLabel = (row) => {
		const options = this.state.users;
		const selectedUser = this.state.selectedUser;

		if (typeof row !== 'object') {
			let item = options.find((t) => String(t?.[this.props.valueName]) === String(row));
			if (!item) {
				item = (selectedUser || []).find((t) => String(t?.[this.props.valueName]) === String(row));
			}
			if (!item) {
				return ""
			}

			return `${item?.name} (ID: ${item?.[this.props.valueName]})`
		}

		return `${row?.name} (ID: ${row?.[this.props.valueName]})`
	}
	_filterOptions = (list, params) => {
		if (this.props.customFilterOptions) {
			return this.props.customFilterOptions(list, params)
		}

		const inputValue = params?.inputValue || "";
		return [...list].filter((t) => {

			if (!inputValue) {
				return true
			}

			const nameLowerCase = t.name.toLowerCase();
			const inputValueLowerCase = inputValue.toLowerCase();

			return Boolean(nameLowerCase.indexOf(inputValueLowerCase) > -1)

		})
	}

	render() {
		const {
			value,
			name,
			multiple,
      error,
      helperText
		} = this.props;
		const {
			users,
			isLoad
		} = this.state;

		return (
			<>

				<Autocomplete
					{...this.props}
					value={value}
					options={users}
					onChange={(event, value) => this.onChange(value, name)}
					name={name}
					variant="outlined"
					fullWidth
					disableClearable
					loading={isLoad}
					limitTags={1}
					filterSelectedOptions={Boolean(multiple)}
					autoComplete={Boolean(multiple)}

					isOptionEqualToValue={(option, value) => Boolean(String(option?.[this.props.valueName]) === String(value))}

					getOptionLabel={this._getOptionLabel}
					filterOptions={this._filterOptions}
					onOpen={this.getUser}

					renderInput={(params) => <TextField
						{...params}
						label="Провайдеры"
						fullWidth
            error={error}
            helperText={helperText}
					/>}
				/>

			</>
		);
	}

}

FilterProvider.defaultProps = {
	valueName: "id",
	isGroupAdapter: false
}
FilterProvider.propTypes = {
	valueName: PropTypes.string,
	isGroupAdapter: PropTypes.bool
}

export default FilterProvider
