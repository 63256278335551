import React, { Component } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import {
    LoadingButton
} from "@mui/lab";
import {
    withStyles
} from "@mui/styles";
import {
    Formik
} from "formik";
import * as Yup from "yup";
import {
    Notification,
    NotificationTypes
} from "../../../common/Notification";
import {
    DialogTwoFaToken
} from "../../../components";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";
import errorAuth from "../../../errors/messages/errorAuth";

class Authorization extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                email: "",
                password: "",
            },

            verificationId: null,
            stage: 1,

            isLoading: false
        };

        this.refFormik = React.createRef();
        this.refDialogTwoFaToken = React.createRef();
    }

    componentDidMount = () => {}

    onSubmit = async () => {
        if (this.state.isLoading) {
            return null
        }

        await this.onAuth('');
    }
    onAuth = async (twoFaToken) => {
        this.setState({ isLoading: true });

        const form = {...this.refFormik.current.values};

        let body = {
            ...form,
            app: process.env.REACT_APP_APP_TOKEN
        };
        if (twoFaToken) {
            body.verify_code = twoFaToken;
        }

        const { data, sessionToken } = await agent.post(`${ urls["api-usertoken-login"] }`, {
            ...body
        }).then((res) => {
            return {
                data: res.data,
                sessionToken: res.headers['x-session-token']
            }
        }).catch((err) => {
            return {
                data: { error: err.response?.data || [] },
                sessionToken: ""
            }
        })
        if (data?.enterTwoFaToken) {
            this.setState({ isLoading: false });
            this.refDialogTwoFaToken.current.open({
                onSubmit: this.onAuth.bind(this)
            })

            return
        }
        if (data.error || (data?.errors || []).length > 0) {
            this.setState({ isLoading: false });
            const message = (data.error || data?.errors || []).map((t) => t.message).join('<br/>');
            Notification({
                type: NotificationTypes.error,
                message: message || "Ошибка сервера"
            })
            return null
        }

        localStorage.setItem('token', data.token);
        agent.defaults.headers['Authorization'] = `Bearer ${data.token}`;

        const user = await agent.get(`${ urls.userSession }?expand=group`).then((res) => {
            return res.data?.user || {}
        }).catch(() => {
            return {}
        })

        this.props.setUser(user);

        this.setState({ isLoading: false });
    }

    changeForm = ({target}) => {
        const {name, value} = target;
        let newForm = {...this.refFormik.current.values};
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    }

    render() {
        const {
            form,

            isLoading
        } = this.state;
        const {
            classes
        } = this.props;

        return (
            <Box display="flex" flexDirection="column" alignItems="center">

                <Box mb={8}>
                    <Typography variant="h1">Авторизация</Typography>
                </Box>

                <Box flex="1" display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">

                    <Formik
                        innerRef={this.refFormik}
                        initialValues={form}
                        validationSchema={validationSchema}
                        onSubmit={this.onSubmit}
                    >
                        {(props) => {
                            const {
                                values,
                                errors,
                                touched,
                                handleSubmit,
                            } = props;

                            return (
                                <Box width={640} px={8} py={4} className={classes.content}>
                                    <form
                                        onSubmit={handleSubmit}
                                        onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
                                    >

                                        <Box mb={4}>
                                            <TextField
                                                value={values.email}
                                                error={Boolean(touched.email && errors.email)}
                                                helperText={touched.email && errors.email}
                                                name="email"
                                                label="Email"
                                                placeholder="mail@mail.ru"
                                                variant="outlined"
                                                fullWidth

                                                onChange={this.changeForm}
                                            />
                                        </Box>

                                        <Box mb={4}>
                                            <TextField
                                                value={values.password}
                                                error={Boolean(touched.password && errors.password)}
                                                helperText={touched.password && errors.password}
                                                name="password"
                                                label="Пароль"
                                                type="password"
                                                placeholder="****"
                                                variant="outlined"
                                                fullWidth

                                                onChange={this.changeForm}
                                            />
                                        </Box>

                                        <Box>
                                            <LoadingButton
                                                variant="contained"
                                                size="large"
                                                onClick={handleSubmit}
                                                loading={isLoading}
                                                fullWidth
                                            >
                                                Авторизоваться
                                            </LoadingButton>
                                        </Box>

                                    </form>
                                </Box>
                            )
                        }}
                    </Formik>
                </Box>

                <DialogTwoFaToken ref={this.refDialogTwoFaToken}/>
            </Box>
        );
    }
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Некоретно введен Email').required('Заполните поле'),
    password: Yup.string().required('Заполните поле'),
});
const styles = {
    content: {
        background: "rgba(0, 0, 0, 0.03)",
        backdropFilter: "blur(50px)",
        borderRadius: 10
    },

    button: {

    }
};

export default withStyles(styles)(Authorization)
