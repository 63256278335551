import React from "react";
import {
  Box,
  Grid,
  Select,
  Button,
  MenuItem,
  Collapse,
  Checkbox,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  FormControlLabel
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Add as AddIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
  FilterAlt as FilterAltIcon
} from "@mui/icons-material";
import {
  FilterUserGroups
} from "../../../../../../components";
import {IMaskInput} from "react-imask";

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCollapse: true
    }
  }

  changeCollapse = () => {
    this.setState({ isCollapse: !this.state.isCollapse })
  }
  changeCommon = ({target}) => {
    const {name, value} = target;

    let newFilter = {...this.props.filter};
    newFilter[name] = value;

    this.props.onChange(newFilter);
  }

  render () {
    const {
      isCollapse
    } = this.state;
    const {
      filter,
      loyalty,

      onReset,
      onCreate,
      onSearch,
    } = this.props;


    return (
      <Box mt={1}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">Фильтры</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                  onClick={this.changeCollapse}
                  endIcon={<FilterAltIcon/>}
                >
                  {isCollapse ? "Свернуть" : "Развернуть"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                  onClick={onReset}
                  endIcon={<ClearIcon/>}
                >
                  Сбросить
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                  onClick={onCreate}
                  endIcon={<AddIcon/>}
                >
                  Создать
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                  endIcon={<SearchIcon/>}
                  onClick={onSearch}
                >
                  Поиск
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box mt={1}/>

        <Collapse in={isCollapse} timeout="auto" unmountOnExit>
          <Box px={2} py={2} bgcolor="white" borderRadius={4}>
            <Grid container spacing={2}>
              <Grid item sm={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Программа лояльности</InputLabel>
                  <Select
                    value={filter['filter[loyalty_id]']}
                    name="filter[loyalty_id]"
                    label="Программа лояльности"
                    onChange={this.changeCommon}
                  >
                    <MenuItem value="">Сбросить</MenuItem>
                    {loyalty.map((t) => (
                      <MenuItem value={t.id}>{ t.name }</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={2}>
                <TextField
                  value={filter['filter[cashback_percent][gt]']}
                  name="filter[cashback_percent][gt]"
                  label="Процент кэшбэка (от)"
                  size="small"
                  fullWidth
                  inputProps={{ scale: 2 }}
                  InputProps={{ inputComponent: CustomInputAmount }}
                  onChange={this.changeCommon}
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  value={filter['filter[cashback_percent][lt]']}
                  name="filter[cashback_percent][lt]"
                  label="Процент кэшбэка (до)"
                  size="small"
                  fullWidth
                  inputProps={{ scale: 2 }}
                  InputProps={{ inputComponent: CustomInputAmount }}
                  onChange={this.changeCommon}
                />
              </Grid>
              <Grid item sm={2}>
                <FilterUserGroups
                  name="filter[user_group_id]"
                  value={filter["filter[user_group_id]"]}
                  size="small"
                  fullWidth
                  onChange={this.changeCommon}
                />
              </Grid>
              <Grid item sm={2}>
                <FormControlLabel
                  control={<Checkbox />}
                  value={filter['filter[is_ban]']}
                  checked={Boolean(filter['filter[is_ban]'])}
                  name="filter[is_ban]"
                  label="Программа лояльности отключена для группы"
                  onChange={this.changeCommon.bind(this, {target: {name: "filter[is_ban]", value: !Boolean(filter['filter[is_ban]'])}})}
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Box>
    )
  }
}
const CustomInputAmount = ({inputRef, ...rest}) => {

  const onComplete = (value) => {
    rest.onChange({
      target: {
        name: rest.name,
        value
      }
    })
  }

  return (
    <IMaskInput
      ref={inputRef}
      mask={Number}
      thousandsSeparator=""
      radix="."
      mapToRadix={[',']}
      scale={rest?.scale || 4}
      padFractionalZeros={true}

      {...rest}

      onComplete={onComplete}
      onChange={null}
    />
  )
}

export default Filter
