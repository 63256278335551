import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,


    Typography,
    Button,
    Grid,
    Box, TextField, InputLabel, Select, MenuItem, FormControl, FormHelperText
} from "@mui/material";
import {
    AutocompleteCountry,
    AutocompleteService,
    AutocompleteUsers,
    FilterProvider,

    FilterUsers
} from "../../../../../../components";
import * as Yup from "yup";
import {Formik} from "formik";

const pageInitForm = {
    countries: [],
    services: [],
    users: [],
    for_wholesale: null,
    sort: "1",
    price: "",
    name: "",
    provider_id: null,
};

class DialogCreateGroup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initForm: {},

            open: false,
            isCreated: true,


            eventSubmit: null
        }

        this.refFormik = React.createRef();
    }


    onSubmit = (form) => {
        if (form.provider_id) {
            form.provider_id = Number.parseFloat(form.provider_id);
        }

        form.sort = Number.parseFloat(form.sort);
        if (form.sort <= 0) {
            form.sort = 1;
        }

        this.state.eventSubmit(form, true);
    }

    changeForm = ({target}) => {
        const {name, value} = target;

        let newForm = {...this.refFormik.current.values};
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    }

    open = async ({initForm, eventSubmit} = {}) => {
        await this.setState({
            open: true,
            isCreated: Boolean(Object.keys((initForm || {})).length <= 0),
            eventSubmit
        });

        this.refFormik.current.setValues(initForm || pageInitForm);
    }
    close = () => {
        this.setState({
            open: false
        })
    }

    onSuccess = () => {
        this.refFormik.current.handleSubmit()
    }
    onCancel = () => {
        this.close()
    }

    render() {
        const {
            open,
            isCreated
        } = this.state;
        const {
            countries,
            services
        } = this.props;

        if (!open) {
            return null
        }

        return (
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                disablePortal={true}
                PaperProps={{
                    sx: {overflow: "initial"}
                }}


                onClose={this.onCancel}
            >

                <DialogTitle sx={{backgroundColor: "rgba(251, 120, 0, 1)"}}>
                    <Typography variant="h3" sx={{color: "white"}}>
                        {isCreated ? "Создание цены" : "Редактирование цены"}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box px={1} py={3}>
                        <Formik
                            innerRef={this.refFormik}
                            initialValues={this.state.initForm}
                            validationSchema={validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched,
                                    handleSubmit,
                                } = props;

                                if (Object.keys(values || {}).length <= 0) {
                                    return null
                                }

                                return (
                                    <Box>
                                        <form
                                            onSubmit={handleSubmit}
                                            onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
                                        >
                                            <Box mb={4}>
                                                <FormControl
                                                    error={Boolean(touched.for_wholesale && errors.for_wholesale)}
                                                    fullWidth>
                                                    <InputLabel>Опт</InputLabel>
                                                    <Select
                                                        value={values.for_wholesale}
                                                        label="Опт"
                                                        name="for_wholesale"
                                                        fullWidth
                                                        onChange={this.changeForm}
                                                        error={Boolean(touched.for_wholesale && errors.for_wholesale)}
                                                        helperText={touched.for_wholesale && errors.for_wholesale}
                                                    >
                                                        <MenuItem value={false}>Нет</MenuItem>
                                                        <MenuItem value={true}>Да</MenuItem>
                                                    </Select>
                                                    {Boolean(touched.for_wholesale && errors.for_wholesale) && (
                                                        <FormHelperText error={true}>
                                                            {touched.for_wholesale && errors.for_wholesale}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Box>

                                            <Box mb={4}>
                                                <FilterProvider
                                                  value={values.provider_id}
                                                  name="provider_id"
                                                  label="Поставщик ID"
                                                  placeholder="Введите ID поставщика"
                                                  onChange={this.changeForm}
                                                  error={Boolean(touched.provider_id && errors.provider_id)}
                                                  helperText={touched.provider_id && errors.provider_id}
                                                />
                                                {Boolean(touched.provider_id && errors.provider_id) && (
                                                  <FormHelperText error>
                                                      { touched.provider_id && errors.provider_id }
                                                  </FormHelperText>
                                                )}
                                            </Box>

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.name}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                    name="name"
                                                    label="Наименование"
                                                    placeholder="Введите наименование"
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.price}
                                                    error={Boolean(touched.price && errors.price)}
                                                    helperText={touched.price && errors.price}
                                                    name="price"
                                                    type="number"
                                                    label="Стоимость"
                                                    placeholder="Введите стоимость"
                                                    variant="outlined"
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.sort}
                                                    error={Boolean(touched.sort && errors.sort)}
                                                    helperText={touched.sort && errors.sort}
                                                    name="sort"
                                                    type="number"
                                                    label="Сортировака"
                                                    placeholder="0"
                                                    variant="outlined"
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <AutocompleteService
                                                    value={values.services}

                                                    name="services"
                                                    label="Услуги"

                                                    multiple={true}

                                                    error={Boolean(touched.services && errors.services)}
                                                    helperText={touched.services && errors.services}

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <AutocompleteCountry
                                                    value={values.countries}

                                                    name="countries"
                                                    label="Страны"

                                                    multiple={true}

                                                    error={Boolean(touched.countries && errors.countries)}
                                                    helperText={touched.countries && errors.countries}

                                                    onChange={this.changeForm}
                                                />

                                            </Box>

                                            <Box mb={4}>
                                                <FilterUsers
                                                    value={values.users}

                                                    name="users"
                                                    label="Пользователи"
                                                    valueName="id"

                                                    multiple={true}

                                                    error={Boolean(touched.users && errors.users)}
                                                    helperText={touched.users && errors.users}

                                                    onChange={this.changeForm}
                                                />

                                            </Box>

                                        </form>
                                    </Box>
                                )
                            }}
                        </Formik>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="outlined"
                                    onClick={this.onCancel}>
                                Отмена
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="contained"
                                    onClick={this.onSuccess}>
                                {isCreated ? "Создать" : "Сохранить"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        )
    }
}

const validationSchema = Yup.object().shape({
    for_wholesale: Yup.string().nullable().required('Выберите типи цены'),
    name: Yup.string().required('Заполните наименование'),
    provider_id: Yup.string().nullable().required('Выберите поставщика'),

    price: Yup.string().required('Заполните стоимость услуги'),
    services: Yup.array().required().min(1, "Выберите минимум 1 услугу"),
    countries: Yup.array().required().min(1, "Выберите минимум 1 страну"),
});

export default DialogCreateGroup
