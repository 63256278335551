import React, {Component} from "react";
import {
	Box,
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	Table,
	Filter,
	DialogChangeStatus
} from "./components";
import {
	Notification,
	NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

const initFilter = {
	page: 1,
	"filter[amount]": "",
	"filter[user_id]": "",
	"filter[provider_id]": "",
	"filter[created_at]": "",
}

class MinutemenBalances extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],

			filter: {...initFilter},
			totalPages: 1,

			isLoading: true,
			isBackdrop: false,
		};
		this.apiController = null;
		this.refDialogChangeStatus = React.createRef();
	}

	componentDidMount = async () => {
		await this.getItems();
	}

	getItems = async () => {
		if (this.apiController) {
			this.apiController.abort();
		}
		this.setState({isLoading: true});
		const filter = this.getFilter();
		const apiController = new AbortController();
		this.apiController = apiController;
		const res = await agent.get(`/smsgoapi/protocol-payout?${ filter }`, {
			signal: apiController.signal
		}).then((res) => {
			return res
		}).catch(() => {
			return {}
		});
		this.setState({
			items: res?.data || [],
			totalPages: res?.headers?.['x-pagination-page-count'] || 1,
			isLoading: false
		})
	}

	getFilter = () => {
		const { filter } = this.state;

		let _str = [];
		Object.keys(filter).map((key) => {
			const val = filter[key];
			if (!!val) {
				_str.push(`${ key }=${ val }`);
			}
		});

		return _str.join('&')
	}
	resetFilter = async () => {
		await this.setState({ filter: {...initFilter} });
		await this.getItems();
	}
	changeFilter = async (filter, isFastStart) => {
		await this.setState({ filter });
		if (!isFastStart) {
			return
		}
		await this.getItems();
	}

	changeStatus = async (item, isSend) => {
		if (!isSend) {
			this.refDialogChangeStatus.current.open({
				item,
				onSubmit: this.changeStatus.bind(this)
			})
			return
		}
		this.setState({ isBackdrop: true });
		const res = await agent.put(`/smsgoapi/protocol-payout/${ item.id }`, {
			status: item.status
		}).then((res) => {
			return res.data
		}).catch((err) => {
			const data = err?.response?.data;
			let message = "";
			if (Array.isArray(data)) {
				message = data.map((t) => t?.message)
			}
			if (!message) {
				message = data?.message
			};
			return {error: message || "Ошибка сервера"}
		});
		if (res?.error) {
			this.setState({ isBackdrop: false });
			Notification({
				message: res?.error,
				type: NotificationTypes.error
			});
			return
		}

		this.refDialogChangeStatus.current.close();
		await this.getItems();
		this.setState({ isBackdrop: false });
		Notification({
			message: "Статус выплаты успешно изменен",
			type: NotificationTypes.success
		})
	}

	render() {
		const {
			items,
			filter,
			totalPages,
			isLoading,
			isBackdrop
		} = this.state;

		return (
			<>

				<Filter
					filter={filter}
					onSearch={this.getItems}
					onReset={this.resetFilter}
					onChange={this.changeFilter}
				/>

				<Box mt={1}/>

				<Table
					items={items}
					filter={filter}
					totalPages={totalPages}
					isLoading={isLoading}

					onChangeFilter={this.changeFilter}
					onChangeStatus={this.changeStatus}
				/>

				<DialogChangeStatus ref={this.refDialogChangeStatus}/>
				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		);
	}
}

export default MinutemenBalances
