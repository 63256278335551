import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  TextField,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  IMaskInput
} from "react-imask";
import {
  DatePicker
} from "@mui/lab";
import {
  Notification,
  NotificationTypes
} from "../../../../../../common/Notification";
import moment from "moment";

const initForm = {
  amount: "",
  date: null
}

class DialogChangeBalance extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {...initForm},
      providerId: null,

      isOpen: false
    }
  }

  open = ({ providerId, onSubmit }) => {
    this.setState({
      providerId,
      isOpen: true,
      onSubmit,
    })
  }
  close = () => {
    this.setState({
      isOpen: false,
      form: {...initForm}
    })
  }

  submit = () => {
    const { form } = this.state;

    if (!form.amount) {
      Notification({
        message: "Введите сумму",
        type: NotificationTypes.error
      })
      return
    }
    if (!form.date) {
      Notification({
        message: "Выберите дату",
        type: NotificationTypes.error
      })
      return
    }

    const date = moment(form.date);
    if (!date._isValid || date < moment().set({year: 1900, month: 1, day: 1}) || date > moment()) {
      Notification({
        message: "Дата должна быть от 1900 года до сегодняшнего дня",
        type: NotificationTypes.error
      })
      return;
    }

    this.state.onSubmit(this.state.providerId, form);
  }

  changeForm = ({ target }) => {
    const { name, value } = target;
    let form = {...this.state.form};
    form[name] = value;
    this.setState({ form });
  }
  changeFormDate = (name, value) => {
    let form = {...this.state.form};
    form[name] = value;
    this.setState({ form });
  }

  render () {
    const {
      form,
      isOpen
    } = this.state;
    const {
      classes
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        onClose={this.close}
      >
        <DialogTitle  sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
          <Typography variant="h3" sx={{color: "white"}}>Добавить изменение баланса</Typography>
        </DialogTitle>
        <DialogContent>
          <Box py={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={form.amount}
                  name="amount"
                  label="Сумма"
                  size="small"
                  fullWidth
                  inputProps={{scale: 2}}
                  InputProps={{inputComponent: CustomInputAmount}}
                  onChange={this.changeForm}
                />
              </Grid>
              <Grid item xs={12} className={classes.dateRange}>
                <DatePicker
                  label="Дата"
                  value={form.date}
                  renderInput={(t) => <TextField {...t} size="small" fullWidth />}
                  onChange={this.changeFormDate.bind(this, 'date')}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box px={2}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{height: "initial", borderRadius: "10px", fontSize: 16, lineHeight: 1, padding: "10px 30px"}}
                  onClick={this.close}
                >
                  Закрыть
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{height: "initial", borderRadius: "10px", fontSize: 16, lineHeight: 1, padding: "10px 30px"}}
                  onClick={this.submit}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}
const CustomInputAmount = ({inputRef, ...rest}) => {

  const onComplete = (value) => {
    rest.onChange({
      target: {
        name: rest.name,
        value
      }
    })
  }

  return (
    <IMaskInput
      ref={inputRef}
      mask={Number}
      thousandsSeparator=""
      radix="."
      mapToRadix={[',']}
      scale={rest?.scale || 4}
      padFractionalZeros={true}
      min={-999999999999}

      {...rest}

      onComplete={onComplete}
      onChange={null}
    />
  )
}

const styles = {
  dateRange: {
    "& .daterangepicker": {
      position: "fixed",
      top: "50%!important",
      left: "50%!important",
      transform: "translate(-50%, -50%)!important"
    }
  }
};
DialogChangeBalance = withStyles(styles)(DialogChangeBalance);

export default DialogChangeBalance
