import React from "react";
import {
	Box,
	Grid,
	Skeleton,
	Typography,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {convertorNumber} from "../../../../../../helper/convertor";

const CardSums = (props) => {
	const {
		data,
		hours,
		isLoad,

		onChange
	} = props;
	const classes = useStyles();


	return (
		<Grid container spacing={1}>
			<Grid item xs={2}>
				<CardItem
					label="Отдано номеров"
					isLoad={isLoad}
					value={`${convertorNumber(data.num_count)}шт.`}
				/>
			</Grid>
			<Grid item xs={2}>
				<CardItem
					label="Процент полученных СМС"
					isLoad={isLoad}
					value={`${convertorNumber(data.percent)}%`}
				/>
			</Grid>
			<Grid item xs={2}>
				<CardItem
					label="Выручка"
					isLoad={isLoad}
					value={`${convertorNumber(data.price_sum, 2)}₽`}
				/>
			</Grid>
			<Grid item xs={2}>
				<CardItem
					label="Чистая прибыль"
					isLoad={isLoad}
					value={`${convertorNumber(data.profit_sum, 2)}₽`}
				/>
			</Grid>
			<Grid item xs={2}>
				<CardItem
					label="Получено номеров"
					isLoad={isLoad}
					value={`${convertorNumber(data.recieve_count)}шт.`}
				/>
			</Grid>
			<Grid item xs={2}>
				<CardItem
					label="Расход"
					isLoad={isLoad}
					value={`${convertorNumber(data.supply_sum, 2)}₽`}
				/>
			</Grid>
		</Grid>
	)
}
const CardItem = (props) => {
	const {
		label,
		value,
		isLoad
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.card}>
			<Typography className={classes.cardLabel}>{label}</Typography>

			{Boolean(isLoad) && (
				<Box mt="auto">
					<Skeleton height={24}/>
				</Box>
			)}
			{Boolean(!isLoad) && (
				<Typography className={classes.cardValue}>{value}</Typography>
			)}
		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	card: {
		display: "flex",
		flexDirection: "column",

		height: "100%",
		boxSizing: "border-box",

		padding: "24px 36px",
		borderRadius: 4,
		backgroundColor: "rgba(251, 120, 0, 0.1)"
	},
	cardLabel: {
		fontSize: 16,
		lineHeight: "18px",
		marginBottom: 8,
	},
	cardValue: {
		fontSize: 20,
		lineHeight: "24px",
		fontWeight: "600",

		marginTop: "auto"
	},
}));

export default CardSums
