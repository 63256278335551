import React from "react";
import {
    Box,
    Grid,
    Avatar,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Typography,

    Pagination,

    Skeleton
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    TableHeadSortCell
} from "../../../../../../components";
import moment from "moment";
import {useSelector} from "react-redux";

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,

        isLoad,

        onChangeFilter,
        onChangePagination,
    } = props;
    const classes = useStyles();
    const userRole = useSelector(state => state?.global?.userRole);

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }
    const handleChangeSort = (sort) => {
        let newFilter = {...filter};
        newFilter.sort = sort;

        onChangeFilter(newFilter, true);
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>

                        <TableHeadSortCell name="id" filterSort={filter.sort} label="ID" onChange={handleChangeSort}/>
                        <TableCell>Страна</TableCell>
                        <TableCell>Сервис</TableCell>
                        <TableCell>ID провайдера</TableCell>
                        <TableHeadSortCell name="created_at" filterSort={filter.sort} label="Дата/время" onChange={handleChangeSort}/>
                        <TableHeadSortCell name="price" filterSort={filter.sort} label="Стоимость" onChange={handleChangeSort}/>
                        <TableCell>Статус</TableCell>
                      <TableCell>SMS</TableCell>
                        <TableCell>Пользователь</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoad) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (

                                        <TableRow key={`row-item-${index}`}>
                                            <TableCell>{ row.id }</TableCell>
                                            <TableCell>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <Avatar src={row.country?.icon} sx={{width: 24, height: 24}}/>
                                                    </Grid>
                                                    <Grid item>
                                                        { row.country?.name }
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <Avatar src={row.service?.icon} sx={{width: 24, height: 24}}/>
                                                    </Grid>
                                                    <Grid item>
                                                        { row.service?.name }
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>{ row.provider_id }</TableCell>
                                            <TableCell>{ moment(row.created_at).format("DD.MM.YYYY / HH:mm:ss") }</TableCell>
                                            <TableCell>
                                                <Typography sx={{color: Boolean((row.amount || "")?.[0] === "-") ? "#DD4321" : "#379E3B"}}>
                                                    { row.price } ₽
                                                </Typography>
                                            </TableCell>
                                            <TableCell>{ row.activation_status }</TableCell>
                                          <TableCell>{ row.code || "-" }</TableCell>
                                            <TableCell>
                                                { Boolean(userRole === 'Bookkeeper') ? row.user?.cms_user?.id : row.user?.cms_user?.email }
                                            </TableCell>
                                        </TableRow>

                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {}

}));

export default TableCustom
