import React, {Component} from "react";
import {
    Container,
    Backdrop,
    CircularProgress
} from "@mui/material";
import {
    Filter as FilterComponent,
    Table as TableComponent,
    DialogCreateGroup as DialogCreateGroupComponent
} from "./components";
import {
    DialogConfirmAction
} from "../../../../components";
import agent from "../../../../agent/agent";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";

class GoUserGroups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userGroups: [],
            filter: {},
            pagination: {
                page: 1,
                totalPage: 1
            },

            isLoad: true,
            isShowBackdrop: false
        };

        this.refDialogConfirmAction = React.createRef();
        this.refDialogCreateGroup = React.createRef();
    }

    componentDidMount = async () => {
        await this.getListUserGroups();
    }

    getListUserGroups = async () => {

        this.setState({isLoad: true});

        const {data, headers} = await agent.get("/smsgoapi/go-discount-groups").then((res) => {
            return {
                data: res.data,
                headers: res.headers
            }
        }).catch((err) => {
            return {data: [], headers: {}}
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: headers?.["x-pagination-page-count"] || 1
        };

        this.setState({
            userGroups: data,
            pagination,
            isLoad: false
        });
    }


    openCreatGroup = () => {
        this.refDialogCreateGroup.current.open({
            eventSubmit: this.saveCreatGroup.bind(this)
        });
    }
    saveCreatGroup = async (form) => {
        this.refDialogCreateGroup.current.close();

        this.setState({isShowBackdrop: true});

        const response = await agent.post("/smsgoapi/go-discount-groups", form).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        });

        Notification({
            message: `Группа ${form.name} успешно создана`,
            type: NotificationTypes.success
        });

        this.setState({isShowBackdrop: false});

        await this.getListUserGroups();
    }

    openEditGroup = (group) => {
        this.refDialogCreateGroup.current.open({
            initForm: {...group},
            eventSubmit: this.saveEditGroup.bind(this)
        });
    }
    saveEditGroup = async (form) => {
        this.refDialogCreateGroup.current.close();

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/smsgoapi/go-discount-groups/${ form.id }`, form).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        })

        Notification({
            message: `Группа ${form.name} успешно изменена`,
            type: NotificationTypes.success
        });

        this.setState({isShowBackdrop: false});

        await this.getListUserGroups();
    }

    openDeleteGroup = (group) => {
        this.refDialogConfirmAction.current.open({
            title: `Удаление группы "${group.name}"`,
            message: `Вы действительно хотите удалить группу "${group.name}"?`,
            labelSuccess: "Да, удалить",
            labelCancel: "Нет, оставить",

            onSuccess: this.confirmDeleteGroup.bind(this, group)
        })
    }
    confirmDeleteGroup = async (group) => {
        this.setState({isShowBackdrop: true});

        const data = await agent.delete(`/smsgoapi/go-discount-groups/${group?.id}`).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        });

        Notification({
            message: `Группа ${group.name} успешно удалена`,
            type: NotificationTypes.success
        });

        this.setState({isShowBackdrop: false});

        await this.getListUserGroups();
    }

    render() {
        const {
            userGroups,

            filter,
            pagination,

            isLoad,
            isShowBackdrop
        } = this.state;

        return (
            <>

                <Container maxWidth="xl">

                    <FilterComponent
                        onCreate={this.openCreatGroup}
                    />

                    <TableComponent
                        data={userGroups}
                        filter={filter}
                        pagination={pagination}

                        isLoad={isLoad}


                        onEdit={this.openEditGroup}
                        onDelete={this.openDeleteGroup}
                    />


                    <DialogConfirmAction
                        ref={this.refDialogConfirmAction}
                    />


                    <DialogCreateGroupComponent
                        ref={this.refDialogCreateGroup}
                    />

                </Container>

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>

            </>
        );
    }
}

export default GoUserGroups
