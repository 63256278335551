import React from "react";
import {
	Box,
	Grid,
	Tooltip,
	Skeleton,
	Typography,
	IconButton,

	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Edit as EditIcon,
	Delete as DeleteIcon
} from "@mui/icons-material";
import agent from "../../../../../../agent/agent";

const TableCustom = (props) => {
	const {
		data,
		isLoad,

		onEditUser,
		onRemoveUser
	} = props;

	return (
		<>

			<Table>
				<TableHead>
					<TableRow>
						<TableCell width={200}>Провайдер</TableCell>
						<TableCell/>
					</TableRow>
				</TableHead>
				<TableBody>
					<VisibleContent isVisible={!isLoad}>
						{(data || []).map((item, index) => (
							<CustomTableRow
								key={`CustomTableRow-${index}`}
								provider={item}

								onEditUser={onEditUser}
								onRemoveUser={onRemoveUser}
							/>
						))}
					</VisibleContent>
					<VisibleContent isVisible={isLoad}>
						{[0,1,2,3,4].map((item) => (
							<TableRow key={`table-row-lading-${item}`}>
								<TableCell><Skeleton height={40}/></TableCell>
								<TableCell align="right">
									<Grid container justifyContent="flex-end">
										<Grid item>
											<Skeleton
												width={40} height={40}
												variant="circular"
											/>
										</Grid>
									</Grid>
								</TableCell>
							</TableRow>
						))}
					</VisibleContent>
				</TableBody>
			</Table>

		</>
	)
}
const VisibleContent = React.memo(({ isVisible, children }) => {
	if (!isVisible) {
		return null
	}

	return children
})
const CustomTableRow = React.memo((props) => {
	const {
		provider,

		onEditUser,
		onRemoveUser
	} = props;
	const classes = useStyles();
	const [userList, setUserList] = React.useState([]);
	const [services, setServices] = React.useState([]);
	const [counties, setCounties] = React.useState([]);
	const [isLoadUserList, setLoadUserList] = React.useState(true);

	React.useEffect(() => {
		(async () => {
			await getUserList();
			await getCounties();
			await getServices();
		})();
	}, []);

	const getUserList = async () => {
		const response = await agent.get(`/smsgoapi/go-provider/${ provider.id }`).then((res) => {
			return res.data?.user_list || []
		}).catch(() => {
			return []
		});

		setUserList(response);
		setLoadUserList(false);
	}
	const getCounties = async () => {
		const response = await agent.get(`/smsgoapi/go-provider/countries/${ provider.id }`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		setCounties(response);
	}
	const getServices = async () => {
		const response = await agent.get(`/smsgoapi/go-provider/services/${ provider.id }`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		setServices(response);
	}

	const _nameServiceCode = (service_code) => {
		if (service_code === '' || service_code === undefined || service_code === -1 || service_code === '-1') {
			return "Сервис не присвоен"
		}

		return services.find((t) => Boolean(t.external_id == service_code || t.code == service_code))?.name || "Сервис не найден"
	}
	const _nameCountryId = (country_id) => {
		if (country_id === '' || country_id === undefined || country_id === -1 || country_id === '-1') {
			return "Страна не присвоена"
		}

		return counties.find((t) => Boolean(t.country_id == country_id))?.name || "Страна не найдена"
	}

	return (
		<TableRow>
			<TableCell>{ provider.name }</TableCell>
			<TableCell>
				<Table className={classes.smallTable}>
					<TableHead>
						<TableRow>
							<TableCell>Пользователь</TableCell>
							<TableCell>Услуга</TableCell>
							<TableCell>Страна</TableCell>
							<TableCell align="right"/>
						</TableRow>
					</TableHead>
					<TableBody>
						<VisibleContent isVisible={!isLoadUserList && userList.length > 0}>
							{userList.map((userItem, index) => (
								<TableRow>
									<TableCell>{ userItem?.cms_user?.email || "-" }</TableCell>
									<TableCell>{ _nameServiceCode(userItem.service_code) }</TableCell>
									<TableCell>{ _nameCountryId(userItem.country_id) }</TableCell>
									<TableCell align="right">
										<Grid container spacing={1} alignItems="center" justifyContent="flex-end">
											<Grid item>
												<Tooltip title="Редактировать" arrow>
													<IconButton color="primary" onClick={onEditUser.bind(this, provider, userItem, userList, false)}>
														<EditIcon/>
													</IconButton>
												</Tooltip>
											</Grid>
											<Grid item>
												<Tooltip title="Удалить" arrow>
													<IconButton color="error" onClick={onRemoveUser.bind(this, provider, userItem, userList, false)}>
														<DeleteIcon/>
													</IconButton>
												</Tooltip>
											</Grid>
										</Grid>
									</TableCell>
								</TableRow>
							))}
						</VisibleContent>
						<VisibleContent isVisible={!isLoadUserList && userList.length <= 0}>
							<TableRow>
								<TableCell colSpan={4}>
									<Typography variant="h5" textAlign="center">
										Нет привязанных пользователей
									</Typography>
								</TableCell>
							</TableRow>
						</VisibleContent>
						<VisibleContent isVisible={isLoadUserList}>
							<TableRow>
								<TableCell><Skeleton/></TableCell>
								<TableCell><Skeleton/></TableCell>
								<TableCell><Skeleton/></TableCell>
								<TableCell align="right">
									<Grid container spacing={1} alignItems="center" justifyContent="flex-end">
										<Grid item>
											<Skeleton
												width={40} height={40}
												variant="circular"
											/>
										</Grid>
										<Grid item>
											<Skeleton
												width={40} height={40}
												variant="circular"
											/>
										</Grid>
									</Grid>
								</TableCell>
							</TableRow>
						</VisibleContent>
					</TableBody>
				</Table>
			</TableCell>
		</TableRow>
	)
});

const useStyles = makeStyles(() => ({
	smallTable: {
		"& .MuiTableCell-head": {
			padding: "4px 8px",
			borderRadius: "0!important",
			backgroundColor: "#838383",

			fontSize: 12,
			lineHeight: "16px"
		},
		"& .MuiTableCell-body": {
			padding: "6px 8px",
			borderRadius: "0!important",

			fontSize: 14,
			lineHeight: "18px"
		},
	}
}));

export default React.memo(TableCustom)