import React from "react";
import {
	TextField,
	Autocomplete
} from "@mui/material";
import agent from "../../../agent/agent";
import PropTypes from "prop-types";

class FilterUserGroups extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			users: [],
			selectedUser: [],

			search: "",

			isLoad: false
		};


		this.timeOutSearch = null;
	}

	componentDidMount = async () => {
    await this.getUser();

		if (this.props.multiple && (this.props.value || []).length > 0) {
			await this.inititSelectedValues();
		}
		if (!this.props.multiple && !!this.props.value) {
			await this.inititSelectedValues();
		}
	}
	componentDidUpdate = async (prevProps) => {
		if (this.props.multiple && JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
			await this.inititSelectedValues();
		}
		if (!this.props.multiple && prevProps.value !== this.props.value) {
			await this.inititSelectedValues();
		}
	}

	inititSelectedValues = async () => {
		if (this.props.disabledInitial) {
			return
		}

		const ids = Boolean(this.props.multiple) ? ([...this.props.value] || []).join(',') : [];
		const id = Boolean(!this.props.multiple) ? this.props.value : null;
		const value = Boolean(this.props.multiple) ? ids : id;
		const filter = Boolean(this.props.multiple) ? `filter[${ this.props.valueName }][in]` : `filter[${ this.props.valueName }]`;

		const data = await agent.get(`/smsgoapi/go-user-groups?${filter}=${ value }&per-page=100`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		this.setState({
			selectedUser: data
		});
	}

	getUser = async () => {
		const users = await agent.get(`/smsgoapi/go-user-groups?filter[email][like]=${ this.state.search }&per-page=100`).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		})

		this.setState({
			users,
			isLoad: false
		})
	}
	changeSearch = async (event, search) => {
		clearTimeout(this.timeOutSearch);

		this.setState({
			search,
			isLoad: Boolean(search)
		});
		if (!search) {
			return
		}

		this.timeOutSearch = setTimeout(async () => {
			await this.getUser();
		}, 1000);
	}

	onChange = (value) => {
		if (this.props.multiple) {
			const parseValue = [...value].map((item) => {
				return item?.[this.props.valueName] || item
			})

			this.props.onChange({
				target: {
					name: this.props.name,
					value: parseValue || []
				}
			});
		}
		if (!this.props.multiple) {
			this.props.onChange({
				target: {
					name: this.props.name,
					value: String(value?.[this.props.valueName]) || null
				},
			});
		}


		setTimeout(() => {
			this.setState({
				selectedUser: value
			})
		}, 500);
	}

	_getOptionLabel = (row) => {
		const options = this.state.users;
		const selectedUser = this.state.selectedUser;

		if (typeof row !== 'object') {
			let item = options.find((t) => String(t?.[this.props.valueName]) === String(row));
			if (!item) {
				item = Boolean(Array.isArray(selectedUser)) ? (selectedUser || []).find((t) => String(t?.[this.props.valueName]) === String(row)) : selectedUser;
			}
			if (!item) {
				return ""
			}

			return `${item?.name} (ID: ${item?.[this.props.valueName]})`
		}

		return `${row?.name} (ID: ${row?.[this.props.valueName]})`
	}
	_filterOptions = (list, params) => {
		const inputValue = params?.inputValue || "";

		return [...list].filter((t) => {

			if (!inputValue) {
				return true
			}

			const nameLowerCase = (t?.name || "").toLowerCase();
			const inputValueLowerCase = (inputValue || "").toLowerCase();

			return Boolean(nameLowerCase.indexOf(inputValueLowerCase) > -1)

		})
	}

	render() {
		const {
			value,
			name,
			multiple
		} = this.props;
		const {
			users,
			isLoad
		} = this.state;

		return (
			<>

				<Autocomplete
					{...this.props}
					value={value}
					options={users}
					onChange={(event, value) => this.onChange(value, name)}
					name={name}
					variant="outlined"
					fullWidth
					disableClearable
					loading={isLoad}
					limitTags={1}
          size={this.props.size}
					filterSelectedOptions={Boolean(multiple)}
					autoComplete={Boolean(multiple)}

					isOptionEqualToValue={(option, value) => Boolean(String(option?.[this.props.valueName]) === String(value))}

					getOptionLabel={this._getOptionLabel}
					filterOptions={this._filterOptions}

					renderInput={(params) => <TextField
						{...params}
						label="Группа пользователей"
						fullWidth
					/>}

					onInputChange={this.changeSearch}
				/>

			</>
		);
	}
}

FilterUserGroups.defaultProps = {
	valueName: "id",
  size: "small",
	disabledInitial: false
}
FilterUserGroups.propTypes = {
	valueName: PropTypes.string,
  size: PropTypes.string,

	disabledInitial: PropTypes.bool
}

export default FilterUserGroups
