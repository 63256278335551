import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Grid,
	Tooltip,
	Checkbox,
	Skeleton,
	IconButton
} from "@mui/material";
import {
	Edit as EditIcon
} from "@mui/icons-material";

const TableCustom = (props) => {
	const {
		data,
		isLoad,

		onEditProvider
	} = props;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>ID</TableCell>
					<TableCell>Наименование</TableCell>
					<TableCell>Алиса</TableCell>
					<TableCell>API-токен</TableCell>
					<TableCell>Базовый URL</TableCell>
					<TableCell>Приоритет</TableCell>
					<TableCell>Активный</TableCell>
					<TableCell>Настройки</TableCell>
					<TableCell align="right"/>
				</TableRow>
			</TableHead>
			<TableBody>

				<VisibleContent visible={!isLoad}>
					{(data || []).map((provider) => (
						<TableRow key={`provider-${ provider.id }`}>
							<TableCell>{ provider.id }</TableCell>
							<TableCell>{ provider.name }</TableCell>
							<TableCell>{ provider.alias }</TableCell>
							<TableCell width="200px">
								<span style={{ wordBreak: "break-all" }}>{ provider.api_token }</span>
							</TableCell>
							<TableCell width="200px">
								<span style={{ wordBreak: "break-all" }}>{ provider.base_url }</span>
							</TableCell>
							<TableCell>{ provider.priority }</TableCell>
							<TableCell>
								<Checkbox checked={provider.active}/>
							</TableCell>
							<TableCell>
								<Grid container spacing={1} flexDirection="column">
									<Grid item><bold style={{fontWeight:"bold"}}>Мин. коэфф. прибыли:</bold> {provider?.settings?.min_profit_coefficient}</Grid>
									<Grid item><bold style={{fontWeight:"bold"}}>Ценовой коэффициент:</bold> {provider?.settings?.price_coefficient}</Grid>
									<Grid item><bold style={{fontWeight:"bold"}}>Процент реферальной программы:</bold> {provider?.settings?.ref_coefficient}</Grid>
									<Grid item><bold style={{fontWeight:"bold"}}>Курс валюты:</bold> {provider?.settings?.currency_rate}</Grid>
									<Grid item><bold style={{fontWeight:"bold"}}>ID пользователя:</bold> {provider?.user_id}</Grid>
								</Grid>
							</TableCell>
							<TableCell align="right">
								<Grid container wrap="nowrap" justifyContent="flex-end">
									<Grid item>
										<Tooltip title="Редактировать" arrow>
											<IconButton color="primary" onClick={onEditProvider.bind(this, provider, false)}>
												<EditIcon/>
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
							</TableCell>
						</TableRow>
					))}
				</VisibleContent>
				<VisibleContent visible={isLoad}>
					{['load1','load2','load3','load4','load5',].map((item) => (
						<TableRow key={item}>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
						</TableRow>
					))}
				</VisibleContent>

			</TableBody>
		</Table>
	)
}
const VisibleContent = React.memo(({ children, visible }) => {
	if (!visible) {
		return null
	}

	return children
})

export default React.memo(TableCustom)
