import React from 'react';
import { withStyles } from '@mui/styles';
import { Grid, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';


class StatisticsLoader extends React.PureComponent {
    render() {
        const {
            classes,
        } = this.props;
        return (
            <Box className={classes.root}>


                <Grid container rowSpacing={4}>
                    <Grid xs={12} item container>

                        <Box className={classes.titleWrapper}>
                            <Skeleton />
                        </Box>
                        <Grid marginTop={1} item container columnSpacing={1} rowSpacing={2} className={classes.gridRoot}>
                            <Grid item xs={6}>
                                <Box sx={{
                                    width: "100%"
                                }}>
                                    <Typography className={classes.title}>
                                        <Skeleton />
                                    </Typography>
                                    <Typography className={classes.item}>
                                        <Skeleton />
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box sx={{
                                    width: "100%"
                                }}>
                                    <Typography className={classes.title}>
                                        <Skeleton />
                                    </Typography>
                                    <Typography className={classes.item}>
                                        <Skeleton />
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box sx={{
                                    width: "100%"
                                }}>
                                    <Typography className={classes.title}>
                                        <Skeleton />
                                    </Typography>
                                    <Typography className={classes.item}>
                                        <Skeleton />
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box sx={{
                                    width: "100%"
                                }}>
                                    <Typography className={classes.title}>
                                        <Skeleton />
                                    </Typography>
                                    <Typography className={classes.item}>
                                        <Skeleton />
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid xs={12} item container>

                        <Box className={classes.titleWrapper}>
                            <Skeleton />
                        </Box>
                        <Grid marginTop={1} item container columnSpacing={1} rowSpacing={2} className={classes.gridRoot}>
                            <Grid item xs={6}>
                                <Box sx={{
                                    width: "100%"
                                }}>
                                    <Typography className={classes.title}>
                                        <Skeleton />
                                    </Typography>
                                    <Typography className={classes.item}>
                                        <Skeleton />
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box sx={{
                                    width: "100%"
                                }}>
                                    <Typography className={classes.title}>
                                        <Skeleton />
                                    </Typography>
                                    <Typography className={classes.item}>
                                        <Skeleton />
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box sx={{
                                    width: "100%"
                                }}>
                                    <Typography className={classes.title}>
                                        <Skeleton />
                                    </Typography>
                                    <Typography className={classes.item}>
                                        <Skeleton />
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box sx={{
                                    width: "100%"
                                }}>
                                    <Typography className={classes.title}>
                                        <Skeleton />
                                    </Typography>
                                    <Typography className={classes.item}>
                                        <Skeleton />
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}
const styles = {
    root: {
        background: "white",
        borderRadius: "8px",
        padding: "18px 24px",
        marginTop: "16px",
        maxWidth: '900px',
    },
    titleWrapper: {
        width: "50%",
        height: "20px",
        "& > *": {
            transform: "scale(1, 1)",
        }
    },

    gridRoot: {
        "& > *": {
            display: "flex",

        },
        "& > *:nth-child(even)": {
            justifyContent: "flex-end",
            "& > div": {
                textAlign: "end"
            }
        },
    },

    title: {
        fontSize: 16,
        opacity: 0.6,
        marginBottom: "4px",
    },
    item: {
        fontSize: 18,
    },
};

export default withStyles(styles)(StatisticsLoader)