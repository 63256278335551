import React from "react";
import {
	Box,
	Grid, TextField

} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import PropTypes from "prop-types";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import dateRangeLocal from "../../../constants/dateRangeLocal";
import moment from "moment/moment";

const startDay = moment().subtract(1, 'd').set({ h: 0, m: 0, s: 0 });
const endDay = moment().set({ h: 23, m: 59, s: 59 });

const startCurrentDay = moment().set({ h: 0, m: 0, s: 0 });
const endCurrentDay = moment().set({ h: 23, m: 59, s: 59 });

const ranges = {
	"Сегодня": [moment(startCurrentDay), moment(endCurrentDay)],
	"Вчера": [moment(startCurrentDay).subtract(1, "days"), moment(endCurrentDay).subtract(1, "days")],
	"Неделя": [moment(startCurrentDay).subtract(7, "days"), moment(endCurrentDay)],
	"Месяц": [moment(startCurrentDay).subtract(1, "months"), moment(endCurrentDay)],
	"Год": [moment(startCurrentDay).subtract(1, "years"), moment(endCurrentDay)],
}

class FilterDateRangeFull extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			value: [startDay, endDay],
		}
	}

	componentDidMount = () => {
		this.initValue();
	}
	componentDidUpdate = (prevProps) => {
		if (JSON.stringify(prevProps?.value) !== JSON.stringify(this.props?.value)) {
			this.initValue();
		}
	}

	initValue = () => {
		const getValueUtm = (val, defaultVal) => {
			if (!val) {
				return defaultVal
			}
			const timeZone = new Date().getTimezoneOffset();
			return moment(val).subtract(timeZone, 'm')
		}

		const value = [
			getValueUtm(this.props?.value?.[0], startDay),
			getValueUtm(this.props?.value?.[1], endDay),
		];
		this.setState({ value });
	}
	changeValue = (iniValueStart, initValueEnd) => {
		const getValueUtm = (val) => {
			if (!val) {
				return null
			}
			const timeZone = new Date().getTimezoneOffset();
			return moment(val).add(timeZone, 'm')
		}

		const value = [
			getValueUtm(iniValueStart),
			getValueUtm(initValueEnd),
		];

		this.props.onChange(value);
	}

	render () {
		const { maxDate } = this.props;
		const { value } = this.state;

		return (
			<DateTimeRangeContainer
				ranges={ranges}
				start={value[0]}
				end={value[1]}
				local={dateRangeLocal}
				style={styles}
				maxDate={maxDate}
				noMobileMode

				applyCallback={this.changeValue}
			>
				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item xs={5}>
						<TextField
							value={moment(value[0]).format('DD.MM.YYYY HH:mm')}
							size="small"
							fullWidth
							label="Дата (от)"
							error={false}
						/>
					</Grid>
					<Grid item xs>
						<Box sx={{textAlign: "center"}}> — </Box>
					</Grid>
					<Grid item xs={5}>
						<TextField
							value={moment(value[1]).format('DD.MM.YYYY HH:mm')}
							size="small"
							fullWidth
							label="Дата (до)"
							error={false}
						/>
					</Grid>
				</Grid>
			</DateTimeRangeContainer>
		)
	}
}

const styles = {
	fromDot: {backgroundColor: '#FB7800'},
	toDot: {backgroundColor: 'rgba(251,120,0, .6)'},
	fromDate: {backgroundColor: '#FB7800'},
	toDate: {backgroundColor: 'rgba(251,120,0, .6)'},
	hoverCell: {color: '#FB7800'},
	customRangeButtons: {backgroundColor: 'rgba(251,120,0, .6)', color: "#ffffff"},
	customRangeSelected: {backgroundColor: '#FB7800', color: "#ffffff"},
};

FilterDateRangeFull.propTypes = {};
FilterDateRangeFull.defaultProps = {
	maxDate: null
};

export default FilterDateRangeFull
