import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,

  Collapse
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  FilterAlt as FilterAltIcon
} from "@mui/icons-material";
import {
  FilterUsers,
  FilterCountry,
  FilterService,
  FilterDateRangeFull,
} from "../../../../../../components";

const Filter = (props) => {
  const {
    filter,

    onChange,
    onReset,
    onSearch
  } = props;
  const [isOpen, setOpen] = React.useState(true);
  const classes = useStyles();

  const handleChangeCommon = ({target}) => {
    const {name, value} = target;

    let newFilter = {...filter};
    newFilter[name] = value;
    newFilter.page = 1;

    onChange(newFilter);
  }
  const handleChangeDateRange = (values) => {
    let newFilter = {...filter};

    newFilter["filter[date][gte]"] = values[0] || null;
    newFilter["filter[date][lte]"] = values[1] || null;
    newFilter.page = 1;

    onChange(newFilter, false);
  }

  return (
    <Box mb={1}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">Фильтры</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                onClick={() => setOpen(!isOpen)}
                endIcon={<FilterAltIcon/>}
              >
                {isOpen ? "Свернуть" : "Развернуть"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                onClick={onReset}
                endIcon={<ClearIcon/>}
              >
                Сбросить
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                endIcon={<SearchIcon/>}
                onClick={onSearch}
              >
                Поиск
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box my={1}/>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box px={2} py={2} bgcolor="white" borderRadius={4}>
          <Grid container spacing={4}>
            <Grid item sm={3} className={classes.dateTimePicker}>
              <FilterDateRangeFull
                value={[
                  filter['filter[date][gte]'],
                  filter['filter[date][lte]'],
                ]}

                onChange={handleChangeDateRange}
              />
            </Grid>
            <Grid item sm={3}>
              <FilterCountry
                name="filter[country_ids]"
                value={filter["filter[country_ids]"]}
                size="small"
                fullWidth
                multiple

                onChange={handleChangeCommon}
              />
            </Grid>
            <Grid item sm={3}>
              <FilterService
                name="filter[service_ids]"
                value={filter["filter[service_ids]"]}
                size="small"
                fullWidth
                multiple

                onChange={handleChangeCommon}
              />
            </Grid>
            <Grid item sm={3}>
              <FilterUsers
                name="filter[user_ids]"
                value={filter["filter[user_ids]"]}
                size="small"
                fullWidth
                valueName="id"
                multiple

                onChange={handleChangeCommon}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
}

const useStyles = makeStyles(() => ({

  active: {
    display: "flex",
    alignItems: "center",
    padding: "2px 12px",
    borderRadius: 6,
    backgroundColor: "#FB7800",
    cursor: "pointer",

    fontSize: 15,
    lineHeight: "22px",
    fontWeight: "600"
  },
  activeLabel: {
    opacity: 0.6,
    fontSize: 15,
    lineHeight: "22px",
    fontWeight: "600",
    color: "white"
  },
  activeValue: {
    fontSize: 15,
    lineHeight: "22px",
    fontWeight: "600",
    color: "white",
    marginLeft: 6
  },


  dateTimePicker: {
    "& .daterangepicker": {
      "& .applyButton": {
        backgroundColor: "rgba(251,120,0, .8) !important",
        border: "none !important",
        "&:hover": {
          backgroundColor: "#FB7800 !important",
        }
      },
      "& .fromDateHourContainer": {
        border: "none !important",
      },
      "& .inputDate": {
        borderWidth: "2px !important",
        height: "33px !important",
        "&:focus": {
          borderColor: "#FB7800 !important",
          boxShadow: "none !important",
        }
      },
      "& .calendarAddon.input-group-addon": {
        borderWidth: "2px !important",
      },
      "& .multipleContentOnLine": {
        outline: "none !important",
        "& select": {
          height: "33px !important",
          borderRadius: "4px",
          borderWidth: "2px",
          outline: "none",
          borderColor: "#ccc",
          "&:focus": {
            borderColor: "#FB7800 !important",
            outline: "none !important",
          }
        }
      },
      "& .monthYearContainer": {
        alignItems: "center",
      },
      "& .timeIconStyle": {
        top: "50%",
        transform: "translate(0, -50%)",
      },
      "& .glyphicon": {
        color: "#FB7800 !important"
      },
      "& .rangecontainer": {
        "& div": {
          outline: "none !important",
        }
      },
      "& .calendarGrid": {
        "& div": {
          outline: "none !important",
        }
      }
    }
  }
}));

export default Filter
