import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
} from "@mui/material";
import {
	SelectElement
} from "../DialogCreate/index";
import {
	Notification,
	NotificationTypes
} from "../../../../../../common/Notification";

class DialogChangeStatus extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			value: null,

			item: {},
			isOpen: null,
			onSubmit: null,
		};
	}

	open = ({ item, onSubmit }) => {
		this.setState({
			value: null,
			isOpen: true,

			item,
			onSubmit,
		})
	};
	close = () => {
		this.setState({ isOpen: false });
	}

	change = ({ target }) => {
		this.setState({
			value: target.value
		});
	}
	submit = () => {
		const { value, item, onSubmit } = this.state;
		if (!value) {
			Notification({
				type: NotificationTypes.error,
				message: "Выберите новый статус заявки"
			})
			return
		}
		onSubmit(item, {
			"status": value
		});
	}

	render () {
		const {
			item,
			value,
			isOpen,
		} = this.state;
		const {
			config
		} = this.props;
		console.log('config: ', config);

		if (!isOpen) {
			return null
		}
		return (
			<Dialog
				open={true}
				fullWidth
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle  sx={{ background: "#FB7800" }}>
					<Typography variant="h3" sx={{ color: "white" }}>Обновление статуса выплаты</Typography>
				</DialogTitle>
				<DialogContent>
					<Box pt={2} mb={2}>
						<SelectElement
							value={value}
							label="Новый статус"
							options={config?.statuses || []}
							onChange={this.change}
						/>
					</Box>
					<Grid container spacing={1} justifyContent="flex-end">
						<Grid item>
							<Button
								size="small"
								variant="outlined"
								onClick={this.close}
							>Отменить</Button>
						</Grid>
						<Grid item>
							<Button
								size="small"
								variant="contained"
								onClick={this.submit}
							>Обновить</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}

export default DialogChangeStatus
