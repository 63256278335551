import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Grid,
  Tooltip,
  Checkbox,
  Skeleton,
  IconButton
} from "@mui/material";
import {
  History as HistoryIcon,
  Autorenew as AutorenewIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon
} from "@mui/icons-material";
import {
  convertorNumber
} from "../../../../../../helper/convertor";
import agent from "../../../../../../agent/agent";

const TableCustom = (props) => {
  const {
    data,
    isLoad,
    onChangeBalance,
    onOpenPopUpHistoryUpdate
  } = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Наименование</TableCell>
          <TableCell>Баланс</TableCell>
          <TableCell>Тип баланса</TableCell>
          <TableCell align="right"/>
        </TableRow>
      </TableHead>
      <TableBody>
        {(isLoad) && (new Array(10)).fill(1).map((a,i)=>i).map((t) => <TableRowLoading/>)}
        {(!isLoad) && (data || []).map((t, i) => <TableRowCustom
          key={`index-${i}`}
          {...t}
          onChangeBalance={onChangeBalance}
          onOpenPopUpHistoryUpdate={onOpenPopUpHistoryUpdate}
        />)}
      </TableBody>
    </Table>
  )
}
const TableRowLoading = React.memo(() => (
  <TableRow>
    <TableCell><Skeleton sx={{height: 25}}/></TableCell>
    <TableCell><Skeleton sx={{height: 25}}/></TableCell>
    <TableCell><Skeleton sx={{height: 25}}/></TableCell>
    <TableCell><Skeleton sx={{height: 25}}/></TableCell>
    <TableCell><Skeleton sx={{height: 25}}/></TableCell>
  </TableRow>
))
const TableRowCustom = React.memo((props) => {
  const {
    onChangeBalance,
    onOpenPopUpHistoryUpdate
  } = props;
  const [type, setType] = React.useState(null);
  const [balance, setBalance] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      await getBalance();
    })();
  }, [props])

  // Получение баланса провайдера
  const getBalance = async () => {
    setType(null);
    setBalance(null);

    let type = "";
    let balance = "";

    const res = await agent.get(`/smsgoapi/go-provider/balance/${props.id}`).then((res) => {
      type = res.data?.data?.type;
      balance = `${convertorNumber(res.data?.data?.balance)} ₽`
    }).catch(() => {
      type = "Ошибка получения баланса";
      balance = "Ошибка получения баланса";
    })

    setType(type);
    setBalance(balance);
  }

  return (
    <TableRow>
      <TableCell>{props.id}</TableCell>
      <TableCell>{props.name}</TableCell>
      <TableCell>
        {Boolean(balance !== null) ? (<>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>{balance}</Grid>
            <Grid item>
              <Tooltip title="Обновить баланс" arrow>
                <IconButton color="primary" onClick={getBalance}>
                  <AutorenewIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </>) : <Skeleton/>}
      </TableCell>
      <TableCell>
        {Boolean(type !== null) ? type : <Skeleton/>}
      </TableCell>
      <TableCell>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Tooltip title="Добавить изменение баланса" arrow>
              <IconButton color="primary" onClick={onChangeBalance.bind(this, props.id, null)}>
                <AccountBalanceWalletIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="История пополнений" arrow>
              <IconButton color="primary" onClick={onOpenPopUpHistoryUpdate.bind(this, props)}>
                <HistoryIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})

export default React.memo(TableCustom)
