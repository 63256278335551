import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Typography,

    Pagination,

    Skeleton, Avatar
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    TableHeadSortCell
} from "../../../../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,

        isLoad,

        onChangeFilter,
        onChangePagination,
    } = props;
    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }
    const handleChangeSort = (sort) => {
        let newFilter = {...filter};
        newFilter.sort = sort;

        onChangeFilter(newFilter, true);
    }

    return (
        <>

            <PerfectScrollbar>
                <Box className={classes.stickyFirstColumn}>
                    <Table>

                        <TableHead>
                            <TableRow>
                                <TableCell>Пользователь</TableCell>
                                <TableHeadSortCell
                                    name="sum_income"
                                    label="Сумма поступлений"
                                    filterSort={filter.sort}
                                    onChange={handleChangeSort}
                                />
                                <TableCell>Сумма ручных зачислений</TableCell>
                                <TableCell>Сумма зачислений</TableCell>
                                <TableCell>Сумма списаний</TableCell>
                                <TableCell>Расход закупа</TableCell>
                                <TableCell>Расход комиссии платежных сервисов</TableCell>
                                <TableCell>Расход налог (6%)</TableCell>
                                <TableCell>Прибыль</TableCell>
                                <TableCell>Неизрасходованный баланс</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>

                            {

                                Boolean(isLoad) ? (

                                    <>

                                        {
                                            [0, 1, 2, 3, 4, 5].map((item) => (

                                                <TableRow key={`row-item-load-${item}`}>
                                                    <TableCell>
                                                        <Skeleton/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Skeleton/>
                                                    </TableCell>
                                                </TableRow>

                                            ))
                                        }

                                    </>

                                ) : (

                                    <>

                                        {

                                            data.map((row, index) => (

                                                <TableRow key={`row-item-${index}`}>
                                                    <TableCell>{ row.email || "-" }</TableCell>
                                                    <TableCell>{ row.sum_income || "-" }</TableCell>
                                                    <TableCell>{ row.sum_manual_income || "-" }</TableCell>
                                                    <TableCell>{ row.sum_income_with_commission || "-" }</TableCell>
                                                    <TableCell>{ row.sum_debt || "-" }</TableCell>
                                                    <TableCell>{ row.sum_supply || "-" }</TableCell>
                                                    <TableCell>{ row.service_commission || "-" }</TableCell>
                                                    <TableCell>{ row.tax_commission || "-" }</TableCell>
                                                    <TableCell>{ row.profit || "-" }</TableCell>
                                                    <TableCell>{ row.balance || "-" }</TableCell>
                                                </TableRow>

                                            ))

                                        }

                                    </>

                                )

                            }

                        </TableBody>

                    </Table>
                </Box>
            </PerfectScrollbar>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    stickyFirstColumn: {
        // '& table:first-child': {
        //     '& tr': {
        //         '& td:first-child, th:first-child': {
        //             position: 'sticky',
        //             left: 0,
        //             zIndex: 999,
        //             filter: "drop-shadow(0px 5px 24px rgba(149, 157, 165, 0.25))"
        //         }
        //     }
        // },

        "& tr th": {
            fontSize: 12,
            lineHeight: "16px",
            padding: "8px 12px"
        },
        "& tr td": {
            fontSize: 16,
            lineHeight: "24px",
            padding: "8px 12px"
        },
    },

    buttonAction: {}

}));

export default TableCustom
