import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Pagination,
    Skeleton,

} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import moment from "moment";

const TableCustom = (props) => {
    const {
        data,
        pagination,
        isLoad,
        onChangePagination,

    } = props;
    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }


    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>Дата</TableCell>
                        <TableCell>Уmail</TableCell>
                        <TableCell>Cумма</TableCell>
                        <TableCell align="right"/>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        Boolean(isLoad) ? (

                            <>
                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton/>
                                            </TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>
                                {
                                    data.map((row, index) => (

                                        <TableRow key={`row-item-${index}`}>
                                            <TableCell>{moment(row?.created_at).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
                                            <TableCell>{row?.cms_user?.email} </TableCell>
                                            <TableCell>{row?.amount}</TableCell>
                                            <TableCell align="right"/>
                                        </TableRow>

                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {}

}));

export default TableCustom
