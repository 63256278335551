import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Box,
  Grid,
  Button,
  Divider,
  Typography,
  CircularProgress
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import agent from "../../../../../../agent/agent";
import moment from "moment";

const initState = {
  user: {},
  info: {},
  cashback: {},
  isOpen: false,
  isLoad: true
}

class DialogLoyaltyProgram extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {...initState}
  }

  open = (user) => {
    this.setState({
      user,
      isOpen: true
    }, async () => {
      await this.initState();
    })
  }
  close = () => {
    this.setState({
      ...initState
    })
  }

  initState = async () => {
    const userId = this.state?.user?.id;
    const cashback = await agent.get(`/smsgoapi/go-loyalty/cashback/${ userId }`).then((res) => {
      return res.data
    }).catch(() => {
      return {}
    });

    this.setState({
      cashback,
      isLoad: false
    });
  }

  render () {
    const {
      user,
      cashback,
      isOpen,
      isLoad
    } = this.state;
    const {
      classes
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        onClose={this.close}
      >
        <DialogTitle sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
          <Typography variant="h3" sx={{color: "white"}}>Программа лояльности</Typography>
        </DialogTitle>
        <DialogContent>
          <VisibleContent visible={!isLoad}>
            <Box pt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} container spacing={4}>
                  <Grid item xs={4} alignItems="flex-end">
                    <Typography variant="h5">Текущий ранг: </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h5">{cashback?.rank || "-"}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}><Divider/></Grid>
                <Grid item xs={12} container spacing={4}>
                  <Grid item xs={4}>
                    <Typography variant="h5">Прогнозируемый кэшбэк: </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h5">{cashback?.projected_cashback || "-"}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}><Divider/></Grid>
                <Grid item xs={12} container spacing={4}>
                  <Grid item xs={4}>
                    <Typography variant="h5">До следующего ранга: </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h5">{cashback?.till_next_rank || "-"}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}><Divider/></Grid>
                <Grid item xs={12} container spacing={4}>
                  <Grid item xs={4}>
                    <Typography variant="h5">Недельный депозит: </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h5">{cashback?.week_deposit || "-"}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}><Divider/></Grid>
                <Grid item xs={12} container spacing={4}>
                  <Grid item xs={4}>
                    <Typography variant="h5">Процент кэшбэка следующий ранг: </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h5">{cashback?.cashback_percent_next_rank || "-"}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Box mt={2}/>
              <Divider/>
              <Box mt={2}/>

              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Сумма</TableCell>
                    <TableCell>Процент</TableCell>
                    <TableCell>С</TableCell>
                    <TableCell>До</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(cashback?.history || []).map((item, index) => (
                    <TableRow key={`history-item-${ index }`}>
                      <TableCell>{ item.amount }</TableCell>
                      <TableCell>{ item.percent }</TableCell>
                      <TableCell>{ moment(item.from).format("DD.MM.YYYY HH:mm:ss") }</TableCell>
                      <TableCell>{ moment(item.to).format("DD.MM.YYYY HH:mm:ss") }</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </VisibleContent>
          <VisibleContent visible={isLoad}>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "240px"
            }}>
              <CircularProgress/>
            </Box>
          </VisibleContent>
        </DialogContent>
        <DialogActions>
          <Box px={2}>
            <Button variant="contained" onClick={this.close}>
              Закрыть
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}
const VisibleContent = (props) => {
  if (!props.visible) {
    return null
  }
  return (props.children)
}

const styles = {
  table: {
    "& .MuiTableHead-root .MuiTableCell-root": {
      padding: "4px 10px",
      fontSize: "12px"
    },
    "& .MuiTableBody-root .MuiTableCell-root": {
      padding: "6px 10px",
      fontSize: "14px"
    },
  }
};
DialogLoyaltyProgram = withStyles(styles)(DialogLoyaltyProgram);

export default DialogLoyaltyProgram
