import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from "@mui/material";
import {
	FilterUsers,
	FilterCountry,
	FilterService,
	FilterProvider
} from "../../../../../../components";
import {
	Notification,
	NotificationTypes
} from "../../../../../../common/Notification";


const initState = {
	userItem: {},
	provider: {},
	userList: {},
	form: {
		service: null,
		country: null,
	},

	indexChangeItem: null,
	onSubmit: null,

	isOpen: false
}

class DialogEditProvider extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {...initState}
	}

	open = ({ provider, userItem, userList, indexChangeItem, onSubmit }) => {
		const form = {
			provider: provider.id,
			user: userItem.user_id,
			service: userItem.service_code,
			country: userItem.country_id,
		}

		this.setState({
			form,

			provider,
			userItem,
			userList,
			indexChangeItem,

			isOpen: true,
			onSubmit
		})
	}
	close = () => {
		this.setState({
			...initState
		})
	}

	changeForm = ({ target }) => {
		const { name, value } = target;

		let form = {...this.state.form}
		form[name] = value;

		this.setState({
			form
		})
	}

	onSubmit = () => {
		const { provider, form, userList, indexChangeItem } = this.state;

		// Валидация
		let errorMessage = [];
		// if (!form.service) {
		// 	errorMessage.push('Выберите услугу')
		// }
		// if (!form.country) {
		// 	errorMessage.push('Выберите страну')
		// }
		if (errorMessage.length > 0) {
			Notification({
				message: errorMessage.join("<br/>"),
				type: NotificationTypes.error
			})

			return
		}

		let userItem = {...this.state.userItem};
		userItem.country_id = form.country;
		userItem.service_code = form.service;

		// Отправка данных
		this.state.onSubmit(provider, userItem, userList, true, indexChangeItem);
	}

	render () {
		const {
			provider,
			userItem,
			form,
			isOpen
		} = this.state;
		if (!isOpen) {
			return null
		}

		return (
			<Dialog
				open={isOpen}

				maxWidth="md"
				fullWidth={true}
				disablePortal={true}
				PaperProps={{
					sx: { overflow: "initial" }
				}}

				onClose={this.close}
			>

				<DialogTitle sx={{ backgroundColor: "#FB7800" }}>
					<Typography variant="h3" sx={{ color: "white" }}>Редактирование пользователя</Typography>
				</DialogTitle>

				<DialogContent>
					<Box py={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h5">Пользователь: {userItem?.cms_user?.email}</Typography>
							</Grid>
							<Grid item xs={12}>
								<FilterService
									value={form.service}
									disabled={Boolean(!form.provider)}
									apiUrl={`/smsgoapi/go-provider/services/${ provider.id }`}
									name="service"
									size="small"
									valueName="code"
									disableClearable={false}
									onChange={this.changeForm}
								/>
							</Grid>
							<Grid item xs={12}>
								<FilterCountry
									value={form.country}
									disabled={Boolean(!form.provider)}
									apiUrl={`/smsgoapi/go-provider/countries/${ provider.id }`}
									name="country"
									size="small"
									nameField="country_id"
									disableClearable={false}
									onChange={this.changeForm}
								/>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>

				<DialogActions>
					<Box px={2}>
						<Grid container justifyContent="flex-end" spacing={2}>
							<Grid item>
								<Button
									variant="outlined"
									size="small"
									sx={{
										borderRadius: "4px",
										height: "auto",
										textTransform: "initial"
									}}
									onClick={this.close}
								>Отменить</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									size="small"
									sx={{
										borderRadius: "4px",
										height: "auto",
										textTransform: "initial"
									}}
									onClick={this.onSubmit}
								>Редактировать</Button>
							</Grid>
						</Grid>
					</Box>
				</DialogActions>

			</Dialog>
		)
	}
}

export default DialogEditProvider