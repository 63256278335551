import { Box, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles'
import React from 'react';

class StatisticsList extends React.PureComponent {
    render() {
        const {
            classes,
            statistics,
        } = this.props;

        return (
            <Box className={classes.statistiscWrapper}>
                <Typography variant='h3'>Статистика</Typography>
                <Grid marginTop={1} container rowSpacing={2} className={classes.root}>
                    <Grid item xs={6}>
                        <Box>
                            <Typography className={classes.title}>Сумма поступлений:</Typography>
                            <Typography className={classes.item}>{statistics?.sum_income || "-"}</Typography>
                        </Box>

                    </Grid>
                    <Grid item xs={6} className={classes.itemWrapper}>
                        <Box>
                            <Typography className={classes.title}>Сумма зачислений:</Typography>
                            <Typography className={classes.item}>{statistics?.sum_income_with_commission || "-"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.itemWrapper}>
                        <Box>
                            <Typography className={classes.title}>Сумма списаний:	</Typography>
                            <Typography className={classes.item}>{statistics?.sum_debt || "-"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.itemWrapper}>
                        <Box>
                            <Typography className={classes.title}>Расход закупа:</Typography>
                            <Typography className={classes.item}>{statistics?.sum_supply || "-"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.itemWrapper}>
                        <Box>
                            <Typography className={classes.title}>Расход комиссии платежных сервисов:	</Typography>
                            <Typography className={classes.item}>{statistics?.service_commission || "-"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.itemWrapper}>
                        <Box>
                            <Typography className={classes.title}>Расход налог (6%):	</Typography>
                            <Typography className={classes.item}>{statistics?.tax_commission || "-"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.itemWrapper}>
                        <Box>
                            <Typography className={classes.title}>Прибыль:</Typography>
                            <Typography className={classes.item}>{statistics?.profit || "-"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.itemWrapper}>
                        <Box>
                            <Typography className={classes.title}>Неизрасходованный баланс:</Typography>
                            <Typography className={classes.item}>{statistics?.balance || "-"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.itemWrapper}>
                        <Box>
                            <Typography className={classes.title}>Сумма ручных зачислений</Typography>
                            <Typography className={classes.item}>{statistics?.sum_manual_income || "-"}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

const styles = {
    root: {
        "& > *": {
            display: "flex",

        },
        "& > *:nth-child(even)": {
            justifyContent: "flex-end",
            "& > div": {
                textAlign: "end"
            }
        },
    },

    statistiscWrapper: {
        background: "white",
        borderRadius: "8px",
        padding: "18px 24px",
        marginTop: "16px",
    },

    title: {
        fontSize: 16,
        opacity: 0.6,
        marginBottom: "4px",
    },
    item: {
        fontSize: 18,
    },
};

export default withStyles(styles)(StatisticsList)
