import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Grid,
  Tooltip,
  Checkbox,
  Skeleton,
  IconButton,
  Pagination
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from "@mui/icons-material";

const TableCustom = (props) => {
  const {
    data,
    total,
    filter,
    isLoad,
    onEdit,
    onDelete,
    onChangePage
  } = props;

  const handleChangePage = (event, page) => {
    let newFilter = {...filter};
    newFilter.page = page;
    onChangePage(newFilter, true)
  }

  return (
    <>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Группа пользователей
            </TableCell>
            <TableCell>
              Программа лояльности
            </TableCell>
            <TableCell>
              Процент кэшбэка
            </TableCell>
            <TableCell>
              Ранг
            </TableCell>
            <TableCell>
              Программа лояльности<br/>отключена для группы
            </TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(!isLoad) && data.map((t, i) => <RowCustom
            key={`RowCustom-${ i }`}
            item={t}
            onEdit={onEdit}
            onDelete={onDelete}
          />)}
          {Boolean(isLoad) && (
            <RowLoading/>
          )}
        </TableBody>
      </Table>

      <Grid container justifyContent="flex-end" mt={1}>
        <Grid item>
          <Pagination
            count={total}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>

    </>
  )
}
const RowCustom = React.memo((props) => {
  const {
    item,
    onEdit,
    onDelete
  } = props;

  return (
    <TableRow>
      <TableCell>
        {item?.userGroup?.name || "-"}
      </TableCell>
      <TableCell>
        {item?.rank?.name || "-"}
      </TableCell>
      <TableCell>
        {item.cashback_percent}
      </TableCell>
      <TableCell>
        {item?.rank?.name || "-"}
      </TableCell>
      <TableCell>
        <Checkbox checked={Boolean(item.is_ban)}/>
      </TableCell>
      <TableCell>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Tooltip title="Удалить" arrow>
              <IconButton color="error" onClick={onDelete.bind(this, item, false)}>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Редактировать" arrow>
              <IconButton color="primary" onClick={onEdit.bind(this, item, false)}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})
const RowLoading = React.memo(() => {
  return (
    <>
      {new Array(10).fill(1).map((t) => (
        <TableRow>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
          <TableCell><Skeleton/></TableCell>
        </TableRow>
      ))}
    </>
  )
})

export default React.memo(TableCustom)
