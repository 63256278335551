import React from "react";
import {
  Box,
  Grid,
  Button
} from "@mui/material";
import {
  FilterUsers,
  FilterDateRangeFull
} from "../../../../../../components";
import moment from "moment";

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {}
  }

  changeFilter = ({ target }) => {
    const { name, value } = target;
    let filter = {...this.props.filter};
    filter[name] = value;
    filter.page = 1;
    this.props.onChange(filter);
  }
  changeFilterRange = (values) => {
    let filter = {...this.props.filter};
    filter['filter[created_at][gt]'] = moment(values[0]).format("YYYY-MM-DD HH:mm:ss");
    filter['filter[created_at][lt]'] = moment(values[1]).format("YYYY-MM-DD HH:mm:ss");
    filter.page = 1;
    this.props.onChange(filter);
  }

  render () {
    const {
      filter,
      onReset,
      onSearch,
    } = this.props;

    return (
      <Box p={2} borderRadius="16px" bgcolor="white">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FilterDateRangeFull
              value={[
                filter['filter[created_at][gt]'],
                filter['filter[created_at][lt]'],
              ]}
              onChange={this.changeFilterRange}
            />
          </Grid>
          <Grid item xs={3}>
            <FilterUsers
              value={filter['filter[user_id]']}
              size="small"
              name="filter[user_id]"
              onChange={this.changeFilter}
            />
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{minHeight: "initial", height: "auto", padding: "4.5px 10px", borderRadius: "5px", textTransform: "initial"}}
                  onClick={onReset}
                >Сбросить фильтр</Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{minHeight: "initial", height: "auto", padding: "4.5px 10px", borderRadius: "5px", textTransform: "initial"}}
                  onClick={onSearch}
                >Поиск</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default Filter
