import React from "react";
import {
    Box,
    Grid,
    Typography,
    Button
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    PlusOne as PlusOneIcon
} from "@mui/icons-material";

const Filter = (props) => {
    const {
        onCreate
    } = props;

    return (
        <Box mb={1}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Фильтры</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={onCreate}
                                endIcon={<PlusOneIcon/>}
                            >
                                Создать группу
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Box>
    )
}

const useStyles = makeStyles(() => ({

    active: {
        display: "flex",
        alignItems: "center",
        padding: "2px 12px",
        borderRadius: 6,
        backgroundColor: "#FB7800",
        cursor: "pointer",

        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600"
    },
    activeLabel: {
        opacity: 0.6,
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white"
    },
    activeValue: {
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
        marginLeft: 6
    },

}));

export default Filter
