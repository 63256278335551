import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@mui/material';
import {withStyles} from '@mui/styles';
import {Box} from '@mui/system';
import agent from "../../../../../../agent/agent";
import StatisticsList from './StatisticsList';
import TopServicesList from './TopServicesList';
import UserInfo from './UserInfo';
import StatisticsLoader from './StatisticsLoader';

class DialogUserStatistics extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			topServices: {},

			user: {},
			statistics: {},

			isLoading: false,

			open: false,
		}
	}

	open = ({currentUser}) => {
		this.setState({
				user: currentUser,
				open: true,
			},
			async () => {
				await this.getUserStatistics();
			});
	}

	close = () => {
		this.setState({
			open: false,
		})
	}

	getUserStatistics = async () => {
		const {
			user,
		} = this.state;

		await this.setState({isLoading: true});

		const userId = user?.external_id || "";

		// Запрос статистики пользователя
		const statistics = await agent.get(`/smsgoapi/go-stat/users?users=${userId}`).then((res) => {
			return res?.data?.[0] || {}
		}).catch((err) => {
			return {
				data: {},
			}
		});

		// запрос топ сервисов пользователя
		const topServices = await agent.get(`/smsgoapi/go-stat/user-service?user_id=${userId}`).then((res) => {
			return res?.data || {};
		}).catch((err) => {
			return {}
		});

		this.setState({
			topServices: topServices || {},
			statistics: statistics || {},
			isLoading: false,
		});
	}

	render() {
		const {
			classes,
		} = this.props;

		const {
			user,
			open,
			statistics,
			topServices,
			isLoading,
		} = this.state;


		return (
			<Dialog
				open={open}
				maxWidth="md"
				fullWidth={true}
				onClose={this.close}
			>
				<DialogTitle sx={{backgroundColor: "rgba(251, 120, 0, 1)"}}>
					<Typography variant="h3" sx={{color: "white"}}>
						Статистика пользователя
					</Typography>
				</DialogTitle>

				{isLoading ? (
					<StatisticsLoader/>
				) : (
					<>
						<DialogContent sx={{
							background: "#f3f1f1",
							padding: "20px 15px"
						}}>
							<Box px={1} py={3}>
								<UserInfo
									user={user}
								/>

								<StatisticsList
									statistics={statistics}
								/>

								<TopServicesList
									topServices={topServices}
								/>

								<Box mt={1}>
									<Button
										fullWidth
										variant="contained"
										component="a"
										href={user?.ref_link || statistics.ref_link}
										target="_blank"
									>Открыть отчет по реферальной программе</Button>
								</Box>

							</Box>
						</DialogContent>
						<DialogActions sx={{background: "#f3f1f1"}}>
							<Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
								<Grid item>
									<Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="contained" onClick={this.close}>
										Отмена
									</Button>
								</Grid>

							</Grid>
						</DialogActions>
					</>

				)}

			</Dialog>
		)
	}
}

const styles = {};

export default withStyles(styles)(DialogUserStatistics)
