import React, {Component} from "react";
import {
  Grid,
  Button,
  Container,
  Backdrop,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  Table as TableComponent,
  PopUpForm as PopUpFormComponent,
  InstallingProvider as InstallingProviderComponent
} from "./components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

class CascadeList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      providers: [],

      filter: {
        "filter[group_id]": null,
        "filter[active]": null,
        "filter[created_at][gt]": null,
        "filter[created_at][lt]": null,
        "sort": "id",
        "page": 1
      },
      pagination: {
        pageCount: 1
      },

      isLoadList: true,
      isBackdrop: false,
    };

    this.refPopUpFormComponent = React.createRef();
    this.refInstallingProviderComponent = React.createRef();
  }

  componentDidMount = async () => {
    await this.getList();
  }

  getList = async ({ cancelLoad } = {}) => {
    if (!cancelLoad) {
      this.setState({ isLoadList: true });
    }

    const filter = this._getUrlFromFilter();
    const response = await agent.get(`/smsgoapi/go-cascade?${filter}`).then((res) => {
      return res
    }).catch(() => {
      return null
    });

    this.setState({
      list: response?.data || [],
      pagination: {
        perPage: response?.headers?.['x-pagination-page-count'] || 1
      },
      isLoadList: false
    });
  }
  getProviders = async () => {
    const res = await agent.post('/smsgoapi/go-provider/list?per-page=100').then((res) => {
      return res.data
    }).catch(() => {
      return []
    });
    await this.setState({
      providers: res
    })
  }

  onEdit = async (form, isSubmit = false) => {
    if (!isSubmit) {
      this.refPopUpFormComponent.current.open({
        form,
        onSubmit: this.onEdit.bind(this)
      })
      return
    }

    this.setState({isBackdrop: true});
    let body = {...form};
    body.min_profit = Number.parseFloat(body.min_profit);
    delete body.id;
    delete body.group_id;
    const res = await agent.put(`/smsgoapi/go-cascade/${form.id}`, body).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    this.refPopUpFormComponent.current.close();
    await this.getList();
    this.setState({isBackdrop: false});
    Notification({
      message: "Каскад успешно изменен",
      type: NotificationTypes.success
    })
  }
  onCreate = async (form, isSubmit = false) => {
    if (!isSubmit) {
      this.refPopUpFormComponent.current.open({
        onSubmit: this.onCreate.bind(this)
      })
      return
    }

    this.setState({isBackdrop: true});
    const res = await agent.post('/smsgoapi/go-cascade', form).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    this.refPopUpFormComponent.current.close();
    await this.getList();
    this.setState({isBackdrop: false});
    Notification({
      message: "Каскад успешно создан",
      type: NotificationTypes.success
    })
  }
  onChangePage = async (event, newPage) => {
    let filter = {...this.state.filter}
    filter.page = newPage;
    await this.setState({filter});
    await this.getList();
  }
  onSetProvider = async (cascadeId, isSubmit, form) => {
    if (!isSubmit) {
      this.refInstallingProviderComponent.current.open({
        cascadeId: cascadeId,
        onSubmit: this.onSetProvider.bind(this)
      });
      return
    }

    this.setState({isBackdrop: true});

    if (Boolean(form.find((t) => t.provider_id === "protocol"))) {
      await this.getProviders();
    }

    let body = {
      "providers": []
    };
    for (const elementProvider of form) {
      if (elementProvider.provider_id === 'protocol') {
        const _items = (this.state.providers || []).filter((t) => t.adapter === 'protocol');
        _items.map((_item) => {
          body.providers.push({
            "provider_id": Number.parseFloat(_item.id),
            "priority": Number.parseFloat(elementProvider.priority)
          })
        })
      } else {
        body.providers.push({
          "provider_id": Number.parseFloat(elementProvider.provider_id),
          "priority": Number.parseFloat(elementProvider.priority)
        })
      }
    }

    const res = await agent.post(`/smsgoapi/go-cascade/set-providers/${cascadeId}`, body).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    this.refInstallingProviderComponent.current.close();
    this.setState({isBackdrop: false});
    await this.getList();
    Notification({
      message: "Вы успешно установили провайдерв",
      type: NotificationTypes.success
    })
  }

  _getUrlFromFilter = () => {
    const filter = {...this.state.filter}

    let strings = [];
    Object.keys(filter).map((filterKey) => {
      const filterValue = filter[filterKey];
      if (!!filterValue) {
        strings.push(`${filterKey}=${filter[filterKey]}`)
      }
    })

    return strings.join("&")
  }

  render() {
    const {
      list,
      filter,
      pagination,
      isLoadList,
      isBackdrop
    } = this.state;

    return (
      <Container maxWidth="xl">

        <Grid container alignItems="center" justifyContent="space-between" mb={2}>
          <Grid item>
            <Typography variant="h1">Каскад</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{borderRadius: "4px", textTransform: "initial", padding: "0 24px"}}
              onClick={this.onCreate.bind(this, null, false)}
            >
              Создать
            </Button>
          </Grid>
        </Grid>

        <TableComponent
          data={list}
          isLoad={isLoadList}
          filter={filter}
          totalPage={pagination.perPage}

          onEdit={this.onEdit}
          onUpdateList={this.getList}
          onChangePage={this.onChangePage}
          onSetProvider={this.onSetProvider}
        />


        <PopUpFormComponent
          ref={this.refPopUpFormComponent}
        />
        <InstallingProviderComponent
          ref={this.refInstallingProviderComponent}
        />

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Container>
    );
  }
}

export default CascadeList
