import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Typography,
    Pagination,
    Skeleton,

    Collapse,
    Tooltip,
    IconButton,
    Avatar
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    ArrowCircleDown as ArrowCircleDownIcon,
    ArrowCircleUp as ArrowCircleUpIcon
} from "@mui/icons-material";

const TableCustom = (props) => {
    const {
        data,
        filter,
        countries,
        pagination,

        isLoad,

        onChangeFilter,
        onChangePagination,


        onEdit,
        onDelete,
    } = props;
    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }
    const handleChangeSort = (sort) => {
        let newFilter = {...filter};
        newFilter.sort = sort;

        onChangeFilter(newFilter, true);
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell width={60}/>
                        <TableCell>Страна</TableCell>
                        <TableCell>Услуга</TableCell>
                        <TableCell>Стоимость</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoad) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    (Object.keys(data || {})).map((key, index) => (
                                        <TableCellCollapse
                                            row={data[key]}
                                            key={`row-item-${index}`}
                                            country={key}
                                            countries={countries}
                                        />
                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    )
}
const TableCellCollapse = (props) => {
    const {
        row,
        countries,
        country: countryKey
    } = props;
    const [isOpen, setOpen] = React.useState(false);
    const country = (countries || []).find((t) => +t.external_id === +countryKey);

    const Icon = Boolean(isOpen) ? ArrowCircleUpIcon : ArrowCircleDownIcon;

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton onClick={() => setOpen(!isOpen)}>
                        <Icon/>
                    </IconButton>
                </TableCell>
                <TableCell width={280}>
                    <Grid container spacing={1} alignItems="center" wrap="nowrap">
                        <Grid item>
                            <Avatar src={country?.icon} sx={{width: 24, height: 24}}/>
                        </Grid>
                        <Grid item>
                            {country?.name}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell/>
                <TableCell/>
            </TableRow>


            {Boolean(isOpen) && (
                <>
                    {Object.keys(row).map((key, index) => (
                        <TableRow sx={{ display: Boolean(!isOpen) && "none" }}>
                            <TableCell></TableCell>
                            <TableCell width={280}/>
                            <TableCell>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Avatar src={row[key]?.icon} sx={{width: 24, height: 24}}/>
                                    </Grid>
                                    <Grid item>
                                        {row[key]?.name}
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell>
                                {row[key]?.price} ₽
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            )}
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({

    buttonAction: {}

}));

export default TableCustom
