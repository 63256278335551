import React from "react";
import {
	Box,
	Grid,
	Button,
	TextField,
	Typography,

	Dialog,
	DialogContent,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";

class DialogTwoFaToken extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: "",
			isOpen: false,
			onSubmit: null
		}
	}

	changeToken = ({ target }) => {
		this.setState({ token: target.value || "" })
	}

	open = ({ onSubmit }) => {
		this.setState({
			onSubmit,
			isOpen: true
		})
	}
	close = () => {
		this.setState({
			token: "",
			isOpen: false,
		})
	}
	submit = () => {
		this.state.onSubmit(this.state.token);
		this.close();
	}

	render () {
		const {
			token,
			isOpen
		} = this.state;
		const {
			classes
		} = this.props;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="xs"
			>
				<DialogContent>
					<Typography variant="h6">
						Введите одноразовый код безопасности из вашего приложения с двухфакторной аутентификации:
					</Typography>
					<Box mt={1}/>
					<TextField
						value={token}
						fullWidth
						size="small"
						variant="outlined"
						label="Одноразовый код"
						onChange={this.changeToken}
					/>
					<Box mt={2}/>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Button
								fullWidth
								size="small"
								variant="outlined"
								className={classes.button}
								onClick={this.close}
							>Отменить</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								fullWidth
								size="small"
								variant="contained"
								className={classes.button}
								onClick={this.submit}
							>Отправить</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}

const styles = {
	button: {
		height: "auto",
		padding: "6px 12px",
		boxSizing: "border-box",
		minHeight: "initial",
		fontSize: "14px",
		textTransform: "initial",
		lineHeight: "16px",
		borderRadius: "8px",
	}
};
DialogTwoFaToken = withStyles(styles)(DialogTwoFaToken);

export default DialogTwoFaToken
