export default {
	format: "DD.MM.YY HH:mm",
		sundayFirst: false,
	days: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
	months: [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Октябрь',
	'Ноябрь',
	'Декабрь',],
	fromDate: 'Дата (от):',
	toDate: 'Дата (до):',
	selectingFrom: 'Выбрать от',
	selectingTo: 'Выбрать до',
	maxDate: 'Максимальная дата',
	close: 'Закрыть',
	apply: 'Принять',
	cancel: 'Отменить',
	showCustomRangeLabel: false,
}