import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,


    Typography,
    Button,
    Grid,
    Box, TextField,
} from "@mui/material";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import {Formik} from "formik";

const pageInitForm = {
    name: "",
    discount: "",
};

class DialogCreateGroup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initForm: {},

            open: false,
            isCreated: true,


            eventSubmit: null
        }

        this.refFormik = React.createRef();
    }


    onSubmit = (form) => {
        this.state.eventSubmit(form);
    }
    changeForm = ({ target }) => {
        const { name, value } = target;

        let newForm = {...this.refFormik.current.values};
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    }

    open = async ({ initForm, eventSubmit } = {}) => {
        await this.setState({
            open: true,
            isCreated: Boolean(Object.keys((initForm || {})).length <= 0),
            eventSubmit
        });

        this.refFormik.current.setValues(initForm || pageInitForm);
    }
    close = () => {
        this.setState({
            open: false
        })
    }

    onSuccess = () => {
        this.refFormik.current.handleSubmit()
    }
    onCancel = () => {
        this.close()
    }

    render() {
        const {
            open,
            isCreated
        } = this.state;

        return (
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}


                onClose={this.onCancel}
            >

                <DialogTitle sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
                    <Typography variant="h3" sx={{color: "white"}}>
                        { isCreated ? "Создание группы": "Редактирование группы" }
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box px={1} py={3}>
                        <Formik
                            innerRef={this.refFormik}
                            initialValues={this.state.initForm}
                            validationSchema={validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched,
                                    handleSubmit,
                                } = props;

                                return (
                                    <Box>
                                        <form
                                            onSubmit={handleSubmit}
                                            onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
                                        >

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.name}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                    name="name"
                                                    label="Наименование группы"
                                                    placeholder="Введите наименование группы"
                                                    variant="outlined"
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.discount}
                                                    error={Boolean(touched.discount && errors.discount)}
                                                    helperText={touched.discount && errors.discount}
                                                    name="discount"
                                                    type="number"
                                                    label="Коэффициент скидки"
                                                    placeholder="Введите коэффициент скидки"
                                                    variant="outlined"
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>

                                        </form>
                                    </Box>
                                )
                            }}
                        </Formik>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <Button sx={{ borderRadius: "4px", textTransform: "initial" }} variant="outlined" onClick={this.onCancel}>
                                Отмена
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button sx={{ borderRadius: "4px", textTransform: "initial" }} variant="contained" onClick={this.onSuccess}>
                                { isCreated ? "Создать" : "Сохранить" }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        )
    }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Заполните наименование группы'),
    discount: Yup.string().required('Заполните коэффициент скидки группы'),
});

export default DialogCreateGroup
