// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import CreatePayout from './CreatePayout';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(CreatePayout);
