import React from "react";
import {
	Autocomplete,
	TextField
} from "@mui/material";
import agent from "../../../agent/agent";

class FilterPaymentType extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			list: [],

			isLoad: false
		};
	}

	componentDidMount = async () => {

		if (Boolean(this.props.value)) {
			await this.getOptions();

			if (this.props.onChangeActiveFilterCountry) {
				this.props.onChangeActiveFilterCountry((this.state.list || []).find((t) => t.external_id === this.props.value)?.name);
			}
		}

	}

	getOptions = async () => {
		if ((this.state?.list || []).length > 0) {
			return null
		}

		this.setState({isLoad: true});

		const list = await agent.get('/smsgoapi/gotransactions/payment-methods').then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		});

		this.setState({
			list,
			isLoad: false
		});
	}

	onChange = (value) => {
		if (this.props.multiple) {
			const parseValue = [...value].map((item) => {
				return item?.[this.props.nameField] || item
			})
			this.props.onChange({
				target: {
					name: this.props.name,
					value: parseValue || []
				}
			});
		}

		if (!this.props.multiple) {
			this.props.onChange({
				target: {
					name: this.props.name,
					value: value?.[this.props.nameField] || null
				}
			});
			if (this.props.onChangeActiveFilterCountry) {
				this.props.onChangeActiveFilterCountry(value.payment_method_name);
			}
		}
	}

	_getOptionLabel = (row) => {
		const options = this.state.list;
		if (typeof row !== 'object') {
			const item = options.find((t) => String(t?.[this.props.nameField]) === String(row));

			if (!item) {
				return ""
			}

			return `${item?.payment_method_name}`
		}
		return `${row.payment_method_name}`
	}
	_filterOptions = (list, params) => {
		const inputValue = params?.inputValue || "";

		return [...list].filter((t) => {

			if (!inputValue) {
				return true
			}

			const nameLowerCase = t.payment_method_name.toLowerCase();
			const inputValueLowerCase = inputValue.toLowerCase();

			return Boolean(nameLowerCase.indexOf(inputValueLowerCase) > -1)

		})
	}

	render() {
		const {
			value,
			name,
			onChangeActiveFilterCountry,
			disableClearable,

			...rest
		} = this.props;
		const {
			list,
			isLoad
		} = this.state;

		return (
			<>

				<Autocomplete
					{...rest}

					value={value}
					disablePortal
					options={list}
					onChange={(event, value) => this.onChange(value, name)}
					name={name}
					variant="outlined"
					fullWidth
					disableClearable={disableClearable}
					autoComplete={false}
					loading={isLoad}
					onOpen={this.getOptions}
					limitTags={1}

					getOptionLabel={this._getOptionLabel}
					filterOptions={this._filterOptions}

					renderInput={(params) => <TextField
						{...params}
						label="Способ оплаты"
						fullWidth
						error={this.props.error}
						helperText={this.props.helperText}
					/>}
				/>

			</>
		);
	}

}

FilterPaymentType.defaultProps = {
	nameField: "payment_method_name",
	disableClearable: true
}

export default FilterPaymentType
