import React, { Component } from "react";
import {
    Container,

    Backdrop,
    CircularProgress
} from "@mui/material";
import {
    Table as TableComponent,
} from "./components";
import { Filter as FilterComponent } from './components';
import queryString from "query-string";
import agent from "../../../../agent/agent";
import moment from "moment";



const initFilter = {

    "filter[admin_user_id]": "",

};

class ChangeLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stats: [],

            pagination: {
                page: 1,
                totalPage: 1
            },
            filter: { ...initFilter },

            isLoad: true,
            initOpenFilter: false,
            isShowBackdrop: false
        };
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getListStats();
    }

    getListStats = async () => {

        await this.setState({ isLoad: true });

        const filter = this._getFilter();
        const response = await agent.get(`/smsgoapi/go-model-log${filter}`).then((res) => {
            return res
        }).catch((err) => {
            return {
                data: [],
                headers: {}
            }
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: response?.headers?.["x-pagination-page-count"] || 1
        };


        this.setState({
            stats: response.data || [],
            pagination,
            isLoad: false
        });
    }


    // Логика работы фильтров
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }
    filterChange = async (filter, isFastStart) => {

        await this.setState({ filter });

        if (!isFastStart) {
            return null
        }

        await this.getListStats();

    }
    filterReset = async () => {

        const filter = { ...initFilter };
        const pagination = { page: 1 };

        await this.setState({
            filter,
            pagination
        });

        await this.getListStats()

    }
    _getFilter = () => {

        const filter = { ...this.state.filter };
        const pagination = { ...this.state.pagination };

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {

                let value = filter[key];

                string.push(`${key}=${value}`);

            }
        });

        window.history.replaceState(null, null, `/changelog?${string.join("&")}`);

        return `?${string.join("&")}`
    }


    // Логика работы пагиации
    paginationChange = async (pagination) => {
        await this.setState({ pagination });

        await this.getListStats();
    }



    render() {
        const {
            stats,
            pagination,
            filter,

            isLoad,
            initOpenFilter,
            isShowBackdrop
        } = this.state;
        return (
            <Container maxWidth="xl">

                <FilterComponent
                    filter={filter}
                    initOpenFilter={initOpenFilter}

                    onChange={this.filterChange}
                    onReset={this.filterReset}
                    onSearch={this.getListStats}

                />
                <TableComponent
                    data={stats}
                    filter={filter}
                    pagination={pagination}

                    isLoad={isLoad}

                    onChangePagination={this.paginationChange}
                    onChangeFilter={this.filterChange}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="white" />
                </Backdrop>
            </Container>
        );
    }
}

export default ChangeLog
