import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  Typography,

  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import {

} from "@mui/styles";
import agent from "../../../../../../agent/agent";
import moment from "moment";

class DialogBalanceHistory extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      history: [],
      provider: null,
      isOpen: false,
      isLoad: true
    }
  }

  open = ({provider}) => {
    this.setState({
      provider,
      isOpen: true,
      isLoad: true,
    }, async () => {
      await this.getBalanceList();
    })
  }
  close = () => {
    this.setState({
      provider: null,
      isOpen: false,
      history: [],
    })
  }

  getBalanceList = async () => {
    const { provider } = this.state;
    const res = await agent.post(`/smsgoapi/go-provider/balance-list`, {
      filter: {provider_id: provider.id}
    }).then((res) => {
      return res.data || []
    }).catch(() => {
      return []
    });
    this.setState({
      history: res,
      isLoad: false
    })
  }

  render () {
    const {
      provider,
      history,
      isOpen,
      isLoad
    } = this.state;
    console.log('history: ', history);

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        onClose={this.close}
      >
        <DialogTitle sx={{ backgroundColor: "rgba(251, 120, 0, 1)" }}>
          <Typography variant="h3" sx={{ color: "white" }}>
            История баланса
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box py={2}>
            <ProviderInfo provider={provider}/>
            {Boolean(isLoad) && (<Typography variant="subtitle1">Загрузка...</Typography>)}
            {Boolean(!isLoad && history.length <= 0) && (<Typography variant="subtitle1">Нет данных</Typography>)}
            {Boolean(!isLoad && history.length > 0) && (<TableList history={history}/>)}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box px={2}>
            <Button variant="outlined" onClick={this.close}>
              Закрыть
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
}
const ProviderInfo = React.memo((props) => {
  const {
    provider
  } = props;

  return (
    <Box mb={2}>
      <Typography variant="h3">{ provider?.name }</Typography>
    </Box>
  )
});
const TableList = React.memo((props) => {
  const {
    history
  } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Сумма</TableCell>
          <TableCell>Дата</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {history.map((t, i) => (
          <TableRow>
            <TableCell>
              {t.amount}
            </TableCell>
            <TableCell>
              {moment(t.created_at).format("DD.MM.YYYY HH:mm:ss")}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
});

export default DialogBalanceHistory
