import React, {useState} from "react";
import {
	Box,
	Grid,
	Typography,
	Button,

	Collapse, TextField
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Clear as ClearIcon,
	Search as SearchIcon,
	PlusOne as PlusOneIcon,
	FilterAlt as FilterAltIcon,
	HighlightOff as HighlightOffIcon
} from "@mui/icons-material";
import {
	FilterAmountRange,
	FilterDateRange,
	FilterUsers,
	FilterDateRangeFull
} from "../../../../../../components";
import moment from "moment";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import {convertorDateToString} from "../../../../../../helper/convertor";

const Filter = (props) => {
	const {
		filter,
		initOpenFilter,

		onChange,
		onReset,
		onSearch
	} = props;
	const [isOpen, setOpen] = React.useState(true);
	const classes = useStyles();

	React.useEffect(() => {
		setOpen(initOpenFilter || true);
	}, [initOpenFilter]);

	const handleChangeDateRange = (values) => {
		let newFilter = {...filter};

		newFilter["filter[created_at][gt]"] = values[0] || null;
		newFilter["filter[created_at][lt]"] = values[1] || null;

		onChange(newFilter);
	}
	const handleChangeDiscountRange = (value) => {
		let newFilter = {...filter};

		newFilter["filter[discount][gt]"] = value[0];
		newFilter["filter[discount][lt]"] = value[1];

		onChange(newFilter);
	}
	const handleChangeBalanceRange = (value) => {
		let newFilter = {...filter};

		newFilter["filter[ref_balance][gt]"] = value[0];
		newFilter["filter[ref_balance][lt]"] = value[1];

		onChange(newFilter);
	}
	const handleChangeCommon = ({target}) => {
		const {name, value} = target;

		let newFilter = {...filter};
		newFilter[name] = value;

		onChange(newFilter);
	}

	const _activeFilters = () => {

		let items = [];

		if (filter["filter[created_at][gt]"] && filter["filter[created_at][lt]"]) {
			const start = convertorDateToString(filter["filter[created_at][gt]"]);
			const end = convertorDateToString(filter["filter[created_at][lt]"]);

			items.push({
				label: "Дата",
				value: `${start} - ${end}`,
				onClick: _deleteDateRange.bind(this, "filter[country]")
			})
		}
		if (filter["filter[discount][gt]"] && filter["filter[discount][lt]"]) {
			items.push({
				label: "Скидка",
				value: `${filter["filter[discount][gt]"]} - ${filter["filter[discount][lt]"]}`,
				onClick: _deleteCommonRange.bind(this, "discount")
			})
		}
		if (filter["filter[ref_balance][gt]"] && filter["filter[ref_balance][lt]"]) {
			items.push({
				label: "Баланс",
				value: `${filter["filter[ref_balance][gt]"]} - ${filter["filter[ref_balance][lt]"]}`,
				onClick: _deleteCommonRange.bind(this, "ref_balance")
			})
		}
		if (filter["filter[external_id]"] && filter["filter[external_id]"].length > 0) {
			items.push({
				label: "ID пользователей",
				value: filter["filter[external_id]"].join(", "),
				onClick: _deleteCommonArray.bind(this, "filter[external_id]")
			})
		}


		return items
	}
	const _deleteCommonArray = (name) => {
		let newFilter = {...filter};
		newFilter[name] = [];

		onChange(newFilter, true);
	}
	const _deleteCommon = (name) => {
		let newFilter = {...filter};
		newFilter[name] = "";

		onChange(newFilter, true);
	}
	const _deleteDateRange = () => {
		let newFilter = {...filter};
		newFilter["filter[created_at][gt]"] = null;
		newFilter["filter[created_at][lt]"] = null;

		onChange(newFilter, true);
	}
	const _deleteCommonRange = (name) => {
		let newFilter = {...filter};
		newFilter[`filter[${name}][gt]`] = "";
		newFilter[`filter[${name}][lt]`] = "";

		onChange(newFilter, true);
	}

	return (
		<Box mb={1}>

			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Typography variant="h4">Фильтры</Typography>
				</Grid>
				<Grid item>
					<Grid container spacing={1}>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								onClick={() => setOpen(!isOpen)}
								endIcon={<FilterAltIcon/>}
							>
								{isOpen ? "Свернуть" : "Развернуть"}
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								onClick={onReset}
								endIcon={<ClearIcon/>}
							>
								Сбросить
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								size="small"
								sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
								endIcon={<SearchIcon/>}
								onClick={onSearch}
							>
								Поиск
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Box my={1}/>

			<Collapse in={isOpen} timeout="auto" unmountOnExit>

				<Box px={2} py={2} bgcolor="white" borderRadius={4}>
					<Grid container spacing={4}>
						<Grid item sm={3}>
							<FilterUsers
								name="filter[external_id]"
								value={filter["filter[external_id]"]}
								size="small"
								fullWidth
								multiple


								onChange={handleChangeCommon}
							/>
						</Grid>
						<Grid
							item
							sm={3}

							className={classes.dateTimePicker}
						>
							<FilterDateRangeFull
								value={[
									filter['filter[created_at][gt]'],
									filter['filter[created_at][lt]'],
								]}

								onChange={handleChangeDateRange}
							/>
						</Grid>
						<Grid item sm={3}>
							<FilterAmountRange
								value={[filter["filter[discount][gt]"], filter["filter[discount][lt]"]]}
								labelAmountStart="Скидка (от)"
								labelAmountEnd="Скидка (до)"

								onChange={handleChangeDiscountRange}
							/>
						</Grid>
						<Grid item sm={3}>
							<FilterAmountRange
								value={[filter["filter[ref_balance][gt]"], filter["filter[ref_balance][lt]"]]}
								labelAmountStart="Баланс (от)"
								labelAmountEnd="Баланс (до)"

								onChange={handleChangeBalanceRange}
							/>
						</Grid>
					</Grid>
				</Box>

			</Collapse>

			{Boolean(_activeFilters().length > 0) && (
				<>

					<Box my={1}/>

					<Grid container spacing={2}>
						{_activeFilters().map((item, index) => (
							<Grid item>
								<Box className={classes.active} onClick={item.onClick}>
									<Typography className={classes.activeLabel}>{item.label}:</Typography>
									<Typography className={classes.activeValue}>{item.value}</Typography>
									<HighlightOffIcon sx={{color: "white", fontSize: 16, marginLeft: 1}}/>
								</Box>
							</Grid>
						))}
					</Grid>

				</>
			)}

		</Box>
	);
}

const useStyles = makeStyles(() => ({

	active: {
		display: "flex",
		alignItems: "center",
		padding: "2px 12px",
		borderRadius: 6,
		backgroundColor: "#FB7800",
		cursor: "pointer",

		fontSize: 15,
		lineHeight: "22px",
		fontWeight: "600"
	},
	activeLabel: {
		opacity: 0.6,
		fontSize: 15,
		lineHeight: "22px",
		fontWeight: "600",
		color: "white"
	},
	activeValue: {
		fontSize: 15,
		lineHeight: "22px",
		fontWeight: "600",
		color: "white",
		marginLeft: 6
	},

	dateTimePicker: {
		"& .daterangepicker": {
			"& .applyButton": {
				backgroundColor: "rgba(251,120,0, .8) !important",
				border: "none !important",
				"&:hover": {
					backgroundColor: "#FB7800 !important",
				}
			},
			"& .fromDateHourContainer": {
				border: "none !important",
			},
			"& .inputDate": {
				borderWidth: "2px !important",
				height: "33px !important",
				"&:focus": {
					borderColor: "#FB7800 !important",
					boxShadow: "none !important",
				}
			},
			"& .calendarAddon.input-group-addon": {
				borderWidth: "2px !important",
			},
			"& .multipleContentOnLine": {
				outline: "none !important",
				"& select": {
					height: "33px !important",
					borderRadius: "4px",
					borderWidth: "2px",
					outline: "none",
					borderColor: "#ccc",
					"&:focus": {
						borderColor: "#FB7800 !important",
						outline: "none !important",
					}
				}
			},
			"& .monthYearContainer": {
				alignItems: "center",
			},
			"& .timeIconStyle": {
				top: "50%",
				transform: "translate(0, -50%)",
			},
			"& .glyphicon": {
				color: "#FB7800 !important"
			},
			"& .rangecontainer": {
				"& div": {
					outline: "none !important",
				}
			},
			"& .calendarGrid": {
				"& div": {
					outline: "none !important",
				}
			}
		}
	}
}));

export default Filter
