import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Grid,
  Button,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
} from "@mui/material";
import {
  IMaskInput
} from "react-imask";
import {
  FilterUserGroups
} from "../../../../../../components";
import {
  Notification,
  NotificationTypes
} from "../../../../../../common/Notification";

const initForm = {
  name: '',
  active: false,
};

class PopUpForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {...initForm},
      onSubmit: null,

      isOpen: false,
      isEdit: false,
    }
  }

  open = ({ form, onSubmit } = {}) => {
    this.setState({
      form: form || initForm,
      onSubmit,
      isOpen: true,
      isEdit: Boolean(Object.keys(form || {}).length > 0)
    })
  }
  close = () => {
    this.setState({
      isOpen: false
    })
  }

  submitForm = () => {
    const form = {...this.state.form}
    let error = [];
    if (!form.name) {
      error.push("Заполните Наименование")
    }
    if (error.length > 0) {
      Notification({
        type: NotificationTypes.error,
        message: error.join(";</br>")
      })
      return
    }
    this.state.onSubmit(form, true)
  }
  changeForm = ({target}, boolVal) => {
    const { name, value } = target;
    let form = {...this.state.form};
    form[name] = (typeof boolVal !== "undefined") ? boolVal : value;
    this.setState({ form });
  }

  _title = () => {
    if (this.state.isEdit) {
      return "Редактиорвание группы"
    }
    return "Создание группы"
  }
  _buttonSubmitTitle = () => {
    if (this.state.isEdit) {
      return "Изменить"
    }
    return "Создать"
  }

  render () {
    const {
      form,
      isOpen,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        onClose={this.close}
      >
        <DialogTitle>
          <Typography variant="h3">{this._title()}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <TextField
                value={form.name}
                size="small"
                name="name"
                label="Наименование"
                fullWidth
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                checked={Boolean(form.active)}
                value={!Boolean(form.active)}
                name="active"
                label="Активный"
                control={<Checkbox />}
                onChange={this.changeForm}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button variant="outlined" size="small" onClick={this.close}>
                Закрыть
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" size="small" onClick={this.submitForm}>
                {this._buttonSubmitTitle()}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}
const CustomInputAmount = ({inputRef, ...rest}) => {

  const onComplete = (value) => {
    rest.onChange({
      target: {
        name: rest.name,
        value
      }
    })
  }

  return (
    <IMaskInput
      ref={inputRef}
      mask={Number}
      thousandsSeparator=""
      radix="."
      mapToRadix={[',']}
      scale={rest?.scale || 4}
      padFractionalZeros={true}

      {...rest}

      onComplete={onComplete}
      onChange={null}
    />
  )
}

export default PopUpForm
