import React, { Component } from "react";
import { Container } from "@mui/material";
import agent from "../../../../agent/agent";
import {
    Table as TableComponent,
    Filter as FilterComponent,
    DialogForm as DialogFormComponent
} from "./components";
import queryString from "query-string";
import urls from '../../../../variables/urls';
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";

const initFilter = {
    "filter[country_id]": "",
    "filter[service_id]": null,

    "sort": "",
};


class NotAvalibleNumbers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            services: [],
            allServices: [],
            allCountries: [],

            filter: { ...initFilter },
            pagination: {
                page: 1,
                totalPage: 1
            },

            activeFilterCountry: "",

            isLoad: true,
            initOpenFilter: false,
        };

        this.refDialogForm = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getServicesStatusList();

        // Получение списка доп данных
        await this.getCountry();
        await this.getService();
    }

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    filterChange = async (filter, isFastStart) => {
        await this.setState({ filter });

        if (!isFastStart) {
            return null
        }

        await this.getServicesStatusList();
    }

    getServicesStatusList = async () => {
        await this.setState({ isLoad: true });

        const filter = this._getFilter();
        const response = await agent.get(`${urls.getNotAvalibleNumberList}${filter}`).then((res) => {
            return res
        }).catch((err) => {
            return {
                data: [],
                headers: {}
            }
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: response?.headers?.["x-pagination-page-count"] || 1
        };

        this.setState({
            services: response.data || [],
            pagination,
            isLoad: false
        });
    }

    _getFilter = () => {
        const filter = { ...this.state.filter };
        const pagination = { ...this.state.pagination };


        let string = [
            `page=${pagination.page}`
        ];

        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);

            }
        });

        window.history.replaceState(null, null, `/services-statuses?${string.join("&")}`);

        return `?${string.join("&")}`
    }

    filterReset = async () => {

        const filter = { ...initFilter };
        const pagination = { page: 1 };

        await this.setState({
            filter,
            pagination
        });

        await this.getServicesStatusList();

    }

    getCountry = async () => {

        const data = await agent.get(`/api/country`).then((res) => {
            return res.data
        }).catch((err) => {
            return []
        });

        this.setState({
            allCountries: data
        })

    }
    getService = async () => {

        const data = await agent.get(`/api/service/get-all?v=${Date.now()}`).then((res) => {
            return res.data
        }).catch((err) => {
            return []
        });


        this.setState({
            allServices: data
        })

    }

    runCheck = async (form, isSubmit) => {
        const services = form?.services || '';
        const countries = form?.countries || '';


        if (!isSubmit) {
            this.refDialogForm.current.open({
                eventSubmit: this.runCheck.bind(this)
            });
            return
        }

        this.refDialogForm.current.close();

        const initForm = {
            "country_external_id": (form?.countries || []).map((item) => item).join(","),
            "service_external_id": (form?.services || []).map((item) => item).join(","),
        };

        let reqString = Object.entries(initForm).map(([key, value]) => {
            if (!!value) {
                return `${key}=${value}`
            }
        }).filter((t) => !!t).join('&');


        const response = await agent.get(`/api/service/check-services?${reqString}`)
            .then((res) => {
                return res
            }).catch((err) => {
                return {
                    data: [],
                    headers: {}
                }
            });

        Notification({ message: 'Проверка запущена', type: NotificationTypes.success })

        await this.getServicesStatusList();
    }

    paginationChange = async (pagination) => {
        await this.setState({ pagination });

        await this.getServicesStatusList();
    }

    filterChange = async (filter, isFastStart) => {
        await this.setState({ filter });

        if (!isFastStart) {
            return null
        }

        await this.getServicesStatusList();
    }

    render() {
        const {
            filter,
            services,
            activeFilterCountry,
            pagination,
            allCountries,
            allServices,

            isLoad,
            initOpenFilter
        } = this.state;
        return (
            <>
                <Container maxWidth="xl">
                    <FilterComponent


                        filter={filter}
                        activeFilterCountry={activeFilterCountry}
                        initOpenFilter={initOpenFilter}

                        runCheck={this.runCheck}
                        onChange={this.filterChange}
                        onReset={this.filterReset}
                        onSearch={this.getServicesStatusList}

                        onChangeActiveFilterCountry={(activeFilterCountry) => this.setState({ activeFilterCountry })}
                    />

                    <TableComponent
                        data={services}
                        filter={filter}
                        activeFilterCountry={activeFilterCountry}
                        pagination={pagination}
                        isLoad={isLoad}
                        onChangeFilter={this.filterChange}
                        onChangePagination={this.paginationChange}
                    />


                </Container>

                <DialogFormComponent
                    ref={this.refDialogForm}

                    countries={allCountries}
                    services={allServices}
                />
            </>
        );
    }
}

export default NotAvalibleNumbers
