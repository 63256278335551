import React from "react";
import {
	Box,
	Grid,

	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Typography,

	Pagination,

	Skeleton
} from "@mui/material";
import moment from "moment";

const TableCustom = (props) => {
	const {
		data,
		pagination,

		isLoad,

		onChangePagination,
	} = props;

	const handleChangePage = (event, page) => {
		let newPagination = {...pagination};
		newPagination.page = page;

		onChangePagination(newPagination)
	}


	const _getContactUser = (row) => {
		const user = row?.number?.user || {};
		const cms_user = user?.cms_user || {};
		const userLink = `/users?page=1&filter[external_id]=${cms_user.id}`;

		return `
        Email: ${cms_user?.email || "Не заполнено"},<br/> 
        Телефон: ${cms_user?.phone || "Не заполнено"},<br/>
        <a target="_blank" href="${userLink}" style="color: #fb7800;text-decoration: underline;">ID пользователя: ${cms_user.id || "Не заполнено"}</a>,<br/>
        `
	}
	const _getContactNumber = (row) => {
		const number = row?.number || {};
		const smsCode = number?.code || "Н/Д";

		return (
			<Grid container>
				<Grid item xs={12}>
					Статус: {number.activation_status},
				</Grid>
				<Grid item xs={12}>
					СМС: {smsCode},
				</Grid>
				<Grid item xs={12}>
					ИД поставщика: {number?.provider_id || 'Н/Д'},
				</Grid>
				<Grid item xs={12}>
					Страна: {number?.country?.name || "Не известно"},
				</Grid>
				<Grid item xs={12}>
					Цена: {number?.price},
				</Grid>
				<Grid item xs={12}>
					Стоимость: {number?.original_price},
				</Grid>
				<Grid item xs={12}>
					Номер: {number?.phone_number},
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={1} alignItems="center">
						<Grid item>
							Сервис:
						</Grid>
						<Grid item>
							<Box display="flex" alignItems="center">
								<img src={number.service?.icon} style={{width: 24, height: 24}}/>
								<span style={{marginLeft: "6px"}}>{number.service?.name}</span>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
	const _getIpAddressCountry = (row) => {
		return row?.user_info?.ip || "Не известно"
	}
	const _getDate = (row) => {
		const transaction = row?.transaction?.[0] || {};
		const createdAt = transaction.createdAt || transaction.created_at;

		if (!createdAt) {
			return "-"
		}
		return moment(createdAt).format("DD.MM.YYYY")
	}
	const _getTime = (row) => {
		const transaction = row?.transaction?.[0] || {};
		const createdAt = transaction.createdAt || transaction.created_at;

		if (!createdAt) {
			return "-"
		}
		return moment(createdAt).format("HH:mm:ss")
	}

	return (
		<>

			<Table>

				<TableHead>
					<TableRow>
						<TableCell>
							Контактные данные пользователя
						</TableCell>
						<TableCell width={320}>
							Информация о номере
						</TableCell>
						<TableCell>
							IP-адрес пользователя (+ код страны)
						</TableCell>
						<TableCell>
							Дата
						</TableCell>
						<TableCell>
							Время
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>

					{

						Boolean(isLoad) ? (

							<>

								{
									[0, 1, 2, 3, 4, 5].map((item) => (

										<TableRow key={`row-item-load-${item}`}>
											<TableCell><Skeleton/></TableCell>
											<TableCell><Skeleton/></TableCell>
											<TableCell><Skeleton/></TableCell>
											<TableCell><Skeleton/></TableCell>
											<TableCell><Skeleton/></TableCell>
										</TableRow>

									))
								}

							</>

						) : (

							<>

								{

									data.map((row, index) => (
										row.map((item, idx) => (
											<TableRow key={`row-item-${index}-${idx}`}>
												<TableCell>
													<Typography
														dangerouslySetInnerHTML={{
															__html: _getContactUser(item)
														}}
													/>
												</TableCell>
												<TableCell>
													{_getContactNumber(item)}
												</TableCell>
												<TableCell>
													{_getIpAddressCountry(item)}
												</TableCell>
												<TableCell>
													{_getDate(item)}
												</TableCell>
												<TableCell>
													{_getTime(item)}
												</TableCell>
											</TableRow>
										))
									))

								}

							</>

						)

					}

				</TableBody>

			</Table>

			<Box mt={3}/>

			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Pagination
						page={Number(pagination.page)}
						count={Number(pagination.totalPage)}

						onChange={handleChangePage}
					/>
				</Grid>
				<Grid item></Grid>
			</Grid>

		</>
	)
}

export default TableCustom
