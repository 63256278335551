import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,


    Typography,
    Button,
    Grid,
    Box, TextField
} from "@mui/material";
import {

    FilterUsers
} from "../../../../../../components";
import * as Yup from "yup";
import {Formik} from "formik";
import agent from "../../../../../../agent/agent";

const pageInitForm = {

    user_id: '',
    amount: '',
};

class DialogCreateGroup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            recomendedAmount: null,

            initForm: {},

            open: false,

            eventSubmit: null
        }

        this.refFormik = React.createRef();
    }


    onSubmit = (form) => {
        this.state.eventSubmit(form);
    }

    changeForm = ({target}) => {
        const {name, value} = target;

        let newForm = {...this.refFormik.current.values};
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    }

    open = async ({initForm, eventSubmit} = {}) => {
        await this.setState({
            open: true,
            eventSubmit
        });

        this.refFormik.current.setValues(initForm || pageInitForm);
    }
    close = () => {
        this.setState({
            open: false
        })
    }

    getAmount = async ({target}) => {
        const res = await agent.get(`/smsgoapi/referrer-payout/available-payout?user_id=${target.value}`)
            .then((res) => {
                return res.data
            }).catch((err) => {
                return {error: err.response}
            });

        this.changeForm({target});
        this.setState({
            recomendedAmount: res?.amount || 0,
        })
    }

    onSuccess = () => {
        this.setState({
            recomendedAmount: null,
        })
        this.refFormik.current.handleSubmit()
    }
    onCancel = () => {
        this.close()
    }

    render() {
        const {
            open,
            recomendedAmount
        } = this.state;

        return (
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                disablePortal={true}
                PaperProps={{
                    sx: {overflow: "initial"}
                }}

                onClose={this.onCancel}
            >

                <DialogTitle sx={{backgroundColor: "rgba(251, 120, 0, 1)"}}>
                    <Typography variant="h3" sx={{color: "white"}}>
                        {"Создание записи"}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box px={1} py={3}>
                        <Formik
                            innerRef={this.refFormik}
                            initialValues={this.state.initForm}
                            validationSchema={validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched,
                                    handleSubmit,
                                } = props;

                                if (Object.keys(values || {}).length <= 0) {
                                    return null
                                }

                                return (
                                    <Box>
                                        <form
                                            onSubmit={handleSubmit}
                                            onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
                                        >

                                            <Box mb={4}>
                                                <FilterUsers
                                                    value={values?.user_id || null}
                                                    name="user_id"
                                                    size="small"
                                                    fullWidth

                                                    error={Boolean(touched.user_id && errors.user_id)}
                                                    helperText={touched.user_id && errors.user_id}

                                                    onChange={this.getAmount}
                                                />
                                            </Box>

                                            <Box mb={4}>
                                                <TextField
                                                    value={values.amount}
                                                    error={Boolean(touched.amount && errors.amount)}
                                                    helperText={touched.amount && errors.amount}
                                                    name="amount"
                                                    type="number"
                                                    label="Введите сумму"
                                                    placeholder={`Доступный баланс: ${recomendedAmount}`}
                                                    variant="outlined"
                                                    disabled={recomendedAmount === null}
                                                    fullWidth

                                                    onChange={this.changeForm}
                                                />
                                            </Box>


                                        </form>
                                    </Box>
                                )
                            }}
                        </Formik>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="outlined"
                                    onClick={this.onCancel}>
                                Отмена
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="contained"
                                    onClick={this.onSuccess}>
                                {"Создать"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        )
    }
}

const validationSchema = Yup.object().shape({
    // user_id: Yup.string().required('Выберите пользователя'),
    amount: Yup.string().required('Выберите пользователя и введите сумму'),
});

export default DialogCreateGroup
