import React from "react";
import {
	Box,
	Grid,
	Button,
	Checkbox,
	TextField,
	Typography,
	Autocomplete,
	FormControlLabel,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import {
	Formik
} from "formik";
import {
	IMaskInput
} from "react-imask";
import {
	FilterUsers,
	FilterProvider
} from "../../../../../../components";
import * as Yup from "yup";
import agent from "../../../../../../agent/agent";

const pTypeOptions = require("../../../../../../constants/providerPTypes.json").options || [];

const initForm = {
	"name": "",
	"alias": "",
	"adapter": "",
	"base_url": "",
	"api_token": "",
	"client_id": "", // API KEY 2
	"user_id": null,
	"ptype": "",
	"priority": "0",
	"rent_priority": "0",
	"active": true,
	"req_timeout": "4",
	"retry_count": "1",
	"copy_provider_id": "",
	"create_user_hub": true,
	"copy_provider_countries": true,
	"copy_provider_services": true,
	"copy_provider_prices": true
};
class DialogProviderCreate extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			adapters: [],

			form: {...initForm},

			isOpen: false,

			onSubmit: null
		};
		this.refFormik = React.createRef();
		this.refProviderSelect = React.createRef();
	}

	open = ({ onSubmit }) => {
		this.setState({
			isOpen: true,
			form: {...initForm},
			onSubmit,
		}, async () => {
			await this.getAdapters();
		})
	}
	close = () => {
		this.setState({
			form: {...initForm},
			isOpen: false,
			onSubmit: null
		})
	}

	getAdapters = async () => {
		const { adapters } = this.state;
		if (adapters.length > 0) {
			return null
		}

		const res = await agent.get('/smsgoapi/go-provider/adapters').then((res) => {
			return res.data
		}).catch(() => {
			return []
		});
		this.setState({ adapters: res });
	}

	changeForm = ({ target }) => {
		if (!this.refFormik.current) {
			return
		}

		const { name, value } = target;
		let values = this.refFormik.current.values;
		values[name] = value;

		if (name === 'adapter') {
			values.user_id = "";
		}

		this.refFormik.current.setValues(values);
	}
	submitForm = (values) => {
		let body = {...values}

		if (!body?.user_id) {
			delete body.user_id
		}
		if (!body?.copy_provider_id) {
			delete body.copy_provider_id
		}

		if (body?.user_id) {
			body.user_id = Number.parseFloat(body?.user_id);
		}
		if (body?.copy_provider_id) {
			body.copy_provider_id = Number.parseFloat(body?.copy_provider_id);
		}
		if (body?.priority) {
			body.priority = Number.parseFloat(body?.priority);
		}
		if (body?.rent_priority) {
			body.rent_priority = Number.parseFloat(body?.rent_priority);
		}
		if (body?.req_timeout) {
			body.req_timeout = Number.parseFloat(body?.req_timeout);
		}
		if (body?.retry_count) {
			body.retry_count = Number.parseFloat(body?.retry_count);
		}

		this.state.onSubmit(body, true);
	}

	_filterUserId = (list, params) => {
		list = [...list].filter((t) => t.adapter === this.refFormik.current.values?.adapter);
		return list
	}

	render () {
		const {
			adapters,
			form,
			isOpen
		} = this.state;

		if (!isOpen) {
			return null
		}
		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="md"
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h3">Создание провайдера</Typography>
				</DialogTitle>
				<DialogContent>
					<Formik
						innerRef={this.refFormik}
						initialValues={form}
						validationSchema={validationSchema}
						onSubmit={this.submitForm}
					>{(props) => {
						const {
							values,
							errors,
							touched,
							handleSubmit
						} = props;
						console.log('errors: ', errors);

						return (
							<Grid container spacing={2} pt={1}>
								<Grid item xs={12} container justifyContent="space-between" alignItems="center" mb={-1}>
									<Grid item>
										<Typography variant="h5">Основная информация</Typography>
									</Grid>
									<Grid item>
										<FormControlLabel
											value={values.active}
											checked={values.active}
											control={<Checkbox />}
											label="Активный"
											onChange={() => this.changeForm({target: {name: 'active', value: !Boolean(values.active)}})}
										/>
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<TextField
										value={values.name}
										name="name"
										label="Наименование"
										placeholder="..."
										size="small"
										fullWidth
										error={Boolean(errors.name && touched.name)}
										helperText={touched.name && errors.name}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										value={values.alias}
										name="alias"
										label="Алиас"
										placeholder="..."
										size="small"
										fullWidth
										error={Boolean(errors.alias && touched.alias)}
										helperText={touched.alias && errors.alias}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										value={values.base_url}
										name="base_url"
										label="Базовый URL"
										placeholder="..."
										size="small"
										fullWidth
										error={Boolean(errors.base_url && touched.base_url)}
										helperText={touched.base_url && errors.base_url}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										value={values.api_token}
										name="api_token"
										label="API токен"
										placeholder="..."
										size="small"
										fullWidth
										error={Boolean(errors.api_token && touched.api_token)}
										helperText={touched.api_token && errors.api_token}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										value={values.client_id}
										name="client_id"
										size="small"
										label="API KEY 2"
										fullWidth
										onChange={this.changeForm}
									/>
								</Grid>

								<Grid item xs={3}>
									<TextField
										value={values.priority}
										name="priority"
										label="Приоритет"
										placeholder="..."
										size="small"
										fullWidth
										error={Boolean(errors.priority && touched.priority)}
										helperText={touched.priority && errors.priority}
										inputProps={{scale: 0}}
										InputProps={{inputComponent: CustomInputAmount}}
										InputLabelProps={{ shrink: true }}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										value={values.rent_priority}
										name="rent_priority"
										label="Приоритет аренды"
										placeholder="..."
										size="small"
										fullWidth
										error={Boolean(errors.rent_priority && touched.rent_priority)}
										helperText={touched.rent_priority && errors.rent_priority}
										inputProps={{scale: 0}}
										InputProps={{inputComponent: CustomInputAmount}}
										InputLabelProps={{ shrink: true }}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										value={values.req_timeout}
										name="req_timeout"
										label="Время ожидания ответа (секунды)"
										placeholder="..."
										size="small"
										fullWidth
										error={Boolean(errors.req_timeout && touched.req_timeout)}
										helperText={touched.req_timeout && errors.req_timeout}
										inputProps={{scale: 0}}
										InputProps={{inputComponent: CustomInputAmount}}
										InputLabelProps={{ shrink: true }}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										value={values.retry_count}
										name="retry_count"
										label="Кол-во попыток обращения"
										placeholder="..."
										size="small"
										fullWidth
										error={Boolean(errors.retry_count && touched.retry_count)}
										helperText={touched.retry_count && errors.retry_count}
										inputProps={{scale: 0}}
										InputProps={{inputComponent: CustomInputAmount}}
										InputLabelProps={{ shrink: true }}
										onChange={this.changeForm}
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography variant="h5">Настройки провайдера</Typography>
								</Grid>
								<Grid item xs={12}>
									<AdapterSelect
										value={values.adapter}
										name="adapter"
										options={adapters}
										error={Boolean(errors.adapter && touched.adapter)}
										helperText={touched.adapter && errors.adapter}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<FilterProvider
										ref={this.refProviderSelect}

										value={values.copy_provider_id}
										name="copy_provider_id"
										size="small"
										label="Провайдер"
										disabled={!Boolean(values.adapter)}
										onChange={this.changeForm}
										error={Boolean(errors.copy_provider_id && touched.copy_provider_id)}
										helperText={touched.copy_provider_id && errors.copy_provider_id}
										customFilterOptions={this._filterUserId}
									/>
								</Grid>
								<Grid item xs={6}>
									<PTypeSelect
										value={values.ptype}
										name="ptype"
										options={pTypeOptions}
										disabled={!Boolean(values.adapter)}
										error={Boolean(errors.ptype && touched.ptype)}
										helperText={touched.ptype && errors.ptype}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={6}>
									<FilterUsers
										value={values.user_id}
										name="user_id"
										size="small"
										label="Пользователь"
										valueName="id"
										disabled={!Boolean(values.adapter)}
										onChange={this.changeForm}
										error={Boolean(errors.user_id && touched.user_id)}
										helperText={touched.user_id && errors.user_id}
										customFilterOptions={this._filterUserId}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControlLabel
										value={values.create_user_hub}
										checked={values.create_user_hub}
										control={<Checkbox />}
										label="Создать пользователя в hub.1grizzly?"
										onChange={() => this.changeForm({target: {name: 'create_user_hub', value: !Boolean(values.create_user_hub)}})}
									/>
									<FormControlLabel
										value={values.copy_provider_countries}
										checked={values.copy_provider_countries}
										control={<Checkbox />}
										label="Копировать страны?"
										onChange={() => this.changeForm({target: {name: 'copy_provider_countries', value: !Boolean(values.copy_provider_countries)}})}
									/>
									<FormControlLabel
										value={values.copy_provider_services}
										checked={values.copy_provider_services}
										control={<Checkbox />}
										label="Копировать сервисы?"
										onChange={() => this.changeForm({target: {name: 'copy_provider_services', value: !Boolean(values.copy_provider_services)}})}
									/>
									<FormControlLabel
										value={values.copy_provider_prices}
										checked={values.copy_provider_prices}
										control={<Checkbox />}
										label="Копировать цены?"
										onChange={() => this.changeForm({target: {name: 'copy_provider_prices', value: !Boolean(values.copy_provider_prices)}})}
									/>
								</Grid>

								<Grid container justifyContent="flex-end" spacing={1}>
									<Grid item>
										<Button
											variant="outlined"
											sx={{padding: "8px 20px", height: "auto", textTransform: "initial"}}
											onClick={this.close}
										>Отменить</Button>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											sx={{padding: "8px 20px", height: "auto", textTransform: "initial"}}
											onClick={handleSubmit}
										>Создать</Button>
									</Grid>
								</Grid>

							</Grid>
						)
					}}</Formik>
				</DialogContent>
			</Dialog>
		)
	}
};
const AdapterSelect = React.memo((props) => {
	const {
		error,
		helperText
	} = props;

	const handleChange = (event, value) => {
		props.onChange({
			target: {
				name: props.name,
				value: value
			}
		});
	}

	return (
		<Autocomplete
			{...props}
			fullWidth
			size="small"
			onChange={handleChange}

			renderInput={(params) => <TextField error={error} helperText={helperText} {...params} label="Адаптер" />}
		/>
	)
});
const PTypeSelect = React.memo((props) => {
	const {
		error,
		helperText
	} = props;

	const handleChange = (event, value) => {
		props.onChange({
			target: {
				name: props.name,
				value: value?.value
			}
		});
	}
	const _getOptionLabel = (option) => {
		if (!option) {
			return ''
		}
		if (option?.name) {
			return option?.name
		}
		return (props?.options || []).find((t) => t.value === option)?.name || ''
	}

	return (
		<Autocomplete
			{...props}
			fullWidth
			size="small"
			getOptionLabel={_getOptionLabel}
			renderInput={(params) => <TextField error={error} helperText={helperText} {...params} label="Тип провайдера" />}
			onChange={handleChange}
		/>
	)
});
const CustomInputAmount = ({inputRef, ...rest}) => {

	const onComplete = (value) => {
		rest.onChange({
			target: {
				name: rest.name,
				value
			}
		})
	}

	return (
		<IMaskInput
			ref={inputRef}
			mask={Number}
			thousandsSeparator=""
			scale={rest?.scale || 4}
			min={0}

			{...rest}

			onComplete={onComplete}
			onChange={null}
		/>
	)
}

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Заполните поле'),
	alias: Yup.string().required('Заполните поле'),
	priority: Yup.string().required('Заполните поле'),
	req_timeout: Yup.string().required('Заполните поле'),
	retry_count: Yup.string().required('Заполните поле'),
	rent_priority: Yup.string().required('Заполните поле'),
	adapter: Yup.string().required('Заполните поле'),
	copy_provider_id: Yup.string().required('Заполните поле'),
	ptype: Yup.string().required('Заполните поле'),
});

export default DialogProviderCreate
export {
	AdapterSelect
}
