import React from "react";
import {
	Table,
	TableRow,
	TableCell,
	TableHead,
	TableBody,

	Box,
	Grid,
	Tooltip,
	Skeleton,
	Pagination,
	IconButton
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Edit as EditIcon
} from "@mui/icons-material";
import moment from "moment";
import {convertorNumber} from "../../../../../../helper/convertor";

const statusList = {
	new: {label: 'Новый', color: '#1881E1'},
	refuse: {label: 'Отклонен', color: '#E13118'},
	success: {label: 'Выполнен', color: '#62AF3E'},
};

const TableCustom = (props) => {
	const {
		items,
		filter,
		totalPages,
		isLoading,

		onChangeFilter,
		onChangeStatus
	} = props;

	const handleChangePage = (event, newPage) => {
		let _filter = {...filter};
		_filter.page = newPage;
		onChangeFilter(_filter, true);
	}

	return (
		<>

			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Пользователь</TableCell>
						<TableCell>Провайдер</TableCell>
						<TableCell>Валюта</TableCell>
						<TableCell>Кошелек</TableCell>
						<TableCell>Сумма к выводу</TableCell>
						<TableCell align="center">Статус</TableCell>
						<TableCell align="right" width="180px">Дата обновления</TableCell>
						<TableCell align="right" width="180px">Дата создания</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<VisibleContent visible={!isLoading}>
						{items.map((item) => (
							<RowItem
								item={item}
								onChange={onChangeStatus.bind(this, item, false)}
							/>
						))}
					</VisibleContent>
					<VisibleContent visible={isLoading}>
						<RowsLoading/>
					</VisibleContent>
				</TableBody>
			</Table>

			<Box mt={2}/>

			<Grid container justifyContent="flex-end">
				<Pagination
					page={Number.parseFloat(filter.page)}
					count={Number.parseFloat(totalPages)}
					onChange={handleChangePage}
				/>
			</Grid>
		</>
	)
}
const RowItem = (props) => {
	const {
		item,
		onChange
	} = props;
	const classes = useStyles();
	const status = statusList[item.status];
	const isAvaibleChange = Boolean(item.status === "new");

	return (
		<TableRow>
			<TableCell>{item?.user?.email || "Н/Д"}</TableCell>
			<TableCell>{item?.provider?.name || "Н/Д"}</TableCell>
			<TableCell>
				<div className={classes.informationItems}>
					<div>
						<div className="--label">Тип валюты</div>
						<div className="--value">{ item?.currency }</div>
					</div>
					<div>
						<div className="--label">Курс валюты</div>
						<div className="--value">{ item?.currency_rate }</div>
					</div>
				</div>
			</TableCell>
			<TableCell>
				<div className={classes.informationItems}>
					<div>
						<div className="--label">Тип кошелька</div>
						<div className="--value">{item?.payout_type}</div>
					</div>
					<div>
						<div className="--label">Адрес кошелька</div>
						<div className="--value">{item?.wallet_address}</div>
					</div>
				</div>
			</TableCell>
			<TableCell>
				<div className={classes.informationItems}>
					<div>
						<div className="--label">Сумма</div>
						<div className="--value">{convertorNumber((item.amount / item.currency_rate), 2, '.')}</div>
					</div>
					<div>
						<div className="--label">Сумма в рублях</div>
						<div className="--value">{item.amount}</div>
					</div>
				</div>
			</TableCell>
			<TableCell align="center">
				<div
					className={classes.status}
					style={{background: status.color}}
				>
					{status.label}
				</div>
			</TableCell>
			<TableCell align="right">{moment(item.updated_at).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
			<TableCell align="right">{moment(item.created_at).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
			<TableCell align="right">
				<Tooltip title="Измнеить статус вывода" arrow>
					<IconButton
						disabled={!isAvaibleChange}
						color="primary"
						onClick={onChange}
					>
						<EditIcon />
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	)
}
const RowsLoading = () => {
	return new Array(10).fill(1).map((t) => {
		return (
			<TableRow>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
				<TableCell><Skeleton/></TableCell>
			</TableRow>
		)
	})
}
const VisibleContent = ({ visible, children }) => {
	if (!visible) return null
	return children
}

const useStyles = makeStyles(() => ({
	status: {
		width: "fit-content",
		margin: "0 auto",
		padding: "10px 14px",
		boxSizing: "border-box",
		borderRadius: "8px",

		fontWeight: "600",
		fontSize: "15px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#FFFFFF",
	},
	informationItems: {
		display: "flex",
		flexDirection: "column",
		gap: "6px",

		"& .--label": {
			marginBottom: 2,
			fontSize: "12px",
			lineHeight: "100%",
			letterSpacing: "-0.02em",
			color: "#647083",
		},
		"& .--value": {
			fontSize: "14px",
			lineHeight: "130%",
			letterSpacing: "-0.02em",
			color: "#152435",
		}
	}
}));

export default TableCustom
