import React, {Component} from "react";
import {
	Backdrop,
	CircularProgress,
	Container
} from "@mui/material";
import {
	Table as TableComponent,
	Filter as FilterComponent,
	DialogEditUser as DialogEditUserComponent,
	DialogCreateUser as DialogCreateUserComponent,
	DialogTopUpBalance as DialogTopUpBalanceComponent,
	DialogUserInfo as DialogUserInfoComponent,
	DialogUserInfo,
	TelegramGroup,
	DialogLoyaltyProgram
} from "./components";
import {
	DialogConfirmAction
} from "../../../../components";
import agent from "../../../../agent/agent";
import urls from "../../../../variables/urls";
import moment from "moment";
import {
	Notification,
	NotificationTypes
} from "../../../../common/Notification";
import queryString from "query-string";
import DialogUserStatistics from './components/DialogUserStatistics';
import axios from "axios";

const initFilter = {
	"filter[created_at][gt]": null,
	"filter[created_at][lt]": null,

	"filter[discount][gt]": "",
	"filter[discount][lt]": "",

	"filter[balance][gt]": "",
	"filter[balance][lt]": "",

	"filter[external_id]": [],

	"sort": ""
};

class GoUsers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			users: [],
			countries: [],
			userGroupList: [],
			customPayments: [],

			pagination: {
				page: 1,
				totalPage: 1
			},
			filter: {...initFilter},

			isLoad: true,
			initOpenFilter: false,
			isShowBackdrop: false,
		};

		this.refTelegramGroup = React.createRef();
		this.refDialogUserInfo = React.createRef();
		this.refDialogEditUser = React.createRef();
		this.refDialogCreateUser = React.createRef();
		this.refDialogTopUpBalance = React.createRef();
		this.refDialogConfirmAction = React.createRef();
		this.refDialogUserStatistics = React.createRef();
		this.refDialogLoyaltyProgram = React.createRef();

		this.apiController = null;
	}

	componentDidMount = async () => {
		await this.initFilter();
		await this.getListTransactions();

		await this.getListUserGroup();
		await this.getCountry();
		await this.getCustomPayments();
	}
	initFilter = async () => {
		const locationSearch = this.props?.location?.search || "";

		let parseSearch = queryString.parse(locationSearch, {
			arrayFormat: "bracket"
		});

		const page = parseSearch.page || 1;
		delete parseSearch.page;

		let filter = {
			...initFilter,
			...parseSearch
		};
		let pagination = {
			...this.state.pagination,
			page: page
		};

		if (!!filter["filter[external_id]"] && typeof filter["filter[external_id]"] === "string") {
			filter['filter[external_id]'] = (filter["filter[external_id]"] || "").split(",")
		}


		await this.setState({
			filter,
			pagination,

			initOpenFilter: Object.keys(parseSearch || {}).length > 0
		});
	}

	getListTransactions = async () => {
		if (this.apiController) {
			this.apiController.abort();
		}

		await this.setState({isLoad: true});

		const filter = this._getFilter();
		const apiController = new AbortController();
		this.apiController = apiController;
		const response = await agent.get(`${urls.getUsersList}${filter}`, {
			signal: apiController.signal
		}).then((res) => {
			return res
		}).catch((err) => {
			return {
				data: [],
				headers: {},

				isCancel: Boolean(err.message)
			}
		});

		const pagination = {
			...this.state.pagination,
			totalPage: response?.headers?.["x-pagination-page-count"] || 1,
			totalCount: response?.headers?.["x-pagination-total-count"] || 0,
		};

		this.setState({
			users: response.data || [],
			pagination,
			isLoad: Boolean(response.isCancel)
		});
	}
	_getFilter = () => {

		const filter = {...this.state.filter};
		const pagination = {...this.state.pagination};

		let stringFrom = [
			`page=${pagination.page}`
		];
		let stringBack = [
			`page=${pagination.page}`
		];
		Object.keys(filter).map((key) => {
			const filterItem = filter[key];
			const isArray = Array.isArray(filterItem);

			if (Boolean(isArray) ? filter[key].length > 0 : filter[key]) {
				let value = filter[key];

				if (
					key === "filter[created_at][gt]" ||
					key === "filter[created_at][lt]"
				) {
					value = moment(value).format("YYYY-MM-DD HH:mm")
				}

				if (
					key === "filter[discount][gt]" ||
					key === "filter[discount][lt]"
				) {
					value = value.replace(/\D+/g, "");
				}

				if (
					key === "filter[balance][gt]" ||
					key === "filter[balance][lt]"
				) {
					value = value.replace(/\D+/g, "");
				}

				if (key === "filter[external_id]") {
					stringBack.push(`${key}=${(value || []).join(',')}`);
				} else {
					stringBack.push(`${key}=${value}`);
				}

				stringFrom.push(`${key}=${value}`);
			}
		});

		window.history.replaceState(null, null, `/users?${stringFrom.join("&")}`);

		return `?${stringBack.join("&")}`
	}

	onStartSearch = async () => {
		await this.cancelGetUserRequest();
		await this.setState({isLoad: true});
		await this.getListTransactions();
	}
	cancelGetUserRequest = async () => {}

	getListUserGroup = async () => {
		const data = await agent.get("/smsgoapi/go-discount-groups").then((res) => {
			return res.data
		}).catch((err) => {
			return []
		});

		this.setState({
			userGroupList: data
		})
	}
	getCountry = async () => {

		const data = await agent.get(`/api/country`).then((res) => {
			return res.data
		}).catch((err) => {
			return []
		});

		this.setState({
			countries: data
		})

	}

	filterChange = async (filter, isFastStart) => {

		await this.setState({filter});

		if (!isFastStart) {
			return null
		}

		await this.cancelGetUserRequest();
		await this.getListTransactions();

	}
	filterReset = async () => {

		const filter = {...initFilter};
		const pagination = {page: 1};

		await this.setState({
			filter,
			pagination
		});

		await this.cancelGetUserRequest();
		await this.getListTransactions();

	}

	paginationChange = async (pagination) => {
		await this.setState({pagination});

		await this.cancelGetUserRequest();
		await this.getListTransactions();
	}
	//Информация о пользователе
	openUserInfo = (user) => {
		const currentUser = {
			...user,
		}

		this.refDialogUserInfo.current.open({
			currentUser: currentUser,
		})
	}
	//Информация о статистике
	openUserStatistics = (user) => {
		const currentUser = {...user};

		this.refDialogUserStatistics.current.open({
			currentUser: currentUser,
		})
	}
	// Редактирование пользователя
	openEditUser = (user) => {
		const initForm = {
			...user,
			discount_group: user?.discount_group?.id || null
		};

		this.refDialogEditUser.current.open({
			initForm: initForm,
			eventSubmit: this.saveEditUser.bind(this)
		});
	}
	saveEditUser = async (user) => {
		this.refDialogEditUser.current.close();

		this.setState({isShowBackdrop: true});

		const form = {
			discount_group_id: user?.discount_group || null,
			discount: user?.discount,
			ref_param: user?.ref_param,
			ref_code: user?.ref_code,
			ref_coefficient: user?.ref_coefficient,
			is_wholesale: user?.is_wholesale,
		};

		const response = await agent.post(`/smsgoapi/gousers/update-discount/${user.external_id}`, form).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})

		Notification({
			message: "Пользователь успешно обновлен",
			type: NotificationTypes.success
		});

		this.setState({isShowBackdrop: false});

		await this.cancelGetUserRequest();
		await this.getListTransactions();
	}

	// Создание пользователя
	createUser = async (form) => {

		if (!form) {
			this.refDialogCreateUser.current.open({
				eventSubmit: this.createUser.bind(this)
			});

			return null
		}

		this.setState({isShowBackdrop: true});

		const responseCreate = await agent.post(`smsgoapi/gousers`, form).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (responseCreate.error) {
			this.setState({isShowBackdrop: false});

			let errorMessage = [];
			const errorData = responseCreate.error?.data || {};

			Object.keys(errorData).map((key) => {
				errorMessage.push(errorData[key]);
			});

			Notification({
				type: NotificationTypes.error,
				message: errorMessage.join('.\n')
			});

			return null
		}

		this.refDialogCreateUser.current.close();

		Notification({
			message: "Пользователь успешно создан",
			type: NotificationTypes.success
		})

		this.setState({isShowBackdrop: false});

		await this.cancelGetUserRequest();
		await this.getListTransactions();
	}

	// Обновление API ключа
	updateKeyUser = async (user, isConfirm) => {

		if (!isConfirm) {
			this.refDialogConfirmAction.current.open({
				title: "Подтверждение смены API ключа",
				message: "Вы действительно хотите изменить API ключ для пользователя?",
				onSuccess: this.updateKeyUser.bind(this, user, true)
			})

			return null
		}

		this.setState({isShowBackdrop: true});

		const response = await agent.post(`/smsgoapi/gousers/update-key/${user.external_id}`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (response.error) {
			this.setState({isShowBackdrop: false});

			Notification({
				type: NotificationTypes.error,
				message: response.error?.data?.message || "Ошибка сервера"
			});

			return null
		}

		Notification({
			type: NotificationTypes.success,
			message: "API ключ успешно обновлен"
		});

		this.setState({isShowBackdrop: false});
	}

	// Пополенение средств
	topUpBalance = async (userId, form) => {
		if (form === null) {
			this.refDialogTopUpBalance.current.open({
				eventSubmit: this.topUpBalance.bind(this),
				userId: userId
			})

			return null
		}

		this.setState({isShowBackdrop: true});

		const response = await agent.post(`/smsgoapi/gousers/add-balance/${userId}`, {
			...form
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (response.error) {
			this.setState({isShowBackdrop: false});

			Notification({
				type: NotificationTypes.error,
				message: response.error?.data?.message || "Ошибка сервера"
			})

			return
		}

		this.refDialogTopUpBalance.current.close();

		Notification({
			type: NotificationTypes.success,
			message: "Баланс успешно обновлен"
		})

		await this.cancelGetUserRequest();
		await this.getListTransactions();

		this.setState({isShowBackdrop: false});

	}

	// Изменение статуса пользователя
	changeActiveUser = async (user, value) => {

		this.setState({isShowBackdrop: true});

		const response = await agent.post(`/smsgoapi/gousers/update-status/${user?.external_id}`, {
			status: value
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (response.error) {
			this.setState({isShowBackdrop: false});

			Notification({
				type: NotificationTypes.error,
				message: response.error?.data?.message || "Ошибка сервера"
			})

			return
		}

		Notification({
			type: NotificationTypes.success,
			message: Boolean(value) ? "Пользователь успешно включен" : "Пользователь успешно отключен"
		});

		await this.cancelGetUserRequest();
		await this.getListTransactions();

		this.setState({isShowBackdrop: false});

	}

	// Вход в ЛС пользователя
	loginAccountUser = async (user) => {
		const userId = user?.external_id;
		const form = document.createElement('form');

		const host = window.location.host || '';
		const domain = host.replace('panel.', '');

		form.action = `https://${domain}/api/private/login-as?user_id=${userId}&domain=${domain}`;
		form.method = "POST";
		form.innerHTML = `<input name="token" value="${localStorage.getItem('token')}">`;
		document.body.append(form);
		form.submit();
	}

	openUserLoyaltyProgram = (user) => {
		this.refDialogLoyaltyProgram.current.open(user);
	}

	setTelegramInfo = async (user, params) => {
		if (!params) {
			this.refTelegramGroup.current.open({
				user,
				onSubmit: this.setTelegramInfo.bind(this)
			});
			return
		}
		this.setState({ isShowBackdrop: true });

		const res = await agent.post('/api/telegrambot/add-user-group', params).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response?.data?.message || 'Ошибка сервера'}
		});
		if (res?.error) {
			this.setState({ isShowBackdrop: false });
			Notification({
				message: res?.error,
				type: NotificationTypes.error
			})
			return
		}

		this.refTelegramGroup.current.close();
		this.setState({ isShowBackdrop: false });
		Notification({
			type: NotificationTypes.success,
			message: "Группа успешно присвоена"
		});
	}


	getCustomPayments = async () => {
		const _res = await agent.get('/smsgoapi/gousers/get-custom-payments')
			.then((res) => {
				return res.data || []
			}).catch(() => {
				return []
			});
		this.setState({
			customPayments: _res
		})
	}


	render() {
		const {
			users,
			countries,
			userGroupList,
			customPayments,

			pagination,
			filter,

			isLoad,
			initOpenFilter,

			isShowBackdrop
		} = this.state;
		const {
			userRole
		} = this.props;

		return (
			<Container maxWidth="xl">

				<FilterComponent
					filter={filter}
					initOpenFilter={initOpenFilter}
					userRole={userRole}
					totalCount={pagination?.totalCount}

					onChange={this.filterChange}
					onReset={this.filterReset}
					onSearch={this.onStartSearch}
					onCreate={() => this.createUser()}
				/>

				<TableComponent
					data={users}
					filter={filter}
					pagination={pagination}
					userRole={userRole}

					isLoad={isLoad}

					onChangePagination={this.paginationChange}
					onChangeFilter={this.filterChange}

					onOpenUserStatistics={this.openUserStatistics}
					onGetUserInfo={this.openUserInfo}
					onEdit={this.openEditUser}
					onUpdateKeyUser={this.updateKeyUser}
					onTopUpBalance={this.topUpBalance}
					onChangeActiveUser={this.changeActiveUser}
					onOpenUserLoyaltyProgram={this.openUserLoyaltyProgram}
					onLoginAccountUser={this.loginAccountUser}
					onSetTelegramInfo={this.setTelegramInfo}
				/>

				<DialogEditUserComponent
					ref={this.refDialogEditUser}

					groupList={userGroupList}
				/>

				<DialogCreateUserComponent
					ref={this.refDialogCreateUser}

					countries={countries}
				/>

				<DialogTopUpBalanceComponent
					ref={this.refDialogTopUpBalance}
					customPayments={customPayments}
				/>

				<DialogConfirmAction
					ref={this.refDialogConfirmAction}
				/>

				<DialogUserInfo
					ref={this.refDialogUserInfo}
				/>

				<DialogUserStatistics
					ref={this.refDialogUserStatistics}
				/>

				<DialogLoyaltyProgram
					ref={this.refDialogLoyaltyProgram}
				/>
				<TelegramGroup ref={this.refTelegramGroup}/>

				<Backdrop open={isShowBackdrop}>
					<CircularProgress color="inherit"/>
				</Backdrop>

			</Container>
		);
	}
}

export default GoUsers
