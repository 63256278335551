import React, {Component} from "react";
import {
    Container,
    Backdrop,
    CircularProgress
} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent
} from "./components";
import agent from "../../../../agent/agent";
import urls from "../../../../variables/urls";
import moment from "moment";
import queryString from "query-string";
import {downloadFile} from "../../../../helper/files";

const initFilter = {
    "filter[country]": "",
    "filter[service]": "",
    "filter[user_id]": "",
    "filter[provider_id]": "",
    "filter[activation_status][]": [],
    "filter[and][][not][service]": [],

    "filter[created_at][gt]": null,
    "filter[created_at][lt]": null,

    "filter[price][gt]": "",
    "filter[price][lt]": "",

    "sort": ""
};

class GoNumbers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transactions: [],

            pagination: {
                page: 1,
                totalPage: 1
            },
            filter: {...initFilter},

            activeFilterCountry: "",

            isLoad: true,
            isShowBackdrop: false
        };
        this.apiController = null;
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getListGoNumbers();
    }
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    getListGoNumbers = async () => {
        if (this.apiController) {
            this.apiController.abort();
        }
        await this.setState({isLoad: true});


        const filter = this._getFilter();
        const apiController = new AbortController();
        this.apiController = apiController;
        const response = await agent.get(`${urls.getGoNumbersList}${filter}`, {
            signal: apiController.signal
        }).then((res) => {
            return res
        }).catch((err) => {
            return {
                data: [],
                headers: {}
            }
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: response?.headers?.["x-pagination-page-count"] || 1,
            totalCount: response?.headers?.["x-pagination-total-count"] || 0,
            userCounts: response?.data?.userCounts || 0,
        };


        this.setState({
            transactions: response.data?.items || [],
            pagination,
            isLoad: false
        });
    }
    _getFilter = () => {

        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};
        const {users} = this.props;

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            const value = JSON.parse(JSON.stringify(filter[key]));
            if (['filter[country]', 'filter[service]', 'filter[user_id]', 'filter[provider_id]', 'sort'].includes(key) && value) {
                string.push(`${key}=${value}`)
            }
            if (['filter[created_at][gt]', 'filter[created_at][lt]'].includes(key) && !!value) {
                string.push(`${key}=${moment(value).format("YYYY-MM-DD HH:mm")}`);
            }
            if (['filter[price][gt]', 'filter[price][lt]'].includes(key) && !!value) {
                string.push(`${key}=${value.replace(/\D+/g, "")}`);
            }
            if (['filter[activation_status][]', 'filter[and][][not][service]'].includes(key) && value.length > 0) {
                value.map((_value) => {
                    string.push(`${key}=${_value}`);
                })
            }
        });

        window.history.replaceState(null, null, `/go-numbers?${string.join("&")}`);

        return `?${string.join("&")}`
    }

    filterChange = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getListGoNumbers();

    }
    filterReset = async () => {

        const filter = {...initFilter};
        const pagination = {page: 1};

        await this.setState({
            filter,
            pagination
        });

        await this.getListGoNumbers()

    }

    exportStatistics = async () => {
        this.setState({isShowBackdrop: true})

        const filter = this._getFilter();
        const documentLink = await agent.get(`${urls.getGoNumbersList}${filter}&return_excel=1`).then((res) => {
            return res.data
        }).catch((err) => {
            return ""
        });

        await downloadFile(documentLink);

        this.setState({isShowBackdrop: false})
    }

    paginationChange = async (pagination) => {
        await this.setState({pagination});

        await this.getListGoNumbers();
    }

    render() {
        const {
            transactions,
            pagination,
            filter,

            activeFilterCountry,

            isLoad,
            isShowBackdrop
        } = this.state;
        const {
          userRole
        } = this.props;

        return (
            <Container maxWidth="xl">

                <FilterComponent
                    filter={filter}
                    userRole={userRole}
                    activeFilterCountry={activeFilterCountry}
                    totalCount={pagination?.totalCount}
                    userCounts={pagination?.userCounts}

                    onChange={this.filterChange}
                    onReset={this.filterReset}
                    onSearch={this.getListGoNumbers}
                    onExportStatistics={this.exportStatistics}

                    onChangeActiveFilterCountry={(activeFilterCountry) => this.setState({activeFilterCountry})}
                />

                <TableComponent
                    data={transactions}
                    filter={filter}
                    pagination={pagination}

                    activeFilterCountry={activeFilterCountry}

                    isLoad={isLoad}

                    onChangePagination={this.paginationChange}
                    onChangeFilter={this.filterChange}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="white"/>
                </Backdrop>

            </Container>
        );
    }
}

export default GoNumbers
