import React from "react";
import {
    TextField,
    Box,
    Grid
} from "@mui/material";
import {
    IMaskInput
} from "react-imask";

class FilterAmountRange extends React.PureComponent {
    onChange = ({ index, value }) => {

        let newValue = [...this.props.value];
        newValue[index] = value;

        this.props.onChange(newValue)
    }

    changeAmountStart = ({ target }) => {
        this.onChange({ index: 0, value: target.value });
    }
    changeAmountEnd = ({ target }) => {
        this.onChange({ index: 1, value: target.value });
    }

    render() {
        const {
            labelAmountStart,
            labelAmountEnd,

            value
        } = this.props;
        const [
            amountStart,
            amountEnd
        ] = value;

        return (
            <>

                <Grid container alignItems="center">
                    <Grid item sx={{flex: 1}}>
                        <TextField
                            value={amountStart}
                            label={labelAmountStart || "Цена (от)"}
                            size="small"
                            fullWidth
                            InputProps={{
                                inputComponent: TextMaskCustom
                            }}
                            onChange={this.changeAmountStart}
                        />
                    </Grid>
                    <Grid item>
                        <Box sx={{ mx: 1 }}> — </Box>
                    </Grid>
                    <Grid item sx={{flex: 1}}>
                        <TextField
                            value={amountEnd}
                            label={labelAmountEnd || "Цена (до)"}
                            size="small"
                            fullWidth
                            InputProps={{
                                inputComponent: TextMaskCustom
                            }}
                            onChange={this.changeAmountEnd}
                        />
                    </Grid>
                </Grid>

            </>
        );
    }
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    const handleOnAccept = (value) => {
        onChange({ target: { name: props.name, value } })
    }

    return (
        <IMaskInput
            {...other}
            mask={Number}
            thousandsSeparator=" "
            radix="."

            inputRef={ref}
            onAccept={handleOnAccept}
            overwrite
        />
    );
});

FilterAmountRange.defaultProps = {
  value: ["", ""]
};

export default FilterAmountRange
